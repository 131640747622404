import React, { useState } from "react";
import { RoomItemFragment } from "generated/graphql";
import {
  generateStyleForItem,
  useRoomItemOverlaysIDs,
} from "../../hooks/useRoomItemOverlays";
import ItemActivityPreview from "../ItemActivityPreview/ItemActivityPreview";
import { fabricTypes } from "utils/fabric-impl";
import ItemActivityHoverCard from "./ItemActivityHoverCard/ItemActivityHoverCard";
import { useFloatingRootContext } from "@floating-ui/react";

import styles from "./RoomItemOverlay.module.css";
import ItemActivityTooltip from "./ItemActivityTooltip/ItemActivityTooltip";
import { useSelector } from "react-redux";
import {
  selectEnableResourceNameViewer,
  selectShowResourceNameOnItemHover,
} from "redux/settingsRedux";
import clsx from "clsx";
import { selectUserRole, UserRole } from "redux/userRedux";

type RoomItemOverlayProps = {
  roomItem: RoomItemFragment;
  thumbnailSrc?: string;
  backgroundImageSizeRef: React.MutableRefObject<
    { width: number; height: number } | undefined
  >;
  canvasRef: React.MutableRefObject<fabricTypes.Canvas | undefined>;
  updateThumbnailKey: (resourceId: string, thumbnailKey: string) => void;
  isEditingRoom?: boolean;
  editItemId?: string;
  editItemMoving?: string;
  isHovering?: boolean;
};

const useDataResourceName = (roomItem: RoomItemFragment) => {
  const { fileActivityItemId, websiteActivityItemId, albumItemId } =
    useRoomItemOverlaysIDs();

  switch (roomItem.id) {
    case fileActivityItemId:
      return "feelingsChartOverlay";
    case websiteActivityItemId:
      return "pencilCupOverlay";
    case albumItemId:
      return "albumOverlay";
  }
};

export const RoomItemOverlay = ({
  roomItem,
  thumbnailSrc,
  backgroundImageSizeRef,
  canvasRef,
  updateThumbnailKey,
  isEditingRoom,
  editItemId,
  editItemMoving,
  isHovering,
}: RoomItemOverlayProps) => {
  const [floatingReferenceElement, setFloatingReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [cardElement, setCardElement] = useState<HTMLElement | null>(null);
  const [tooltipElement, setTooltipElement] = useState<HTMLElement | null>(
    null
  );

  const isResourceNameViewerEnabled = useSelector(
    selectEnableResourceNameViewer
  );

  const showResourceNameOnItemHoverSetting = useSelector(
    selectShowResourceNameOnItemHover
  );

  const isProvider = useSelector(selectUserRole) === UserRole.THERAPIST;

  const dataResourceName = useDataResourceName(roomItem);

  const isHoveringThisItemOutsideEditMode = !isEditingRoom && isHovering;
  const isEditingThisItem = !!editItemId && roomItem.id === editItemId;
  const isActiveItem = isHovering || isEditingThisItem;

  const showTooltip =
    (!showResourceNameOnItemHoverSetting ||
      !isHoveringThisItemOutsideEditMode) &&
    !!isResourceNameViewerEnabled;

  const cardFloatingContext = useFloatingRootContext({
    open: true,
    elements: {
      reference: floatingReferenceElement,
      floating: cardElement,
    },
  });

  const tooltipFloatingContext = useFloatingRootContext({
    open: true,
    elements: {
      reference: floatingReferenceElement,
      floating: tooltipElement,
    },
  });

  const canvasZoom = canvasRef.current?.getZoom();

  return (
    <div
      key={roomItem.id}
      id={`room-item-overlay-${roomItem.id}`}
      data-resource-name={dataResourceName}
      className={clsx(styles.roomItemOverlayContainer, {
        [styles.activeItem]: isActiveItem,
      })}
      style={{
        ...generateStyleForItem(roomItem, backgroundImageSizeRef),
      }}
    >
      {isProvider &&
        isEditingRoom &&
        (isEditingThisItem || isHovering) &&
        !editItemMoving && (
          <ItemActivityPreview
            key={roomItem.id}
            itemId={roomItem.id}
            updateThumbnailKey={updateThumbnailKey}
            canvasRef={canvasRef}
          />
        )}
      <div
        className={styles.floatingReference}
        ref={setFloatingReferenceElement}
      ></div>
      <div
        className={styles.floatingScaleContainer}
        style={
          canvasZoom
            ? {
                transform: `scale(${canvasZoom})`,
              }
            : {}
        }
      >
        {isProvider && !isEditingRoom && editItemMoving !== roomItem.id && (
          <ItemActivityHoverCard
            key={`card-${roomItem.id}`}
            roomItem={roomItem}
            thumbnailSrc={thumbnailSrc}
            show={!!showResourceNameOnItemHoverSetting && !!isHovering}
            canvasRef={canvasRef}
            floatingContext={cardFloatingContext}
            setCardRef={setCardElement}
            cardClass={styles.card}
          />
        )}
        {isProvider && !(editItemMoving && isHovering) && (
          <ItemActivityTooltip
            key={`tooltip-${roomItem.id}`}
            roomItem={roomItem}
            canvasRef={canvasRef}
            floatingContext={tooltipFloatingContext}
            show={showTooltip}
            setTooltipRef={setTooltipElement}
          />
        )}
      </div>
    </div>
  );
};

import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import styles from "./EditLinkInput.module.css";
import Button from "components/Button/Button";
import { ApolloError } from "@apollo/client";
import { useSetUrlNameMutation } from "generated/graphql";
import { useSelector } from "react-redux";
import { selectUserId } from "redux/userRedux";
import { logUnexpectedError } from "utils/errorUtils";
import { MAX_URL_NAME_LENGTH } from "pages/DemoStart/hooks/useSetUrlName";
import { useClearWaitingRoom } from "utils/waitingRoomUtils";

type EditLinkInputProps = {
  urlName?: string | null;
  closeEditLinkMode: () => void;
};

export const EditLinkInput = ({
  urlName,
  closeEditLinkMode,
}: EditLinkInputProps) => {
  const [pendingUrlName, setPendingUrlName] = useState(urlName || "");
  const inputRef = useRef<HTMLInputElement>(null);
  const [setUrlNameMutation] = useSetUrlNameMutation();
  const userId = useSelector(selectUserId);
  const clearWaitingRoom = useClearWaitingRoom(urlName ?? "");

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  }, []);

  const textChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setPendingUrlName(event.target.value);
  };

  // Return true if the name was successfully set, false if it was already taken
  const tryToSetUrlName = async () => {
    try {
      await clearWaitingRoom();
      await setUrlNameMutation({
        variables: { userId, urlName: pendingUrlName },
      });
      return true;
    } catch (e) {
      if (
        e instanceof ApolloError &&
        e.message ===
          'Uniqueness violation. duplicate key value violates unique constraint "provider_settings_url_name_key"'
      ) {
        return false;
      }
      throw e;
    }
  };

  const submitNewName = async () => {
    try {
      // Check that it contains the right types of characters
      if (pendingUrlName.search(/[^a-z0-9-]/) !== -1) {
        alert(
          'Your link must contain only lowercase letters a-z, numbers, and dashes ("-").'
        );
        return;
      }

      // Check that it is not too short (at least 4 characters)
      if (pendingUrlName.length < 4) {
        alert("Your link name must be at least 4 characters long.");
        return;
      }

      // Check that it is not too long
      if (pendingUrlName.length > MAX_URL_NAME_LENGTH) {
        alert(
          `Your link name must at most ${MAX_URL_NAME_LENGTH} characters long.`
        );
        return;
      }

      // Check that it is not already taken
      const nameWasSet = await tryToSetUrlName();
      if (nameWasSet) {
        closeEditLinkMode();
        return;
      } else {
        alert(`This link is already in use. Please try a different one.`);
        return;
      }
    } catch (e) {
      alert("An unexpected error occurred. Please try again.");
      logUnexpectedError(e);
    }
  };

  const baseUrl = window.location.href + "space/";

  return (
    <>
      <div className={styles.linkRow}>
        <div className={styles.urlTextBox}>
          <div className={styles.urlText}>{baseUrl}</div>
          <input
            ref={inputRef}
            type="text"
            value={pendingUrlName}
            className={styles.textInput}
            onChange={textChangeHandler}
            maxLength={MAX_URL_NAME_LENGTH}
          />
        </div>
        <Button onClick={submitNewName}>Update</Button>
        <Button className={styles.cancelButton} onClick={closeEditLinkMode}>
          Cancel
        </Button>
      </div>
      <div className={styles.instructionsText}>
        {
          'Your link must contain only lowercase letters a-z, numbers, and dashes ("-").'
        }
      </div>
    </>
  );
};

import React, { ChangeEvent } from "react";
import styles from "./TextInput.module.css";
import clsx from "clsx";

type TextInputProps = {
  value: string;
  setValue: (value: string) => void;
  placeholder: string;
  maxLength?: number;
  autoFocus?: boolean;
  className?: string;
};

const TextInput = ({
  value,
  setValue,
  placeholder,
  maxLength,
  autoFocus,
  className,
}: TextInputProps) => {
  const textChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <input
      type="text"
      value={value}
      placeholder={placeholder}
      className={clsx(styles.textInput, className)}
      onChange={textChangeHandler}
      maxLength={maxLength}
      autoFocus={autoFocus}
    />
  );
};

export default TextInput;

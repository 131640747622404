import React, {
  PointerEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import "./Videos.module.css";
import { useSelector } from "react-redux";
import { selectUserRole, UserRole } from "redux/userRedux";
import {
  selectPeerSettings,
  selectVideoConferencing,
} from "redux/settingsRedux";
import { VideoOrError } from "./VideoOrError";
import clsx from "clsx";
import { useFullScreenDimensions } from "./useFullScreenDimensions";
import { selectIsFullScreenVideo } from "redux/spaceNavigationRedux";
import {
  getLocalPeerId,
  Peers,
} from "pages/Space/hooks/connection/usePeerWebRTCConnection";

type VideosProps = {
  peers: Peers;
  localStream: MediaStream | undefined;
  userMediaError: string | undefined;
  reloadUserMedia: () => void;
  loadingUserMedia: boolean;
  setRemoteFullScreenVideoConferencing: (isFullScreen: boolean) => void;
  setRemoteIsPeerMuted: (isPeerMuted: boolean, peerId?: string) => void;
  setRemoteIsPeerVideoOff: (isPeerVideoOff: boolean, peerId?: string) => void;
  setRemotePeerIdWithControl: (peerId: string) => void;
};

const INITIAL_POSITION = { top: 45, left: 40 };

export const Videos = ({
  peers,
  localStream,
  userMediaError,
  reloadUserMedia,
  loadingUserMedia,
  setRemoteFullScreenVideoConferencing,
  setRemoteIsPeerMuted,
  setRemoteIsPeerVideoOff,
  setRemotePeerIdWithControl,
}: VideosProps) => {
  const localUserRole = useSelector(selectUserRole);
  const [position, setPosition] = useState(INITIAL_POSITION);
  const positionRef = useRef(position);
  useEffect(() => {
    positionRef.current = position;
  }, [position]);
  const isFullScreen = useSelector(selectIsFullScreenVideo);
  const videoConferencing = useSelector(selectVideoConferencing);
  const peerSettings = useSelector(selectPeerSettings);
  const localPeerId = getLocalPeerId();
  const containerRef = useRef(null);

  const dragging = useRef(false);
  const lastPos = useRef({ x: 0, y: 0 });

  const {
    setPeerRawVideoDimensions,
    setLocalRawVideoDimensions,
    peerFullScreenDimensions,
    localFullScreenDimensions,
    parentDimensions,
  } = useFullScreenDimensions(containerRef, isFullScreen || false);

  const movePositionIntoView = (pos: { top: number; left: number }) => {
    const newPos = { ...pos };
    if (pos.top < 0) {
      newPos.top = 0;
    }
    if (pos.left < 0) {
      newPos.left = 0;
    }
    if (pos.top + 100 > window.innerHeight) {
      newPos.top = window.innerHeight - 100;
    }
    if (pos.left + 100 > window.innerWidth) {
      newPos.left = window.innerWidth - 100;
    }
    return newPos;
  };

  const moveVideoIntoView = () => {
    const pos = positionRef.current;
    const newPos = movePositionIntoView(pos);
    if (pos.top !== newPos.top || pos.left !== newPos.left) {
      setPosition(newPos);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", moveVideoIntoView);

    return () => {
      window.removeEventListener("resize", moveVideoIntoView);
    };
  }, []);

  useEffect(() => {
    // Reset position when video conferencing setting is toggled, to be able to recover if the
    // video goes off screen
    if (videoConferencing) {
      setPosition(INITIAL_POSITION);
    }
  }, [videoConferencing]);

  const onMouseDown: PointerEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      dragging.current = true;
      lastPos.current = {
        x: event.clientX,
        y: event.clientY,
      };
      window.addEventListener("pointermove", onMouseMove);
      window.addEventListener("pointerup", onMouseUp);
    },
    []
  );

  const onMouseMove = useCallback((event: PointerEvent) => {
    if (!dragging.current) return;
    const deltaX = event.clientX - lastPos.current.x;
    const deltaY = event.clientY - lastPos.current.y;
    lastPos.current = {
      x: event.clientX,
      y: event.clientY,
    };
    setPosition((pos) => {
      if (!pos) {
        return pos;
      }
      const newPos = {
        top: pos.top + deltaY,
        left: pos.left + deltaX,
      };
      return movePositionIntoView(newPos);
    });
  }, []);

  const onMouseUp = useCallback(() => {
    dragging.current = false;
    window.removeEventListener("pointermove", onMouseMove);
    window.removeEventListener("pointerup", onMouseUp);
  }, [onMouseMove]);

  // filter out failed and closed connections
  const peersToShow = useMemo(
    () =>
      [...Object.keys(peers)].filter(
        (peerId) =>
          !["failed", "closed"].includes(
            peers[peerId]?.webRTCConnection?.connectionState!
          )
      ),
    [peers]
  );

  // Sorting rule is:
  // 1. Therapist always comes last
  // 2. If both are clients, sort by peerId
  // TODO: use a join timestamp to sort peers
  // reverse here and use flex-direction: column-reverse to show therapist at the bottom
  const sortedIds = useMemo(
    () =>
      [...peersToShow, localPeerId ?? ""]
        .sort((a, b) => {
          const peerARole = peers[a]?.role ?? localUserRole;
          const peerBRole = peers[b]?.role ?? localUserRole;
          if (peerARole === UserRole.THERAPIST) {
            return 1;
          }
          if (peerBRole === UserRole.THERAPIST) {
            return -1;
          }

          return a > b ? 1 : -1;
        })
        .reverse(),
    [peers, localPeerId, localUserRole]
  );

  // The leftmost peer
  const lastPeerId = useMemo(() => {
    for (const peerId of [...sortedIds].reverse()) {
      if (peers[peerId]) {
        return peerId;
      }
    }
  }, [peers, sortedIds]);

  // The rightmost peer
  const firstPeerId = useMemo(() => {
    for (const peerId of sortedIds) {
      if (peers[peerId]) {
        return peerId;
      }
    }
  }, [peers, sortedIds]);

  if (!videoConferencing) {
    return null;
  }

  const localVideo = (
    <VideoOrError
      key={"localVideo"}
      stream={localStream}
      isLocal
      error={userMediaError}
      reloadUserMedia={reloadUserMedia}
      setRemoteIsPeerMuted={setRemoteIsPeerMuted}
      setRemoteIsPeerVideoOff={setRemoteIsPeerVideoOff}
      setRemotePeerIdWithControl={setRemotePeerIdWithControl}
      loadingUserMedia={loadingUserMedia}
      isFullScreen={isFullScreen}
      setRawVideoDimensions={setLocalRawVideoDimensions}
      peerFullScreenDimensions={peerFullScreenDimensions}
      localFullScreenDimensions={localFullScreenDimensions}
      parentDimensions={parentDimensions}
      userRole={localUserRole!}
      peerCount={sortedIds.length}
      setRemoteFullScreenVideoConferencing={
        setRemoteFullScreenVideoConferencing
      }
      showExpandButton={
        isFullScreen ? false : localUserRole === UserRole.THERAPIST
      }
    />
  );

  const orderedVideos = sortedIds.map((peerId) =>
    peerId === localPeerId ? (
      localVideo
    ) : (
      <VideoOrError
        key={`peerVideo-${peerId}`}
        peerId={peerId}
        peer={peers[peerId]}
        stream={peers[peerId]?.mediaStream}
        isFullScreen={isFullScreen}
        error={peerSettings?.[peerId]?.mediaError}
        setRemoteIsPeerMuted={setRemoteIsPeerMuted}
        setRemoteIsPeerVideoOff={setRemoteIsPeerVideoOff}
        setRemotePeerIdWithControl={setRemotePeerIdWithControl}
        setRawVideoDimensions={setPeerRawVideoDimensions}
        peerFullScreenDimensions={peerFullScreenDimensions}
        localFullScreenDimensions={localFullScreenDimensions}
        parentDimensions={parentDimensions}
        userRole={peers[peerId]?.role}
        isLastPeer={sortedIds.length > 2 && peerId === lastPeerId}
        peerCount={sortedIds.length}
        setRemoteFullScreenVideoConferencing={
          setRemoteFullScreenVideoConferencing
        }
        showExpandButton={
          isFullScreen
            ? peerId === firstPeerId
            : localUserRole === UserRole.CLIENT && peerId === firstPeerId
        }
      />
    )
  );

  // Add a placeholder video if there are no peers connected.
  // This might happen while the provider is alone in the room,
  // and if they go full screen, this will show the "Client is not connected" message
  if (orderedVideos.length <= 1) {
    orderedVideos.push(
      <VideoOrError
        key={"peerVideo-0"}
        stream={undefined}
        isFullScreen={isFullScreen}
        setRawVideoDimensions={setPeerRawVideoDimensions}
        setRemoteIsPeerMuted={setRemoteIsPeerMuted}
        setRemoteIsPeerVideoOff={setRemoteIsPeerVideoOff}
        setRemotePeerIdWithControl={setRemotePeerIdWithControl}
        peerFullScreenDimensions={peerFullScreenDimensions}
        localFullScreenDimensions={localFullScreenDimensions}
        parentDimensions={parentDimensions}
        userRole={
          localUserRole === UserRole.CLIENT
            ? UserRole.THERAPIST
            : UserRole.CLIENT
        }
        peerCount={sortedIds.length + 1}
        setRemoteFullScreenVideoConferencing={
          setRemoteFullScreenVideoConferencing
        }
        showExpandButton={!!isFullScreen}
      />
    );
  }

  return (
    <div
      ref={containerRef}
      className={clsx({
        ["minimizedVideoContainer"]: !isFullScreen,
        ["fullScreenVideoContainer"]: isFullScreen,
      })}
      style={isFullScreen ? undefined : position}
      onPointerDown={onMouseDown}
    >
      {orderedVideos}
    </div>
  );
};

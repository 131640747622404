import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { toast } from "react-toastify";

import styles from "./ShareRoomModal.module.css";
import Modal from "components/Modal/Modal";
import Button from "components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "components/TextInput/TextInput";
import {
  GetSharedRoomFolderModalDataDocument,
  Shared_Room_Insert_Input as SharedRoomInsertInput,
  Shared_Room_Item_Insert_Input as SharedRoomItemInsertInput,
  useCreateSharedRoomMutation,
  useGetRoomForShareLazyQuery,
  useGetShareRoomModalDataQuery,
} from "generated/graphql";
import { selectOrganizationId, selectUserId } from "redux/userRedux";
import {
  setShowOpenRoomModal,
  setShareRoomModalRoomId,
  selectShareRoomModalRoomId,
  setSharedRoomFolderModalFolderId,
} from "redux/spaceNavigationRedux";
import DropDown from "components/DropDown/DropDown";
import folderSharedIcon from "assets/icons/folder_shared.svg";
import { logUnexpectedError } from "utils/errorUtils";
import { RESOURCE_SHARED_TYPES } from "utils/organizationUtils";
import SharedRoomConfirmationModal from "./ShareRoomConfirmationModal";

const MAX_NAME_LENGTH = 1000;

type ShareRoomModalProps = {};

const ShareRoomModal = ({}: ShareRoomModalProps) => {
  const dispatch = useDispatch();
  const [getRoomForShareQuery] = useGetRoomForShareLazyQuery({
    fetchPolicy: "no-cache",
  });
  const [createSharedRoomMutation] = useCreateSharedRoomMutation();
  const shareRoomModalRoomId = useSelector(selectShareRoomModalRoomId);
  const userId = useSelector(selectUserId);
  const organizationId = useSelector(selectOrganizationId);
  const { data: shareRoomModalData } = useGetShareRoomModalDataQuery({
    variables: {
      roomId: shareRoomModalRoomId,
      organizationId: organizationId || "",
    },
    skip: !organizationId,
  });

  const [sharedRoomFolderId, setSharedRoomFolderId] = useState("");
  const [roomName, setRoomName] = useState("");
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const trimmedRoomName = roomName.trim();

  const closeModal = () => {
    dispatch(setShareRoomModalRoomId(undefined));
  };

  const openPreviousModal = () => {
    closeModal();
    dispatch(setShowOpenRoomModal(true));
  };

  const validateAndSubmit = () => {
    if (!sharedRoomFolderId) {
      toast("Please select a shared folder.", { type: "error" });
      return;
    }

    if (!trimmedRoomName) {
      toast("Please enter a room name.", { type: "error" });
      return;
    }

    if (trimmedRoomName === shareRoomModalData?.room?.name) {
      setIsConfirmationModalOpen(true);
      return;
    }

    handleSubmit();
  };

  const handleSubmit = async () => {
    const { data: roomData } = await getRoomForShareQuery({
      variables: { roomId: shareRoomModalRoomId },
    });
    const room = roomData?.room_by_pk;

    if (!room || !organizationId) {
      toast("There was an error sharing the room. Please try again.", {
        type: "error",
      });
      return;
    }

    const fileIds: string[] = [];

    const sharedRoomItems = room.room_items.map((roomItem) => {
      const sharedRoomItem: SharedRoomItemInsertInput = {
        source_room_item_id: roomItem.id,
        icon_file_id: roomItem.icon_file_id,
        icon_id: roomItem.icon_id,
        rx: roomItem.rx,
        ry: roomItem.ry,
        z: roomItem.z,
      };

      // record file ids so the organizationId can be added to the file
      if (roomItem.icon_file_id) {
        fileIds.push(roomItem.icon_file_id);
      }

      const resource = roomItem.resource;
      if (resource.owner_id) {
        sharedRoomItem["resource"] = {
          data: {
            id: uuid(),
            source_resource_id: resource.id,
            source_owner_id: resource.owner_id,
            organization_id: organizationId,
            shared_type: RESOURCE_SHARED_TYPES.PROTOTYPE,
            name: resource.name,
            url: resource.url,
            file_key: resource.file_key,
            thumbnail_file_key: resource.thumbnail_file_key,
          },
        };
      } else {
        sharedRoomItem["resource_id"] = resource.id;
      }

      return sharedRoomItem;
    });

    const sharedRoom: SharedRoomInsertInput = {
      shared_room_folder_id: sharedRoomFolderId,
      source_room_id: room.id,
      source_owner_id: userId,
      name: trimmedRoomName,
      background_id: room.background_id,
      collection_id: room.collection_id,
      shared_room_items: { data: sharedRoomItems },
    };

    const createSharedRoomMutationResult = await createSharedRoomMutation({
      variables: { sharedRoom, fileIds, organizationId },
      refetchQueries: [
        {
          query: GetSharedRoomFolderModalDataDocument,
          variables: { sharedRoomFolderId, userId },
        },
      ],
    });

    if (createSharedRoomMutationResult.errors) {
      logUnexpectedError(createSharedRoomMutationResult.errors);
      toast("There was an error sharing the room. Please try again.", {
        type: "error",
      });
      return;
    }

    toast("Room shared successfully", { type: "success" });

    closeModal();
    dispatch(setSharedRoomFolderModalFolderId(sharedRoomFolderId));
  };

  useEffect(() => {
    if (shareRoomModalData?.room?.name) {
      setRoomName(shareRoomModalData.room.name);
    }
  }, [shareRoomModalData?.room?.name]);

  return (
    <>
      <Modal closeModal={closeModal}>
        <div className={styles.heading}>Share Room</div>
        <div className={styles.subheading}>
          Select a folder and name your room template to share with colleagues.
          <br />
          Colleagues can copy your room as a template but cannot edit the
          underlying room.
        </div>
        <label className={styles.nameInputLabel}>Shared Folder</label>
        {shareRoomModalData?.sharedRoomFolders && (
          <DropDown
            options={shareRoomModalData.sharedRoomFolders.map(
              (sharedRoomFolder) => ({
                value: sharedRoomFolder.id,
                label: sharedRoomFolder.name,
              })
            )}
            value={sharedRoomFolderId}
            setValue={setSharedRoomFolderId}
            placeholder={"Choose a shared folder"}
            labelIcon={folderSharedIcon}
          />
        )}
        <label className={styles.nameInputLabel}>Room Name</label>
        <TextInput
          placeholder="Name"
          setValue={setRoomName}
          value={roomName}
          className={styles.nameInput}
          maxLength={MAX_NAME_LENGTH}
        />
        <div className={styles.buttonRow}>
          <Button className={styles.cancelButton} onClick={openPreviousModal}>
            Cancel
          </Button>

          <Button
            onClick={validateAndSubmit}
            disabled={trimmedRoomName === "" || !sharedRoomFolderId}
          >
            Share
          </Button>
        </div>
      </Modal>
      {isConfirmationModalOpen && (
        <SharedRoomConfirmationModal
          roomName={trimmedRoomName}
          handleConfirm={handleSubmit}
          closeModal={() => setIsConfirmationModalOpen(false)}
        />
      )}
    </>
  );
};

export default ShareRoomModal;

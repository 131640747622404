import React, { useEffect, useState } from "react";
import DropDown from "../DropDown/DropDown";
import Checkbox from "components/Checkbox/Checkbox";
import styles from "./MultiSelectDropDown.module.css";
import { useDebounce } from "../useDebounce";
import { useTrackResourceFilter } from "../../useActivityBankAnalytics";

type MultiSelectDropDownProps = {
  selectedValues: string[];
  setValues: (value: string[]) => void;
  possibleValues: string[];
  placeholderText: string;
  rightAlign?: boolean;
};

const MultiSelectDropDown = ({
  selectedValues,
  setValues,
  possibleValues,
  placeholderText,
  rightAlign,
}: MultiSelectDropDownProps) => {
  const [newlySelectedValues, setNewlySelectedValues] = useState([
    ...selectedValues,
  ]);

  const [isTouched, setIsTouched] = useState(false);

  const { debounce, triggerAndClearDebounce, cancelDebounce } = useDebounce();

  const { trackOpen, trackClose, trackFilterChange, trackFilterClear } =
    useTrackResourceFilter(placeholderText);

  const cancelSelection = () => {
    triggerAndClearDebounce();
  };

  const applySelection = () => {
    if (
      JSON.stringify(newlySelectedValues) !== JSON.stringify(selectedValues)
    ) {
      setValues([...newlySelectedValues]);
      trackFilterChange({ "Filter Value": newlySelectedValues });
    }
  };

  const clearSelection = () => {
    if (selectedValues.length > 0) {
      setValues([]);
    }
    if (newlySelectedValues.length > 0) {
      setNewlySelectedValues([]);
    }
    setIsTouched(false);
    trackFilterClear();
  };

  const onToggle = (valueIndex: number) => (isChecked: boolean) => {
    const value = possibleValues[valueIndex];
    if (isChecked && !newlySelectedValues.includes(value)) {
      setNewlySelectedValues([...newlySelectedValues, value].sort());
    }
    if (!isChecked && newlySelectedValues.includes(value)) {
      setNewlySelectedValues(
        newlySelectedValues.filter((val) => val !== value)
      );
    }
    setIsTouched(true);
  };

  useEffect(() => {
    if (isTouched) {
      debounce(applySelection);
    }
  }, [newlySelectedValues]);

  // When the filter is cleared
  useEffect(() => {
    if (selectedValues.length === 0 && newlySelectedValues.length > 0) {
      setNewlySelectedValues([]);
      setIsTouched(false);
      cancelDebounce();
    }
  }, [selectedValues]);

  const value = selectedValues.length ? selectedValues.join(", ") : null;

  return (
    <DropDown
      placeholderText={placeholderText}
      value={value}
      cancelSelection={cancelSelection}
      clearSelection={clearSelection}
      rightAlign={rightAlign}
      onOpen={trackOpen}
      onClose={trackClose}
    >
      <div className={styles.body}>
        {possibleValues.map((value, index) => (
          <Checkbox
            key={index}
            onToggle={onToggle(index)}
            checked={newlySelectedValues.includes(value)}
          >
            {value}
          </Checkbox>
        ))}
      </div>
    </DropDown>
  );
};

export default MultiSelectDropDown;

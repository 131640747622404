import { createSlice } from "@reduxjs/toolkit";

export type Snapshot = { fileKey: string; thumbnailKey: string };

export type Page = {
  title: string;
  snapshots: Snapshot[];
  date: string;
};

export interface ClientAlbumState {
  currentAlbumPage: number;
  albumPages: Page[] | undefined;
  imgKeysToURL: { [key: string]: string };
  viewingSnapshot: Snapshot | undefined;
}

export interface RootState {
  clientAlbum: ClientAlbumState;
}

const INITIAL_STATE = {
  currentAlbumPage: 0,
  albumPages: undefined,
  imgKeysToURL: {},
  viewingSnapshot: undefined,
} as ClientAlbumState;

export const clientAlbumSlice = createSlice({
  name: "clientAlbum",
  initialState: INITIAL_STATE,
  reducers: {
    setCurrentAlbumPage: (
      state,
      { payload }: { payload: ClientAlbumState["currentAlbumPage"] }
    ) => {
      state.currentAlbumPage = payload;
    },
    setAlbumPages: (
      state,
      { payload }: { payload: ClientAlbumState["albumPages"] }
    ) => {
      state.albumPages = payload;
    },
    setImgKeysToURL: (
      state,
      { payload }: { payload: ClientAlbumState["imgKeysToURL"] }
    ) => {
      state.imgKeysToURL = payload;
    },
    setViewingSnapshot: (
      state,
      { payload }: { payload: ClientAlbumState["viewingSnapshot"] }
    ) => {
      state.viewingSnapshot = payload;
    },
  },
});

export const {
  setCurrentAlbumPage,
  setAlbumPages,
  setImgKeysToURL,
  setViewingSnapshot,
} = clientAlbumSlice.actions;

export const selectCurrentAlbumPage = (state: RootState) =>
  state.clientAlbum.currentAlbumPage;
export const selectAlbumPages = (state: RootState) =>
  state.clientAlbum.albumPages;
export const selectImgKeysToURL = (state: RootState) =>
  state.clientAlbum.imgKeysToURL;
export const selectViewingSnapshot = (state: RootState) => {
  return state.clientAlbum.viewingSnapshot;
};

export default clientAlbumSlice.reducer;

import React from "react";

import styles from "./LoadingAnimation.module.css";
import clsx from "clsx";

type LoadingAnimationProps = {
  small?: boolean;
};

const LoadingAnimation = ({ small }: LoadingAnimationProps) => {
  return (
    <div className={clsx(styles.loadingRing, { [styles.small]: small })}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default LoadingAnimation;

import React from "react";

import styles from "./RoomMenu.module.css";
import MenuRow from "../../Menu/MenuRow";

import copyIcon from "assets/icons/copy.svg";
import openIcon from "assets/icons/folder_open.svg";
import shareIcon from "assets/icons/share.svg";
import renameIcon from "assets/icons/rename.svg";
import deleteIcon from "assets/icons/delete.svg";

import { useGetRoomMenuDataQuery } from "generated/graphql";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentRoomId,
  selectOrganizationId,
  selectUserId,
} from "redux/userRedux";
import {
  selectIsFullScreenVideo,
  setDeleteRoomModalRoom,
  setShareRoomModalRoomId,
  setShowOpenRoomModal,
} from "redux/spaceNavigationRedux";

import { MAX_ROOM_NAME_LENGTH } from "../ControlBar";
import { useTrackEvent } from "utils/metricsUtils";

import clsx from "clsx";

import NewRoomMenu from "./NewRoomMenu";
import { generateNewRoomName } from "./roomNameUtils";
import { useCreateRoom } from "./useCreateRoom";

type RoomMenuProps = {
  closeMenu: () => void;
  renameRoom: () => void;
  sendRemoteRoomChanged: () => void;
};

const RoomMenu = ({
  closeMenu,
  renameRoom,
  sendRemoteRoomChanged,
}: RoomMenuProps) => {
  const userId = useSelector(selectUserId);

  const currentRoomId = useSelector(selectCurrentRoomId);
  const organizationId = useSelector(selectOrganizationId);
  const isFullScreenVideo = useSelector(selectIsFullScreenVideo);
  const dispatch = useDispatch();
  const { trackEvent } = useTrackEvent();

  const { createRoom } = useCreateRoom(sendRemoteRoomChanged);

  const { data: roomMenuData } = useGetRoomMenuDataQuery({
    variables: {
      userId: userId || "",
    },
    skip: !userId,
  });

  const generateDuplicateRoomName = (currentRoomName: string | undefined) => {
    if (currentRoomName) {
      const newRoomName = `Copy of ${currentRoomName}`;
      return newRoomName.substring(0, MAX_ROOM_NAME_LENGTH);
    }

    if (roomMenuData?.providerRooms) {
      return generateNewRoomName(roomMenuData?.providerRooms);
    }

    return null;
  };

  const duplicateRoom = async () => {
    const currentRoom = roomMenuData?.currentRoom[0];
    const currentRoomItems = currentRoom?.room_items?.map(
      // Remove '__typename' (added by Apollo) and 'id' before inserting items
      (item) => ({
        resource_id: item.resource_id,
        icon_id: item.icon_id,
        rx: item.rx,
        ry: item.ry,
        z: item.z,
        icon_file_id: item.icon_file_id,
      })
    );
    const currentRoomBackgroundId = currentRoom?.background_id;
    const currentRoomCollectionId = currentRoom?.collection_id;
    const currentRoomName = currentRoom?.name;
    const newRoomName = generateDuplicateRoomName(currentRoomName);
    if (
      newRoomName &&
      currentRoomItems &&
      currentRoomBackgroundId &&
      currentRoomCollectionId
    ) {
      await createRoom(
        newRoomName,
        currentRoomItems,
        currentRoomBackgroundId,
        currentRoomCollectionId
      );
      trackEvent("New Room From Duplicate");
    }
    closeMenu();
  };

  const openRoom = () => {
    dispatch(setShowOpenRoomModal(true));
    closeMenu();
  };

  const shareRoom = () => {
    dispatch(setShareRoomModalRoomId(currentRoomId));
    closeMenu();
  };

  const renameRoomAndCloseMenu = () => {
    renameRoom();
    closeMenu();
  };

  const deleteRoom = () => {
    dispatch(setDeleteRoomModalRoom(roomMenuData?.currentRoom[0]));
    closeMenu();
  };

  return (
    <div
      className={clsx(styles.menuContainer, {
        [styles.menuContainerElevated]: isFullScreenVideo,
      })}
    >
      <NewRoomMenu
        isFullScreenVideo={isFullScreenVideo}
        loading={!roomMenuData}
        roomMenuData={roomMenuData}
        sendRemoteRoomChanged={sendRemoteRoomChanged}
        closeMenu={closeMenu}
      />
      <MenuRow
        label="Open Room"
        iconSrc={openIcon}
        onClick={openRoom}
        elevated={isFullScreenVideo}
      />
      {currentRoomId === undefined ? null : (
        <MenuRow
          label="Duplicate Room"
          iconSrc={copyIcon}
          onClick={duplicateRoom}
          loading={!roomMenuData}
          elevated={isFullScreenVideo}
        />
      )}
      {currentRoomId === undefined || !organizationId ? null : (
        <MenuRow
          label="Share Room"
          iconSrc={shareIcon}
          onClick={shareRoom}
          loading={!roomMenuData}
          elevated={isFullScreenVideo}
        />
      )}
      {currentRoomId === undefined ? null : (
        <MenuRow
          label="Rename Room"
          iconSrc={renameIcon}
          onClick={renameRoomAndCloseMenu}
          elevated={isFullScreenVideo}
        />
      )}
      {currentRoomId === undefined ? null : (
        <MenuRow
          label="Delete Room"
          iconSrc={deleteIcon}
          onClick={deleteRoom}
          elevated={isFullScreenVideo}
        />
      )}
    </div>
  );
};

export default RoomMenu;

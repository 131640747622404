import { BACKGROUND_ID, COLLECTION_ID } from "./backgroundUtils";
import { useSelector } from "react-redux";
import { selectCurrentRoomId } from "redux/userRedux";
import React, { useState } from "react";
import {
  useGetRoomBackgroundInfoQuery,
  useUpdateRoomBackgroundMutation,
} from "generated/graphql";

const GENERIC_ERROR_MESSAGE =
  "Sorry, there was a problem changing the room design. Please try again later.";

export const getPossibleBackgrounds = (collectionId: string | undefined) => {
  if (!collectionId) {
    return [];
  }
  if (collectionId === COLLECTION_ID.YOUNGER) {
    return [BACKGROUND_ID.PLAYROOM, BACKGROUND_ID.RAINBOW, BACKGROUND_ID.SPACE];
  } else {
    return [
      BACKGROUND_ID.DEFAULT_OLDER,
      BACKGROUND_ID.BOHO,
      BACKGROUND_ID.GRAFFITI,
    ];
  }
};

export const useBackgroundSelection = () => {
  const roomId = useSelector(selectCurrentRoomId);
  const [loadingBackgroundId, setLoadingBackgroundId] = useState<string>();
  const [updateRoomBackgroundMutation] = useUpdateRoomBackgroundMutation();
  const { data } = useGetRoomBackgroundInfoQuery({
    variables: {
      roomId,
    },
    skip: !roomId,
  });

  const onClick: (
    backgroundId: string
  ) => React.MouseEventHandler<HTMLDivElement> =
    (backgroundId: string) => async () => {
      setLoadingBackgroundId(backgroundId);
      const { errors } = await updateRoomBackgroundMutation({
        variables: {
          roomId,
          backgroundId,
        },
      });
      setLoadingBackgroundId(undefined);
      if (errors) {
        alert(GENERIC_ERROR_MESSAGE);
        return;
      }
    };

  const selectedBackgroundId = data?.room_by_pk?.background_id;
  const collectionId = data?.room_by_pk?.collection_id;
  const possibleBackgrounds = getPossibleBackgrounds(collectionId);

  return {
    selectedBackgroundId,
    loadingBackgroundId,
    possibleBackgrounds,
    onClick,
  };
};

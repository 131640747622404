import React, { useRef, useState } from "react";
import { ICE_TRANSPORT_POLICY } from "./connectionUtils";
import { useStateWithRef } from "hooks/useStateWithRef";
import { getAddBreadcrumb, logUnexpectedError } from "utils/errorUtils";

const addBreadcrumb = getAddBreadcrumb("webrtc.remote");

export const useRemoteWebRTCConnection = (
  sendMessage: (message: any) => void,
  iceServersRef: React.MutableRefObject<RTCIceServer[] | undefined>,
  onConnectedToRemote: () => void
) => {
  const remoteConnectionRef = useRef<RTCPeerConnection>();
  const remoteDataChannelRef = useRef<RTCDataChannel>();
  const [remoteMediaStream, setRemoteMediaStream, remoteMediaStreamRef] =
    useStateWithRef(new MediaStream());
  const makingOfferRef = useRef(false);
  const ignoreOfferRef = useRef(false);

  const [isConnectedToRemote, setIsConnectedToRemote] = useState(false);
  const [isConnectingToRemote, setIsConnectingToRemote] = useState(true);
  const [isSettingUpConnectionToRemote, setIsSettingUpConnectionToRemote] =
    useState(true);

  const polite = true;

  const setUpRemoteWebRTCConnection = () => {
    try {
      if (!iceServersRef.current) {
        return;
      }
      if (
        remoteConnectionRef.current &&
        remoteConnectionRef.current.connectionState !== "closed"
      ) {
        addBreadcrumb(
          "info",
          "remote connection already exists. Keeping the previous one."
        );
        if (
          ["disconnected", "failed"].includes(
            remoteConnectionRef.current.connectionState
          )
        ) {
          addBreadcrumb(
            "info",
            "remote connection state is disconnected or failed. Restarting ice"
          );
          remoteConnectionRef.current.restartIce();
        }
        return;
      }
      const pc = new RTCPeerConnection({
        iceServers: iceServersRef.current,
        iceTransportPolicy: ICE_TRANSPORT_POLICY,
      });
      makingOfferRef.current = false;
      ignoreOfferRef.current = false;
      pc.ondatachannel = (event: RTCDataChannelEvent) => {
        remoteDataChannelRef.current = event.channel;
        addBreadcrumb("info", "ondatachannel");
      };

      pc.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
        if (event.candidate) {
          sendMessage({
            type: "candidate:remote",
            candidate: event.candidate,
          });
        }
      };
      pc.oniceconnectionstatechange = () => {
        addBreadcrumb("info", "oniceconnectionstatechange", {
          state: pc.iceConnectionState,
        });
        if (pc.iceConnectionState === "failed") {
          logUnexpectedError(
            "WebRTC remote ice connection failed, restarting ice"
          );
          pc.restartIce();
        }
      };

      pc.onconnectionstatechange = () => {
        addBreadcrumb("info", "onconnectionstatechange", {
          state: pc.connectionState,
        });
        addBreadcrumb("info", "remote webrtc connection state changed", {
          state: pc.connectionState,
        });
        if (pc.connectionState === "failed") {
          setIsConnectedToRemote(false);
          setIsConnectingToRemote(true);
          pc.restartIce();
          logUnexpectedError(
            "WebRTC remote connection failed. restarting ice."
          );
        } else if (pc.connectionState === "connected") {
          onConnectedToRemote();
          setIsConnectedToRemote(true);
          setIsConnectingToRemote(false);
          setIsSettingUpConnectionToRemote(false);
        } else if (pc.connectionState === "disconnected") {
          setIsConnectedToRemote(false);
          setIsConnectingToRemote(true);
          logUnexpectedError(
            "WebRTC remote connection disconnected. Still trying to reconnect."
          );
        } else if (pc.connectionState === "closed") {
          setIsConnectedToRemote(false);
          setIsConnectingToRemote(false);
          logUnexpectedError("WebRTC remote connection closed");
        }
      };

      pc.ontrack = (ev: RTCTrackEvent) => {
        ev.track.onunmute = () => {
          addBreadcrumb("info", "track.onunmute", { track: ev.track.kind });
          if (
            !remoteMediaStreamRef.current &&
            !ev.track &&
            ev.streams &&
            ev.streams[0]
          ) {
            setRemoteMediaStream(ev.streams[0]);
          } else {
            addBreadcrumb("debug", "adding single track to stream");

            if (!remoteMediaStreamRef.current) {
              addBreadcrumb(
                "debug",
                "creating new remoteMediaStreamRef.current"
              );
              remoteMediaStreamRef.current = new MediaStream();
            }
            const oldTrack = remoteMediaStreamRef.current
              .getTracks()
              .find(
                (track) =>
                  track.kind === ev.track.kind && track.id !== ev.track.id
              );
            if (oldTrack) {
              addBreadcrumb("debug", "had old track, removing it");
              oldTrack?.stop();
              remoteMediaStreamRef.current.removeTrack(oldTrack);
            }
            remoteMediaStreamRef.current.addTrack(ev.track);
            setRemoteMediaStream(remoteMediaStreamRef.current);
          }
        };
        remoteMediaStreamRef.current.addTrack(ev.track);
        addBreadcrumb("info", "ontrack");
      };

      pc.onnegotiationneeded = async () => {
        addBreadcrumb("info", "remote negotiationneeded");
        try {
          makingOfferRef.current = true;
          await pc.setLocalDescription();
          sendMessage({
            type: "offer:remote",
            description: pc.localDescription,
            isFirstOffer: !pc.remoteDescription,
          });
        } catch (error) {
          logUnexpectedError(error);
        } finally {
          makingOfferRef.current = false;
        }
      };

      // close the previous connection if it exists
      if (remoteConnectionRef.current) {
        addBreadcrumb("info", "closing previous remote connection");
        clearAndCloseWebRTCConnection(remoteConnectionRef.current);
      }
      remoteConnectionRef.current = pc;
    } catch (e) {
      logUnexpectedError(e);
      return;
    }
  };

  const clearAndCloseWebRTCConnection = (connection: RTCPeerConnection) => {
    addBreadcrumb("info", "clearAndCloseWebRTCConnection Remote");
    connection.ondatachannel = null;
    connection.onicecandidate = null;
    connection.oniceconnectionstatechange = null;
    connection.onconnectionstatechange = null;
    connection.ontrack = null;
    connection.onnegotiationneeded = null;
    connection.close();
  };

  const closeRemoteWebRTCConnection = () => {
    addBreadcrumb("debug", "closeRemoteWebRTCConnection");
    setIsConnectedToRemote(false);
    if (remoteConnectionRef.current) {
      clearAndCloseWebRTCConnection(remoteConnectionRef.current);
    }
    const newMediaStream = new MediaStream();
    setRemoteMediaStream(newMediaStream);
  };

  const handleRemoteNegotiationMessage = async (message: any) => {
    addBreadcrumb("info", "handleRemoteNegotiationMessage", message);
    if (message?.type?.endsWith(":remote")) {
      const { description, candidate } = message;
      if (message.isFirstOffer) {
        setUpRemoteWebRTCConnection();
      }
      if (!remoteConnectionRef.current) {
        logUnexpectedError(
          "Remote connection not set up while handleRemoteNegotiationMessage"
        );
        return;
      }
      try {
        if (description) {
          const offerCollision =
            description.type === "offer" &&
            (makingOfferRef.current ||
              remoteConnectionRef.current.signalingState !== "stable");

          ignoreOfferRef.current = !polite && offerCollision;
          if (ignoreOfferRef.current) {
            return;
          }

          await remoteConnectionRef.current.setRemoteDescription(description);
          if (description.type === "offer") {
            await remoteConnectionRef.current.setLocalDescription();
            sendMessage({
              type: "answer:remote",
              description: remoteConnectionRef.current.localDescription,
            });
          }
        } else if (candidate) {
          try {
            await remoteConnectionRef.current.addIceCandidate(candidate);
          } catch (err) {
            if (!ignoreOfferRef.current) {
              throw err;
            }
          }
        }
      } catch (err) {
        logUnexpectedError(err);
      }
    }
  };

  const getRemoteVideoBytesReceived = async () => {
    let bytes = -1;
    const stats = await remoteConnectionRef.current?.getStats();
    stats?.forEach((stat) => {
      if (stat.type === "inbound-rtp" && stat.kind === "video") {
        bytes = stat.bytesReceived;
      }
    });
    return bytes;
  };

  return {
    setUpRemoteWebRTCConnection,
    closeRemoteWebRTCConnection,
    handleRemoteNegotiationMessage,
    getRemoteVideoBytesReceived,
    remoteDataChannelRef,
    remoteMediaStream,
    isConnectedToRemote,
    isConnectingToRemote,
    setIsConnectingToRemote,
    remoteWebRTCConnectionRef: remoteConnectionRef,
    remoteMediaStreamRef,
    isSettingUpConnectionToRemote,
  };
};

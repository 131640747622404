import React, {
  MouseEventHandler,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import clsx from "clsx";

import styles from "./DropDown.module.css";
import { ReactComponent as ExpandIcon } from "assets/icons/expand_more.svg";
import { ReactComponent as XIcon } from "assets/icons/x.svg";

type DropDownProps = {
  placeholderText: string;
  value: string | null;
  cancelSelection: () => void;
  clearSelection: () => void;
  rightAlign?: boolean;
  children: React.ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
};

const DropDown = ({
  placeholderText,
  value,
  cancelSelection,
  clearSelection,
  rightAlign,
  children,
  onOpen,
  onClose,
}: DropDownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrollbarIsVisible, setScrollbarIsVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (isOpen) {
      const scrollbarIsNowVisible =
        (bodyRef.current?.scrollHeight || 0) >
        (bodyRef.current?.clientHeight || 0);
      setScrollbarIsVisible(scrollbarIsNowVisible);
    }
  }, [isOpen]);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
    if (isOpen) {
      cancelSelection();
    }
    if (isOpen) {
      onClose?.();
    } else {
      onOpen?.();
    }
  };

  const clear: MouseEventHandler<HTMLDivElement> = (event) => {
    clearSelection();
    event.stopPropagation();
  };

  // Close the dropdown if clicked outside
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      event.target &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  // Attach the event listener on mount and cleanup on unmount
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div
      ref={dropdownRef}
      className={clsx(styles.container, {
        [styles.activeContainer]: isOpen,
      })}
    >
      <div
        className={clsx(styles.topPart, {
          [styles.topPartWithoutValue]: !value,
          [styles.activeTopPart]: isOpen,
        })}
        onClick={toggleOpen}
      >
        <div
          className={clsx(styles.leftPart, {
            [styles.leftPartWithValue]: value,
          })}
        >
          {value ? (
            <div className={clsx(styles.text, styles.textWithValue)}>
              {value}
            </div>
          ) : (
            <div className={styles.text}>{placeholderText}</div>
          )}
        </div>
        <div
          className={clsx(styles.rightButton, {
            [styles.closeButton]: value,
          })}
          onClick={value ? clear : undefined}
        >
          {value ? (
            <XIcon className={styles.xIcon} />
          ) : (
            <ExpandIcon
              className={clsx(styles.expandIcon, {
                [styles.openExpandIcon]: isOpen,
              })}
            />
          )}
        </div>
      </div>
      {isOpen ? (
        <div
          className={clsx(styles.dropDown, {
            [styles.rightAlign]: rightAlign,
          })}
        >
          <div
            ref={bodyRef}
            className={clsx(styles.body, {
              [styles.bodyWithScrollbar]: scrollbarIsVisible,
            })}
          >
            {children}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DropDown;

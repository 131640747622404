import {
  from,
  ApolloCache,
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import React from "react";

export const createApolloClient = (
  authTokenRef: React.MutableRefObject<string | undefined> | null,
  clientTokenRef: React.MutableRefObject<string | undefined> | null
) => {
  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_HASURA_ENDPOINT,
  });

  const authLink = new ApolloLink((operation, forward) => {
    const token = authTokenRef?.current
      ? authTokenRef?.current
      : clientTokenRef?.current;
    operation.setContext((prevContext: { headers: any }) => ({
      ...prevContext,
      headers: {
        ...prevContext.headers,
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
    }));
    return forward(operation);
  });

  return new ApolloClient({
    link: from([authLink, httpLink]),
    cache: new InMemoryCache(),
  });
};

export const evictCurrentRoom =
  (providerId: string) => (cache: ApolloCache<any>) => {
    const providerCacheId = `user:${providerId}`;
    cache.evict({ id: providerCacheId, fieldName: "current_room" });
    // "Evicting an object often makes other cached objects unreachable.
    //    Because of this, you should call cache.gc after evicting one or
    //    more objects from the cache."
    // https://www.apollographql.com/docs/react/caching/garbage-collection#cacheevict
    cache.gc();
  };

export const evictCurrentRoomItems =
  (roomId: string) => (cache: ApolloCache<any>) => {
    const roomCacheId = `room:${roomId}`;
    cache.evict({ id: roomCacheId, fieldName: "room_items" });
    cache.gc();
  };

export const evictCurrentRoomBackground =
  (roomId: string) => (cache: ApolloCache<any>) => {
    const roomCacheId = `room:${roomId}`;
    cache.evict({ id: roomCacheId, fieldName: "background_id" });
    cache.gc();
  };

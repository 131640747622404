export const mergeFiltersAsOr = (
  includeA: boolean,
  includeB: boolean,
  whereA: object,
  whereB: object
) => {
  if (includeA && includeB) {
    return {
      _or: [whereA, whereB],
    };
  }

  if (includeA && !includeB) {
    return whereA;
  }

  if (!includeA && includeB) {
    return whereB;
  }

  return {};
};

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import SearchBar from "./SearchBar/SearchBar";
import styles from "./ResourceFilters.module.css";
import MultiSelectDropDown from "./MultiSelectDropDown/MultiSelectDropDown";
import { useGetFilterValuesQuery } from "generated/graphql";
import AgeDropDown from "./AgeDropDown/AgeDropDown";
import Button from "components/Button/Button";
import plusIcon from "assets/icons/plus.svg";
import { setShowAddResourceModal } from "redux/editRoomNavigationRedux";
import { selectOrganizationId } from "redux/userRedux";
import { PSYCHOEDUCATIONAL } from "../ActivityList";
import { getOrganizationConfig } from "utils/organizationUtils";

const SMALL_VIEW_MAX_WIDTH = 1240;

const getPossibleApproaches = (
  approaches: { approach: string }[] | undefined
) => {
  const possibleApproaches =
    approaches
      ?.map((entry) => entry.approach || "")
      ?.filter((approach) => approach !== "") || [];

  possibleApproaches.sort();

  return possibleApproaches;
};

const getPossibleTopics = (topics: { topic: string }[] | undefined) => {
  const possibleTopics = topics?.map((entry) => entry.topic || "") || [];

  possibleTopics.sort();

  return possibleTopics;
};

const getPossibleTypes = (
  categories: { categories?: string | null | undefined }[] | undefined
) => {
  const possibleTypes =
    categories?.map((entry) => entry.categories || "") || [];

  possibleTypes.push(PSYCHOEDUCATIONAL);
  possibleTypes.sort();

  return possibleTypes;
};

const getPossibleSources = (organizationName?: string) => {
  const possibleSources = ["Teleo Activity Bank", "Private"];
  if (organizationName) {
    possibleSources.unshift(organizationName);
  }
  return possibleSources;
};

type ResourceFiltersProps = {
  numResults: number | null;
  isFilterApplied: boolean;
  search: string;
  setSearch: (value: string) => void;
  approaches: string[];
  setApproaches: (value: string[]) => void;
  topics: string[];
  setTopics: (value: string[]) => void;
  types: string[];
  setTypes: (value: string[]) => void;
  sources: string[];
  setSources: (value: string[]) => void;
  ageMin: number;
  setAgeMin: (value: number) => void;
  ageMax: number;
  setAgeMax: (value: number) => void;
  clearSearch: () => void;
};

const ResourceFilters = ({
  numResults,
  isFilterApplied,
  search,
  setSearch,
  approaches,
  setApproaches,
  topics,
  setTopics,
  types,
  setTypes,
  sources,
  setSources,
  ageMin,
  setAgeMin,
  ageMax,
  setAgeMax,
  clearSearch,
}: ResourceFiltersProps) => {
  const organizationId = useSelector(selectOrganizationId);
  const organizationConfig = getOrganizationConfig(organizationId);
  const organizationName = organizationConfig?.hasCustomActivityBank
    ? organizationConfig?.name
    : undefined;
  const { data: filterValueData } = useGetFilterValuesQuery();
  const dispatch = useDispatch();

  const [smallView, setSmallView] = useState(
    window.innerWidth <= SMALL_VIEW_MAX_WIDTH
  );

  const resize = () => {
    setSmallView(window.innerWidth <= SMALL_VIEW_MAX_WIDTH);
  };

  useEffect(() => {
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  });

  const possibleApproaches = useMemo(
    () => getPossibleApproaches(filterValueData?.approaches),
    [filterValueData?.approaches]
  );

  const possibleTopics = useMemo(
    () => getPossibleTopics(filterValueData?.topics),
    [filterValueData?.topics]
  );

  const possibleTypes = useMemo(
    () => getPossibleTypes(filterValueData?.categories),
    [filterValueData?.categories]
  );

  const possibleSources = useMemo(
    () => getPossibleSources(organizationName),
    [organizationName]
  );

  const numResultsString =
    numResults === null
      ? ""
      : numResults === 1
      ? isFilterApplied
        ? "1 Result"
        : "1 Total activity"
      : isFilterApplied
      ? `${numResults} Results`
      : `${numResults} Total activities`;

  const addResource = () => {
    dispatch(setShowAddResourceModal(true));
  };

  return (
    <div className={styles.container}>
      <div className={styles.topRow}>
        <SearchBar
          search={search}
          setSearch={setSearch}
          smallWidth={smallView}
        />
        <div className={styles.resultsGroup}>
          <div
            className={clsx(styles.numResultsText, {
              [styles.numResultsTextSmall]: smallView,
            })}
          >
            {numResultsString}
          </div>
          {isFilterApplied && (
            <Button onClick={clearSearch} className={styles.clearFiltersButton}>
              Clear All
            </Button>
          )}
        </div>
        <Button onClick={addResource} className={styles.addActivityButton}>
          <div className={styles.buttonContents}>
            <img src={plusIcon} className={styles.plusIcon} />
            <span className={styles.buttonText}>
              {smallView ? "New" : "New Activity"}
            </span>
          </div>
        </Button>
      </div>
      <div className={styles.filtersRow}>
        <MultiSelectDropDown
          placeholderText={"Approach"}
          selectedValues={approaches}
          setValues={setApproaches}
          possibleValues={possibleApproaches}
        />
        <AgeDropDown
          selectedAgeMin={ageMin}
          selectedAgeMax={ageMax}
          setAgeMin={setAgeMin}
          setAgeMax={setAgeMax}
        />
        <MultiSelectDropDown
          placeholderText={"Topic"}
          selectedValues={topics}
          setValues={setTopics}
          possibleValues={possibleTopics}
        />
        <MultiSelectDropDown
          placeholderText={"Type"}
          selectedValues={types}
          setValues={setTypes}
          possibleValues={possibleTypes}
        />
        <MultiSelectDropDown
          placeholderText={"Source"}
          selectedValues={sources}
          setValues={setSources}
          possibleValues={possibleSources}
          rightAlign
        />
      </div>
    </div>
  );
};

export default ResourceFilters;

import React from "react";
import styles from "./ControlButton.module.css";
import clsx from "clsx";
import { fabricTypes } from "utils/fabric-impl";
import { COLORS } from "teleoConstants";

import deleteIcon from "assets/icons/eraser.svg";
import zoomOutIcon from "assets/icons/zoom_out.svg";
import zoomInIcon from "assets/icons/zoom_in.svg";
import { ReactComponent as ViewportLinkedIcon } from "assets/icons/viewport_linked.svg";
import { ReactComponent as ViewportUnlinkedIcon } from "assets/icons/viewport_unlinked.svg";
import { ReactComponent as HelpIcon } from "assets/icons/help.svg";

import {
  ERASER_BRUSH_HEIGHT_MULTIPLIER,
  getEraserBrush,
  getPaintBrush,
} from "pages/Space/subpages/SpaceRoom/utils/drawingUtils";
import TextButton, { TextSize } from "./TextButton";
import { TOOLBAR_BUTTON_BORDER_MULTIPLIER } from "./WhiteboardControls";
import { zoomLevelType } from "pages/Space/subpages/Whiteboard/whiteboardTypes";
import { useSelector } from "react-redux";
import { selectUserRole, UserRole } from "redux/userRedux";
import { selectIsViewportLocked } from "redux/settingsRedux";
import Tooltip from "components/Tooltip/Tooltip";
import { MultiColorButton } from "./MultiColorButton";
import { Peers } from "pages/Space/hooks/connection/usePeerWebRTCConnection";

export enum ControlButtonType {
  TEXT,
  FREE_DRAW,
  DELETE,
  ZOOM,
  VIEWPORT_LOCK,
  DIVIDER,
}

export type BaseConfig = {
  type: ControlButtonType;
};

interface FreeDrawConfig extends BaseConfig {
  type: ControlButtonType.FREE_DRAW;
  color: string;
  hasColorPicker?: boolean;
}

interface TextConfig extends BaseConfig {
  type: ControlButtonType.TEXT;
}

interface DeleteConfig extends BaseConfig {
  type: ControlButtonType.DELETE;
}

interface ZoomConfig extends BaseConfig {
  type: ControlButtonType.ZOOM;
}

interface ViewportLockConfig extends BaseConfig {
  type: ControlButtonType.VIEWPORT_LOCK;
}

interface DividerConfig extends BaseConfig {
  type: ControlButtonType.DIVIDER;
}

export type ControlButtonConfig =
  | FreeDrawConfig
  | TextConfig
  | DeleteConfig
  | ZoomConfig
  | ViewportLockConfig
  | DividerConfig;

type ControlButtonProps = {
  peersRef: React.MutableRefObject<Peers>;
  peers: Peers;
  id: string;
  config: ControlButtonConfig;
  isSelected: boolean;
  setIsSelected: () => void;
  canvasRef: React.MutableRefObject<fabricTypes.Canvas | undefined>;
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  focusedTextRef: React.MutableRefObject<fabricTypes.IText | undefined>;
  textSizeRef: React.MutableRefObject<TextSize>;
  emitBrushChange: (color: string) => void;
  buttonHeight: number;
  visibleHeight: number;
  setCursorRadius: (radius: number) => void;
  setShowCursor: (show: boolean) => void;
  is2xZoomEnabled: boolean;
  is3xZoomEnabled: boolean;
  zoomLevel: zoomLevelType;
  setZoomLevel: (value: zoomLevelType) => void;
  onToggleViewportLock: () => void;
  buttonSpacing: number;
};

const ControlButton = ({
  peersRef,
  peers,
  id,
  config,
  isSelected,
  setIsSelected,
  canvasRef,
  containerRef,
  focusedTextRef,
  textSizeRef,
  emitBrushChange,
  buttonHeight,
  visibleHeight,
  setCursorRadius,
  setShowCursor,
  is2xZoomEnabled,
  is3xZoomEnabled,
  zoomLevel,
  setZoomLevel,
  onToggleViewportLock,
  buttonSpacing,
}: ControlButtonProps) => {
  const userRole = useSelector(selectUserRole);
  const isViewportLocked = useSelector(selectIsViewportLocked);
  const isViewportLockButton = config.type === ControlButtonType.VIEWPORT_LOCK;

  const setCustomColor = (color: string) => {
    if (canvasRef.current) {
      canvasRef.current.freeDrawingBrush = getPaintBrush(
        color,
        visibleHeight,
        canvasRef.current
      );
      emitBrushChange(color);
    }
  };

  const onClick = () => {
    if (!isSelected && canvasRef.current) {
      if (
        config.type !== ControlButtonType.ZOOM &&
        config.type !== ControlButtonType.VIEWPORT_LOCK
      ) {
        setIsSelected();
      }
      if (config.type !== ControlButtonType.TEXT) {
        // Unfocus any text elements being edited
        if (focusedTextRef.current) {
          focusedTextRef.current.exitEditing();
          focusedTextRef.current = undefined;
        }
      }

      if (config.type === ControlButtonType.FREE_DRAW) {
        canvasRef.current.isDrawingMode = true;
        if (!config.hasColorPicker) {
          canvasRef.current.freeDrawingBrush = getPaintBrush(
            config.color,
            visibleHeight,
            canvasRef.current
          );
          emitBrushChange(config.color);
        }
        canvasRef.current.freeDrawingCursor = "crosshair";
        setShowCursor(false);
      }
      if (config.type === ControlButtonType.TEXT) {
        canvasRef.current.isDrawingMode = false;
        canvasRef.current.defaultCursor = "text";
        canvasRef.current.hoverCursor = "text";
        setShowCursor(false);
      }
      if (config.type === ControlButtonType.DELETE) {
        canvasRef.current.isDrawingMode = true;
        canvasRef.current.freeDrawingCursor = "none";
        canvasRef.current.freeDrawingBrush = getEraserBrush(
          visibleHeight,
          canvasRef.current
        );
        setCursorRadius((visibleHeight * ERASER_BRUSH_HEIGHT_MULTIPLIER) / 2);
        setShowCursor(true);
      }
      if (config.type === ControlButtonType.ZOOM) {
        let nextZoomLevel: zoomLevelType;
        switch (zoomLevel) {
          case "1x":
            nextZoomLevel = is2xZoomEnabled ? "2x" : "3x";
            setZoomLevel(nextZoomLevel);
            break;
          case "2x":
            nextZoomLevel = is3xZoomEnabled ? "3x" : "1x";
            setZoomLevel(nextZoomLevel);
            break;
          case "3x":
            setZoomLevel("1x");
            break;
        }
      }
      if (isViewportLockButton) {
        onToggleViewportLock();
      }
    }
  };

  const innerButtonHeight =
    (1 - 2 * TOOLBAR_BUTTON_BORDER_MULTIPLIER) * buttonHeight;
  const selectedButtonBorderWidth =
    TOOLBAR_BUTTON_BORDER_MULTIPLIER * buttonHeight;
  const hasBorder = isSelected || (isViewportLockButton && isViewportLocked);
  const buttonStyle = {
    height: innerButtonHeight,
    width: innerButtonHeight,
    borderRadius: innerButtonHeight,
    ...(hasBorder
      ? {
          marginTop: 0,
          marginRight: 0,
          marginBottom: buttonSpacing,
          marginLeft: 0,
        }
      : {
          marginTop: selectedButtonBorderWidth,
          marginRight: selectedButtonBorderWidth,
          marginBottom: selectedButtonBorderWidth + buttonSpacing,
          marginLeft: selectedButtonBorderWidth,
        }),
    fontSize: (38 / 70) * buttonHeight,
    borderWidth: hasBorder ? selectedButtonBorderWidth : 0,
  };
  const iconStyle = {
    height: (4 / 7) * buttonHeight,
    width: (4 / 7) * buttonHeight,
  };

  if (config.type === ControlButtonType.DIVIDER) {
    return (
      <div
        className={styles.dividerContainer}
        style={{ height: buttonHeight * 0.25, width: buttonHeight }}
      >
        <div
          className={styles.divider}
          style={{ marginBottom: buttonSpacing }}
        />
      </div>
    );
  }

  if (config.type === ControlButtonType.FREE_DRAW) {
    if (config.hasColorPicker) {
      const colorPickerButtonStyle = {
        width: buttonStyle.width * (isSelected ? 0.54 : 0.44),
        height: buttonStyle.height * (isSelected ? 0.54 : 0.44),
      };
      return (
        <MultiColorButton
          peersRef={peersRef}
          peers={peers}
          id={id}
          isSelected={isSelected}
          defaultColor={config.color}
          onClick={onClick}
          setCustomColor={setCustomColor}
          buttonStyle={buttonStyle}
          containerRef={containerRef}
          subIconStyle={colorPickerButtonStyle}
        />
      );
    }
    return (
      <button
        className={clsx(styles.button, { [styles.isSelected]: isSelected })}
        style={{ ...buttonStyle, backgroundColor: config.color }}
        onClick={onClick}
      />
    );
  }

  if (config.type === ControlButtonType.TEXT) {
    return (
      <TextButton
        isSelected={isSelected}
        buttonStyle={buttonStyle}
        textSizeRef={textSizeRef}
        onClick={onClick}
      />
    );
  }

  if (config.type === ControlButtonType.DELETE) {
    return (
      <button
        id={"eraserButton"}
        className={clsx(styles.button, { [styles.isSelected]: isSelected })}
        style={{ ...buttonStyle, backgroundColor: COLORS.GRAY }}
        onClick={onClick}
      >
        <img src={deleteIcon} style={iconStyle} />
      </button>
    );
  }

  if (config.type === ControlButtonType.ZOOM) {
    const sourceIcon =
      zoomLevel === "3x" || !is3xZoomEnabled ? zoomOutIcon : zoomInIcon;
    const isZoomEnabled =
      is2xZoomEnabled || is3xZoomEnabled || zoomLevel !== "1x";

    if (!isZoomEnabled) {
      return null;
    }

    return (
      <button
        id={isZoomEnabled ? "zoomButton" : undefined}
        className={styles.button}
        style={{ ...buttonStyle, backgroundColor: COLORS.GRAY }}
        onClick={onClick}
      >
        <img src={sourceIcon} style={iconStyle} />
      </button>
    );
  }

  if (isViewportLockButton) {
    if (userRole !== UserRole.THERAPIST) {
      return null;
    }
    const IconComponent = isViewportLocked
      ? ViewportLinkedIcon
      : ViewportUnlinkedIcon;

    const tooltipText = `${
      isViewportLocked ? "Unlink" : "Link"
    } scrolling and zooming with your client`;

    // 42% fits the help icon well without overlapping the link icon
    const helpIconStyles = {
      width: buttonStyle.width * 0.42,
      height: buttonStyle.height * 0.42,
    };

    return (
      <div className={styles.buttonContainer}>
        <button
          id={"viewportLockButton"}
          className={clsx(styles.button, {
            [styles.isToggled]: isViewportLocked,
          })}
          style={{ ...buttonStyle, backgroundColor: COLORS.GRAY }}
          onClick={onClick}
        >
          <IconComponent
            className={styles.svgIconComponent}
            style={iconStyle}
          />
        </button>
        <Tooltip
          elementWrapperClassName={styles.helpIconWrapper}
          elementWrapperStyle={{ bottom: buttonSpacing }}
          text={tooltipText}
          placement="bottom"
        >
          <HelpIcon style={helpIconStyles} onClick={onClick} />
        </Tooltip>
      </div>
    );
  }

  return null;
};

export default ControlButton;

import React, { useEffect, useState } from "react";
import OuterSpace from "./OuterSpace";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  UserRole,
  setClientPreAdmitToken,
  setDecodedAuthToken,
  setEncodedAuthToken,
  setSignInExpires,
  setUserRole,
} from "redux/userRedux";
import mixpanel from "mixpanel-browser";
import * as Sentry from "@sentry/react";
import { setMeetingID } from "redux/spaceNavigationRedux";
import { logUnexpectedError } from "utils/errorUtils";
import { useAppSignOut } from "utils/appSignOutUtils";
import { setSessionEHRSystem } from "redux/ehrSystemRedux";
import { backendRequest } from "utils/backendRequest";

const EpicMeeting = () => {
  const [loading, setLoading] = useState(true);
  const [roomIsValid, setRoomIsValid] = useState<boolean>();
  const [roomIsOpen, setRoomIsOpen] = useState<boolean>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const errorQueryParam = searchParams.get("error");

  const signOut = useAppSignOut();

  useEffect(() => {
    if (errorQueryParam) {
      logUnexpectedError(
        new Error("Error from epic sign in: " + errorQueryParam)
      );
      alert("Internal error. Please, try again later.");
    }
  }, [errorQueryParam]);

  if (errorQueryParam) {
    return null;
  }

  const epicSignIn = async () => {
    try {
      const mixpanelUserId: string = mixpanel.get_distinct_id();
      const code = searchParams.get("code");
      const state = searchParams.get("state");

      if (!code || !state) {
        throw new Error("Missing code or state");
      }

      const result = await backendRequest({
        path: "/sign-in-epic",
        searchParams: {
          code,
          state,
        },
        options: {
          method: "POST",
          body: JSON.stringify({
            mixpanelUserId,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        },
      });

      if (!result.ok && result.status === 403) {
        alert("Meeting already in use by other provider");
        logUnexpectedError(
          new Error("Failed to sign in due to meeting already in use")
        );
        return;
      }

      if (!result.ok && result.status === 503) {
        navigate("/maintenance");
        logUnexpectedError(
          new Error("Failed to sign in during deployment maintenance")
        );
        return;
      }

      if (!result.ok) {
        throw new Error("Failed to sign in");
      }
      const resultData = await result.json();
      const meetingID = resultData.meetingID;

      dispatch(setMeetingID(meetingID));
      dispatch(setSessionEHRSystem("epic"));
      if (resultData.userType === "therapist") {
        const encodedToken = resultData.encodedToken;
        dispatch(setEncodedAuthToken(encodedToken));
        const decodedToken = JSON.parse(resultData.decodedToken);
        if (decodedToken) {
          dispatch(setDecodedAuthToken(decodedToken));
        }
        const userId = decodedToken?.userId;
        if (userId) {
          Sentry.setUser({ id: userId });
        }
        // TODO: determine how we will set organization id during sign-in
        // This must be done before page load completes to ensure the logo is set
        dispatch(setUserRole(UserRole.THERAPIST));
      } else if (resultData.userType === "patient") {
        dispatch(setClientPreAdmitToken(resultData.preAdmitToken));
        dispatch(setUserRole(UserRole.CLIENT));
      }

      dispatch(setSignInExpires(resultData.expires));

      setRoomIsValid(true);
      setRoomIsOpen(true);
      setLoading(false);
    } catch (error) {
      logUnexpectedError(error);
      alert("Internal error. Please, try again later.");
    }
  };
  return (
    <OuterSpace
      loading={loading}
      roomIsValid={roomIsValid}
      roomIsOpen={roomIsOpen}
      startMeeting={epicSignIn}
      startMeetingTrigger="onInteract"
      signOut={signOut}
    />
  );
};

export default EpicMeeting;

import React from "react";
import { Link } from "react-router-dom";

const Maintenance = () => (
  <div
    style={{
      textAlign: "center",
      paddingTop: "100px",
      backgroundColor: "#f7d749",
      height: "100%",
    }}
  >
    <h1>Teleo is undergoing maintenance.</h1>
    <p>
      We apologize for the inconvenience. Please check back in a few minutes.
    </p>
    <Link to={`https://${process.env.REACT_APP_HOME_URL}`}>Try Again</Link>
  </div>
);

export default Maintenance;

import React from "react";

import teleoLogo from "assets/logo.png";
import plant from "assets/plant.png";

import styles from "./OutsideRoomLayout.module.css";
import { getOrganizationConfig } from "utils/organizationUtils";
import { useSelector } from "react-redux";
import { selectOrganizationId } from "redux/userRedux";

type OutsideRoomLayoutProps = { children: React.ReactNode };

const OutsideRoomLayout = ({ children }: OutsideRoomLayoutProps) => {
  const organizationId = useSelector(selectOrganizationId);
  const organizationConfig = getOrganizationConfig(organizationId);
  return (
    <div className={styles.container}>
      <img src={plant} alt={"Plant"} className={styles.plant} />
      <div className={styles.content}>
        {organizationConfig ? (
          <div className={styles.header}>
            <img
              src={organizationConfig.logo}
              className={organizationId}
              alt={organizationConfig.name}
            />
          </div>
        ) : (
          <div className={styles.header}>
            <img className={styles.logo} src={teleoLogo} alt="Teleo" />
            Teleo
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
export default OutsideRoomLayout;

import React, { useState } from "react";

import styles from "./ContainerDebugSection.module.css";
import CloseMeetingButton from "./CloseMeetingButton";
import { MeetingsData, RoomsConfig } from "./admin";
import { backendRequest } from "utils/backendRequest";

const ContainerDebugSection = () => {
  const [meetingsData, setMeetingsData] = useState<MeetingsData>();
  const [roomsConfig, setRoomsConfig] = useState<RoomsConfig>();
  const [loading, setLoading] = useState(false);

  const [actionLoading, setActionLoading] = useState(false);
  const [actionSuccess, setActionSuccess] = useState<boolean | undefined>();

  const fetchMeetingsAndConfig = () => {
    const doIt = async () => {
      setLoading(true);

      const meetingResult = await backendRequest({ path: "/rooms" });

      const configResult = await backendRequest({
        path: "/admin/rooms-api-config",
      });
      const meetingData = await meetingResult.json();
      const configData = await configResult.json();
      setMeetingsData(meetingData);
      setRoomsConfig(configData);
      setLoading(false);
    };
    doIt().catch((err) => {
      console.log(err);
      setMeetingsData(undefined);
      setRoomsConfig(undefined);
      setLoading(false);
    });
  };

  const adminActionRequest = (path: string, method: "PUT" | "POST") => {
    const doIt = async () => {
      setActionLoading(true);
      const result = await backendRequest({
        path: `/admin/${path}`,
        options: { method },
      });
      setActionSuccess(result.status === 200);
      setActionLoading(false);
      // Refresh the data after a delay to give the server time to update
      setTimeout(fetchMeetingsAndConfig, 1000);
    };
    doIt().catch((err) => {
      console.log(err);
      setActionSuccess(false);
      setActionLoading(false);
    });
  };

  const getMeetingRows = () => {
    if (loading) {
      return (
        <tr>
          <td>Loading</td>
        </tr>
      );
    }
    if (!meetingsData) {
      return (
        <tr>
          <td>No data</td>
        </tr>
      );
    }
    if (Object.keys(meetingsData.meetings).length === 0) {
      return (
        <tr>
          <td>No meetings</td>
        </tr>
      );
    }
    return Object.entries(meetingsData.meetings).map(
      ([meetingID, meetingInfo]) => (
        <tr key={meetingID}>
          <td>{meetingID}</td>
          <td>{meetingInfo.containerId}</td>
          <td>
            {new Date(meetingInfo.allocatedSince).toLocaleString("en-US", {
              timeZone: "America/Los_Angeles",
            })}
          </td>
          <td>{`${meetingInfo.therapistConnected}`}</td>
          <td>{`${meetingInfo.clientsConnected.length}`}</td>
          <td>
            <CloseMeetingButton
              meetingID={meetingID}
              callback={fetchMeetingsAndConfig}
            />
          </td>
        </tr>
      )
    );
  };

  const containerDebugInfo = () => (
    <>
      <h4>Containers</h4>{" "}
      <table>
        <tbody>
          <tr key={"header"}>
            <th>meetingID</th>
            <th>containerId</th>
            <th>allocatedSince (Pacific Time)</th>
            <th>therapistConnected</th>
            <th>clientsConnected</th>
            <th></th>
          </tr>
          {getMeetingRows()}
        </tbody>
      </table>
      <div className={styles.containersSection}>
        {Object.entries(meetingsData?.containers || {}).map(
          ([containerId, usage], index) => {
            return <p key={index}>{`${containerId}: ${usage}`}</p>;
          }
        )}
      </div>
    </>
  );

  const roomsConfigInfo = () => {
    if (actionLoading) {
      return <p>Loading</p>;
    }
    if (!roomsConfig) {
      return null;
    }
    return (
      <div>
        <h4>API Config</h4>
        {Object.entries(roomsConfig).map(
          ([
            roomName,
            { externalUrl, internalUrl, instanceStatus, instanceState },
          ]) => (
            <p key={roomName}>
              {roomName}: {externalUrl} {internalUrl && `(${internalUrl})`} -{" "}
              {instanceState} - {instanceStatus}
            </p>
          )
        )}
      </div>
    );
  };

  return (
    <>
      <h3>Containers Debug Info</h3>
      <h4>Actions</h4>
      <div className={styles.containerDebugButtons}>
        <button onClick={fetchMeetingsAndConfig}>
          Fetch Containers and Config
        </button>
        <button onClick={() => adminActionRequest("rooms-api-config", "PUT")}>
          Reload Rooms API Config from EC2
        </button>
        <button onClick={() => adminActionRequest("reload-env", "PUT")}>
          Reload Gateway Environmental Variables
        </button>
        <button
          onClick={() => adminActionRequest("create-unused-container", "POST")}
        >
          Create Unused Container (If Needed)
        </button>
      </div>
      {actionSuccess !== undefined && (
        <h5>
          Action Success: {actionSuccess !== undefined && `${actionSuccess}`}
        </h5>
      )}
      {containerDebugInfo()}
      {roomsConfigInfo()}
    </>
  );
};

export default ContainerDebugSection;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { toast } from "react-toastify";

import styles from "./ActivityNavigationHeader.module.css";
import {
  selectCurrentResourceId,
  setBrowserSandboxInitialize,
  setBrowserSandboxUrl,
  setCurrentPage,
  setCurrentResourceId,
  setCurrentRoomItemId,
  setShowSourceModal,
  setWhiteboardBackground,
  SpacePage,
} from "redux/spaceNavigationRedux";
import { selectUserRole, UserRole } from "redux/userRedux";
import { sendEventToPeers } from "utils/webrtcUtils";
import { ReactComponent as BackIcon } from "assets/icons/back_arrow.svg";
import { ReactComponent as WorldIcon } from "assets/icons/world.svg";
import { ReactComponent as BadgeIcon } from "assets/icons/badge.svg";
import { ReactComponent as Refresh } from "assets/icons/refresh.svg";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import Modal from "components/Modal/Modal";
import Button from "components/Button/Button";
import { createPortal } from "react-dom";
import { Peers } from "pages/Space/hooks/connection/usePeerWebRTCConnection";
import {
  useGetResourceSharedTypeQuery,
  useUpdateResourceSharedTypeMutation,
} from "generated/graphql";
import { RESOURCE_SHARED_TYPES } from "utils/organizationUtils";

type ActivityNavigationHeaderProps = {
  peersRef: React.MutableRefObject<Peers>;
  isExternal?: boolean;
  message?: string;
  onReset?: () => void;
  onGoBack?: () => void;
};

const ActivityNavigationHeader = ({
  peersRef,
  isExternal,
  message,
  onReset,
  onGoBack = () => {},
}: ActivityNavigationHeaderProps) => {
  const dispatch = useDispatch();
  const currentResourceId = useSelector(selectCurrentResourceId);
  const userRole = useSelector(selectUserRole);
  const isTherapist = userRole === UserRole.THERAPIST;

  const { data: resourceSharedTypeResult } = useGetResourceSharedTypeQuery({
    variables: { resourceId: currentResourceId ?? "" },
    skip: !currentResourceId || !isTherapist,
  });

  const [updateResourceSharedType] = useUpdateResourceSharedTypeMutation();

  const sharedType = resourceSharedTypeResult?.resource_by_pk?.shared_type;

  const handleUpdateSharedType = async () => {
    if (!currentResourceId) {
      return;
    }
    await updateResourceSharedType({
      variables: {
        resourceId: currentResourceId,
        sharedType: RESOURCE_SHARED_TYPES.COPY_ACCEPTED,
      },
    });
    toast("Shared activity successfully added to your personal activity bank", {
      type: "success",
    });
  };

  const goBack = () => {
    onGoBack();
    dispatch(setWhiteboardBackground(null));
    dispatch(setBrowserSandboxUrl(null));
    dispatch(setBrowserSandboxInitialize(null));
    dispatch(setCurrentPage(SpacePage.ROOM));
    dispatch(setCurrentResourceId(null));
    dispatch(setCurrentRoomItemId(null));
    sendEventToPeers(peersRef.current, "navigate", {
      currentPage: SpacePage.ROOM,
    });
  };

  const SourceIcon = isExternal ? WorldIcon : BadgeIcon;

  const openSourceModal = () => {
    dispatch(setShowSourceModal(true));
  };

  const [showResetConfirmation, setShowResetConfirmation] =
    useState<boolean>(false);

  const handleResetConfirm = () => {
    if (onReset) {
      onReset();
      setShowResetConfirmation(false);
    }
  };

  return (
    <div className={styles.navHeader}>
      <div
        className={styles.backButton}
        onClick={goBack}
        id={"backButton"}
        data-testid="back-button"
      >
        <BackIcon className={styles.backIcon} />
      </div>
      <div className={styles.rightGroup}>
        {showResetConfirmation &&
          createPortal(
            <Modal
              closeModal={() => setShowResetConfirmation(false)}
              className={styles.resetConfirmation}
            >
              <div className={styles.heading}>Reset Activity</div>
              <div className={styles.confirmationText}>
                If you reset this activity, all your progress will be lost.{" "}
                <br /> Are you sure you want to reset?
              </div>
              <div className={styles.buttonRow}>
                <Button onClick={() => setShowResetConfirmation(false)}>
                  Cancel
                </Button>
                <Button
                  id={"resetConfirmButton"}
                  onClick={handleResetConfirm}
                  className={styles.resetConfirmButton}
                >
                  Reset
                </Button>
              </div>
            </Modal>,
            document.body
          )}
        {isTherapist && sharedType === RESOURCE_SHARED_TYPES.COPY_DISMISSED && (
          <div className={styles.button} onClick={handleUpdateSharedType}>
            <Plus className={styles.plus} />
          </div>
        )}
        {onReset && (
          <div
            id={"resetButton"}
            onClick={() => setShowResetConfirmation(true)}
            className={styles.button}
          >
            <Refresh className={styles.reset} />
          </div>
        )}
        {message && <p className={styles.messageText}>{message}</p>}
        <div
          className={clsx(styles.button, {
            [styles.warnSourceButton]: isExternal,
          })}
          onClick={openSourceModal}
        >
          <SourceIcon className={styles.sourceIcon} />
        </div>
      </div>
    </div>
  );
};

export default ActivityNavigationHeader;

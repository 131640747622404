import {
  GetRoomMenuDataQuery,
  GetSharedRoomFolderModalDataQuery,
} from "generated/graphql";

const MAX_ROOM_NAME_COUNT = 500;

export const generateNewRoomName = (
  existingRoomDataNotNull: GetRoomMenuDataQuery["providerRooms"]
) => {
  const existingRoomNames = new Set(
    existingRoomDataNotNull.map((room) => room.name)
  );
  for (
    let i = existingRoomDataNotNull.length + 1;
    i <= MAX_ROOM_NAME_COUNT;
    i++
  ) {
    const newName = `Room ${i}`;
    if (!existingRoomNames.has(newName)) {
      return newName;
    }
  }
  return "Unnamed Room";
};

export const generateSharedRoomCopyName = (
  firstName: string | null | undefined,
  sharedRoomName: string,
  existingRoomDataNotNull:
    | GetSharedRoomFolderModalDataQuery["existingRoomNames"]
    | undefined
) => {
  const existingRoomNames = new Set(
    existingRoomDataNotNull?.map((room) => room.name)
  );
  const defaultNewName = `${sharedRoomName} (${firstName}'s Copy)`;
  if (!existingRoomNames.has(defaultNewName)) {
    return defaultNewName;
  }
  for (let i = 1; i <= MAX_ROOM_NAME_COUNT; i++) {
    const newName = `${sharedRoomName} (${firstName}'s Copy ${i})`;
    if (!existingRoomNames.has(newName)) {
      return newName;
    }
  }
  return defaultNewName;
};

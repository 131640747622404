import {
  EventFunctionArgs,
  UserMediaErrorEventData,
} from "pages/Space/eventMessagesTypes";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserRole, selectUserRole } from "redux/userRedux";
import { sendEventToPeers } from "utils/webrtcUtils";
import { getLocalPeerId, Peers } from "../connection/usePeerWebRTCConnection";
import { setPeerMediaError } from "redux/settingsRedux";

export const useSyncMediaError = (
  peersRef: React.MutableRefObject<Peers>,
  peers: Peers,
  userMediaError?: string
) => {
  const dispatch = useDispatch();

  const userRole = useSelector(selectUserRole);

  const latestUserMediaError = useRef<string | undefined>(userMediaError);

  useEffect(() => {
    latestUserMediaError.current = userMediaError;
  }, [userMediaError]);

  const sendMediaError = (error?: string) => {
    if (userRole === UserRole.CLIENT) {
      sendEventToPeers(peersRef.current, "userMediaError", {
        error: error,
        peerID: getLocalPeerId(),
      });
    }
  };

  useEffect(() => {
    sendMediaError(userMediaError);
  }, [userMediaError, peers]);

  useEffect(() => {
    const onReceiveMessageCallback = (event: MessageEvent) => {
      const data = JSON.parse(event.data);
      const eventType = data.event as EventFunctionArgs[0];
      if (eventType === "userMediaError") {
        const eventData = data.data as UserMediaErrorEventData;
        dispatch(
          setPeerMediaError({
            peerId: eventData.peerID,
            mediaError: eventData.error,
          })
        );
      }
    };
    const peerDataChannels = Object.values(peers).map(
      (peer) => peer.dataChannel
    );
    for (const peerDataChannel of peerDataChannels) {
      if (peerDataChannel) {
        peerDataChannel.addEventListener("message", onReceiveMessageCallback);
      }
    }
    return () => {
      for (const peerDataChannel of peerDataChannels) {
        if (peerDataChannel) {
          peerDataChannel.removeEventListener(
            "message",
            onReceiveMessageCallback
          );
        }
      }
    };
  }, [peers]);

  const triggerMediaErrorSync = () => {
    sendMediaError(latestUserMediaError.current);
  };
  return { triggerMediaErrorSync };
};

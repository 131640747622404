import React, { useState } from "react";

import styles from "./AdminPageButton.module.css";

type AdminPageButtonProps = {
  onClick: () => Promise<void>;
  children?: React.ReactNode;
};
const AdminPageButton = ({ onClick, children }: AdminPageButtonProps) => {
  const [loading, setLoading] = useState(false);

  const wrappedOnClick = async () => {
    setLoading(true);
    try {
      await onClick();
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  if (loading) {
    return <div className={styles.loading}>Loading</div>;
  }

  return (
    <button onClick={wrappedOnClick} className={styles.button}>
      {children}
    </button>
  );
};

export default AdminPageButton;

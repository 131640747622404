import { useEffect, useRef } from "react";
import { getAddBreadcrumb, logUnexpectedError } from "utils/errorUtils";
import { useTrackEvent } from "utils/metricsUtils";
import { RequestOptions } from "mixpanel-browser";

// 10 minutes
const DEVICE_INFORMATION_METRICS_INTERVAL = 10 * 60 * 1000;

export const addBreadcrumb = getAddBreadcrumb("connectionHealth");

const collectDeviceInformationMetricsAsync = async (
  trackEvent: (
    eventName: string,
    eventProperties?: any,
    options?: RequestOptions
  ) => void
) => {
  const mixpanelProps: { [key: string]: string | number | boolean } = {};
  try {
    mixpanelProps["navigator.language"] = navigator.language;
    mixpanelProps["navigator.cookieEnabled"] = navigator.cookieEnabled;

    mixpanelProps["connection.onLine"] = navigator.onLine;

    // navigator.connection is chrome only
    if ("connection" in navigator) {
      const connection: any = navigator.connection;
      mixpanelProps["connection.downlink"] = connection.downlink;
      mixpanelProps["connection.effectiveType"] = connection.effectiveType;
      mixpanelProps["connection.rtt"] = connection.rtt;
      mixpanelProps["connection.saveData"] = connection.saveData;
      mixpanelProps["connection.type"] = connection.type;
    }

    mixpanelProps["screen.width"] = screen.width;
    mixpanelProps["screen.height"] = screen.height;
    mixpanelProps["screen.availWidth"] = screen.availWidth;
    mixpanelProps["screen.availHeight"] = screen.availHeight;
    mixpanelProps["screen.colorDepth"] = screen.colorDepth;
    mixpanelProps["screen.pixelDepth"] = screen.pixelDepth;
    mixpanelProps["screen.innerWidth"] = window.innerWidth;
    mixpanelProps["screen.innerHeight"] = window.innerHeight;

    // screen.orientation.type is not supported prior to Safari 16.4
    if (screen.orientation?.type) {
      mixpanelProps["screen.orientation.type"] = screen.orientation.type;
    }

    if (window.performance) {
      // only available in chrome, even though it is deprecated
      if ("memory" in window.performance) {
        const memory: any = window.performance.memory;
        mixpanelProps["performance.jsHeapSizeLimit"] = memory.jsHeapSizeLimit;
        mixpanelProps["performance.totalJSHeapSize"] = memory.totalJSHeapSize;
        mixpanelProps["performance.usedJSHeapSize"] = memory.usedJSHeapSize;
      }

      if ("timing" in window.performance) {
        const timing = window.performance.timing;
        mixpanelProps["performance.loadTime"] =
          timing.loadEventEnd - timing.navigationStart;

        mixpanelProps["performance.domContentLoaded"] =
          timing.domContentLoadedEventEnd - timing.navigationStart;
      }

      // Capture CPU performance (estimation based on JavaScript task execution time)
      const startTime = performance.now();
      for (let i = 0; i < 1000000; i++) {} // Simple loop to simulate CPU load
      const endTime = performance.now();
      mixpanelProps["performance.cpuEstimation"] = endTime - startTime; // Time to execute simple task as a CPU performance proxy
    }

    // chrome only. it is capped at 8GiB for privacy reasons
    if ("deviceMemory" in navigator) {
      mixpanelProps["navigator.deviceMemory"] = navigator.deviceMemory as any;
    }

    // not supported in safari
    if (navigator.hardwareConcurrency) {
      mixpanelProps["navigator.hardwareConcurrency"] =
        navigator.hardwareConcurrency;
    }

    // Capture battery status
    if ("getBattery" in navigator) {
      const battery = await (navigator.getBattery as Function)();
      mixpanelProps["battery.batteryLevel"] = battery.level * 100; // Battery level as percentage
      mixpanelProps["battery.isCharging"] = battery.charging; // Whether the device is charging
    }

    // simple latency test with a request
    try {
      const startTime = new Date().getTime();
      const host = process.env.REACT_APP_SIGNALING_SERVER;
      if (!host || !host.startsWith("https://")) {
        throw new Error(
          "REACT_APP_SIGNALING_SERVER must be set and start with https://"
        );
      }
      await fetch(host);
      const endTime = new Date().getTime();
      mixpanelProps["latency.teleoApi"] = endTime - startTime;
    } catch (err) {
      logUnexpectedError(
        new Error("Failed to fetch teleo api", { cause: err })
      );
    }
  } catch (err) {
    logUnexpectedError(err);
  }
  addBreadcrumb("debug", "Device Information Metrics", { mixpanelProps });
  trackEvent("Device Information Metrics", mixpanelProps);
};

export const useCollectDeviceInformationMetrics = () => {
  const { trackEvent } = useTrackEvent();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    collectDeviceInformationMetricsAsync(trackEvent).catch(logUnexpectedError);
    intervalRef.current = setInterval(() => {
      collectDeviceInformationMetricsAsync(trackEvent).catch(
        logUnexpectedError
      );
    }, DEVICE_INFORMATION_METRICS_INTERVAL);
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);
};

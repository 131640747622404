const playroomImage = require("assets/backgrounds/playroom.png");
const rainbowImage = require("assets/backgrounds/rainbow.png");
const spaceImage = require("assets/backgrounds/space.png");
const defaultOlderImage = require("assets/backgrounds/default_older.png");
const bohoImage = require("assets/backgrounds/boho.png");
const graffitiImage = require("assets/backgrounds/graffiti.png");

export const COLLECTION_ID = {
  YOUNGER: "YOUNGER",
  OLDER: "OLDER",
};

export const BACKGROUND_ID = {
  PLAYROOM: "PLAYROOM",
  RAINBOW: "RAINBOW",
  SPACE: "SPACE",
  DEFAULT_OLDER: "DEFAULT_OLDER",
  BOHO: "BOHO",
  GRAFFITI: "GRAFFITI",
};

const BACKGROUND_ID_TO_IMAGE: { [index: string]: any } = {
  [BACKGROUND_ID.PLAYROOM]: playroomImage,
  [BACKGROUND_ID.RAINBOW]: rainbowImage,
  [BACKGROUND_ID.SPACE]: spaceImage,
  [BACKGROUND_ID.DEFAULT_OLDER]: defaultOlderImage,
  [BACKGROUND_ID.BOHO]: bohoImage,
  [BACKGROUND_ID.GRAFFITI]: graffitiImage,
};

export const getBackgroundImage = (backgroundId: string) => {
  return BACKGROUND_ID_TO_IMAGE[backgroundId] || playroomImage;
};

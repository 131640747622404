import { fabricTypes } from "utils/fabric-impl";
// We use a custom build of fabric including Erasing functionality,
// see http://fabricjs.com/build/

// @ts-ignore
export const fabric = window.fabric;

fabric.Object.prototype.objectCaching = false;
fabric.Object.prototype.selectable = false;
fabric.Object.prototype.hasBorders = false;
fabric.Object.prototype.hasControls = false;
fabric.IText.prototype.selectable = true;
fabric.IText.prototype.lockMovementX = true;
fabric.IText.prototype.lockMovementY = true;

export function isFabricIText(
  object: fabricTypes.Object | undefined
): object is fabricTypes.IText {
  return object?.type === "i-text";
}

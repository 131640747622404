import React from "react";

import styles from "./DisabledOverlay.module.css";

type DisabledOverlayProps = {
  message: string;
};

const DisabledOverlay = ({ message }: DisabledOverlayProps) => {
  return (
    <div className={styles.overlay}>
      <div className={styles.message}>{message}</div>
    </div>
  );
};

export default DisabledOverlay;

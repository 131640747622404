import { useSelector } from "react-redux";
import {
  selectEmail,
  selectUserId,
  selectUserJoinTimestamp,
} from "redux/userRedux";
import { useEffect } from "react";

export const usePendo = (agreedToTerms: boolean) => {
  const userId = useSelector(selectUserId);
  const email = useSelector(selectEmail);
  const userJoinTimestamp = useSelector(selectUserJoinTimestamp);

  useEffect(() => {
    // Initialize pendo only for therapists, and show onboarding only if the therapist joined
    // after the pendo launch date and they have agreed to the terms and conditions.
    if (userId && agreedToTerms && userJoinTimestamp) {
      // @ts-ignore
      window.pendo.initialize({
        visitor: {
          id: userId,
          email,
          userJoinTimestamp,
        },
        excludeAllText: true,
      });
    }
  }, [userId, agreedToTerms, userJoinTimestamp]);
};

export const showOnboardingGuide = () => {
  // @ts-ignore
  window.pendo.showGuideById("liHrnGIXK7YoeGz__AF8db0zTM4");
};

import React from "react";

import MenuRow from "../../Menu/MenuRow";
import { logUnexpectedError } from "utils/errorUtils";

import plusIcon from "assets/icons/plus.svg";
import faceIcon from "assets/icons/face.svg";
import skateboarderIcon from "assets/icons/skateboarder.svg";
import { GetRoomMenuDataQuery, RoomDataFragment } from "generated/graphql";
import { generateNewRoomName } from "./roomNameUtils";
import { useCreateRoom } from "./useCreateRoom";
import { useTrackEvent } from "utils/metricsUtils";

type RoomTemplate =
  | "defaultYoungerRoom"
  | "defaultOlderRoom"
  | "olderMindfulnessRoom"
  | "olderBehavioralActivationRoom"
  | "olderAnxietyRoom"
  | "olderCBTRoom"
  | "youngerCBTRoom"
  | "youngerEmotionsRoom"
  | "youngerAnxietyRoom"
  | "youngerPlayRoom";

type NewRoomMenuProps = {
  isFullScreenVideo?: boolean;
  loading: boolean;
  roomMenuData?: GetRoomMenuDataQuery;
  sendRemoteRoomChanged: () => void;
  closeMenu: () => void;
};

const NewRoomMenu = ({
  isFullScreenVideo,
  loading,
  roomMenuData,
  sendRemoteRoomChanged,
  closeMenu,
}: NewRoomMenuProps) => {
  const { createRoom } = useCreateRoom(sendRemoteRoomChanged);
  const { trackEvent } = useTrackEvent();

  const newRoom = async (roomData: RoomDataFragment | null | undefined) => {
    if (!roomMenuData || !roomData) {
      return;
    }
    const defaultRoomItems = roomData.room_items.map(
      // Remove '__typename' (added by Apollo) and 'id' before inserting items
      (item) => ({
        resource_id: item.resource_id,
        icon_id: item.icon_id,
        rx: item.rx,
        ry: item.ry,
        z: item.z,
      })
    );
    const defaultRoomBackground = roomData.background_id;
    const defaultRoomCollection = roomData.collection_id;
    const newRoomName = generateNewRoomName(roomMenuData.providerRooms);
    if (!defaultRoomBackground || !defaultRoomItems) {
      logUnexpectedError("Error creating new default room: missing data");
      return;
    }
    await createRoom(
      newRoomName,
      defaultRoomItems,
      defaultRoomBackground,
      defaultRoomCollection
    );
    trackEvent("New Room From Default");
    closeMenu();
  };

  const newRoomHandler = (roomTemplate: RoomTemplate) => {
    newRoom(roomMenuData?.[roomTemplate]).catch(logUnexpectedError);
  };

  return (
    <MenuRow label="New Room" iconSrc={plusIcon} elevated={isFullScreenVideo}>
      <MenuRow
        label="Younger Room"
        iconSrc={faceIcon}
        onClick={() => newRoomHandler("defaultYoungerRoom")}
        loading={loading}
        elevated={isFullScreenVideo}
        triggerOpenOnArrow
      >
        <MenuRow
          label="Default Room"
          onClick={() => newRoomHandler("defaultYoungerRoom")}
          loading={loading}
          elevated={isFullScreenVideo}
        />
        <MenuRow
          label="CBT Basics"
          onClick={() => newRoomHandler("youngerCBTRoom")}
          loading={loading}
          elevated={isFullScreenVideo}
        />
        <MenuRow
          label="Anxiety"
          onClick={() => newRoomHandler("youngerAnxietyRoom")}
          loading={loading}
          elevated={isFullScreenVideo}
        />
        <MenuRow
          label="Emotions"
          onClick={() => newRoomHandler("youngerEmotionsRoom")}
          loading={loading}
          elevated={isFullScreenVideo}
        />
        <MenuRow
          label="Play"
          onClick={() => newRoomHandler("youngerPlayRoom")}
          loading={loading}
          elevated={isFullScreenVideo}
        />
      </MenuRow>
      <MenuRow
        label="Older Room"
        iconSrc={skateboarderIcon}
        onClick={() => newRoomHandler("defaultOlderRoom")}
        loading={loading}
        elevated={isFullScreenVideo}
        triggerOpenOnArrow
      >
        <MenuRow
          label="Default Room"
          onClick={() => newRoomHandler("defaultOlderRoom")}
          loading={loading}
          elevated={isFullScreenVideo}
        />
        <MenuRow
          label="Mindfulness"
          onClick={() => newRoomHandler("olderMindfulnessRoom")}
          loading={loading}
          elevated={isFullScreenVideo}
        />
        <MenuRow
          label="Behavioral Activation"
          onClick={() => newRoomHandler("olderBehavioralActivationRoom")}
          loading={loading}
          elevated={isFullScreenVideo}
        />
        <MenuRow
          label="Anxiety"
          onClick={() => newRoomHandler("olderAnxietyRoom")}
          loading={loading}
          elevated={isFullScreenVideo}
        />
        <MenuRow
          label="CBT Basics"
          onClick={() => newRoomHandler("olderCBTRoom")}
          loading={loading}
          elevated={isFullScreenVideo}
        />
      </MenuRow>
    </MenuRow>
  );
};

export default NewRoomMenu;

import React from "react";
import { fabricTypes } from "utils/fabric-impl";

import styles from "./ItemActivityTooltip.module.css";
import { FloatingRootContext, ReferenceType } from "@floating-ui/react";
import { ICON_ID } from "../../../itemsConfig";
import clsx from "clsx";
import { RoomItemFragment } from "generated/graphql";
import { useTooltipFloating } from "../useTooltipFloating";

type ItemActivityTooltipProps = {
  roomItem: RoomItemFragment;
  canvasRef: React.MutableRefObject<fabricTypes.Canvas | undefined>;
  floatingContext: FloatingRootContext<ReferenceType>;
  show: boolean;
  setTooltipRef: (node: HTMLElement | null) => void;
};

const ItemActivityTooltip = ({
  roomItem,
  canvasRef,
  floatingContext,
  show,
  setTooltipRef,
}: ItemActivityTooltipProps) => {
  const resource = roomItem.resource;

  const iconType = roomItem.icon_id;
  const isPoster = iconType === ICON_ID.POSTER;

  const { floatingStyles } = useTooltipFloating(
    floatingContext,
    iconType,
    canvasRef
  );

  if (!canvasRef.current || !resource || isPoster) {
    return null;
  }

  return (
    <div
      className={clsx(styles.tooltip, { [styles.show]: show })}
      style={floatingStyles}
      ref={setTooltipRef}
    >
      {resource.name}
    </div>
  );
};

export default ItemActivityTooltip;

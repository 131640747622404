import * as bodySegmentation from "@tensorflow-models/body-segmentation";
// TODO: See if we can remove @tensorflow/tfjs-backend-webgl, @tensorflow/tfjs-core, or @tensorflow/tfjs-converter packages.
//  Removing @tensorflow/tfjs-core or @tensorflow/tfjs-converter currently gives build errors.
//  Removing @tensorflow/tfjs-backend-webgl seemed to work fine, but https://blog.tensorflow.org/2022/01/body-segmentation.html
//  says it is required, so we didn't want to risk removing it.
import "@tensorflow/tfjs-backend-webgl";
import { logUnexpectedError } from "utils/errorUtils";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectIsVideoBlurred, setIsVideoBlurred } from "redux/settingsRedux";
import { setShowVideoBlurLoading } from "redux/spaceNavigationRedux";
import { drawBokehEffect } from "pages/Space/hooks/video/utils/blurringUtils";
import { MediaPipeSelfieSegmentationModelType } from "@tensorflow-models/body-segmentation";
import { calculateFitDimensions } from "utils/sizingUtils";
import { isMobileDevice } from "utils/deviceUtils";
import { selectUserId, selectUserRole, UserRole } from "redux/userRedux";
import { useSetVideoBlurMutation } from "generated/graphql";

// Helpful documentation: https://blog.tensorflow.org/2022/01/body-segmentation.html
//
// Decision log:
// - Used the Selfie Segmentation model instead of BodyPix because BodyPix had worse accuracy and no better performance in testing
// - Used MediaPipe runtime rather than TFJS runtime because it loads faster and was more performant on desktop safari in testing, although TFJS was slightly more performant on ipad

const CONFIG = {
  modelType: "general", // Possible options: landscape or general; landscape is a smaller model but doesn't seem to significantly improve performance
  runRenderEvery: 4, // Run the segmentation and rendering only every X animation frames, to improve app responsiveness
  resolutionMaxSize: 480, // Compress the image so that its longest dimension (height or width) is this size; mainly useful for ensuring a consistent "blurriness", but also improves performance a bit
  foregroundThreshold: 0.7, // How confident the model has to be to consider a pixel as a person (0-1)
  backgroundBlur: 15, // How blurry to make the background (1-20)
  edgeBlur: 3, // How blurry to make the edge between the person and the background (1-20)
  // ChatGPT: "Common frame rates for video are 24 fps (film standard), 30 fps (TV standard), and 60 fps (high frame
  // rate for TV and gaming). In video conferencing, 30 fps is typically sufficient for clear and smooth video, as it
  // aligns with the standard frame rate for television and many online video platforms."
  outputStreamFPS: 30,
};

export const useBackgroundBlur = (videoStream: MediaStream | undefined) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const ctxRef = useRef<CanvasRenderingContext2D | null>(null);
  const videoElementRef = useRef<HTMLVideoElement | null>(null);
  const [outputStream, setOutputStream] = useState<MediaStream | null>(null);
  const segmenterRef = useRef<bodySegmentation.BodySegmenter | null>(null);
  const isVideoBlurred = useSelector(selectIsVideoBlurred);
  const requestAnimationFrameIDRef = useRef<number | null>(null);
  const isSegmenterSetUpStartedRef = useRef(false);
  const [isSegmenterSetUpComplete, setIsSegmenterSetUpComplete] =
    useState(false);
  const [isComponentSetUpComplete, setIsComponentSetUpComplete] =
    useState(false);
  const userId = useSelector(selectUserId);
  const userRole = useSelector(selectUserRole);
  const [setVideoBlurMutation] = useSetVideoBlurMutation();
  const isRenderStartedRef = useRef(false);
  const isRenderFrameRunningRef = useRef(false);
  const [isRenderReady, setIsRenderReady] = useState(false);
  const animationFrameCount = useRef(0);
  const tmpCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const tmpCtxRef = useRef<CanvasRenderingContext2D | null>(null);

  const dispatch = useDispatch();

  const handleError = (error: any) => {
    logUnexpectedError(error);
    dispatch(setIsVideoBlurred(false));
    if (userRole === UserRole.THERAPIST) {
      setVideoBlurMutation({
        variables: {
          userId,
          videoBlur: false,
        },
      }).catch(logUnexpectedError);
    }
    alert("There was an error blurring the video background.");
  };

  const loadSegmenterOnce = async () => {
    try {
      if (segmenterRef.current) return;
      segmenterRef.current = await bodySegmentation.createSegmenter(
        bodySegmentation.SupportedModels.MediaPipeSelfieSegmentation,
        {
          runtime: "mediapipe",
          modelType: CONFIG.modelType as MediaPipeSelfieSegmentationModelType,
          // TODO: Consider hosting these static files ourselves if helpful in the future
          solutionPath:
            "https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation",
        }
      );
    } catch (error) {
      segmenterRef.current = null;
      handleError(error);
    }
  };

  const cancelAnimationFrameIfExists = () => {
    if (requestAnimationFrameIDRef.current) {
      cancelAnimationFrame(requestAnimationFrameIDRef.current);
      requestAnimationFrameIDRef.current = null;
    }
  };

  const renderFrame = async () => {
    try {
      if (
        !isRenderStartedRef.current ||
        isRenderFrameRunningRef.current ||
        !segmenterRef.current ||
        !canvasRef.current ||
        !ctxRef.current ||
        !videoElementRef.current
      ) {
        return;
      }
      isRenderFrameRunningRef.current = true;

      const finishRender = () => {
        if (isRenderStartedRef.current) {
          setIsRenderReady(true);
          // Cancel any duplicated animation request that has been started in the meantime
          cancelAnimationFrameIfExists();
          animationFrameCount.current += 1;
          requestAnimationFrameIDRef.current =
            requestAnimationFrame(renderFrame);
        }
        isRenderFrameRunningRef.current = false;
      };

      const runRenderEvery = CONFIG.runRenderEvery;
      const videoWidth = videoElementRef.current?.videoWidth;
      const videoHeight = videoElementRef.current?.videoHeight;

      if (
        (runRenderEvery &&
          animationFrameCount.current % runRenderEvery !== 0) ||
        videoWidth === 0 ||
        videoHeight === 0
      ) {
        finishRender();
        return;
      }

      const { fitHeight: height, fitWidth: width } = calculateFitDimensions(
        CONFIG.resolutionMaxSize,
        CONFIG.resolutionMaxSize,
        videoHeight,
        videoWidth,
        null
      );
      if (!tmpCanvasRef.current) {
        tmpCanvasRef.current = document.createElement("canvas");
      }
      if (!tmpCtxRef.current) {
        tmpCtxRef.current = tmpCanvasRef.current.getContext("2d");
      }
      tmpCanvasRef.current.width = width;
      tmpCanvasRef.current.height = height;
      tmpCtxRef.current?.drawImage(
        videoElementRef.current,
        0,
        0,
        width,
        height
      );
      const segmentationInput = tmpCtxRef.current?.getImageData(
        0,
        0,
        width,
        height
      );
      if (segmentationInput === undefined) {
        handleError(
          "Undefined segmentation input- stopping render of blurred background"
        );
        return;
      }

      const segmentation = await segmenterRef.current.segmentPeople(
        segmentationInput,
        {
          flipHorizontal: false,
        }
      );

      await drawBokehEffect(
        canvasRef.current,
        segmentationInput,
        segmentation,
        CONFIG.foregroundThreshold,
        CONFIG.backgroundBlur,
        CONFIG.edgeBlur
      );

      ctxRef.current?.drawImage(
        canvasRef.current,
        0,
        0,
        segmentationInput.width,
        segmentationInput.height
      );

      finishRender();
    } catch (error) {
      handleError(error);
    }
  };

  const setUpInputVideoBasedElements = async () => {
    if (!videoStream) return;

    if (!canvasRef.current) {
      canvasRef.current = document.createElement("canvas");
    }
    if (!ctxRef.current) {
      ctxRef.current = canvasRef.current.getContext("2d");
    }

    if (!videoElementRef.current) {
      videoElementRef.current = document.createElement("video");
      videoElementRef.current.muted = true;
      // Must set playsInline and muted attributes for this to work on iPhone
      videoElementRef.current.playsInline = true;
      videoElementRef.current.defaultMuted = true;
    }
    if (videoElementRef.current.srcObject !== videoStream) {
      // Initialize or update the input video element with the new videoStream if needed
      videoElementRef.current.srcObject = videoStream;
      try {
        await videoElementRef.current.play();
      } catch (error: any) {
        // Safari seems to regularly throw an "AbortError: The operation was aborted." error here.
        // And when switching on bluetooth headphones, we get an error:
        // "AbortError: The play() request was interrupted by a new load request. https://goo.gl/LdLk22"
        // These doesn't seem to affect playback, so we ignore this error and don't show an alert or turn off blurring.
        if (
          error.name === "AbortError" &&
          (error.message === "The operation was aborted." ||
            error.message.startsWith(
              "The play() request was interrupted by a new load request"
            ))
        ) {
          logUnexpectedError(
            new Error(
              "[Potentially expected] AbortError in background blur video element play",
              {
                cause: error,
              }
            )
          );
        } else {
          throw error;
        }
      }
      // Required for the tfjs MediaPipeSelfieSegmentation code to recognize the input size
      videoElementRef.current.width = videoElementRef.current.videoWidth;
      videoElementRef.current.height = videoElementRef.current.videoHeight;

      // Set up the output stream.
      const newOutputStream =
        canvasRef.current?.captureStream(CONFIG.outputStreamFPS) || null;
      const audioTracks = videoStream.getAudioTracks();
      audioTracks.forEach((track) => {
        newOutputStream.addTrack(track);
      });
      // Clean up the existing output stream if one was already created (in the case of the input
      // videoStream changing).
      if (outputStream) {
        outputStream.getVideoTracks().forEach((track) => {
          track.stop();
        });
      }
      setOutputStream(newOutputStream);
    }
  };

  const startRender = async () => {
    // Be sure to only start a single render loop at a time
    if (isRenderStartedRef.current) return;
    isRenderStartedRef.current = true;
    cancelAnimationFrameIfExists();
    animationFrameCount.current = 0;
    requestAnimationFrameIDRef.current = requestAnimationFrame(renderFrame);
  };

  const stopRender = () => {
    isRenderStartedRef.current = false;
    setIsRenderReady(false);
    cancelAnimationFrameIfExists();
  };

  const setUpSegmenter = async () => {
    try {
      dispatch(setShowVideoBlurLoading(true));
      await loadSegmenterOnce();
      setIsSegmenterSetUpComplete(true);
    } catch (error) {
      handleError(error);
    } finally {
      dispatch(setShowVideoBlurLoading(false));
    }
  };

  const setUpOrUpdateVideoStream = async () => {
    stopRender();
    await setUpInputVideoBasedElements();
    setIsComponentSetUpComplete(true);
    if (isSegmenterSetUpComplete) {
      await startRender();
    }
  };

  const cleanUpInputVideoBasedElements = () => {
    stopRender();
    if (videoElementRef.current) {
      videoElementRef.current.srcObject = null;
    }
    if (outputStream) {
      outputStream.getVideoTracks().forEach((track) => {
        track.stop();
      });
      setOutputStream(null);
    }
  };

  const cleanUp = () => {
    stopRender();
    canvasRef.current = null;
    ctxRef.current = null;
    cleanUpInputVideoBasedElements();
    videoElementRef.current = null;
    if (segmenterRef.current) {
      segmenterRef.current.dispose();
      segmenterRef.current = null;
    }
  };

  // Wait to initialize the pipeline until the user starts blurring the video,
  // to prevent unnecessary resource usage such as downloading the model, if the user is never going
  // to blur their background. This does result in a few second delay when the user first starts blurring,
  // but that seems worth it for now to not require everyone to use these resources.
  useEffect(() => {
    // Don't run background blurring on mobile devices because it's too resource intensive.
    // We don't show the blur button on these devices, but also add the check here to be safe before instantiating
    // the segmenter, in case e.g. the provider has background blurring set to true in their DB settings when they
    // join on a mobile device.
    if (isMobileDevice()) return;

    if (isVideoBlurred && !isSegmenterSetUpStartedRef.current) {
      isSegmenterSetUpStartedRef.current = true;
      setUpSegmenter().catch(handleError);
    }
    // Pause rendering when the user stops blurring the video.
    // Note that the model does not seem to be cached if the segmenter is disposed, so we don't dispose of the
    // segmenter once loaded, to avoid a long pause if the user toggles the blur on again.
    if (!isVideoBlurred) {
      stopRender();
    }
  }, [isVideoBlurred]);

  useEffect(() => {
    if (isMobileDevice()) return;

    if (videoStream) {
      setUpOrUpdateVideoStream().catch(handleError);
    } else {
      // If video conferencing is turned off, clean up to avoid errors because of using the old video stream
      cleanUpInputVideoBasedElements();
    }
  }, [videoStream]);

  useEffect(() => {
    if (isMobileDevice()) return;

    if (
      isSegmenterSetUpComplete &&
      isComponentSetUpComplete &&
      isVideoBlurred
    ) {
      startRender().catch(handleError);
    }
  }, [isSegmenterSetUpComplete, isComponentSetUpComplete, isVideoBlurred]);

  useEffect(() => {
    if (isMobileDevice()) return;

    return cleanUp;
  }, []);

  return {
    outputStream,
    ready: isRenderReady,
  };
};

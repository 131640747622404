import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { selectIsVideoBlurred, setIsVideoBlurred } from "redux/settingsRedux";
import { ReactComponent as BlurIcon } from "assets/icons/blur.svg";
import { ReactComponent as BlurOffIcon } from "assets/icons/blur_off.svg";
import { VideoButton } from "pages/Space/components/Videos/VideosButtons/VideoButton";
import { useSetVideoBlurMutation } from "generated/graphql";
import { selectUserId, selectUserRole, UserRole } from "redux/userRedux";
import { logUnexpectedError } from "utils/errorUtils";
import { useLogMediaEvent } from "utils/metricsUtils";

type BlurButtonProps = {
  className: string;
  style?: React.CSSProperties;
};

export const BlurButton = ({ className, style }: BlurButtonProps) => {
  const isBlurred = useSelector(selectIsVideoBlurred);
  const userId = useSelector(selectUserId);
  const userRole = useSelector(selectUserRole);
  const [setVideoBlurMutation] = useSetVideoBlurMutation();

  const dispatch = useDispatch();

  const logMediaEvent = useLogMediaEvent();

  const toggleBlur = () => {
    const newBlurValue = !isBlurred;
    dispatch(setIsVideoBlurred(newBlurValue));
    if (userRole === UserRole.THERAPIST) {
      setVideoBlurMutation({
        variables: {
          userId,
          videoBlur: newBlurValue,
        },
      }).catch(logUnexpectedError);
    }
    logMediaEvent(newBlurValue ? "ENABLE_BLUR" : "DISABLE_BLUR");
  };

  return (
    <VideoButton
      className={className}
      style={style}
      onClick={toggleBlur}
      tooltipText={isBlurred ? "Un-blur Background" : "Blur Background"}
      forceVisible={false}
      icon={isBlurred ? BlurOffIcon : BlurIcon}
    />
  );
};

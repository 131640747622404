import React from "react";

import styles from "./TimeoutWarningModal.module.css";
import Button from "components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { setShowTimeoutWarningModal } from "redux/spaceNavigationRedux";
import Modal from "components/Modal/Modal";
import { selectUserRole, UserRole } from "redux/userRedux";

const TimeoutWarningModal = () => {
  const dispatch = useDispatch();
  const userRole = useSelector(selectUserRole);

  const closeModal = () => {
    dispatch(setShowTimeoutWarningModal(false));
  };

  const text =
    userRole === UserRole.THERAPIST
      ? "This room will be closed automatically after 2 more minutes of inactivity."
      : "This session will be ended automatically after 2 more minutes of inactivity.";

  return (
    <Modal closeModal={closeModal}>
      <div className={styles.heading}>Are you still there?</div>
      <div className={styles.text}>{text}</div>
      <Button onClick={closeModal}>Stay Connected</Button>
    </Modal>
  );
};

export default TimeoutWarningModal;

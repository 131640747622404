import { createSlice } from "@reduxjs/toolkit";

// null means the user authenticated through Teleo's sign in
type EHRSystem = "epic" | null;

export interface EHRSystemState {
  // the EHR system that the user is authenticated with
  sessionEHRSystem: EHRSystem;
}

export interface RootState {
  EHRSystem: EHRSystemState;
}

const INITIAL_STATE = {
  sessionEHRSystem: null,
} as EHRSystemState;

export const eHRSystemSlice = createSlice({
  name: "EHRSystem",
  initialState: INITIAL_STATE,
  reducers: {
    setSessionEHRSystem: (
      state,
      { payload }: { payload: EHRSystemState["sessionEHRSystem"] }
    ) => {
      state.sessionEHRSystem = payload;
    },
  },
});

export const { setSessionEHRSystem } = eHRSystemSlice.actions;

export const selectSessionEHRSystem = (state: RootState) => {
  return state.EHRSystem.sessionEHRSystem;
};

export default eHRSystemSlice.reducer;

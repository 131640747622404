import React from "react";

import styles from "./SideBarMenu.module.css";
import SideBarMenuItem from "./SideBarMenuItem";
import categoryIcon from "assets/icons/category.svg";
import posterIcon from "assets/icons/poster.svg";
import paintIcon from "assets/icons/paint.svg";
import plusIcon from "assets/icons/plus.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectRoomItems } from "redux/spaceNavigationRedux";
import {
  setEditItemId,
  setShowAddItemSideBar,
  setShowBackgroundEditSideBar,
} from "redux/editRoomNavigationRedux";
import { isPoster } from "../../utils/posterUtil";

const SideBarMenu = () => {
  const roomItems = useSelector(selectRoomItems);
  const dispatch = useDispatch();

  const addItem = () => {
    dispatch(setShowAddItemSideBar(true));
  };

  const customizePoster = () => {
    const posterItem = roomItems?.find((item) => item.icon_id === "POSTER");
    if (posterItem) {
      dispatch(setEditItemId(posterItem.id));
    }
  };

  const changeBackground = () => {
    dispatch(setShowBackgroundEditSideBar(true));
  };

  const numberOfPosters = roomItems?.filter(isPoster).length || 0;
  const multiplePosters = numberOfPosters > 1;

  return (
    <div className={styles.container}>
      <div className={styles.headerRow}>
        <div className={styles.header}>Edit Your Room</div>
      </div>
      <div className={styles.body}>
        <SideBarMenuItem
          iconSrc={categoryIcon}
          title={"Edit Items"}
          description={"Click on an item in the room to change the activity."}
        />
        {numberOfPosters > 0 ? (
          <>
            <div className={styles.horizontalLine} />
            <SideBarMenuItem
              iconSrc={posterIcon}
              title={"Customize the Poster" + (multiplePosters ? "s" : "")}
              navigation={multiplePosters ? undefined : customizePoster}
              description={
                multiplePosters
                  ? "Click on a poster in the room to edit it."
                  : undefined
              }
            />
          </>
        ) : null}
        <div className={styles.horizontalLine} />
        <SideBarMenuItem
          iconSrc={paintIcon}
          title={"Change the Room Background"}
          navigation={changeBackground}
        />
        <div className={styles.horizontalLine} />
        <SideBarMenuItem
          iconSrc={plusIcon}
          title={"Add an Item"}
          navigation={addItem}
        />
      </div>
    </div>
  );
};

export default SideBarMenu;

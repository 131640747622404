import React, { useEffect, useRef, useState } from "react";

import styles from "./SnapshotSnackbar.module.css";
import { SnapshotTask, removeSnapshotTask } from "redux/spaceNavigationRedux";
import clsx from "clsx";

import controlBarStyles from "../ControlBar.module.css";
import { useDispatch } from "react-redux";

type SnapshotSnackbarProps = {
  snapshotTask: SnapshotTask;
  clientButtonRef?: React.RefObject<HTMLDivElement>;
};

const SnapshotSnackbar = ({
  snapshotTask,
  clientButtonRef,
}: SnapshotSnackbarProps) => {
  const [imageDataURL, setImageDataURL] = useState<string | undefined>(
    undefined
  );
  const [isClosing, setIsClosing] = useState<boolean>(false);
  const snackbarRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (snapshotTask.image) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result && typeof event.target?.result === "string") {
          setImageDataURL(event.target?.result);
        }
      };
      reader.readAsDataURL(snapshotTask.image);
    }
  }, [snapshotTask.image]);

  useEffect(() => {
    if (snapshotTask.status === "saving") {
      clientButtonRef?.current?.classList.remove(
        controlBarStyles.clientSelected
      );
      setTimeout(() => {
        setIsClosing(true);
        clientButtonRef?.current?.classList.add(
          controlBarStyles.clientSelected
        );
      }, 1500);
      snackbarRef.current?.addEventListener("transitionend", () => {
        dispatch(removeSnapshotTask(snapshotTask.id));
      });
    }
  }, [snapshotTask.status]);

  return (
    <>
      {snapshotTask.image && (
        <div
          className={clsx(styles.snackbar, styles[snapshotTask.status], {
            [styles.closing]: isClosing,
          })}
          ref={snackbarRef}
        >
          <img src={imageDataURL} className={styles.snapshot} />
        </div>
      )}
    </>
  );
};

export default SnapshotSnackbar;

import React, { ChangeEvent, useState } from "react";

import styles from "pages/AdminPage/CustomResourceLookupSection.module.css";
import AdminPageButton from "./AdminPageButton";
import { getFileUrl } from "utils/fileUtils";

const MAX_TEXT_LENGTH = 1000;

const CustomResourceLookupSection = () => {
  const [result, setResult] = useState<string>();
  const [fileKey, setFileKey] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const getDownloadUrl = async () => {
    try {
      setLoading(true);
      const url = await getFileUrl(fileKey, "activity", true);
      setResult(url);
    } catch (err) {
      console.log(err);
      alert("There was an error retrieving the download URL");
    } finally {
      setLoading(false);
    }
  };

  const fileKeyChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setFileKey(event.target.value);
  };

  return (
    <div className={styles.container}>
      <h3>Get Custom File Resource</h3>
      Resource file key
      <input
        type="text"
        value={fileKey}
        className={styles.fileKeyInput}
        onChange={fileKeyChangeHandler}
        maxLength={MAX_TEXT_LENGTH}
        placeholder={"e.g. custom/00483060-a8d9-415c-9a07-a3b9480f9411.png"}
      />
      <AdminPageButton onClick={getDownloadUrl}>
        Get Download URL
      </AdminPageButton>
      {loading ? "..." : result}
      {result && (
        <a href={result} target="_blank" rel="noreferrer">
          Download
        </a>
      )}
    </div>
  );
};

export default CustomResourceLookupSection;

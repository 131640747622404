// A Audio stream component for either a local or remote stream

import React, { useEffect, useRef } from "react";

type AudioProps = {
  stream: MediaStream | undefined;
  muted?: boolean;
};

export const Audio = ({ stream, muted }: AudioProps) => {
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (audioRef.current && stream) {
      audioRef.current.srcObject = stream;
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.srcObject = null;
      }
    };
  }, [stream]);

  return <audio ref={audioRef} autoPlay muted={muted}></audio>;
};

import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setShowTimeoutWarningModal } from "redux/spaceNavigationRedux";

// Logout after the user has been inactive for this amount of time
const INACTIVITY_LOGOUT_MS = 60 * 60 * 1000; // 1 hour

// Show a warning this amount of time before logging the user out due to inactivity
const INACTIVITY_WARNING_MS_DELTA = 2 * 60 * 1000; // 2 minutes

// Log the user out after this much time no matter what
const TOTAL_LOGOUT_MS = 18 * 60 * 60 * 1000; // 18 hours

// For performance, only send one "activity" signal every this period of time
const THROTTLE_MS = 60 * 1000; // 1 minute

export const useAutomaticLogout = (
  signOut: () => void,
  onActivity?: () => void
) => {
  const warningTimeout = useRef<NodeJS.Timeout>();
  const logoutTimeout = useRef<NodeJS.Timeout>();
  const totalLogoutTimeout = useRef<NodeJS.Timeout>();
  const throttlingTimeout = useRef<NodeJS.Timeout>();
  const startTime = useRef(Date.now());
  const lastActiveTime = useRef(Date.now());
  const blockActiveTimeUpdates = useRef(false);
  const dispatch = useDispatch();

  const startActivityTimers = () => {
    warningTimeout.current = setTimeout(() => {
      dispatch(setShowTimeoutWarningModal(true));
    }, INACTIVITY_LOGOUT_MS - INACTIVITY_WARNING_MS_DELTA);

    logoutTimeout.current = setTimeout(signOut, INACTIVITY_LOGOUT_MS);
  };

  const clearTimersOnActivity = () => {
    if (warningTimeout.current) {
      clearTimeout(warningTimeout.current);
    }
    if (logoutTimeout.current) {
      clearTimeout(logoutTimeout.current);
    }
  };

  const resetActivityTimers = () => {
    clearTimersOnActivity();
    startActivityTimers();
  };

  const handleActivity = () => {
    if (onActivity) {
      onActivity();
    }
    if (!blockActiveTimeUpdates.current) {
      lastActiveTime.current = Date.now();
    }
    resetActivityTimers();
  };

  const throttledHandleActivity = () => {
    if (!throttlingTimeout.current) {
      handleActivity();
      // Block additional updates for THROTTLE_MS ms
      throttlingTimeout.current = setTimeout(() => {
        if (throttlingTimeout.current) {
          clearTimeout(throttlingTimeout.current);
        }
        throttlingTimeout.current = undefined;
      }, THROTTLE_MS);
    }
  };

  // Since timers do not run consistently when the site is not active, check on the timeouts whenever the page becomes
  // active again, and reset the timers.
  const checkOnVisibilityChange = () => {
    if (document.visibilityState === "hidden") {
      // Indicate that last active time should not be updated until the 'visible' state event has been processed
      blockActiveTimeUpdates.current = true;
      return;
    }
    const now = Date.now();
    if (lastActiveTime.current + INACTIVITY_LOGOUT_MS <= now) {
      signOut();
    }
    if (startTime.current + TOTAL_LOGOUT_MS <= now) {
      signOut();
    }
    blockActiveTimeUpdates.current = false;
    handleActivity();
    if (totalLogoutTimeout.current) {
      clearTimeout(totalLogoutTimeout.current);
    }
    // Reset the total timeout to the remaining time, in case the timer has gotten off while inactive
    totalLogoutTimeout.current = setTimeout(
      signOut,
      TOTAL_LOGOUT_MS - (now - startTime.current)
    );
  };

  useEffect(() => {
    startActivityTimers();
    totalLogoutTimeout.current = setTimeout(signOut, TOTAL_LOGOUT_MS); // Note that a credential expiration time sign out is implemented in useSignIn
    window.addEventListener("pointermove", throttledHandleActivity);
    window.addEventListener("pointerdown", throttledHandleActivity); // for touchscreens, in case they only press and don't drag
    window.addEventListener("visibilitychange", checkOnVisibilityChange);

    return () => {
      window.removeEventListener("pointermove", throttledHandleActivity);
      window.removeEventListener("pointerdown", throttledHandleActivity);
      window.removeEventListener("visibilitychange", checkOnVisibilityChange);
      clearTimersOnActivity();
      if (totalLogoutTimeout.current) {
        clearTimeout(totalLogoutTimeout.current);
      }
    };
  }, []);
};

import React, {
  MutableRefObject,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styles from "./WhiteboardControls.module.css";
import ControlButton, {
  ControlButtonConfig,
  ControlButtonType,
} from "./ControlButton";
import { COLORS } from "teleoConstants";
import { fabricTypes } from "utils/fabric-impl";
import { TextSize } from "./TextButton";
import { zoomLevelType } from "./whiteboardTypes";
import { Peers } from "pages/Space/hooks/connection/usePeerWebRTCConnection";

export const TOOLBAR_HORIZONTAL_PADDING_MULTIPLIER = 27 / 70;
export const TOOLBAR_BUTTON_SPACING_MULTIPLIER = 1 / 7;
export const TOOLBAR_BUTTON_BORDER_MULTIPLIER = 1 / 14;

type OptionType = {
  id: string;
  config: ControlButtonConfig;
};

const OPTIONS: OptionType[] = [
  {
    id: "TEXT",
    config: {
      type: ControlButtonType.TEXT,
    },
  },
  {
    id: "DELETE",
    config: {
      type: ControlButtonType.DELETE,
    },
  },
  {
    id: "DARK",
    config: {
      type: ControlButtonType.FREE_DRAW,
      color: COLORS.DARK,
    },
  },
  {
    id: "GREEN",
    config: {
      type: ControlButtonType.FREE_DRAW,
      color: COLORS.GREEN,
    },
  },
  {
    id: "BLUE",
    config: {
      type: ControlButtonType.FREE_DRAW,
      color: COLORS.BLUE,
    },
  },
  {
    id: "YELLOW",
    config: {
      type: ControlButtonType.FREE_DRAW,
      color: COLORS.YELLOW,
    },
  },
  {
    id: "RED",
    config: {
      type: ControlButtonType.FREE_DRAW,
      color: COLORS.RED,
    },
  },
  {
    id: "Divider",
    config: {
      type: ControlButtonType.DIVIDER,
    },
  },
  {
    id: "Orange",
    config: {
      type: ControlButtonType.FREE_DRAW,
      color: COLORS.ORANGE,
      hasColorPicker: true,
    },
  },
  {
    id: "Purple",
    config: {
      type: ControlButtonType.FREE_DRAW,
      color: COLORS.PURPLE,
      hasColorPicker: true,
    },
  },
  {
    id: "ZOOM",
    config: {
      type: ControlButtonType.ZOOM,
    },
  },
  {
    id: "VIEWPORT_LOCK",
    config: {
      type: ControlButtonType.VIEWPORT_LOCK,
    },
  },
];

export const DEFAULT_OPTION = OPTIONS[3];
export const OPTIONS_BY_ID: { [key: string]: ControlButtonConfig } =
  OPTIONS.reduce((prev, value) => ({ ...prev, [value.id]: value.config }), {});

type WhiteboardControlsProps = {
  peersRef: React.MutableRefObject<Peers>;
  peers: Peers;
  canvasRef: React.MutableRefObject<fabricTypes.Canvas | undefined>;
  focusedTextRef: React.MutableRefObject<fabricTypes.IText | undefined>;
  textSizeRef: React.MutableRefObject<TextSize>;
  emitBrushChange: (color: string) => void;
  selectedOptionRef: MutableRefObject<string | undefined>;
  visibleHeight: number;
  setCursorRadius: (radius: number) => void;
  setShowCursor: (show: boolean) => void;
  updateCanvasSize: () => void;
  is2xZoomEnabled: boolean;
  is3xZoomEnabled: boolean;
  zoomLevel: zoomLevelType;
  setZoomLevel: (value: zoomLevelType) => void;
  onToggleViewportLock: () => void;
  isFileActivity: boolean;
};

const WhiteboardControls = ({
  peersRef,
  peers,
  canvasRef,
  focusedTextRef,
  textSizeRef,
  emitBrushChange,
  selectedOptionRef,
  visibleHeight,
  setCursorRadius,
  setShowCursor,
  updateCanvasSize,
  is2xZoomEnabled,
  is3xZoomEnabled,
  zoomLevel,
  setZoomLevel,
  onToggleViewportLock,
  isFileActivity,
}: WhiteboardControlsProps) => {
  const [selectedOption, setSelectedOption] = useState(DEFAULT_OPTION.id);
  const [buttonHeight, setButtonHeight] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const shouldShowButton = (buttonOption: OptionType) => {
    if (buttonOption.id === "VIEWPORT_LOCK") {
      return isFileActivity;
    }

    if (
      buttonOption.config.type === ControlButtonType.DIVIDER ||
      (buttonOption.config.type === ControlButtonType.FREE_DRAW &&
        buttonOption.config.hasColorPicker)
    ) {
      return !isFileActivity;
    }
    return true;
  };

  useLayoutEffect(() => {
    function updateSize() {
      if (containerRef.current) {
        const numButtons = OPTIONS.length;
        const parentHeight = containerRef.current?.clientHeight || 0;
        const calculatedButtonHeight =
          (parentHeight * 7) / (3 + 8 * numButtons);
        const newButtonHeight = Math.min(
          Math.max(35, calculatedButtonHeight),
          70
        );
        setButtonHeight(newButtonHeight);
      }
    }
    updateSize();
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useLayoutEffect(() => {
    updateCanvasSize();
  }, [buttonHeight]);

  const containerStyle = {
    paddingTop: 2 * TOOLBAR_BUTTON_SPACING_MULTIPLIER * buttonHeight,
    paddingRight: TOOLBAR_HORIZONTAL_PADDING_MULTIPLIER * buttonHeight,
    paddingLeft: TOOLBAR_HORIZONTAL_PADDING_MULTIPLIER * buttonHeight,
  };

  return (
    <div className={styles.controls} ref={containerRef} style={containerStyle}>
      {OPTIONS.map((o) =>
        shouldShowButton(o) ? (
          <div key={o.id}>
            <ControlButton
              peersRef={peersRef}
              peers={peers}
              id={o.id}
              config={o.config}
              isSelected={o.id === selectedOption}
              setIsSelected={() => {
                selectedOptionRef.current = o.id;
                setSelectedOption(o.id);
              }}
              canvasRef={canvasRef}
              containerRef={containerRef}
              focusedTextRef={focusedTextRef}
              textSizeRef={textSizeRef}
              emitBrushChange={emitBrushChange}
              buttonHeight={buttonHeight}
              visibleHeight={visibleHeight}
              setCursorRadius={setCursorRadius}
              setShowCursor={setShowCursor}
              is2xZoomEnabled={is2xZoomEnabled}
              is3xZoomEnabled={is3xZoomEnabled}
              zoomLevel={zoomLevel}
              setZoomLevel={setZoomLevel}
              onToggleViewportLock={onToggleViewportLock}
              buttonSpacing={TOOLBAR_BUTTON_SPACING_MULTIPLIER * buttonHeight}
            />
          </div>
        ) : null
      )}
    </div>
  );
};

export default WhiteboardControls;

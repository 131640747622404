import React, { useEffect, useState } from "react";

import { DeploymentConfig } from "./admin";
import { backendRequest } from "utils/backendRequest";

const DEFAULT_DEPLOYMENT_CONFIG: DeploymentConfig = {
  shouldDeployWeb: false,
  shouldDeployGateway: false,
  shouldDeployRooms: false,
  shouldDeployHasura: false,
  isDownForMaintenance: false,
};

export const DeploymentSection = () => {
  const [deploymentConfig, setDeploymentConfig] = useState<DeploymentConfig>(
    DEFAULT_DEPLOYMENT_CONFIG
  );
  const [actionResult, setActionResult] = useState<string>();

  const fetchScheduledDeployment = () => {
    const doIt = async () => {
      const result = await backendRequest({
        path: "/admin/scheduled-deployment",
      });
      const data = await result.json();
      setDeploymentConfig(data);
    };
    doIt().catch((err) => {
      console.log(err);
      setDeploymentConfig(DEFAULT_DEPLOYMENT_CONFIG);
      alert("Error fetching scheduled deployment");
    });
  };

  const scheduleDeployment = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const doIt = async () => {
      const result = await backendRequest({
        path: "/admin/scheduled-deployment",
        options: { method: "PUT", body: JSON.stringify(deploymentConfig) },
      });

      if (result.status !== 200) {
        setActionResult("Error");
        return;
      }
      setActionResult("Success");
      fetchScheduledDeployment();
    };
    doIt().catch((err) => {
      console.log(err);
      setActionResult("Error");
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const checked = event.target.checked;
    setDeploymentConfig({ ...deploymentConfig, [name]: checked });
  };

  useEffect(fetchScheduledDeployment, []);

  return (
    <>
      <h3>Scheduled Deployment</h3>
      <p>
        Deployment will be attempted every 5 mins between 3-5 AM PT and will
        only occur if no sessions are in-progress.
      </p>
      <form onSubmit={scheduleDeployment}>
        <div>
          <input
            id="shouldDeployWeb"
            name="shouldDeployWeb"
            type="checkbox"
            checked={deploymentConfig.shouldDeployWeb}
            onChange={handleChange}
          />
          <label htmlFor="shouldDeployWeb">Should Deploy Web</label>
          <br />
          <br />
          <input
            id="shouldDeployGateway"
            name="shouldDeployGateway"
            type="checkbox"
            checked={deploymentConfig.shouldDeployGateway}
            onChange={handleChange}
          />
          <label htmlFor="shouldDeployGateway">Should Deploy Gateway</label>
          <br />
          <br />
          <input
            id="shouldDeployRooms"
            name="shouldDeployRooms"
            type="checkbox"
            checked={deploymentConfig.shouldDeployRooms}
            onChange={handleChange}
            disabled={!deploymentConfig.shouldDeployGateway}
          />
          <label htmlFor="shouldDeployRooms">Should Deploy Rooms</label>
          <br />
          <br />
          <input
            id="shouldDeployHasura"
            name="shouldDeployHasura"
            type="checkbox"
            checked={deploymentConfig.shouldDeployHasura}
            onChange={handleChange}
          />
          <label htmlFor="shouldDeployHasura">Should Deploy Hasura</label>
          <br />
          <br />
          <input
            id="isDownForMaintenance"
            name="isDownForMaintenance"
            type="checkbox"
            checked={deploymentConfig.isDownForMaintenance}
            onChange={handleChange}
          />
          <label htmlFor="isDownForMaintenance">Down for Maintenance</label>
        </div>
        <br />
        <button type="submit">Schedule Deployment</button>
        {actionResult && <span> Result: {actionResult}</span>}
      </form>
    </>
  );
};

export default DeploymentSection;

import React, { MouseEventHandler } from "react";

import styles from "./Menu.module.css";

type MenuProps = {
  closeMenu: MouseEventHandler<HTMLDivElement>;
  children: React.ReactNode;
};

const Menu = ({ closeMenu, children }: MenuProps) => {
  return (
    <>
      <div className={styles.overlay} onClick={closeMenu} />
      <div className={styles.menuContainer}>{children}</div>
    </>
  );
};

export default Menu;

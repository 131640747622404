import { fabricTypes } from "utils/fabric-impl";
import { ICON_ID } from "../../itemsConfig";
import {
  autoUpdate,
  FloatingRootContext,
  offset,
  ReferenceType,
  shift,
  useFloating,
} from "@floating-ui/react";

const IconIDToPosition = {
  [ICON_ID.WHITEBOARD]: 0.3,
  [ICON_ID.EASEL]: 0.3,
} as const;

export const useTooltipFloating = (
  floatingContext: FloatingRootContext<ReferenceType>,
  iconType: string | undefined,
  canvasRef: React.MutableRefObject<fabricTypes.Canvas | undefined>
) => {
  const verticalOffsetPercentage: number | undefined =
    IconIDToPosition[iconType as keyof typeof IconIDToPosition];
  const { floatingStyles } = useFloating({
    rootContext: floatingContext,
    open: true,
    placement: "bottom",
    strategy: "absolute",
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(({ rects }) => ({
        mainAxis: verticalOffsetPercentage
          ? -rects.reference.height * verticalOffsetPercentage
          : -14,
      })),
      shift({
        mainAxis: true,
        crossAxis: true,
        padding: 5,
        boundary: canvasRef.current?.getElement(),
      }),
    ],
  });

  return {
    floatingStyles: floatingStyles,
  };
};

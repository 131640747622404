import React, { useRef, useEffect } from "react";
import {
  useFloating,
  offset,
  shift,
  arrow,
  FloatingPortal,
  FloatingArrow,
  autoUpdate,
} from "@floating-ui/react";

import styles from "./ColorPicker.module.css";
import { COLORS } from "teleoConstants";
import { colors, getColorAtPosition } from "./colorPickerHelpers";

type ColorPickerProps = {
  selectedColor: string;
  setSelectedColor: (color: string, position: number) => void;
  indicatorPosition: number;
  setIndicatorPosition: (position: number) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  referenceElement: HTMLElement | null;
};

const ColorPicker: React.FC<ColorPickerProps> = ({
  selectedColor,
  setSelectedColor,
  indicatorPosition,
  setIndicatorPosition,
  open,
  setOpen,
  referenceElement,
}) => {
  const gradientRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef(null);

  const { x, y, strategy, refs, context } = useFloating({
    open,
    onOpenChange: setOpen,
    placement: "left",
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(28),
      shift({ padding: 10 }),
      arrow({ element: arrowRef }),
    ],
  });

  // Sync reference element
  useEffect(() => {
    refs.setReference(referenceElement);
  }, [referenceElement, refs]);

  const handleMouseDown = (e: React.MouseEvent) => {
    updateIndicatorPosition(e.clientY);
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e: MouseEvent) => {
    updateIndicatorPosition(e.clientY);
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const updateIndicatorPosition = (clientY: number) => {
    if (!gradientRef.current) return;
    const gradientRect = gradientRef.current.getBoundingClientRect();
    const y = clientY - gradientRect.top;
    const clampedY = Math.max(0, Math.min(y, gradientRect.height));
    setIndicatorPosition(clampedY);
    const t = clampedY / gradientRect.height;
    const color = getColorAtPosition(t);
    setSelectedColor(color, clampedY);
  };

  if (!open) {
    return null;
  }

  return (
    <FloatingPortal>
      <div
        ref={refs.setFloating}
        className={styles.floatingContainer}
        style={{
          position: strategy,
          top: y ?? 0,
          left: x ?? 0,
        }}
      >
        <div
          ref={gradientRef}
          onMouseDown={handleMouseDown}
          className={styles.linearGradient}
          style={{
            background: `linear-gradient(to bottom, ${colors.join(", ")})`,
          }}
        >
          <div
            className={styles.indicator}
            style={{
              top: indicatorPosition + "px",
              backgroundColor: selectedColor,
            }}
          ></div>
        </div>
        <FloatingArrow
          ref={arrowRef}
          context={context}
          fill="white"
          stroke={COLORS.DARK_100}
          strokeWidth={1}
          width={40}
          height={20}
        />
      </div>
    </FloatingPortal>
  );
};

export default ColorPicker;

import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CursorIcon } from "assets/icons/cursor.svg";
import { selectUserRole, UserRole } from "redux/userRedux";
import { VideoButton } from "pages/Space/components/Videos/VideosButtons/VideoButton";
import {
  selectPeerIdWithControl,
  setPeerIdWithControl,
} from "redux/settingsRedux";
import Tooltip from "components/Tooltip/Tooltip";
import clsx from "clsx";
import { selectIsFullScreenVideo } from "redux/spaceNavigationRedux";
import { getLocalPeerId } from "pages/Space/hooks/connection/usePeerWebRTCConnection";

import styles from "./CanControlCursorButton.module.css";

type CanControlCursorButtonProps = {
  setRemotePeerIdWithControl: (peerId: string) => void;
  peerId?: string;
  className: string;
};

export const CanControlCursorButton = ({
  setRemotePeerIdWithControl,
  peerId,
  className,
}: CanControlCursorButtonProps) => {
  const dispatch = useDispatch();
  const localUserRole = useSelector(selectUserRole);
  const localPeerId = getLocalPeerId();
  const peerIdWithControl = useSelector(selectPeerIdWithControl);
  const isFullScreen = useSelector(selectIsFullScreenVideo);

  const canControlCursor =
    peerId === peerIdWithControl ||
    (!peerId && peerIdWithControl === localPeerId);

  if (canControlCursor) {
    return (
      <div className={className}>
        <Tooltip text={"Has control"}>
          <div
            className={clsx(styles.peerCanControlIndicatorButton, {
              [styles.fullScreenButton]: isFullScreen,
            })}
          >
            <CursorIcon className={styles.icon} />
          </div>
        </Tooltip>
      </div>
    );
  }

  if ((!canControlCursor && localUserRole === UserRole.CLIENT) || !peerId) {
    return null;
  }

  const handleGrantControl = () => {
    dispatch(setPeerIdWithControl(peerId));
    setRemotePeerIdWithControl(peerId);
  };

  return (
    <VideoButton
      className={className}
      onClick={handleGrantControl}
      tooltipText="Grant control"
      forceVisible={false}
      icon={CursorIcon}
    />
  );
};

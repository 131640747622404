import classroomClinicPoster from "assets/itemIcons/poster/classroomClinicPoster.svg";
import ohanaPoster from "assets/itemIcons/poster/ohanaPoster.svg";

import classroomClinicIcon from "assets/icons/classroomClinic.svg";

export const RESOURCE_SHARED_TYPES = {
  PROTOTYPE: "PROTOTYPE",
  COPY_PENDING: "COPY_PENDING",
  COPY_DISMISSED: "COPY_DISMISSED",
  COPY_ACCEPTED: "COPY_ACCEPTED",
};

// Config objects
type ConfigType = {
  name: string;
  logo: string;
  icon?: string;
  hasCustomActivityBank?: boolean;
  clientSurveyUrl?: string;
  shouldHideEditRoomLink?: boolean;
  isClientBackgroundBlurDisabled?: boolean; // Primarily for performance reasons
  defaultRoomLinkPrefix?: string;
};

const CLASSROOM_CLINIC_CONFIG: ConfigType = {
  name: "Classroom Clinic",
  logo: classroomClinicPoster,
  icon: classroomClinicIcon,
  hasCustomActivityBank: true,
  shouldHideEditRoomLink: true,
  isClientBackgroundBlurDisabled: true,
  defaultRoomLinkPrefix: "cc",
};

const OHANA_CONFIG: ConfigType = {
  name: "Ohana",
  logo: ohanaPoster,
};

// Helpers
export const getOrganizationConfig = (
  organizationId: string | null | undefined
) => {
  switch (organizationId) {
    case "classroom-clinic":
      return CLASSROOM_CLINIC_CONFIG;
    case "ohana":
      return OHANA_CONFIG;
    default:
      return null;
  }
};

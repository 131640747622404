import React from "react";

import styles from "./SideBar.module.css";
import { useSelector } from "react-redux";
import { selectRoomItems } from "redux/spaceNavigationRedux";
import {
  selectEditItemId,
  selectShowAddItemSideBar,
  selectShowBackgroundEditSideBar,
} from "redux/editRoomNavigationRedux";
import ChangeItemSideBar from "../ChangeItemSideBar/ChangeItemSideBar";
import ChangePosterSideBar from "../ChangePosterSideBar/ChangePosterSideBar";
import SideBarMenu from "../SideBarMenu/SideBarMenu";
import ChangeBackgroundSideBar from "../ChangeBackgroundSideBar/ChangeBackgroundSideBar";
import AddItemSideBar from "../AddItemSideBar/AddItemSideBar";
import { selectClientFileOpen } from "redux/clientManagementRedux";
import ClientFile from "pages/Space/components/ClientFile/ClientFile";

type SideBarProps = {};

const SideBar = ({}: SideBarProps) => {
  const editItemId = useSelector(selectEditItemId);
  const roomItems = useSelector(selectRoomItems);
  const showBackgroundEditSideBar = useSelector(
    selectShowBackgroundEditSideBar
  );
  const showAddItemSideBar = useSelector(selectShowAddItemSideBar);
  const isClientFileOpen = useSelector(selectClientFileOpen);

  const getContent = () => {
    if (isClientFileOpen) {
      return <ClientFile />;
    }
    if (editItemId) {
      const editItem = roomItems?.find((item) => item.id === editItemId);
      if (editItem?.icon_id === "POSTER") {
        return <ChangePosterSideBar />;
      }

      return <ChangeItemSideBar />;
    }

    if (showBackgroundEditSideBar) {
      return <ChangeBackgroundSideBar />;
    }
    if (showAddItemSideBar) {
      return <AddItemSideBar />;
    } else {
      return <SideBarMenu />;
    }
  };

  return <div className={styles.container}>{getContent()}</div>;
};

export default SideBar;

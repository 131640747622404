import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import * as Sentry from "@sentry/react";

import GuacamoleKeyboard from "./guacamoleKeyboard";

import styles from "./BrowserSandbox.module.css";
import { useSelector } from "react-redux";
import { selectUserRole, UserRole } from "redux/userRedux";
import {
  selectAudioProviderOnly,
  selectCanControl,
  selectVideoConferencing,
} from "redux/settingsRedux";
import clsx from "clsx";
import LoadingAnimation from "components/LoadingAnimation/LoadingAnimation";
import playIcon from "assets/icons/play.svg";
import { logUnexpectedError } from "utils/errorUtils";
import { calculateFitDimensions } from "utils/sizingUtils";
import ActivityNavigationHeader from "pages/Space/components/ActivityNavigationHeader/ActivityNavigationHeader";
import {
  selectCurrentResourceId,
  selectCurrentRoomItemId,
} from "redux/spaceNavigationRedux";
import { useSnapshot } from "pages/Space/hooks/useSnapshot";
import { selectClientFileOpen } from "redux/clientManagementRedux";
import { Peers } from "pages/Space/hooks/connection/usePeerWebRTCConnection";

const hasMacOSKbd = () => {
  // TODO: don't use deprecated navigator.platform
  return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
};

const LOAD_TIME = 1000;

const KeyTable = {
  XK_ISO_Level3_Shift: 0xfe03, // AltGr
  XK_Mode_switch: 0xff7e, // Character set switch
  XK_Control_L: 0xffe3, // Left control
  XK_Control_R: 0xffe4, // Right control
  XK_Meta_L: 0xffe7, // Left meta
  XK_Meta_R: 0xffe8, // Right meta
  XK_Alt_L: 0xffe9, // Left alt
  XK_Alt_R: 0xffea, // Right alt
  XK_Super_L: 0xffeb, // Left super
  XK_Super_R: 0xffec, // Right super
};
const keyMap = (key: number): number => {
  // Alt behaves more like AltGraph on macOS, so shuffle the
  // keys around a bit to make things more sane for the remote
  // server. This method is used by noVNC, RealVNC and TigerVNC
  // (and possibly others).
  if (hasMacOSKbd()) {
    switch (key) {
      case KeyTable.XK_Meta_L:
        key = KeyTable.XK_Control_L;
        break;
      case KeyTable.XK_Super_L:
        key = KeyTable.XK_Alt_L;
        break;
      case KeyTable.XK_Super_R:
        key = KeyTable.XK_Super_L;
        break;
      case KeyTable.XK_Alt_L:
        key = KeyTable.XK_Mode_switch;
        break;
      case KeyTable.XK_Alt_R:
        key = KeyTable.XK_ISO_Level3_Shift;
        break;
    }
  }
  return key;
};

const clearTimeoutIfSet = (
  timeoutRef: React.MutableRefObject<NodeJS.Timeout | undefined>
) => {
  if (timeoutRef.current) {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = undefined;
  }
};

type BrowserSandboxProps = {
  url: string;
  initialize: boolean; // whether to open the url (otherwise assume the peer has already opened it)
  remoteDataChannelRef: React.MutableRefObject<RTCDataChannel | undefined>;
  remoteMediaStream: MediaStream;
  peersRef: React.MutableRefObject<Peers>;
  getRemoteVideoBytesReceived: () => Promise<number>;
};

const BrowserSandbox = ({
  url,
  initialize,
  remoteDataChannelRef,
  remoteMediaStream,
  peersRef,
  getRemoteVideoBytesReceived,
}: BrowserSandboxProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const wheelThrottleRef = useRef(false);
  // @ts-ignore
  const keyboard = useRef(new GuacamoleKeyboard());
  const [showLoading, setShowLoading] = useState(true);
  const [forceMute, setForceMute] = useState(true);
  const [showPlayButton, setShowPlayButton] = useState(false);
  const playingTimeout = useRef<NodeJS.Timeout>();
  const remoteMediaStreamRef = useRef<MediaStream>(remoteMediaStream); // for callback
  const [videoHeight, setVideoHeight] = useState(0);
  const [videoWidth, setVideoWidth] = useState(0);

  useEffect(() => {
    remoteMediaStreamRef.current = remoteMediaStream;
  }, [remoteMediaStream]);

  const userRole = useSelector(selectUserRole);
  const clientCanControl = useSelector(selectCanControl);
  const audioProviderOnly = useSelector(selectAudioProviderOnly);
  const videoConferencing = useSelector(selectVideoConferencing);
  const resourceId = useSelector(selectCurrentResourceId);
  const roomItemId = useSelector(selectCurrentRoomItemId);
  const isClientFileOpen = useSelector(selectClientFileOpen);

  const userCanControl =
    (userRole == UserRole.CLIENT && clientCanControl) ||
    userRole === UserRole.THERAPIST;

  const userCanControlRef = useRef(userCanControl); // for use in keyUp and keyDown callbackes
  userCanControlRef.current = userCanControl;

  useSnapshot(videoRef.current);

  const maybeSend = (event: string, data: any) => {
    if (remoteDataChannelRef.current?.readyState === "open") {
      remoteDataChannelRef.current?.send(
        JSON.stringify({ event, data: { ...data, agent: userRole } })
      );
    }
  };

  useEffect(() => {
    if (url) {
      // Show loading screen for a second
      setShowLoading(true);
      setTimeout(() => setShowLoading(false), LOAD_TIME);
      // Mute so when changing tabs, we don't hear the sound from the last activity
      setForceMute(true);
      setTimeout(() => setForceMute(false), LOAD_TIME);
    }
    if (url && initialize) {
      // TODO: handle if we haven't made the webrtc connection yet, or don't allow navigating to this page until we do
      maybeSend("openUrl", { url, roomItemId, resourceId });
    }
  }, [url, initialize]);

  useEffect(() => {
    // Initialize playing timeout to send Sentry error if media does not play within a few seconds
    const initializePlayingTimeout = async () => {
      const videoBytesBefore = await getRemoteVideoBytesReceived();
      playingTimeout.current = setTimeout(async () => {
        const videoBytesAfter = await getRemoteVideoBytesReceived();
        Sentry.captureException("BrowserSandbox play timed out", {
          extra: {
            remoteMediaStream: JSON.stringify(remoteMediaStreamRef.current),
            remoteMediaStreamTracks: JSON.stringify(
              remoteMediaStreamRef.current.getTracks()
            ),
            videoBytesReceivedStart: videoBytesBefore, // -1 if no 'inbound-rtp' 'video' connection found in stats
            videoBytesReceivedNow: videoBytesAfter, // -1 if no 'inbound-rtp' 'video' connection found in stats
            videoBytesReceivedDelta: videoBytesAfter - videoBytesBefore, // should be around ??? // TODO: check this
          },
        });
      }, 2 * 1000); // 2 seconds
    };
    initializePlayingTimeout();

    // document.onkeydown = onKeyDown
    /* Initialize Guacamole Keyboard */
    keyboard.current.onkeydown = (key: number) => {
      if (document.activeElement !== document.body) {
        return true;
      }
      if (userCanControlRef.current) {
        maybeSend("keyDown", { key: keyMap(key) });
      }
      return false;
    };
    keyboard.current.onkeyup = (key: number) => {
      if (userCanControlRef.current) {
        maybeSend("keyUp", { key: keyMap(key) });
      }
    };
    const cleanupKeyboard = keyboard.current.listenTo(document);

    return () => {
      cleanupKeyboard();
      clearTimeoutIfSet(playingTimeout);
    };
  }, []);

  useLayoutEffect(() => {
    videoRef.current?.addEventListener("resize", resizeVideo);
    window.addEventListener("resize", resizeVideo);

    return () => {
      videoRef.current?.removeEventListener("resize", resizeVideo);
      window.removeEventListener("resize", resizeVideo);
    };
  }, []);

  const play = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      try {
        videoRef.current.play().catch((err) => {
          logUnexpectedError(err);
          // Show the play button if user interaction is required to play the video
          setShowPlayButton(true);
        });
        setShowPlayButton(false);
      } catch (err) {
        logUnexpectedError(err);
        // Show the play button if user interaction is required to play the video
        setShowPlayButton(true);
      }
    }
  };

  const resizeVideo = () => {
    const parentHeight = videoContainerRef.current?.offsetHeight;
    const parentWidth = videoContainerRef.current?.offsetWidth;
    const rawHeight = videoRef.current?.videoHeight;
    const rawWidth = videoRef.current?.videoWidth;
    if (!parentHeight || !parentWidth || !rawHeight || !rawWidth) {
      return;
    }
    const { fitHeight, fitWidth } = calculateFitDimensions(
      parentHeight,
      parentWidth,
      rawHeight,
      rawWidth,
      null
    );
    setVideoHeight(fitHeight);
    setVideoWidth(fitWidth);
  };

  useEffect(() => {
    resizeVideo();
  }, [isClientFileOpen]);

  useEffect(() => {
    // Set up video media stream
    if (videoRef.current) {
      videoRef.current.onplaying = () => {
        clearTimeoutIfSet(playingTimeout);
      };
      videoRef.current.srcObject = remoteMediaStream;
      play();
    }
  }, [remoteMediaStream]);

  const getPointerOffsetPercentages = ({
    offsetX,
    offsetY,
  }: {
    offsetX: number;
    offsetY: number;
  }) => {
    if (
      !videoRef.current ||
      videoRef.current.offsetWidth === 0 ||
      videoRef.current.offsetHeight === 0
    ) {
      return null;
    }
    return {
      xp: offsetX / videoRef.current.offsetWidth,
      yp: offsetY / videoRef.current.offsetHeight,
    };
  };

  // TODO: decrease message size, potentially by using bit arrays like in neko https://github.com/m1k1o/neko/blob/fed6ddbd4e9f2eff487f8a7d5194a3d7e4f4a10b/client/src/neko/base.ts#L130
  const sendMousePosition = (offsets: { offsetX: number; offsetY: number }) => {
    const offsetPercentages = getPointerOffsetPercentages(offsets);
    if (offsetPercentages) {
      maybeSend("mouseMove", offsetPercentages);
    }
  };

  const onPointerMove = (event: React.PointerEvent<HTMLVideoElement>) => {
    sendMousePosition(event.nativeEvent);
  };

  const WHEEL_LINE_HEIGHT = 19;

  const onWheel = (event: React.WheelEvent<HTMLVideoElement>) => {
    if (!videoRef.current) return;

    let x = event.deltaX;
    let y = event.deltaY;

    // Pixel units unless it's non-zero.
    // Note that if deltamode is line or page won't matter since we aren't
    // sending the mouse wheel delta to the server anyway.
    // The difference between pixel and line can be important however since
    // we have a threshold that can be smaller than the line height.
    if (event.deltaMode !== 0) {
      x *= WHEEL_LINE_HEIGHT;
      y *= WHEEL_LINE_HEIGHT;
    }

    // TODO: handle scroll direction settings (scroll_invert, scroll sensitivity, wheelThrottle?)
    // if (this.scroll_invert) {
    //   x = x * -1
    //   y = y * -1
    // }

    const SCROLL_SENSITIVITY = 1; // Make higher to make the scroll move more

    x = Math.min(Math.max(x, -SCROLL_SENSITIVITY), SCROLL_SENSITIVITY);
    y = Math.min(Math.max(y, -SCROLL_SENSITIVITY), SCROLL_SENSITIVITY);

    sendMousePosition(event.nativeEvent);

    if (!wheelThrottleRef.current) {
      wheelThrottleRef.current = true;
      maybeSend("scroll", { x, y });
      window.setTimeout(() => {
        wheelThrottleRef.current = false;
      }, 100);
    }
  };

  const onPointerDown = (event: React.PointerEvent<HTMLVideoElement>) => {
    const offsetPercentages = getPointerOffsetPercentages(event.nativeEvent);
    if (offsetPercentages) {
      maybeSend("buttonDown", { key: event.button + 1, ...offsetPercentages });
    }
  };

  const onPointerUp = (event: React.PointerEvent<HTMLVideoElement>) => {
    const offsetPercentages = getPointerOffsetPercentages(event.nativeEvent);
    if (offsetPercentages) {
      maybeSend("buttonUp", { key: event.button + 1, ...offsetPercentages });
    }
  };

  const muteAudio =
    (userRole === UserRole.CLIENT && audioProviderOnly && !videoConferencing) ||
    forceMute;

  const onReset = () => {
    maybeSend("refreshPage", {});
    // Show loading screen, disable keyboard inputs and mute video for a second
    setShowLoading(true);
    setForceMute(true);
    userCanControlRef.current = false;
    setTimeout(() => {
      setShowLoading(false);
      setForceMute(false);
      userCanControlRef.current = true;
    }, LOAD_TIME);
  };

  return (
    <div data-testid="browser-sandbox" className={styles.container}>
      <div
        className={clsx(styles.navOuterContainer, {
          [styles.outerNoControl]: !userCanControl,
        })}
      >
        <div
          className={clsx(styles.navInnerContainer, {
            [styles.innerNoControl]: !userCanControl,
          })}
        >
          <ActivityNavigationHeader
            peersRef={peersRef}
            isExternal={true}
            onReset={onReset}
          />
        </div>
      </div>
      <div
        className={clsx(styles.videoOuterContainer, {
          [styles.outerNoControl]: !userCanControl && !showPlayButton,
        })}
      >
        <div
          ref={videoContainerRef}
          className={clsx(styles.videoContainer, {
            [styles.innerNoControl]: !userCanControl,
          })}
        >
          <video
            className={styles.video}
            ref={videoRef}
            style={{
              width: videoWidth,
              height: videoHeight,
            }}
            playsInline
            autoPlay
            onPointerMove={onPointerMove}
            onWheel={onWheel}
            onPointerDown={onPointerDown}
            onPointerUp={onPointerUp}
            onContextMenu={(e) => e.preventDefault()}
            muted={muteAudio}
            disablePictureInPicture
          />
        </div>
        {showPlayButton && !showLoading ? (
          <img
            className={styles.playButton}
            src={playIcon}
            alt={"Start"}
            onClick={play}
          />
        ) : null}
      </div>
      {showLoading ? (
        <div className={styles.loadingContainer}>
          <LoadingAnimation />
        </div>
      ) : null}
    </div>
  );
};

export default BrowserSandbox;

import React from "react";
import styles from "./SideBarMenuItem.module.css";
import expandArrow from "assets/icons/expand_more.svg";
import clsx from "clsx";

type SideBarMenuItemProps = {
  iconSrc: any;
  title: string;
  description?: string;
  navigation?: () => void;
};

const SideBarMenuItem = ({
  iconSrc,
  title,
  description,
  navigation,
}: SideBarMenuItemProps) => {
  return (
    <div
      className={clsx(styles.container, { [styles.selectable]: navigation })}
      onClick={navigation}
    >
      <img className={styles.icon} src={iconSrc} />
      <div className={styles.rightSide}>
        <div className={styles.topRow}>
          <div className={styles.title}>{title}</div>
          {navigation ? (
            <img className={styles.navigateButton} src={expandArrow} />
          ) : null}
        </div>
        {description ? (
          <div className={styles.description}>{description}</div>
        ) : null}
      </div>
    </div>
  );
};

export default SideBarMenuItem;

export const colors = [
  "#CB5C4A",
  "#EAAC42",
  "#F7D749",
  "#99AE5F",
  "#3DADD2",
  "#6176B6",
  "#8445D6",
  "#D3329A",
];

// The height of the gradient in pixels
const GRADIENT_HEIGHT = 300;

const interpolateColor = (c1: string, c2: string, t: number) => {
  const color1 = hexToRgb(c1);
  const color2 = hexToRgb(c2);
  const r = Math.round(color1.r + (color2.r - color1.r) * t);
  const g = Math.round(color1.g + (color2.g - color1.g) * t);
  const b = Math.round(color1.b + (color2.b - color1.b) * t);
  return rgbToHex(r, g, b);
};

const hexToRgb = (hex: string) => {
  const match = hex.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i);
  if (!match) {
    throw new Error("Invalid hex color: " + hex);
  }
  return {
    r: parseInt(match[1], 16),
    g: parseInt(match[2], 16),
    b: parseInt(match[3], 16),
  };
};

const rgbToHex = (r: number, g: number, b: number) => {
  return (
    "#" +
    [r, g, b]
      .map((x) => {
        const hex = x.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      })
      .join("")
  );
};

export const getColorAtPosition = (t: number) => {
  const totalStops = colors.length - 1;
  const scaledT = t * totalStops;
  const index = Math.floor(scaledT);
  const localT = scaledT - index;
  const color1 = colors[index];
  const color2 = colors[Math.min(index + 1, colors.length - 1)];
  return interpolateColor(color1, color2, localT);
};

export const getPositionForColor = (color: string) => {
  const rgbColor = hexToRgb(color);
  let closestIndex = 0;
  let closestDistance = Infinity;

  colors.forEach((hexColor, index) => {
    const rgb = hexToRgb(hexColor);
    const distance = Math.sqrt(
      Math.pow(rgb.r - rgbColor.r, 2) +
        Math.pow(rgb.g - rgbColor.g, 2) +
        Math.pow(rgb.b - rgbColor.b, 2)
    );

    if (distance < closestDistance) {
      closestDistance = distance;
      closestIndex = index;
    }
  });

  const position = (closestIndex / (colors.length - 1)) * GRADIENT_HEIGHT;
  return position;
};

import React, { useState } from "react";
import { toast } from "react-toastify";

import styles from "./DeleteSharedRoomModal.module.css";
import Button from "components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import Modal from "components/Modal/Modal";
import { logUnexpectedError } from "utils/errorUtils";
import {
  GetSharedRoomFolderModalDataDocument,
  useDeleteSharedRoomMutation,
} from "generated/graphql";
import {
  selectDeleteSharedRoomModalSharedRoom,
  setDeleteSharedRoomModalSharedRoom,
} from "redux/spaceNavigationRedux";
import { selectUserId } from "redux/userRedux";

const GENERIC_ERROR_MESSAGE =
  "Sorry, there was a problem deleting your room. Please try again later.";

const DeleteSharedRoomModal = () => {
  const dispatch = useDispatch();
  const userId = useSelector(selectUserId);
  const sharedRoom = useSelector(selectDeleteSharedRoomModalSharedRoom);
  const [deleteSharedRoomMutation] = useDeleteSharedRoomMutation();
  const [deleting, setDeleting] = useState(false);

  const closeModal = () => {
    dispatch(setDeleteSharedRoomModalSharedRoom(undefined));
  };

  const deleteRoom = async () => {
    try {
      setDeleting(true);
      if (!sharedRoom) {
        return;
      }

      const { id, sharedRoomFolderId } = sharedRoom;
      const { errors } = await deleteSharedRoomMutation({
        variables: { sharedRoomId: id },
        refetchQueries: [
          {
            query: GetSharedRoomFolderModalDataDocument,
            variables: { sharedRoomFolderId, userId },
          },
        ],
      });

      if (errors) {
        logUnexpectedError(errors);
        toast(GENERIC_ERROR_MESSAGE, { type: "error" });
        return;
      }

      closeModal();
    } catch (e) {
      logUnexpectedError(e);
      toast(GENERIC_ERROR_MESSAGE, { type: "error" });
    } finally {
      setDeleting(false);
    }
  };

  if (!sharedRoom) {
    return null;
  }

  return (
    <Modal closeModal={closeModal}>
      <div className={styles.heading}>Delete Shared Room</div>
      <div className={styles.body}>
        {`Are you sure you want to delete the shared room "${sharedRoom.name}"?`}
        <br />
        This room will no longer be copyable by others.
      </div>
      <div className={styles.buttonRow}>
        <Button className={styles.cancelButton} onClick={closeModal}>
          Cancel
        </Button>
        <Button onClick={deleteRoom} disabled={deleting}>
          {deleting ? "..." : "Delete"}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteSharedRoomModal;

// Helper function to flatten nested objects concatenating the keys
// Currently does not handle arrays or functions
export const flattenObject = (
  obj: any,
  prefix = ""
): { [key: string]: number | boolean | string } => {
  if (!obj || typeof obj !== "object") {
    return {};
  }
  const flattened: { [key: string]: any } = {};
  const prefixWithDot = prefix ? prefix + "." : "";
  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === "object") {
      Object.assign(flattened, flattenObject(value, `${prefixWithDot}${key}`));
    } else if (
      ["undefined", "boolean", "number", "bigint", "string"].includes(
        typeof value
      )
    ) {
      flattened[`${prefixWithDot}${key}`] = value;
    } else {
      // ignore unsupported types
      console.log(
        "ignoring unsupported type when flattening",
        typeof value,
        value
      );
    }
  }
  return flattened;
};

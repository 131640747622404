import React, { useEffect, useRef } from "react";

import styles from "./RoomCustomizationActivityModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectMeetingID,
  setCurrentResourceId,
  setShowRoomCustomizationActivityModal,
  SpacePage,
} from "redux/spaceNavigationRedux";
import { COLORS } from "teleoConstants";
import { sendEventToPeers } from "utils/webrtcUtils";
import InRoomModal from "./InRoomModal/InRoomModal";
import designTitle from "assets/inRoomElements/design_title.png";
import button from "assets/inRoomElements/button.png";
import checkIcon from "assets/inRoomElements/check.png";
import { useBackgroundSelection } from "../../utils/useBackgroundSelection";
import clsx from "clsx";
import { getBackgroundImage } from "../../utils/backgroundUtils";
import { evictCurrentRoomBackground } from "utils/dbUtils";
import {
  selectCurrentRoomId,
  selectProviderId,
  selectUserId,
  selectUserRole,
  UserRole,
} from "redux/userRedux";
import { useApolloClient } from "@apollo/client";
import { useInsertAnalyticsBackgroundUpdateMutation } from "generated/graphql";
import { logUnexpectedError } from "utils/errorUtils";
import { logUsageEvent } from "utils/metricsUtils";
import { selectClientHasJoinedRoom } from "redux/clientManagementRedux";
import { Peers } from "pages/Space/hooks/connection/usePeerWebRTCConnection";

export const BACKGROUND_CHANGED_EVENT = "room-customization-background-changed";

type RoomCustomizationActivityModalProps = {
  peersRef: React.MutableRefObject<Peers>;
  peers: Peers;
  roomWidth: number | undefined;
};

const RoomCustomizationActivityModal = ({
  peersRef,
  peers,
  roomWidth,
}: RoomCustomizationActivityModalProps) => {
  const userId = useSelector(selectUserId);
  const providerId = useSelector(selectProviderId);
  const roomId = useSelector(selectCurrentRoomId);
  const meetingID = useSelector(selectMeetingID);
  const userRole = useSelector(selectUserRole);
  const roomIdRef = useRef(roomId); // for callback
  const client = useApolloClient();
  const dispatch = useDispatch();
  const clientHasJoinedRoom = useSelector(selectClientHasJoinedRoom);
  const isChosenByClient = clientHasJoinedRoom || userRole === UserRole.CLIENT;
  const {
    selectedBackgroundId,
    loadingBackgroundId,
    possibleBackgrounds,
    onClick: select,
  } = useBackgroundSelection();
  const [insertAnalyticsBackgroundUpdateMutation] =
    useInsertAnalyticsBackgroundUpdateMutation();

  useEffect(() => {
    roomIdRef.current = roomId;
  }, [roomId]);

  const onReceiveMessageCallback = (event: MessageEvent) => {
    const data = JSON.parse(event.data);
    const eventType = data.event;
    if (eventType === BACKGROUND_CHANGED_EVENT && roomIdRef.current) {
      evictCurrentRoomBackground(roomIdRef.current)(client.cache);
    }
  };

  useEffect(() => {
    const peerDataChannels = Object.values(peers).map(
      (peer) => peer.dataChannel
    );
    for (const peerDataChannel of peerDataChannels) {
      if (peerDataChannel) {
        peerDataChannel.addEventListener("message", onReceiveMessageCallback);
      }
    }

    return () => {
      for (const peerDataChannel of peerDataChannels) {
        if (peerDataChannel) {
          peerDataChannel.removeEventListener(
            "message",
            onReceiveMessageCallback
          );
        }
      }
    };
  }, [peers]);

  const closeModal = () => {
    if (selectedBackgroundId) {
      insertAnalyticsBackgroundUpdateMutation({
        variables: {
          userId: userId || providerId,
          isChosenByClient,
          isCreateRoom: false,
          backgroundId: selectedBackgroundId,
        },
      }).catch(logUnexpectedError);
    }
    logUsageEvent("BACKGROUND_CHANGED", meetingID);
    dispatch(setShowRoomCustomizationActivityModal(false));
    dispatch(setCurrentResourceId(null));
    sendEventToPeers(peersRef.current, "navigate", {
      currentPage: SpacePage.ROOM,
    });
  };

  const relativeMultiplier = (roomWidth || 0) / 1000;
  const headingStyle = {
    width: 300 * relativeMultiplier,
    height: ((300 * 209) / 1208) * relativeMultiplier,
    marginBottom: 30 * relativeMultiplier,
    marginTop: -10 * relativeMultiplier,
  };
  const selectorContainerStyle = {
    marginBottom: 20 * relativeMultiplier,
  };
  const selectorImageContainerStyle = (backgroundId: string) => ({
    margin: 10 * relativeMultiplier,
    padding: 4 * relativeMultiplier,
    backgroundColor:
      backgroundId === selectedBackgroundId ? COLORS.DARK : undefined,
  });
  const selectorImageStyle = {
    width: 180 * relativeMultiplier,
    height: ((180 * 880) / 1733) * relativeMultiplier,
    borderWidth: 2 * relativeMultiplier,
  };
  const buttonContainerStyle = {
    width: 150 * relativeMultiplier,
    height: ((150 * 119) / 420) * relativeMultiplier,
  };
  const buttonCheckStyle = {
    width: 167 * 0.14 * relativeMultiplier,
    height: 124 * 0.14 * relativeMultiplier,
    marginRight: 10 * relativeMultiplier,
  };
  const buttonTextStyle = {
    fontSize: 17 * relativeMultiplier,
    marginTop: 3 * relativeMultiplier,
  };

  const onClick: (
    backgroundId: string
  ) => React.MouseEventHandler<HTMLDivElement> =
    (backgroundId: string) => async (event) => {
      await select(backgroundId)(event);
      sendEventToPeers(peersRef.current, BACKGROUND_CHANGED_EVENT, {});
    };

  return (
    <InRoomModal closeModal={closeModal} sizeRelativeToParent={roomWidth}>
      <img src={designTitle} style={headingStyle} />
      <div style={selectorContainerStyle} className={styles.selectorContainer}>
        {possibleBackgrounds.map((backgroundId) => (
          <div
            key={backgroundId}
            style={selectorImageContainerStyle(backgroundId)}
            className={styles.backgroundImageContainer}
          >
            <img
              style={selectorImageStyle}
              className={clsx(styles.backgroundImage, {
                [styles.loadingBackground]:
                  backgroundId === loadingBackgroundId,
              })}
              onClick={onClick(backgroundId)}
              src={getBackgroundImage(backgroundId)}
            />
          </div>
        ))}
      </div>
      <div
        style={buttonContainerStyle}
        className={styles.buttonContainer}
        onClick={closeModal}
      >
        <img className={styles.buttonImage} src={button} />
        <div className={styles.buttonContents}>
          <img style={buttonCheckStyle} src={checkIcon} />
          <div style={buttonTextStyle}>Done</div>
        </div>
      </div>
    </InRoomModal>
  );
};

export default RoomCustomizationActivityModal;

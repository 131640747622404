import React, { MouseEventHandler } from "react";

import styles from "pages/Space/components/ClientManagement/SwitchClientButton.module.css";
import Tooltip from "components/Tooltip/Tooltip";
import clsx from "clsx";

type SwitchClientButtonProps = {
  id?: string;
  className?: string;
  onClick: MouseEventHandler;
  tooltipText: string;
  icon: React.ComponentType<{ className: string }>;
};

export const SwitchClientButton = ({
  id,
  className,
  onClick,
  tooltipText,
  icon: Icon,
}: SwitchClientButtonProps) => {
  return (
    <div id={id} className={clsx(styles.buttonContainer, className)}>
      <Tooltip text={tooltipText}>
        <button className={styles.button} onClick={onClick}>
          <Icon className={styles.icon} />
        </button>
      </Tooltip>
    </div>
  );
};

import React, { useState } from "react";

import styles from "./ClientCreationModal.module.css";
import Modal from "components/Modal/Modal";
import Button from "components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "components/TextInput/TextInput";
import {
  GetClientsDocument,
  useCheckIfClientExistLazyQuery,
  useCreateClientMutation,
} from "generated/graphql";
import { selectUserId } from "redux/userRedux";
import {
  cancelNewClient,
  clientCreated,
  selectClientSearchText,
  selectIncomingClient,
} from "redux/clientManagementRedux";
import { useLogClientCount } from "utils/metricsUtils";
import { useTrackNewClient } from "../useClientManagementAnalytics";

const MAX_NAME_LENGTH = 1000;

type ClientCreationModalProps = {};

const ClientCreationModal = ({}: ClientCreationModalProps) => {
  const dispatch = useDispatch();
  const [checkIfClientExistQuery] = useCheckIfClientExistLazyQuery({
    fetchPolicy: "no-cache",
  });
  const [createClient] = useCreateClientMutation();

  const userId = useSelector(selectUserId);

  const searchFilterClientName = useSelector(selectClientSearchText);
  const incomingClient = useSelector(selectIncomingClient);
  const [newClientName, setNewClientName] = useState<string>(
    searchFilterClientName || incomingClient?.name || ""
  );
  const logClientCount = useLogClientCount();
  const { trackCreate, trackCancel } = useTrackNewClient();

  const closeModal = () => {
    trackCancel();
    dispatch(cancelNewClient());
  };

  const handleSubmit = async () => {
    const trimmedClientName = newClientName.trim();
    const escapedNewClientName = trimmedClientName
      .replace("_", "\\_")
      .replace("%", "\\%");
    const existingClientResult = await checkIfClientExistQuery({
      variables: { clientName: escapedNewClientName, providerId: userId },
    });

    if (existingClientResult.data?.client.length === 0) {
      const createClientResponse = await createClient({
        variables: { clientName: trimmedClientName },
        refetchQueries: [
          { query: GetClientsDocument, variables: { providerId: userId } },
        ],
      });
      const createdClientId =
        createClientResponse.data?.insert_client_one?.canonical_id;
      if (createdClientId) {
        logClientCount();
        trackCreate();
        dispatch(
          clientCreated({
            canonical_id: createdClientId,
            name: trimmedClientName,
          })
        );
      } else {
        alert("There was an error creating the client. Please try again.");
      }
    } else {
      alert(
        `A client with the name “${trimmedClientName}” already exists. Please enter a unique name ID.`
      );
    }
  };

  return (
    <Modal closeModal={closeModal}>
      <div className={styles.heading}>New Client Record</div>
      <label className={styles.nameInputLabel}>Client Name</label>
      <TextInput
        placeholder="Name"
        setValue={setNewClientName}
        value={newClientName}
        className={styles.nameInput}
        maxLength={MAX_NAME_LENGTH}
        autoFocus
      />
      <div className={styles.buttonRow}>
        <Button className={styles.cancelButton} onClick={closeModal}>
          Cancel
        </Button>

        <Button onClick={handleSubmit} disabled={newClientName.trim() === ""}>
          Create
        </Button>
      </div>
    </Modal>
  );
};

export default ClientCreationModal;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import styles from "./OpenRoomRow.module.css";
import shareIcon from "assets/icons/share.svg";
import deleteIcon from "assets/icons/delete.svg";
import { GetOpenRoomModalDataQuery } from "generated/graphql";
import {
  setDeleteRoomModalRoom,
  setShowOpenRoomModal,
  setShareRoomModalRoomId,
} from "redux/spaceNavigationRedux";
import Tooltip from "components/Tooltip/Tooltip";
import { selectOrganizationId } from "redux/userRedux";

type OpenRoomRowProps = {
  room: GetOpenRoomModalDataQuery["providerRooms"][0];
  onClick: () => void;
  onDoubleClick: () => void;
  isSelected: boolean;
  isCurrentRoom: boolean;
};

const OpenRoomRow = ({
  room,
  onClick,
  onDoubleClick,
  isSelected,
  isCurrentRoom,
}: OpenRoomRowProps) => {
  const dispatch = useDispatch();

  const organizationId = useSelector(selectOrganizationId);

  const showShareRoomModal = (event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch(setShowOpenRoomModal(false));
    dispatch(setShareRoomModalRoomId(room.id));
  };

  const showDeleteRoomModal = (event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch(setDeleteRoomModalRoom(room));
  };

  return (
    <div
      className={clsx(styles.container, {
        [styles.selected]: isSelected,
        [styles.currentRoom]: isCurrentRoom,
      })}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      <span className={styles.text}>{room.name}</span>
      <span className={styles.iconContainer}>
        {organizationId && (
          <Tooltip text="Share Room" elementWrapperClassName={styles.shareIcon}>
            <img
              className={styles.rowIcon}
              src={shareIcon}
              onClick={showShareRoomModal}
            />
          </Tooltip>
        )}
        <img
          className={styles.rowIcon}
          src={deleteIcon}
          onClick={showDeleteRoomModal}
        />
      </span>
    </div>
  );
};

export default OpenRoomRow;

import React, { useState } from "react";

import styles from "./InviteModal.module.css";
import Button from "components/Button/Button";
import { useDispatch } from "react-redux";
import { setShowInviteModal } from "redux/spaceNavigationRedux";
import Modal from "components/Modal/Modal";
import CopyTextBox from "../CopyTextBox/CopyTextBox";
import TextInput from "components/TextInput/TextInput";
import { ReactComponent as MailIcon } from "assets/icons/mail.svg";
import clsx from "clsx";
import { useSendLink } from "utils/emailUtils";
import ConfirmationModal from "pages/Space/components/ConfirmationModal/ConfirmationModal";
import { useLogEmailInviteEvent } from "utils/metricsUtils";

const MAX_EMAIL_ADDRESS_LENGTH = 200;

type InviteModalProps = {
  url?: string;
};

const InviteModal = ({ url }: InviteModalProps) => {
  const dispatch = useDispatch();
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [sentEmailAddress, setSentEmailAddress] = useState<string>("");
  const modalOpenFrom = !!url ? "HOME" : "ROOM";
  const logEmailSent = useLogEmailInviteEvent(modalOpenFrom);

  const closeModal = () => {
    dispatch(setShowInviteModal(false));
  };

  const onSend = (successfullySentEmailAddress: string) => {
    logEmailSent();
    setSentEmailAddress(successfullySentEmailAddress);
  };

  const [isEmailValid, sendLink, loading] = useSendLink(emailAddress, onSend);

  if (sentEmailAddress) {
    return (
      <ConfirmationModal closeModal={closeModal}>
        Success!
        <br />
        Email sent to {sentEmailAddress}.
      </ConfirmationModal>
    );
  }

  return (
    <Modal closeModal={closeModal}>
      <div className={styles.heading}>Invite</div>
      <div className={styles.subHeading}>Copy your Teleo Link to Share</div>
      <CopyTextBox
        text={url ?? window.location.href}
        className={styles.copyTextBox}
      />
      <div className={styles.subHeading}>Or Send via Email</div>
      <div className={styles.emailSection}>
        <div className={styles.emailPreview}>
          <div className={clsx(styles.label, styles.toLabel)}>To:</div>
          <TextInput
            value={emailAddress}
            setValue={setEmailAddress}
            placeholder={"Email address"}
            className={styles.textInput}
            maxLength={MAX_EMAIL_ADDRESS_LENGTH}
          />
          <div className={clsx(styles.label, styles.fromLabel)}>From:</div>
          <div className={styles.from}>no-reply@teleo.space</div>
          <div className={clsx(styles.label, styles.subjectLabel)}>
            Subject:
          </div>
          <div className={styles.subject}>Your Teleo link</div>
          <div className={styles.body}>
            Your therapist has invited you to join:{" "}
            <a>{url ?? window.location.href}</a>
          </div>
        </div>
        <Button
          onClick={sendLink}
          disabled={!isEmailValid || loading}
          className={styles.sendButton}
        >
          {loading ? (
            "..."
          ) : (
            <>
              <MailIcon
                className={clsx(styles.mailIcon, {
                  [styles.disabled]: !isEmailValid,
                })}
              />
              Send
            </>
          )}
        </Button>
      </div>
      <Button onClick={closeModal}>Close</Button>
    </Modal>
  );
};

export default InviteModal;

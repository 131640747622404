import { useEffect, useRef } from "react";
import { useAnalyticsInfo, useTrackEvent } from "utils/metricsUtils";
import { sendEvent } from "utils/webrtcUtils";
import { ConnectionHealthStatusResult } from "./connectionHealthMonitor/useCollectTeleoConnectionStats";
import { logUnexpectedError } from "utils/errorUtils";

export const useConnectionAnalytics = (
  isTherapist: boolean,
  updateAnalyticsInfoInRoomsManager: (analyticsInfo: any) => void,
  getAdditionalConnectionAnalyticsData: () => ConnectionHealthStatusResult
) => {
  const { trackEvent, isReadyToTrackEventForClient } = useTrackEvent();
  const getAnalyticsInfo = useAnalyticsInfo();
  const sessionClientIsInternalRef = useRef<boolean>();

  const sessionStartTime = useRef<number>();
  const roomStartTime = useRef<number>();

  useEffect(() => {
    if (isTherapist) {
      trackEvent("Room Open");
      roomStartTime.current = Date.now();
    }
  }, [isTherapist]);

  const trackClientSessionStart = (
    providerDataChannel: RTCDataChannel | undefined
  ) => {
    if (sessionStartTime.current !== undefined) {
      // Ignore if we've already tracked this session start (e.g. when reconnecting the same client due to internet instability)
      return;
    }
    sessionStartTime.current = Date.now();
    trackEvent("Room Session Join (Client)");
    const isInternal = localStorage.getItem("isInternal") === "true";
    sendEvent("session-start", { isInternal }, providerDataChannel);
  };

  const listenForSessionStartEvent = async (event: MessageEvent) => {
    const data = JSON.parse(event.data);
    const eventType = data.event;
    if (eventType === "session-start") {
      const peerIsInternal = data.data.isInternal;
      sessionStartTime.current = Date.now();
      trackEvent(
        "Room Session Start",
        peerIsInternal ? { ["Teleo Internal"]: true } : {}
      );
      sessionClientIsInternalRef.current = peerIsInternal;
      if (isTherapist) {
        const analyticsInfo = await getAnalyticsInfo();
        if (analyticsInfo) {
          if (peerIsInternal) {
            analyticsInfo.isInternal = true;
          }
          updateAnalyticsInfoInRoomsManager(analyticsInfo);
        }
      }
    }
  };

  const getMixpanelPropsFromConnectionHealth = (
    connectionHealth: ConnectionHealthStatusResult
  ) => {
    try {
      return {
        ["ConnectionHealth.Healthy"]: connectionHealth.healthyCount,
        ["ConnectionHealth.Unhealthy"]: connectionHealth.unhealthyCount,
        ["ConnectionHealth.Mixeds"]: connectionHealth.mixedCount,
        ["ConnectionHealth.Total"]: connectionHealth.total,
      };
    } catch (error) {
      logUnexpectedError(error);
      return {};
    }
  };

  const maybeTrackRoomClose = () => {
    if (isTherapist && roomStartTime.current) {
      const roomDuration = Date.now() - roomStartTime.current;
      roomStartTime.current = undefined;
      const connectionHealth = getAdditionalConnectionAnalyticsData();
      const healthInfoProps =
        getMixpanelPropsFromConnectionHealth(connectionHealth);
      trackEvent("Room Close", {
        ["Duration - Minutes"]: roomDuration / 1000 / 60,
        ["Duration - Hours"]: roomDuration / 1000 / 60 / 60,
        ...healthInfoProps,
      });
    }
  };

  const trackSessionEnd = () => {
    if (sessionStartTime.current) {
      const sessionDuration = Date.now() - sessionStartTime.current;
      sessionStartTime.current = undefined;
      const connectionHealth = getAdditionalConnectionAnalyticsData();
      const healthInfoProps =
        getMixpanelPropsFromConnectionHealth(connectionHealth);
      const eventName = isTherapist
        ? "Room Session End"
        : "Room Session Leave (Client)";
      trackEvent(eventName, {
        ["Duration - Minutes"]: sessionDuration / 1000 / 60,
        ["Duration - Hours"]: sessionDuration / 1000 / 60 / 60,
        ...healthInfoProps,
        ...(sessionClientIsInternalRef.current
          ? { ["Teleo Internal"]: true }
          : {}),
      });
      // Reset "isInternal" to depend only on therapist
      sessionClientIsInternalRef.current = undefined;
    }
  };

  return {
    isReadyToTrackEventForClient,
    trackClientSessionStart,
    listenForSessionStartEvent,
    maybeTrackRoomClose,
    trackSessionEnd,
  };
};

import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { selectIsMuted, setIsMuted } from "redux/settingsRedux";
import { ReactComponent as MicOffIcon } from "assets/icons/mic_off.svg";
import { VideoButton } from "pages/Space/components/Videos/VideosButtons/VideoButton";

type MuteButtonProps = {
  setRemoteIsPeerMuted: (isPeerMuted: boolean, peerId?: string) => void;
  className: string;
  style?: React.CSSProperties;
};

export const MuteButton = ({
  setRemoteIsPeerMuted,
  className,
  style,
}: MuteButtonProps) => {
  const isMuted = useSelector(selectIsMuted);

  const dispatch = useDispatch();

  const toggleMute = () => {
    dispatch(setIsMuted(!isMuted));
    setRemoteIsPeerMuted(!isMuted);
  };

  return (
    <VideoButton
      className={className}
      style={style}
      onClick={toggleMute}
      tooltipText={isMuted ? "Unmute" : "Mute"}
      forceVisible={!!isMuted}
      icon={MicOffIcon}
      isIndicator={!!isMuted}
    />
  );
};

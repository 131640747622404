import React from "react";

import styles from "./ItemButton.module.css";
import clsx from "clsx";
import Tooltip from "components/Tooltip/Tooltip";

type ItemButtonProps = {
  isWarnColor?: boolean;
  iconSrc: any;
  iconSize: number;
  onClick: () => void;
  tooltipText: string;
  style?: React.CSSProperties;
};

const ItemButton = ({
  isWarnColor,
  iconSrc,
  iconSize,
  onClick,
  tooltipText,
  style,
}: ItemButtonProps) => {
  const sizeStyle = {
    height: iconSize,
    width: iconSize,
  };
  return (
    <div className={styles.container} style={style}>
      <Tooltip text={tooltipText}>
        <div
          className={clsx(styles.button, { [styles.warn]: isWarnColor })}
          onClick={onClick}
        >
          <img src={iconSrc} style={sizeStyle} />
        </div>
      </Tooltip>
    </div>
  );
};

export default ItemButton;

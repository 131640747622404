import React from "react";
import styles from "./ResourceCategoryIcon.module.css";
import { ReactComponent as ArtIcon } from "assets/icons/art.svg";
import { ReactComponent as GameIcon } from "assets/icons/game.svg";
import { ReactComponent as InstructionalIcon } from "assets/icons/instructional.svg";
import { ReactComponent as PsychoeducationalIcon } from "assets/icons/psychoeducational.svg";
import { ReactComponent as SandtrayIcon } from "assets/icons/sandtray.svg";
import { ReactComponent as VideoIcon } from "assets/icons/video.svg";
import { ReactComponent as WhiteboardIcon } from "assets/icons/whiteboard.svg";
import { ReactComponent as WorksheetIcon } from "assets/icons/worksheet.svg";
import { ReactComponent as MusicIcon } from "assets/icons/music.svg";
import { ReactComponent as BookIcon } from "assets/icons/book.svg";
import { ReactComponent as LockIcon } from "assets/icons/lock.svg";
import Tooltip from "components/Tooltip/Tooltip";
import clsx from "clsx";

type ResourceCategoryIconProps = {
  category: string | null | undefined;
  iconClassName?: string;
  containerClassName?: string;
};

const categoryToIcon: { [category: string]: React.ElementType } = {
  ["Art/Creative"]: ArtIcon,
  ["EBook"]: BookIcon,
  ["Game"]: GameIcon,
  ["Instructional"]: InstructionalIcon,
  ["Music"]: MusicIcon,
  ["Sand Tray"]: SandtrayIcon,
  ["Video"]: VideoIcon,
  ["Whiteboard"]: WhiteboardIcon,
  ["Worksheet/Chart"]: WorksheetIcon,
  ["Psychoeducational"]: PsychoeducationalIcon,
  ["private"]: LockIcon,
};

const ResourceCategoryIcon = ({
  category,
  iconClassName,
  containerClassName,
}: ResourceCategoryIconProps) => {
  if (!category || !categoryToIcon.hasOwnProperty(category)) {
    return null;
  }

  const CategoryIconComponent = categoryToIcon[category];

  return (
    <div className={clsx(styles.container, containerClassName)}>
      <Tooltip text={category}>
        <CategoryIconComponent className={clsx(styles.icon, iconClassName)} />
      </Tooltip>
    </div>
  );
};

export default ResourceCategoryIcon;

import React, { useState } from "react";

import styles from "./ChangeIconModal.module.css";
import Button from "components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  selectEditItemId,
  setIsDoneLoadingId,
  setIsLoadingId,
  setShowChangeIconModal,
} from "redux/editRoomNavigationRedux";
import Modal from "components/Modal/Modal";
import { useChangeItemIconMutation } from "generated/graphql";
import { selectCurrentRoomId } from "redux/userRedux";
import { evictCurrentRoomItems } from "utils/dbUtils";
import { logUnexpectedError } from "utils/errorUtils";
import { getIconConfig, ICON_ID } from "../../subpages/SpaceRoom/itemsConfig";
import clsx from "clsx";
import {
  selectBackgroundSize,
  selectRoomItems,
} from "redux/spaceNavigationRedux";
import { useLogRoomItemEvent } from "utils/metricsUtils";
import { getPositionInCanvas } from "../../subpages/SpaceRoom/utils/itemDrawingUtils";

const GENERIC_ERROR_MESSAGE =
  "Sorry, there was a problem changing your item icon. Please try again later.";

const icons = [
  ICON_ID.GLASS_WHITEBOARD,
  ICON_ID.WHITEBOARD,
  ICON_ID.EASEL,
  ICON_ID.SKETCH,
  ICON_ID.SKETCH_2,
  ICON_ID.PAINT,
  ICON_ID.PAINT_2,
  ICON_ID.PAINT_BUCKET,

  ICON_ID.TV,
  ICON_ID.TABLET,
  ICON_ID.WORKSHEET,
  ICON_ID.BOOK,
  ICON_ID.BOOK_2,
  ICON_ID.BOOK_3,
  ICON_ID.ALBUM,

  ICON_ID.GAME,
  ICON_ID.GAME_2,
  ICON_ID.GAME_3,
  ICON_ID.GAME_4,
  ICON_ID.VIDEO_GAME,
  ICON_ID.SAND_TRAY,
  ICON_ID.JEOPARDY,
  ICON_ID.SPINNER,

  ICON_ID.FEELINGS_CHART,
  ICON_ID.NEON,
  ICON_ID.THERMOMETER,

  ICON_ID.TREASURE,
  ICON_ID.HOUSE,
];

const ChangeIconModal = () => {
  const dispatch = useDispatch();
  const editItemId = useSelector(selectEditItemId);
  const roomId = useSelector(selectCurrentRoomId);
  const roomItems = useSelector(selectRoomItems);
  const backgroundSize = useSelector(selectBackgroundSize);
  const [changeIconMutation] = useChangeItemIconMutation();
  const [updating, setUpdating] = useState(false);
  const logRoomItemEvent = useLogRoomItemEvent();

  const currentItem = roomItems?.find((item) => item.id === editItemId);
  const currentIconId = currentItem?.icon_id;

  const [selectedIconId, setSelectedIconId] = useState<string | undefined>(
    currentIconId
  );

  const closeModal = () => {
    dispatch(setShowChangeIconModal(false));
  };

  const changeIcon = async (iconId: string | undefined) => {
    const loadingKey = `CHANGE_ICON_${Date.now()}`;
    try {
      dispatch(setIsLoadingId(loadingKey));
      setUpdating(true);
      if (
        !editItemId ||
        !roomId ||
        !iconId ||
        !currentIconId ||
        !backgroundSize
      ) {
        return;
      }
      const oldRx = currentItem?.rx;
      const oldRy = currentItem?.ry;
      const {
        scale: oldScale,
        srcWidth: oldWidth,
        srcHeight: oldHeight,
      } = getIconConfig(currentIconId);
      const { scale, srcWidth, srcHeight } = getIconConfig(iconId);
      const top =
        oldRy * backgroundSize.height +
        ((oldHeight * oldScale) / 2 - (srcHeight * scale) / 2);
      const left =
        oldRx * backgroundSize.width +
        ((oldWidth * oldScale) / 2 - (srcWidth * scale) / 2);
      const { newTop, newLeft } = getPositionInCanvas(
        top,
        left,
        srcWidth * scale,
        srcHeight * scale,
        backgroundSize.width,
        backgroundSize.height
      );
      const newRx = newLeft / backgroundSize.width;
      const newRy = newTop / backgroundSize.height;
      const resourceId = currentItem.resource.id;
      const { errors } = await changeIconMutation({
        variables: {
          itemId: editItemId,
          iconId: iconId,
          rx: newRx,
          ry: newRy,
        },
        update: evictCurrentRoomItems(roomId),
      });
      if (errors) {
        logUnexpectedError(errors);
        alert(GENERIC_ERROR_MESSAGE);
        return;
      }

      logRoomItemEvent({
        action: "CHANGE_ICON",
        iconId,
        resourceId,
        previousIconId: currentIconId,
      }).catch(logUnexpectedError);
      closeModal();
    } catch (e) {
      logUnexpectedError(e);
      alert(GENERIC_ERROR_MESSAGE);
    } finally {
      setUpdating(false);
      dispatch(setIsDoneLoadingId(loadingKey));
    }
  };

  const changeIconToSelected = async () => {
    try {
      await changeIcon(selectedIconId);
    } catch (e) {
      logUnexpectedError(e);
      alert(GENERIC_ERROR_MESSAGE);
    }
  };

  const selectIcon = (iconId: string) => () => {
    setSelectedIconId(iconId);
  };

  return (
    <Modal closeModal={closeModal}>
      <div className={styles.heading}>Change Item Icon</div>
      <div className={styles.body}>
        {icons.map((iconId, index) => {
          const { src } = getIconConfig(iconId);
          if (!src) {
            return null;
          }
          return (
            <div
              key={index}
              className={clsx(styles.iconContainer, {
                [styles.selectedIcon]: selectedIconId === iconId,
              })}
              onClick={selectIcon(iconId)}
              onDoubleClick={() => changeIcon(iconId)}
            >
              <img className={styles.itemIcon} src={src} />
            </div>
          );
        })}
      </div>
      <div className={styles.buttonRow}>
        <Button className={styles.cancelButton} onClick={closeModal}>
          Cancel
        </Button>
        <Button onClick={changeIconToSelected} disabled={updating}>
          {updating ? "..." : "Update"}
        </Button>
      </div>
    </Modal>
  );
};

export default ChangeIconModal;

import React from "react";
import { useDispatch } from "react-redux";

import styles from "./OpenSharedRoomFolderRow.module.css";
import folderSharedIcon from "assets/icons/folder_shared.svg";
import { GetOpenRoomModalDataQuery } from "generated/graphql";
import {
  setSharedRoomFolderModalFolderId,
  setShowOpenRoomModal,
} from "redux/spaceNavigationRedux";

type OpenSharedRoomFolderRowProps = {
  sharedRoomFolder: NonNullable<
    GetOpenRoomModalDataQuery["sharedRoomFolders"]
  >[0];
};

const OpenSharedRoomFolderRow = ({
  sharedRoomFolder,
}: OpenSharedRoomFolderRowProps) => {
  const dispatch = useDispatch();

  const showSharedRoomFolderModal = (event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch(setShowOpenRoomModal(false));
    dispatch(setSharedRoomFolderModalFolderId(sharedRoomFolder.id));
  };

  return (
    <div className={styles.container} onClick={showSharedRoomFolderModal}>
      <img className={styles.shareFolderIcon} src={folderSharedIcon} />
      <span className={styles.text}>{sharedRoomFolder.name}</span>
    </div>
  );
};

export default OpenSharedRoomFolderRow;

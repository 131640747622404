import { fabric } from "utils/fabricUtils";
import { fabricTypes } from "utils/fabric-impl";

export const THUMBNAIL_ERROR_KEY = "ERROR";

export enum ResourceType {
  FILE,
  WEBSITE,
}

export const defaultShadow = (scale: number) => {
  return `rgba(0,0,0,0.8) ${2 / scale}px ${2 / scale}px ${10 / scale}px`;
};

export const maybeSetHoverShadow = (
  target: fabricTypes.Object,
  itemId: string,
  isPoster: boolean | undefined,
  editRoomMode: boolean | undefined,
  editItemId: string | undefined
) => {
  if (editRoomMode && itemId === editItemId) {
    return;
  }
  // Don't show a shadow on the poster unless editing the room
  if (!editRoomMode && isPoster) {
    return;
  }
  const scale = target.scaleX || 1;
  const shadow = editRoomMode
    ? `rgba(0,0,0,0.9) ${10 / scale}px ${10 / scale}px ${10 / scale}px`
    : `rgba(0,0,0,0.9) ${2 / scale}px ${2 / scale}px ${20 / scale}px`;
  target.set({ shadow });
};

export const loadImage = (imageUrl: string) => {
  return new Promise<fabricTypes.Image>((resolve) => {
    fabric.Image.fromURL(
      imageUrl,
      function (oImg: fabricTypes.Image) {
        resolve(oImg);
      },
      { crossOrigin: "anonymous" }
    ); // Required to be able to call toDataURL on any canvas an external image is added to, for example to copy from a temporary canvas
  });
};

export const copyFabricImage = (object: fabricTypes.Image) => {
  return new Promise<fabricTypes.Image>((resolve) => {
    object.clone(function (obj: fabricTypes.Image) {
      resolve(obj);
    });
  });
};

export const PAINT_BRUSH_HEIGHT_MULTIPLIER = 0.0075;
export const getPaintBrush = (
  color: string,
  visibleHeight: number,
  canvas: fabricTypes.Canvas
) => {
  const brush = new fabric.PencilBrush(canvas);
  brush.width = visibleHeight * PAINT_BRUSH_HEIGHT_MULTIPLIER;
  brush.color = color;
  brush.decimate = 2; // make the lines a bit smoother
  return brush;
};

export const ERASER_BRUSH_HEIGHT_MULTIPLIER = 0.075;
export const getEraserBrush = (
  visibleHeight: number,
  canvas: fabricTypes.Canvas
) => {
  const brush = new fabric.EraserBrush(canvas);
  brush.width = visibleHeight * ERASER_BRUSH_HEIGHT_MULTIPLIER;
  brush.decimate = 2;
  return brush;
};

export const getRemoteEraserBrush = (
  visibleHeight: number,
  canvas: fabricTypes.Canvas
) => {
  const brush = new fabric.PencilBrush(canvas);
  brush.width = visibleHeight * ERASER_BRUSH_HEIGHT_MULTIPLIER;
  brush.color = "rgba(255,255,255,0.75)";
  brush.decimate = 2;
  return brush;
};

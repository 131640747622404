import React, { useEffect } from "react";

import LoggedInApp from "./components/LoggedInApp";
import { useSignIn } from "./utils/signInUtils";
import { useAppSignOut } from "./utils/appSignOutUtils";
import { useTrackEvent } from "./utils/metricsUtils";
import { useSelector } from "react-redux";
import {
  selectDecodedAuthToken,
  selectEncodedAuthToken,
} from "./redux/userRedux";

function App() {
  const signOut = useAppSignOut();
  useSignIn("", signOut);
  const encodedToken = useSelector(selectEncodedAuthToken);
  const decodedToken = useSelector(selectDecodedAuthToken);
  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    trackEvent("Load - Home");
  }, []);

  return decodedToken && encodedToken ? <LoggedInApp /> : null;
}

export default App;

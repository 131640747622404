import React, { useEffect, useRef, useState } from "react";

import styles from "./ClientSelectionModal.module.css";
import Modal from "components/Modal/Modal";
import Button from "components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  admitClient,
  cancelAdmitClient,
  cancelLoading,
  loadClient,
  openNameConfirmationModal,
  selectClientFileOpen,
  selectClientHasJoinedRoom,
  selectClientSearchText,
  selectClientSelectionStage,
  selectCurrentClient,
  selectIncomingClient,
  selectLoadingClientForSnapshot,
  selectSelectingClient,
  setSelectingClient,
  toggleClientFileOpen,
} from "redux/clientManagementRedux";
import {
  useGetClientsQuery,
  useSetCurrentClientMutation,
} from "generated/graphql";
import { selectUserId } from "redux/userRedux";
import ClientSelectionList from "../ClientSelectionList/ClientSelectionList";
import { useAdmitClient } from "utils/waitingRoomUtils";
import { triggerSnapshotTask } from "redux/spaceNavigationRedux";
import { createFuseInstance } from "utils/fuseUtils";
import { logUnexpectedError } from "utils/errorUtils";
import { useLogClientManagementEvent } from "utils/metricsUtils";
import { selectSessionEHRSystem } from "redux/ehrSystemRedux";
import { useTrackClientSelection } from "../useClientManagementAnalytics";

type ClientSelectionModalProps = {};

const ClientSelectionModal = ({}: ClientSelectionModalProps) => {
  const dispatch = useDispatch();

  const userId = useSelector(selectUserId);
  const clientsQuery = useGetClientsQuery({
    variables: { providerId: userId },
    fetchPolicy: "cache-first",
  });

  const isClientFileOpen = useSelector(selectClientFileOpen);

  const clientSelectionStage = useSelector(selectClientSelectionStage);
  const incomingClient = useSelector(selectIncomingClient);
  const previouslySelectedClient = useSelector(selectCurrentClient);
  const selectingClient = useSelector(selectSelectingClient);
  const searchText = useSelector(selectClientSearchText);
  const admitClientOnServer = useAdmitClient();
  const providerId = useSelector(selectUserId);
  const [setCurrentClient] = useSetCurrentClientMutation();

  const ehrSystem = useSelector(selectSessionEHRSystem);

  const logClientManagementEvent = useLogClientManagementEvent();
  const { trackCancel, trackLoad } = useTrackClientSelection();

  const shouldCenterAutoScrollRef = useRef<boolean>(!!previouslySelectedClient);
  const [clientSelectedSource, setClientSelectedSource] = useState<
    "Filtered List" | "Full List" | "Auto Selected"
  >("Auto Selected");
  const clientHasJoinedRoom = useSelector(selectClientHasJoinedRoom);

  const isLoadingClientForSnapshot = useSelector(
    selectLoadingClientForSnapshot
  );

  const selectedClientAlreadyLoaded =
    !!selectingClient?.canonical_id &&
    selectingClient?.canonical_id === previouslySelectedClient?.canonical_id;

  const closeModal = () => {
    trackCancel();
    if (clientSelectionStage === "clientAdmissionSelect") {
      dispatch(cancelAdmitClient());
    } else if (clientSelectionStage === "clientLoadSelect") {
      dispatch(cancelLoading());
    }
  };

  /**
   * Checks if two client names match using fuzzy search.
   *
   * Creates a Fuse.js instance with the first name and searches for the second name.
   * If a match is found, returns true. Otherwise, sets the collection to the second name and searches for the first name.
   * Returns true if a match is found in the second search, false otherwise.
   */
  const clientNameMatches = (nameA: string, nameB: string) => {
    const fuse = createFuseInstance([nameA]);
    if (fuse.search(nameB).length !== 0) {
      return true;
    }
    fuse.setCollection([nameB]);
    return fuse.search(nameA).length !== 0;
  };

  const saveCurrentClientOnDB = async (
    clientId: string,
    providerId: string
  ) => {
    const result = await setCurrentClient({
      variables: {
        clientId: clientId,
        providerId: providerId,
      },
    });
    if (
      result.errors ||
      !result.data?.update_user_by_pk?.current_client_canonical_id
    ) {
      alert("There was an error loading the client. Please try again.");
    }
  };

  const handleSubmit = async () => {
    // should not be called without a client selected
    if (!selectingClient) {
      logUnexpectedError(
        new Error("Unexpectedly trying to select a client without one")
      );
      alert("You must select a client.");
      return;
    }

    let action:
      | "CLIENT_SELECTED_FROM_SEARCH_RESULTS"
      | "CLIENT_SELECTED_FROM_FULL_LIST"
      | "CLIENT_SELECTED_AUTOMATICALLY";
    switch (clientSelectedSource) {
      case "Filtered List":
        action = "CLIENT_SELECTED_FROM_SEARCH_RESULTS";
        break;
      case "Full List":
        action = "CLIENT_SELECTED_FROM_FULL_LIST";
        break;
      default:
        action = "CLIENT_SELECTED_AUTOMATICALLY";
        break;
    }
    // not awaiting to let it run in the background
    logClientManagementEvent({
      action: action,
      clientCanonicalId: selectingClient.canonical_id,
      duringSession: clientHasJoinedRoom,
    });

    trackLoad(selectedClientAlreadyLoaded, clientSelectedSource);

    if (clientSelectionStage === "clientAdmissionSelect") {
      if (
        !clientNameMatches(incomingClient?.name ?? "", selectingClient.name)
      ) {
        dispatch(openNameConfirmationModal());
        // not awaiting to let it run in the background
        logClientManagementEvent({
          action: "NAME_CONFIRMATION_SHOWN",
          clientName: selectingClient.name,
          incomingName: incomingClient?.name ?? "",
        });
      } else {
        await admitClientOnServer({});
        await saveCurrentClientOnDB(selectingClient.canonical_id, providerId);
        // not awaiting to let it run in the background
        logClientManagementEvent({
          action: "CLIENT_ADMITTED",
          clientCanonicalId: selectingClient.canonical_id,
          previousClientCanonicalId: previouslySelectedClient?.canonical_id,
          duringSession: clientHasJoinedRoom,
        });
        dispatch(admitClient(selectingClient));
      }
    } else if (clientSelectionStage === "clientLoadSelect") {
      await saveCurrentClientOnDB(selectingClient.canonical_id, providerId);
      // not awaiting to let it run in the background
      logClientManagementEvent({
        action: "CLIENT_LOADED",
        clientCanonicalId: selectingClient.canonical_id,
        previousClientCanonicalId: previouslySelectedClient?.canonical_id,
        duringSession: clientHasJoinedRoom,
      });
      dispatch(loadClient(selectingClient));
      if (isLoadingClientForSnapshot) {
        dispatch(triggerSnapshotTask());
      } else if (!isClientFileOpen) {
        dispatch(toggleClientFileOpen());
      }
    }
  };

  let mainCTA = "Select client above";

  if (selectingClient) {
    const clientName = `“${selectingClient?.name}"`;

    if (clientSelectionStage === "clientAdmissionSelect") {
      mainCTA = `Admit ${clientName}`;
    } else if (isLoadingClientForSnapshot) {
      mainCTA = `Load ${clientName} & Save`;
    } else if (selectedClientAlreadyLoaded) {
      mainCTA = `Continue with ${clientName}`;
    } else {
      mainCTA = `Load ${clientName}`;
    }
  }

  const clientList = React.useMemo(
    () =>
      clientsQuery.data?.client
        ?.slice()
        .sort((a, b) => a.name.localeCompare(b.name)) || [],
    [clientsQuery.data?.client]
  );

  useEffect(() => {
    // find best match for incoming client
    if (incomingClient && clientList.length > 0) {
      const fuseList = createFuseInstance(clientList);
      let fuseResults = fuseList.search(incomingClient?.name);
      // as results are sorted, the first result is the best match
      if (fuseResults.length > 0) {
        shouldCenterAutoScrollRef.current = true;
        dispatch(setSelectingClient(fuseResults[0].item));
        return;
      }
      fuseList.setCollection([incomingClient]);
      for (const client of clientList) {
        fuseResults = fuseList.search(client.name);
        if (fuseResults.length > 0) {
          shouldCenterAutoScrollRef.current = true;
          dispatch(setSelectingClient(client));
          return;
        }
      }
    }
  }, [incomingClient, clientList]);

  return (
    <Modal className={styles.modal} closeModal={closeModal}>
      <div className={styles.heading}>
        {clientSelectionStage === "clientAdmissionSelect"
          ? `Admit ${incomingClient ? `“${incomingClient.name}"` : "Client"}`
          : "Load Client"}
      </div>
      <ClientSelectionList
        clientList={clientList ?? []}
        value={selectingClient}
        onChange={(client) => {
          dispatch(setSelectingClient(client));
          setClientSelectedSource(!!searchText ? "Filtered List" : "Full List");
        }}
        showNewClientButton={!ehrSystem}
        shouldCenterAutoScrollRef={shouldCenterAutoScrollRef}
      />
      <Button
        onClick={handleSubmit}
        disabled={!selectingClient}
        className={styles.button}
      >
        {mainCTA}
      </Button>
    </Modal>
  );
};

export default ClientSelectionModal;

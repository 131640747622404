import React from "react";

import styles from "./ChangeItemSideBar.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectEditItemId,
  setEditItemId,
  setIsDoneLoadingId,
  setIsLoadingId,
} from "redux/editRoomNavigationRedux";
import xIcon from "assets/icons/x.svg";
import ActivityList from "../ActivityList/ActivityList";
import { evictCurrentRoomItems } from "utils/dbUtils";
import { useLogRoomItemEvent } from "utils/metricsUtils";
import { logUnexpectedError } from "utils/errorUtils";
import { GetResourcesQuery, useSetRoomItemMutation } from "generated/graphql";
import { selectCurrentRoomId } from "redux/userRedux";
import { selectRoomItems } from "redux/spaceNavigationRedux";

const ChangeItemSideBar = () => {
  const dispatch = useDispatch();
  const [setRoomItem] = useSetRoomItemMutation();
  const editItemId = useSelector(selectEditItemId);
  const roomItems = useSelector(selectRoomItems);
  const roomId = useSelector(selectCurrentRoomId);
  const logRoomItemEvent = useLogRoomItemEvent();

  const editItem = roomItems?.find((item) => item.id === editItemId);

  const close = () => {
    dispatch(setEditItemId(null));
  };

  const selectResource = async (resource: GetResourcesQuery["resource"][0]) => {
    const loadingKey = `CHANGE_ACTIVITY_${Date.now()}`;
    try {
      dispatch(setIsLoadingId(loadingKey));
      if (editItemId && roomId && resource.id) {
        const previousResource = editItem?.resource.id;
        const iconId = editItem?.icon_id;
        await setRoomItem({
          variables: {
            resourceId: resource.id,
            itemId: editItemId,
          },
          update: evictCurrentRoomItems(roomId),
        });
        if (iconId) {
          logRoomItemEvent({
            action: "CHANGE_ACTIVITY",
            iconId,
            resourceId: resource.id,
            previousResourceId: previousResource,
          }).catch(logUnexpectedError);
        }
      }
      dispatch(setIsDoneLoadingId(loadingKey));
    } catch (err) {
      logUnexpectedError(err);
      dispatch(setIsDoneLoadingId(loadingKey));
    }
  };

  const editItemResourceId = editItem?.resource?.id;

  return (
    <div className={styles.container}>
      <div className={styles.headerRow}>
        <img src={xIcon} className={styles.hideIcon} onClick={close} />
        <div className={styles.header}>Select Item Activity</div>
      </div>
      <div className={styles.horizontalLine} />
      <div className={styles.activityListContainer}>
        <ActivityList
          onClick={selectResource}
          selectedResourceId={editItemResourceId}
          excludePoster
        />
      </div>
    </div>
  );
};

export default ChangeItemSideBar;

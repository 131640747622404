import React, { useState } from "react";

import styles from "./AddItemSideBar.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsDoneLoadingId,
  setIsLoadingId,
  setShowAddItemSideBar,
} from "redux/editRoomNavigationRedux";
import xIcon from "assets/icons/x.svg";
import ActivityList from "../ActivityList/ActivityList";
import Button from "components/Button/Button";
import {
  GetResourcesQuery,
  useInsertRoomItemMutation,
} from "generated/graphql";
import { logUnexpectedError } from "utils/errorUtils";
import { selectCurrentRoomId } from "redux/userRedux";
import { evictCurrentRoomItems } from "utils/dbUtils";
import {
  selectBackgroundSize,
  selectRoomItems,
} from "redux/spaceNavigationRedux";
import { getDefaultConfig } from "utils/resourceUtils";
import { useLogRoomItemEvent } from "utils/metricsUtils";

type AddItemSideBarProps = {};

const AddItemSideBar = ({}: AddItemSideBarProps) => {
  const roomId = useSelector(selectCurrentRoomId);
  const roomItems = useSelector(selectRoomItems);
  const logRoomItemEvent = useLogRoomItemEvent();
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] =
    useState<GetResourcesQuery["resource"][0]>();
  const [insertRoomItem] = useInsertRoomItemMutation();

  const close = () => {
    dispatch(setShowAddItemSideBar(false));
  };

  const backgroundImageSize = useSelector(selectBackgroundSize);

  const selectItem = async (resource: GetResourcesQuery["resource"][0]) => {
    setSelectedItem(resource);
  };

  const addItem = async (resource: GetResourcesQuery["resource"][0]) => {
    const loadingKey = `ADD_ITEM_${Date.now()}`;
    try {
      dispatch(setIsLoadingId(loadingKey));

      const defaultConfig = getDefaultConfig(
        resource.default_icon_id,
        resource.file_key,
        backgroundImageSize,
        roomItems
      );
      if (!roomId || !defaultConfig) {
        return;
      }

      const { errors } = await insertRoomItem({
        variables: {
          roomId,
          resourceId: resource.id,
          ...defaultConfig,
        },
        update: evictCurrentRoomItems(roomId),
      });

      if (errors) {
        logUnexpectedError(errors);
        alert(
          "Sorry, there was a problem adding the item to your room. Please try again later."
        );
        return;
      }

      logRoomItemEvent({
        action: "ADD",
        iconId: defaultConfig.iconId,
        resourceId: resource.id,
      }).catch(logUnexpectedError);
    } catch (e) {
      logUnexpectedError(e);
      alert(
        "Sorry, there was a problem adding the item to your room. Please try again later."
      );
      return;
    } finally {
      dispatch(setIsDoneLoadingId(loadingKey));
    }
  };

  const addSelectedItem = async () => {
    if (selectedItem) {
      await addItem(selectedItem).catch(logUnexpectedError);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerRow}>
        <img src={xIcon} className={styles.hideIcon} onClick={close} />
        <div className={styles.header}>Add an Item</div>
      </div>
      <div className={styles.horizontalLine} />
      <div className={styles.activityListContainer}>
        <ActivityList
          onClick={selectItem}
          onDoubleClick={addItem}
          selectedResourceId={selectedItem?.id}
        />
      </div>
      <div className={styles.horizontalLine} />
      <Button
        onClick={addSelectedItem}
        className={styles.addButton}
        disabled={!selectedItem}
      >
        Add
      </Button>
    </div>
  );
};

export default AddItemSideBar;

import React, { useRef, useState } from "react";
import clsx from "clsx";

import styles from "./MultiColorButton.module.css";
import colorPicker from "assets/color_picker.png";
import ColorPicker from "./ColorPicker";
import { Peers } from "pages/Space/hooks/connection/usePeerWebRTCConnection";
import { useRemoteCustomColor } from "./useRemoteCustomColors";
import { getPositionForColor } from "./colorPickerHelpers";

interface MultiColorButtonProps {
  peersRef: React.MutableRefObject<Peers>;
  peers: Peers;
  id: string;
  isSelected: boolean;
  defaultColor: string;
  onClick: () => void;
  setCustomColor: (color: string) => void;
  buttonStyle?: React.CSSProperties;
  containerRef: React.RefObject<HTMLDivElement>;
  subIconStyle?: React.CSSProperties;
}

export const MultiColorButton = ({
  peersRef,
  peers,
  id,
  isSelected,
  defaultColor,
  onClick,
  setCustomColor,
  buttonStyle,
  containerRef,
  subIconStyle,
}: MultiColorButtonProps) => {
  const [showPicker, setShowPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState(defaultColor);
  const [indicatorPosition, setIndicatorPosition] = useState(
    getPositionForColor(selectedColor)
  );
  const buttonContainerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const changeCustomColor = (color: string, position: number) => {
    setSelectedColor(color);
    setCustomColor(color);
    setIndicatorPosition(position);
  };

  const { emitCustomColorChanged } = useRemoteCustomColor({
    peersRef,
    peers,
    id,
    changeCustomColor,
  });

  const handleMultiColorButtonClick = () => {
    onClick();
    setCustomColor(selectedColor);
    if (showPicker) {
      setShowPicker(false);
    }
  };

  const handleColorPickerIconButtonClick = () => {
    setShowPicker(!showPicker);
    onClick();
    setCustomColor(selectedColor);
  };

  const handleSetSelectedColor = (color: string, position: number) => {
    setSelectedColor(color);
    setCustomColor(color);
    emitCustomColorChanged(id, color, position);
  };

  const handleClickOutside = (event: MouseEvent) => {
    // Close the color picker if the user clicks within the control button container
    // Don't close the color picker if the user is drawing since they might want to adjust the color they're using while drawing
    if (
      containerRef.current &&
      buttonContainerRef.current &&
      containerRef.current.contains(event.target as Node) &&
      !buttonContainerRef.current.contains(event.target as Node)
    ) {
      setShowPicker(false);
    }
  };

  React.useEffect(() => {
    if (showPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPicker]);

  return (
    <div ref={buttonContainerRef} className={styles.buttonContainer}>
      <button
        id={"viewportLockButton"}
        ref={buttonRef}
        className={clsx(styles.button, {
          [styles.isSelected]: isSelected,
        })}
        style={{ ...buttonStyle, backgroundColor: selectedColor }}
        onClick={handleMultiColorButtonClick}
      ></button>
      <img
        src={colorPicker}
        className={styles.colorPickerButton}
        style={{ ...subIconStyle }}
        onClick={handleColorPickerIconButtonClick}
      />
      <ColorPicker
        open={showPicker}
        setOpen={setShowPicker}
        selectedColor={selectedColor}
        setSelectedColor={handleSetSelectedColor}
        indicatorPosition={indicatorPosition}
        setIndicatorPosition={setIndicatorPosition}
        referenceElement={buttonRef.current}
      />
    </div>
  );
};

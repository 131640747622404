import Fuse from "fuse.js";

export const createFuseInstance = (itemList: any[]) => {
  return new Fuse(itemList, {
    includeScore: true,
    isCaseSensitive: false,
    shouldSort: true,
    keys: ["name"],
    threshold: 0.2,
  });
};

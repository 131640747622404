import React from "react";
import { toast } from "react-toastify";

import styles from "./SharedResourceAlert.module.css";
import { Alert } from "./Alert";
import {
  useGetResourceSharedTypeQuery,
  useUpdateResourceSharedTypeMutation,
} from "generated/graphql";
import { RESOURCE_SHARED_TYPES } from "utils/organizationUtils";

type SharedResourceAlertProps = {
  resourceId: string;
};

export const SharedResourceAlert = ({
  resourceId,
}: SharedResourceAlertProps) => {
  const { data: resourceSharedTypeResult } = useGetResourceSharedTypeQuery({
    variables: { resourceId: resourceId },
    skip: !resourceId,
  });

  const [updateResourceSharedType] = useUpdateResourceSharedTypeMutation();

  const sharedType = resourceSharedTypeResult?.resource_by_pk?.shared_type;

  const handleUpdateSharedType = async (newSharedType: string) => {
    await updateResourceSharedType({
      variables: {
        resourceId: resourceId,
        sharedType: newSharedType,
      },
    });

    if (newSharedType === RESOURCE_SHARED_TYPES.COPY_ACCEPTED) {
      toast(
        "Shared activity successfully added to your personal activity bank",
        {
          type: "success",
        }
      );
    }
  };

  if (sharedType !== RESOURCE_SHARED_TYPES.COPY_PENDING) {
    return null;
  }

  return (
    <Alert
      actionCTAText="Add"
      className={styles.container}
      actionHandler={() =>
        handleUpdateSharedType(RESOURCE_SHARED_TYPES.COPY_ACCEPTED)
      }
      dismissHandler={() =>
        handleUpdateSharedType(RESOURCE_SHARED_TYPES.COPY_DISMISSED)
      }
    >
      <div className={styles.text}>
        This activity is from a shared room and this is your first time using
        it. Do you want to add it your personal activity bank?
      </div>
    </Alert>
  );
};

import React from "react";

import styles from "./TakeSnapshotButton.module.css";
import ControlBarItem from "../ControlBarItem";
import { ReactComponent as CameraIcon } from "assets/icons/camera.svg";
import { useDispatch, useSelector } from "react-redux";

import { triggerSnapshotTask } from "redux/spaceNavigationRedux";
import {
  openLoadModal,
  selectCurrentClient,
} from "redux/clientManagementRedux";
import { selectSessionEHRSystem } from "redux/ehrSystemRedux";

type TakeSnapshotButtonProps = {
  showSnapshot?: boolean;
};

const TakeSnapshotButton = ({ showSnapshot }: TakeSnapshotButtonProps) => {
  const dispatch = useDispatch();
  const currentClient = useSelector(selectCurrentClient);
  const ehrSystem = useSelector(selectSessionEHRSystem);

  const handleClick = () => {
    if (currentClient) {
      dispatch(triggerSnapshotTask());
    } else if (!ehrSystem) {
      dispatch(openLoadModal(true));
    }
  };
  return showSnapshot ? (
    <div className={styles.container}>
      <ControlBarItem
        id="takeSnapshotButton"
        icon={CameraIcon}
        label="Snapshot"
        onClick={handleClick}
        key="snapshot"
      />
    </div>
  ) : (
    <></>
  );
};

export default TakeSnapshotButton;

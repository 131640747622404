import { readLargeEvent, sendLargeEventToPeers } from "utils/webrtcUtils";
import { useEffect } from "react";
import {
  Page,
  Snapshot,
  setCurrentAlbumPage,
  setImgKeysToURL,
  setAlbumPages,
  setViewingSnapshot,
} from "redux/clientAlbumRedux";
import { useDispatch } from "react-redux";
import { Peers } from "pages/Space/hooks/connection/usePeerWebRTCConnection";

export const useRemoteAlbum = ({
  peersRef,
  peers,
}: {
  peersRef: React.MutableRefObject<Peers>;
  peers: Peers;
}) => {
  const dispatch = useDispatch();
  const sendInitializeAlbum = (albumData: {
    pages: Page[] | undefined;
    imgKeysToURL: { [key: string]: string };
    currentPage: number;
    viewingSnapshot?: Snapshot;
  }) => {
    sendLargeEventToPeers(peersRef.current, "albumInitialize", albumData);
  };

  const sendMoveToPage = (page: number) => {
    sendLargeEventToPeers(peersRef.current, "albumMoveToPage", page);
  };

  const sendSetViewingSnapshot = (snapshot?: Snapshot) => {
    sendLargeEventToPeers(
      peersRef.current,
      "albumSetViewingSnapshot",
      snapshot
    );
  };

  const onReceiveMessageCallback = async (event: MessageEvent) => {
    const data = await readLargeEvent(event);
    if (!data) {
      return;
    }
    const eventType = data.event;
    if (eventType === "albumInitialize") {
      dispatch(setAlbumPages(data.data.pages));
      dispatch(setCurrentAlbumPage(data.data.currentPage));
      dispatch(setImgKeysToURL(data.data.imgKeysToURL));
      dispatch(setViewingSnapshot(data.data.viewingSnapshot));
    } else if (eventType === "albumMoveToPage") {
      dispatch(setCurrentAlbumPage(data.data));
    } else if (eventType === "albumSetViewingSnapshot") {
      dispatch(setViewingSnapshot(data.data));
    }
  };

  useEffect(() => {
    const peerDataChannels = Object.values(peers).map(
      (peer) => peer.dataChannel
    );
    for (const peerDataChannel of peerDataChannels) {
      if (peerDataChannel) {
        peerDataChannel.addEventListener("message", onReceiveMessageCallback);
      }
    }

    return () => {
      for (const peerDataChannel of peerDataChannels) {
        if (peerDataChannel) {
          peerDataChannel.removeEventListener(
            "message",
            onReceiveMessageCallback
          );
        }
      }
    };
  }, [peers]);

  return { sendInitializeAlbum, sendMoveToPage, sendSetViewingSnapshot };
};

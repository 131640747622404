import { zoomLevelType } from "pages/Space/subpages/Whiteboard/whiteboardTypes";

// Size of margin to leave so that the page can be scrolled on touch devices
const TOUCH_SCROLL_WIDTH = 56;
const TWO_X_ZOOM_MARGIN_RATIO = 1 / 3;
export const MIN_WIDTH_RATIO = 0.75;

export const calculateFitDimensions = (
  parentHeight: number,
  parentWidth: number,
  rawHeight: number,
  rawWidth: number,
  minWidthRatio: number | null,
  zoomLevel?: zoomLevelType
) => {
  if (
    parentHeight === 0 ||
    parentWidth === 0 ||
    rawHeight === 0 ||
    rawWidth === 0
  ) {
    return {
      fitHeight: 0,
      fitWidth: 0,
      ratio: 0,
      heightIsLimiting: false,
    };
  }
  const heightIsLimiting = parentHeight / rawHeight < parentWidth / rawWidth;
  const minWidthIsLimiting =
    minWidthRatio && heightIsLimiting && rawWidth / rawHeight < minWidthRatio;
  const getRatio = () => {
    if (zoomLevel === "2x") {
      const twoXZoomMarginWidth = parentWidth * TWO_X_ZOOM_MARGIN_RATIO;
      return Math.max(
        (parentWidth - twoXZoomMarginWidth) / rawWidth,
        parentHeight / rawHeight
      );
    }
    if (zoomLevel === "3x") {
      return Math.max(
        (parentWidth - 2 * TOUCH_SCROLL_WIDTH) / rawWidth,
        parentHeight / rawHeight
      );
    }
    if (minWidthIsLimiting) {
      const intendedWidth = minWidthRatio * parentHeight;
      return intendedWidth / rawWidth;
    }
    if (heightIsLimiting) {
      return parentHeight / rawHeight;
    }
    return parentWidth / rawWidth;
  };
  const ratio = getRatio();
  const fitHeight = rawHeight * ratio;
  const fitWidth = rawWidth * ratio;
  return {
    fitHeight,
    fitWidth,
    ratio,
    heightIsLimiting,
  };
};

export const isSmallerThanFilledWidth = (
  width: number,
  parentWidth: number
) => {
  return (parentWidth - width) / 2 > TOUCH_SCROLL_WIDTH;
};

export const getIs2xZoomEnabled = (
  parentHeight: number,
  parentWidth: number,
  fitHeight: number,
  fitWidth: number
) => {
  const hasAvailableMarginForZoom =
    parentWidth - fitWidth > parentWidth * TWO_X_ZOOM_MARGIN_RATIO;
  const isLandscapeDevice = parentWidth > parentHeight;
  const isPortraitContent = fitWidth < fitHeight;

  return hasAvailableMarginForZoom && isLandscapeDevice && isPortraitContent;
};

import { logUnexpectedError } from "./errorUtils";
import { useState } from "react";
import { backendRequest } from "utils/backendRequest";

const GENERIC_EMAIL_ERROR_MESSAGE =
  "There was an error sending the invite email. Please try again later.";

export const useSendLink = (
  emailAddress: string,
  onSuccess: (successfullySentEmailAddress: string) => void
) => {
  const [loading, setLoading] = useState(false);

  const validateEmail = (email: string) => {
    return /^.+@.+$/.test(email);
  };

  const isEmailValid = validateEmail(emailAddress);
  const sendLink = async () => {
    setLoading(true);

    try {
      if (!validateEmail(emailAddress)) return;

      const result = await backendRequest({
        path: "/invitation-email",
        options: {
          method: "POST",
          body: JSON.stringify({ emailAddress }),
          headers: {
            "Content-Type": "text/plain",
          },
        },
      });
      if (result.status === 400) {
        alert("Invalid email address.");
        return;
      }

      if (result.ok && result.status === 200) {
        onSuccess(emailAddress);
      } else {
        alert(GENERIC_EMAIL_ERROR_MESSAGE);
      }
    } catch (error) {
      logUnexpectedError(error);
      alert(GENERIC_EMAIL_ERROR_MESSAGE);
    } finally {
      setLoading(false);
    }
  };
  return [isEmailValid, sendLink, loading] as const;
};

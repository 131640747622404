import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import { fabric } from "utils/fabricUtils";
import { fabricTypes } from "utils/fabric-impl";
import styles from "./SpaceRoom.module.css";
import { HIT_SLOP } from "pages/Space/subpages/Whiteboard/Whiteboard";
import { Platform } from "./itemsConfig";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBackgroundId,
  selectMeetingID,
  selectRoomItems,
  selectShowRoomCustomizationActivityModal,
  setBackgroundId,
  setBackgroundSize,
  setBrowserSandboxInitialize,
  setBrowserSandboxUrl,
  setCurrentPage,
  setCurrentResourceId,
  setCurrentRoomItemId,
  setRoomItems,
  setShowRoomCustomizationActivityModal,
  setWhiteboardBackground,
  SpacePage,
} from "redux/spaceNavigationRedux";
import { sendEventToPeers } from "utils/webrtcUtils";
import clsx from "clsx";
import {
  selectCanControl,
  selectEnableResourceNameViewer,
  selectShowResourceNameOnItemHover,
} from "redux/settingsRedux";
import {
  selectEncodedAuthToken,
  selectEncodedClientToken,
  selectUserRole,
  UserRole,
} from "redux/userRedux";
import {
  useGetRoomQuery,
  useUpdateItemLocationMutation,
  useUpdateThumbnailKeyMutation,
} from "generated/graphql";
import { getNextZ, resourceToAction } from "utils/resourceUtils";
import { useRemoteMouseOnCanvas } from "../Whiteboard/useRemoteMouseOnCanvas";
import LoadingAnimation from "components/LoadingAnimation/LoadingAnimation";
import ConnectionError from "../ConnectionError/ConnectionError";
import { logUnexpectedError } from "utils/errorUtils";
import { calculateFitDimensions } from "utils/sizingUtils";
import {
  defaultShadow,
  loadImage,
  maybeSetHoverShadow,
} from "./utils/drawingUtils";
import EditRoomTip from "./components/EditRoomTip/EditRoomTip";
import { useStateWithRef } from "hooks/useStateWithRef";
import { clearFabricCanvas, clearHTMLCanvas } from "utils/canvasUtils";
import { getBackgroundImage } from "./utils/backgroundUtils";
import RoomCustomizationActivityModal from "./components/RoomCustomizationActivityModal/RoomCustomizationActivityModal";
import {
  selectEditItemId,
  selectEditItemMoving,
  selectEditRoomMode,
  setEditItemId,
  setEditItemMoving,
  setIsDoneLoadingId,
  setIsLoadingId,
} from "redux/editRoomNavigationRedux";
import { addOrUpdateItem, setShadow } from "./utils/itemDrawingUtils";
import { useLogRoomItemEvent, useTrackEvent } from "utils/metricsUtils";
import DisabledOverlay from "pages/Space/components/DisabledOverlay/DisabledOverlay";
import { selectClientFileOpen } from "redux/clientManagementRedux";
import { useRoomItemOverlays } from "pages/Space/subpages/SpaceRoom/hooks/useRoomItemOverlays";
import { Peers } from "pages/Space/hooks/connection/usePeerWebRTCConnection";
import { RoomItemOverlay } from "./components/RoomItemOverlay/RoomItemOverlay";
import { getFileUrls, sanitizeFileKeys } from "utils/fileUtils";

type SpaceRoomProps = {
  peersRef: React.MutableRefObject<Peers>;
  peers: Peers;
  setRemoteEditRoomMode: (editRoomMode: boolean) => void;
};

const ROOM_ITEM_DRAG_THRESHOLD = 8 as const;

const SpaceRoom = ({
  peersRef,
  peers,
  setRemoteEditRoomMode,
}: SpaceRoomProps) => {
  const canvasSectionRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<fabricTypes.Canvas>();
  const [canvasIsInitialized, setCanvasIsInitialized] = useState(false);
  const remoteCursorCanvasRef = useRef<fabricTypes.Canvas>(); // for the remote cursor, so it's always in front and can't be deleted, etc
  const backgroundImageSizeRef = useRef<{ width: number; height: number }>();
  const clientCanControl = useSelector(selectCanControl);
  const showResourceNameOnItemHover = useSelector(
    selectShowResourceNameOnItemHover
  );
  const showEnableResourceNameViewer = useSelector(
    selectEnableResourceNameViewer
  );
  const enableResourceNameViewer = useSelector(selectEnableResourceNameViewer);
  const showResourceNameOnItemHoverRef = useRef(!!showResourceNameOnItemHover); // for callback
  const showEnableResourceNameViewerRef = useRef(
    !!showEnableResourceNameViewer
  ); // for callback
  const editRoomMode = useSelector(selectEditRoomMode);
  const editRoomModeRef = useRef(editRoomMode); // for callback
  const userRole = useSelector(selectUserRole);
  const userCanControl = userRole === UserRole.THERAPIST || clientCanControl;
  const showRoomCustomizationActivityModal = useSelector(
    selectShowRoomCustomizationActivityModal
  );
  const [isBackgroundLoaded, setIsBackgroundLoaded] = useState(false);
  const editItemIdRef = useRef<string>();
  const editItemId = useSelector(selectEditItemId);
  const editItemMoving = useSelector(selectEditItemMoving);
  const roomItems = useSelector(selectRoomItems);
  const roomItemsRef = useRef(roomItems); // for callback
  const meetingID = useSelector(selectMeetingID);
  const encodedAuthToken = useSelector(selectEncodedAuthToken);
  const encodedClientToken = useSelector(selectEncodedClientToken);
  const encodedToken = encodedAuthToken || encodedClientToken;
  const [hoverItemId, setHoverItemId, hoverItemIdRef] = useStateWithRef<
    string | undefined
  >(undefined);
  const [loadingPreImages, setLoadingPreImages] = useState(true);
  const [loadingImages, setLoadingImages] = useState<boolean[]>();
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const [updateThumbnailKeyMutation] = useUpdateThumbnailKeyMutation();
  const [updateItemLocationMutation] = useUpdateItemLocationMutation();
  const [zoomLevelState, setZoomLevelState, zoomLevelStateRef] =
    useStateWithRef(1);
  const rawCanvasRef = useRef<HTMLCanvasElement>(null);
  const rawRemoteCursorCanvasRef = useRef<HTMLCanvasElement>(null);
  const logRoomItemEvent = useLogRoomItemEvent();
  const logRoomItemEventRef = useRef(logRoomItemEvent); // for mouse event callback
  useEffect(() => {
    logRoomItemEventRef.current = logRoomItemEvent;
  }, [logRoomItemEvent]);
  const { trackEvent } = useTrackEvent();
  const mouseDownEvent = useRef<
    | {
        x: number;
        y: number;
        elementId: string;
        itemLeft?: number;
        itemTop?: number;
      }
    | undefined
  >(undefined);
  const isClientFileOpen = useSelector(selectClientFileOpen);
  const {
    fileItemIdRef,
    fileActivityStyle,
    websiteItemIdRef,
    websiteActivityStyle,
    hasAlbumItem,
    albumActivityStyle,
  } = useRoomItemOverlays(backgroundImageSizeRef);
  const [thumnbnailSrcs, setThumbnailSrcs] = useState<{
    [fileKey: string]: string;
  }>({});
  const backgroundId = useSelector(selectBackgroundId);
  const backgroundIdRef = useRef(backgroundId);
  backgroundIdRef.current = backgroundId;

  const canMoveRoomItems = userRole === UserRole.THERAPIST;

  useEffect(() => {
    // Clear canvas contents when unmounting to work around Safari canvas caching issues
    return () => {
      for (const canvas of [canvasRef.current, remoteCursorCanvasRef.current]) {
        clearFabricCanvas(canvas);
      }
      for (const rawCanvas of [
        rawCanvasRef.current,
        rawRemoteCursorCanvasRef.current,
      ]) {
        clearHTMLCanvas(rawCanvas);
      }
    };
  }, []);

  const {
    data,
    loading: loadingRoomItemsQuery,
    refetch: refetchGetRoomQuery,
  } = useGetRoomQuery({
    variables: {
      meetingID: meetingID || "",
      isProvider: userRole === UserRole.THERAPIST,
    },
    skip: !meetingID,
    notifyOnNetworkStatusChange: true,
  });

  const firstLoadingRoomItemsQuery = !data && loadingRoomItemsQuery;

  useEffect(() => {
    const onReceiveRoomItemMovedCallback = (event: MessageEvent) => {
      const data = JSON.parse(event.data);
      if (data.event === "room-item-moved") {
        refetchGetRoomQuery();
      }
    };
    // keep this array in scope to remove event listeners properly when unmounting
    const peersDataChannels = Object.values(peers).map(
      (peerObject) => peerObject.dataChannel
    );
    for (const peerDataChannel of peersDataChannels) {
      peerDataChannel?.addEventListener(
        "message",
        onReceiveRoomItemMovedCallback
      );
    }

    return () => {
      for (const peerDataChannel of peersDataChannels) {
        peerDataChannel?.removeEventListener(
          "message",
          onReceiveRoomItemMovedCallback
        );
      }
    };
  }, [peers]);

  useEffect(() => {
    if (
      !canvasIsInitialized ||
      firstLoadingRoomItemsQuery ||
      !data ||
      data.meeting.length === 0 ||
      !data.meeting[0].provider?.current_room
    ) {
      return;
    }
    try {
      const room = data.meeting[0].provider.current_room;
      const newBackgroundId = room.background_id;
      if (newBackgroundId !== backgroundIdRef.current) {
        setIsBackgroundLoaded(false);
        setBackground(newBackgroundId).catch(logUnexpectedError);
      }

      const roomItems = room.room_items || [];
      dispatch(setRoomItems(roomItems));

      if (userRole === UserRole.THERAPIST) {
        const executeAsync = async () => {
          const thumbnailKeys = roomItems
            .map((roomItem) => roomItem.resource.thumbnail_file_key)
            .filter((key) => !!key);
          const keysToGet = sanitizeFileKeys(thumbnailKeys);

          if (keysToGet.length > 0) {
            const thumbnailImageUrls = await getFileUrls(keysToGet);
            setThumbnailSrcs(thumbnailImageUrls);
          }
        };

        executeAsync().catch(logUnexpectedError);
      }
    } catch (e) {
      logUnexpectedError(e);
      setError(true);
    }
  }, [data, firstLoadingRoomItemsQuery, canvasIsInitialized]);

  useEffect(() => {
    if (!roomItems || !isBackgroundLoaded || !encodedToken) {
      return;
    }

    try {
      setLoadingPreImages(true);
      setLoadingImages(Array(roomItems.length).fill(false));

      roomItemsRef.current = roomItems;

      // Delete any items no longer included
      const currentObjects = canvasRef.current?.getObjects() || [];
      const itemIdSet = new Set(roomItems.map((item) => item.id));
      for (const object of currentObjects) {
        if (
          !itemIdSet.has(object.itemId) ||
          (object.labelRelatedItemId &&
            !itemIdSet.has(object.labelRelatedItemId))
        ) {
          canvasRef.current?.remove(object);
        }
      }

      // Add or update items
      roomItems.forEach((item, index) => {
        addOrUpdateItem(
          item,
          index,
          backgroundImageSizeRef,
          canvasRef,
          editItemIdRef,
          editRoomModeRef,
          canMoveRoomItems,
          setError,
          setLoadingImages,
          dispatch
        ).catch((e) => {
          setError(true);
          logUnexpectedError(e);
        });
      });
    } catch (e) {
      setError(true);
      logUnexpectedError(e);
      setLoadingImages(Array(roomItems.length).fill(false));
    } finally {
      setLoadingPreImages(false);
    }
  }, [roomItems, isBackgroundLoaded, encodedToken]);

  useEffect(() => {
    editRoomModeRef.current = editRoomMode;
  }, [editRoomMode]);

  useEffect(() => {
    showResourceNameOnItemHoverRef.current = !!showResourceNameOnItemHover;
  }, [showResourceNameOnItemHover]);

  useEffect(() => {
    showEnableResourceNameViewerRef.current = !!enableResourceNameViewer;
  }, [enableResourceNameViewer]);

  useEffect(() => {
    const currentGroups = canvasRef.current?.getObjects("group") || [];
    for (const group of currentGroups) {
      // Only label groups will have a labelRelatedItemId
      const labelGroup = group as fabricTypes.Group & {
        labelRelatedItemId?: string;
      };
      if (labelGroup.labelRelatedItemId) {
        group.set({ visible: !!enableResourceNameViewer });
      }
    }
    canvasRef.current?.renderAll();
  }, [enableResourceNameViewer]);

  const { emitCursorMove, emitCursorOut, onReceiveMessageCallback } =
    useRemoteMouseOnCanvas(
      remoteCursorCanvasRef,
      zoomLevelState,
      zoomLevelStateRef,
      peersRef
    );

  useEffect(() => {
    const peerDataChannels = Object.values(peers).map(
      (peer) => peer.dataChannel
    );
    for (const peerDataChannel of peerDataChannels) {
      if (peerDataChannel) {
        peerDataChannel.addEventListener("message", onReceiveMessageCallback);
      }
    }

    return () => {
      for (const peerDataChannel of peerDataChannels) {
        if (peerDataChannel) {
          peerDataChannel.removeEventListener(
            "message",
            onReceiveMessageCallback
          );
        }
      }
    };
  }, [peers]);

  const baseOnMouseOut = () => {
    emitCursorOut();
  };

  const calculateCanvasDimensions = () => {
    const parentHeight = canvasSectionRef.current?.clientHeight || 0;
    const parentWidth = canvasSectionRef.current?.clientWidth || 0;
    const rawWidth = backgroundImageSizeRef.current?.width || 0;
    const rawHeight = backgroundImageSizeRef.current?.height || 0;
    const { fitHeight, fitWidth, ratio } = calculateFitDimensions(
      parentHeight,
      parentWidth,
      rawHeight,
      rawWidth,
      null
    );
    setZoomLevelState(ratio);
    return {
      canvasHeight: fitHeight,
      canvasWidth: fitWidth,
      zoomLevel: ratio,
    };
  };

  function updateSize() {
    const { canvasHeight, canvasWidth, zoomLevel } =
      calculateCanvasDimensions();
    // Avoid setting to 0 because this causes the canvas to be blank when it is made bigger
    // again later.
    if (!canvasHeight || !canvasWidth || !zoomLevel) {
      return;
    }
    for (const canvas of [canvasRef.current, remoteCursorCanvasRef.current]) {
      if (!canvas) {
        continue;
      }
      canvas.setHeight(canvasHeight);
      canvas.setWidth(canvasWidth);
      canvas.setZoom(zoomLevel);
    }
  }

  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    editItemIdRef.current = editItemId;
    // Set shadows
    for (const object of canvasRef.current?.getObjects() || []) {
      setShadow(object, editItemIdRef, editRoomModeRef);
    }
    canvasRef.current?.renderAll();
  }, [editItemId]);

  useEffect(() => {
    // Set shadows and update the cursor for the poster
    for (const object of canvasRef.current?.getObjects() || []) {
      setShadow(object, editItemIdRef, editRoomModeRef);
      if (object.isPoster) {
        object.set({ hoverCursor: editRoomMode ? "pointer" : "default" });
      }
    }
    canvasRef.current?.renderAll();
  }, [editRoomMode]);

  const updateThumbnailKey = async (
    resourceId: string,
    thumbnailKey: string
  ) => {
    await updateThumbnailKeyMutation({
      variables: {
        resourceId,
        thumbnailKey,
      },
    });
  };

  const setBackground = async (backgroundId: string) => {
    dispatch(setBackgroundId(backgroundId));
    const backgroundImageSrc = getBackgroundImage(backgroundId);
    const backgroundImage = await loadImage(backgroundImageSrc);
    backgroundImage.set({ opacity: 0.7 });
    const backgroundSize = {
      height: backgroundImage.height || 0,
      width: backgroundImage.width || 0,
    };
    dispatch(setBackgroundSize(backgroundSize));
    backgroundImageSizeRef.current = backgroundSize;
    updateSize();
    canvasRef.current?.setBackgroundImage(backgroundImage, () => {
      setIsBackgroundLoaded(true);
    });
  };

  const initializeCanvas = () => {
    try {
      if (!canvasRef.current) {
        // Fabric will create a wrapper around the html canvas element with the id 'canv'
        canvasRef.current = new fabric.Canvas("room-canv", {
          backgroundColor: "white",
          selection: false,
          targetFindTolerance: HIT_SLOP * 2,
          preserveObjectStacking: true,
        });
        if (backgroundIdRef.current) {
          setBackground(backgroundIdRef.current).catch(logUnexpectedError);
        }

        const moveItemHandler = async (
          roomItem: fabricTypes.Object,
          originalItemLeft: number | undefined,
          originalItemTop: number | undefined
        ) => {
          const errorMessage =
            "Sorry, there was a problem moving the item. Please try again later.";
          if (!backgroundImageSizeRef.current) {
            logUnexpectedError(
              "No backgroundImageSizeRef.current when dragging object"
            );
            alert(errorMessage);
            return;
          }
          const newTop = roomItem.top;
          const newLeft = roomItem.left;
          const newX = (newLeft || 0) / backgroundImageSizeRef.current.width;
          const newY = (newTop || 0) / backgroundImageSizeRef.current.height;

          const newZ = getNextZ(roomItemsRef.current || []);
          const { errors } = await updateItemLocationMutation({
            variables: {
              itemId: roomItem.itemId,
              rx: newX,
              ry: newY,
              z: newZ,
            },
          });
          dispatch(setEditItemMoving(undefined));
          if (errors) {
            logUnexpectedError(errors);
            alert(errorMessage);
          } else {
            const item = roomItemsRef.current?.find(
              (item) => item.id === roomItem.itemId
            );
            if (item) {
              logRoomItemEventRef
                .current({
                  action: "MOVE",
                  iconId: item.icon_id,
                  resourceId: item.resource.id,
                })
                .catch(logUnexpectedError);

              const previousX = originalItemLeft
                ? originalItemLeft / backgroundImageSizeRef.current.width
                : undefined;
              const previousY = originalItemTop
                ? originalItemTop / backgroundImageSizeRef.current.height
                : undefined;

              trackEvent("Room item moved", {
                "Old x coordinate": previousX,
                "Old y coordinate": previousY,
                "New x coordinate": newX,
                "New y coordinate": newY,
                "Item icon ID": item.icon_id,
                "Resource ID": item.resource.id,
                "Resource name": !!item.resource.owner_id
                  ? "PRIVATE RESOURCE"
                  : item.resource.name,
                "Is activity bank open": editRoomModeRef.current
                  ? "open"
                  : "closed",
              });
            }
          }
        };

        canvasRef.current?.on("mouse:over", async (event) => {
          const itemId = event.target?.itemId;
          const isPoster = event.target?.isPoster;
          if (event.target && itemId && canvasRef.current) {
            setHoverItemId(itemId);
            maybeSetHoverShadow(
              event.target,
              itemId,
              isPoster,
              editRoomModeRef.current,
              editItemIdRef.current
            );
            canvasRef.current.renderAll();
          }
        });
        canvasRef.current?.on("mouse:out", async (event) => {
          const itemId = event.target?.itemId;
          const isPoster = event.target?.isPoster;
          if (event.target && itemId) {
            if (itemId === hoverItemIdRef.current) {
              setHoverItemId(undefined);
            }
            if (editRoomModeRef.current && itemId === editItemIdRef.current) {
              return;
            }
            // Don't show a shadow on the poster unless editing the room
            if (!editRoomModeRef.current && isPoster) {
              return;
            }
            const scale = event.target.scaleX || 1;
            const shadow = defaultShadow(scale);
            event.target.set({ shadow });
            canvasRef.current?.renderAll();
          }
        });
        canvasRef.current?.on("mouse:down", (event) => {
          // Fabric.js can trigger a mouse:down with a TouchEvent param instead of a MouseEvent.
          const clickOrTouch: MouseEvent | Touch | undefined =
            event.e instanceof MouseEvent
              ? event.e
              : (event.e as unknown as TouchEvent).touches?.[0];

          if (
            !clickOrTouch ||
            !clickOrTouch.pageX ||
            !clickOrTouch.pageY ||
            !event.target?.itemId
          ) {
            return;
          }
          mouseDownEvent.current = {
            x: clickOrTouch.pageX,
            y: clickOrTouch.pageY,
            elementId: event.target.itemId,
            itemLeft: event.target.left,
            itemTop: event.target.top,
          };
        });
        canvasRef.current?.on("mouse:move", (event) => {
          if (!mouseDownEvent.current || !canMoveRoomItems) {
            return;
          }

          // Fabric.js can trigger a mouse:move with a TouchEvent param instead of a MouseEvent.
          const clickOrTouch: MouseEvent | Touch | undefined =
            event.e instanceof MouseEvent
              ? event.e
              : (event.e as unknown as TouchEvent).touches?.[0];

          if (!clickOrTouch) {
            return;
          }

          const { x: downX, y: downY, elementId } = mouseDownEvent.current;

          const originalTargetObject = canvasRef.current
            ?.getObjects()
            .find((obj) => obj.itemId === elementId);
          if (!originalTargetObject) {
            return;
          }

          const distanceX = Math.abs(clickOrTouch.pageX - downX);
          const distanceY = Math.abs(clickOrTouch.pageY - downY);
          const totalDistance = Math.sqrt(distanceX ** 2 + distanceY ** 2);

          if (totalDistance > ROOM_ITEM_DRAG_THRESHOLD) {
            // When item position is updated, updateItemFields will lock it back
            originalTargetObject.set({
              lockMovementX: false,
              lockMovementY: false,
            });
            originalTargetObject.bringToFront();
          }
        });
        canvasRef.current?.on("mouse:up", async (event) => {
          // Detect if drag or click
          // If user can't move items, always treat as click
          // If object was unlocked due to movement, treat as drag
          const itemIsUnlocked = event.target?.lockMovementX === false;
          const isClick = !canMoveRoomItems || !itemIsUnlocked;
          const {
            elementId: mouseDownItemId,
            itemLeft: originalItemLeft,
            itemTop: originalItemTop,
          } = mouseDownEvent.current ?? {};
          mouseDownEvent.current = undefined;

          const itemId = event.target?.itemId;

          if (!itemId || !mouseDownItemId || itemId !== mouseDownItemId) {
            dispatch(setEditItemId(null));
            dispatch(setEditItemMoving(undefined));
            return;
          }

          if (editRoomModeRef.current) {
            if (isClick) {
              dispatch(setEditItemMoving(undefined));
              // Click
              if (itemId) {
                if (itemId !== editItemIdRef.current) {
                  dispatch(setEditItemId(itemId));
                }
              } else {
                dispatch(setEditItemId(null));
              }
            } else {
              // Drag
              if (!itemId || !event.target) {
                return;
              }
              if (itemId !== editItemIdRef.current) {
                dispatch(setEditItemId(itemId));
              }

              const loadingKey = `CHANGE_ITEM_LOCATION_${Date.now()}`;
              dispatch(setIsLoadingId(loadingKey));
              await moveItemHandler(
                event.target,
                originalItemLeft,
                originalItemTop
              );

              dispatch(setIsDoneLoadingId(loadingKey));
            }
            return;
          }

          if (isClick) {
            dispatch(setEditItemMoving(undefined));
            const roomItem = roomItemsRef.current?.find(
              (item) => item.id === itemId
            );
            const resource = roomItem?.resource;
            if (!resource) {
              return;
            }
            const onClick = resourceToAction(resource);
            const resourceId = resource.id;
            if (userRole === UserRole.THERAPIST) {
              if (itemId === fileItemIdRef.current) {
                // @ts-ignore
                window.pendo.onGuideAdvanced(3);
              }
              if (itemId === websiteItemIdRef.current) {
                // @ts-ignore
                window.pendo.onGuideAdvanced(5);
              }
            }
            if (onClick?.type === Platform.WHITEBOARD) {
              if (onClick.backgroundImageKey) {
                dispatch(setWhiteboardBackground(onClick.backgroundImageKey));
              }
              dispatch(setCurrentPage(SpacePage.WHITEBOARD));
              dispatch(setCurrentResourceId(resourceId));
              dispatch(setCurrentRoomItemId(itemId));
              sendEventToPeers(peersRef.current, "navigate", {
                currentPage: SpacePage.WHITEBOARD,
                whiteboardBackground: onClick.backgroundImageKey,
                resourceId,
                roomItemId: itemId,
              });
            } else if (onClick?.type === Platform.BROWSER_SANDBOX) {
              dispatch(setBrowserSandboxUrl(onClick.url));
              dispatch(setBrowserSandboxInitialize(true));
              dispatch(setCurrentPage(SpacePage.BROWSER_SANDBOX));
              dispatch(setCurrentResourceId(resourceId));
              dispatch(setCurrentRoomItemId(itemId));
              sendEventToPeers(peersRef.current, "navigate", {
                currentPage: SpacePage.BROWSER_SANDBOX,
                browserSandboxUrl: onClick.url,
                resourceId,
                roomItemId: itemId,
              });
            } else if (onClick?.type === Platform.MEDIA_PLAYER) {
              // TODO: add new state variable for media player
              dispatch(setBrowserSandboxUrl(onClick.url));
              dispatch(setCurrentPage(SpacePage.MEDIA_PLAYER));
              dispatch(setCurrentResourceId(resourceId));
              dispatch(setCurrentRoomItemId(itemId));
              sendEventToPeers(peersRef.current, "navigate", {
                currentPage: SpacePage.MEDIA_PLAYER,
                browserSandboxUrl: onClick.url,
                resourceId,
                roomItemId: itemId,
              });
            } else if (onClick?.type === Platform.ROOM_CUSTOMIZATION) {
              dispatch(setShowRoomCustomizationActivityModal(true));
              dispatch(setCurrentResourceId(resourceId));
              dispatch(setCurrentRoomItemId(itemId));
              sendEventToPeers(peersRef.current, "navigate", {
                currentPage: SpacePage.ROOM,
                showRoomCustomizationActivityModal: true,
                resourceId,
                roomItemId: itemId,
              });
            } else if (onClick?.type === Platform.ALBUM) {
              dispatch(setCurrentPage(SpacePage.ALBUM));
              dispatch(setCurrentResourceId(resourceId));
              dispatch(setCurrentRoomItemId(itemId));
              sendEventToPeers(peersRef.current, "navigate", {
                currentPage: SpacePage.ALBUM,
                resourceId,
                roomItemId: itemId,
              });
            }
          } else {
            // handle drag while outside of edit mode
            if (!itemId || !event.target) {
              return;
            }
            await moveItemHandler(
              event.target,
              originalItemLeft,
              originalItemTop
            );
            sendEventToPeers(peersRef.current, "room-item-moved", {});
          }
        });
      }
      setCanvasIsInitialized(true);
      if (!remoteCursorCanvasRef.current) {
        remoteCursorCanvasRef.current = new fabric.Canvas(
          "room-remoteCursorCanv",
          {
            backgroundColor: undefined,
          }
        );
      }
    } catch (e) {
      logUnexpectedError(e);
      setError(true);
    }

    const mouseMoveListener = (event: MouseEvent) => {
      if (editRoomModeRef.current) {
        return;
      }
      const pointer = canvasRef.current?.getPointer(event);
      if (pointer) {
        emitCursorMove(pointer);
      }
    };

    window.addEventListener("mousemove", mouseMoveListener);
    return () => {
      window.removeEventListener("mousemove", mouseMoveListener);

      // When opening/closing activities, this component is unmounted.
      // We need to cancel room item moving when this happens.
      const { elementId, itemLeft, itemTop } = mouseDownEvent.current ?? {};
      mouseDownEvent.current = undefined;

      const movingObject = canvasRef.current
        ?.getObjects()
        .find((obj) => obj.itemId === elementId);

      movingObject?.set({
        lockMovementX: true,
        lockMovementY: true,
        left: itemLeft,
        top: itemTop,
      });

      canvasRef.current?.discardActiveObject();
      dispatch(setEditItemMoving(undefined));
    };
  };

  useEffect(initializeCanvas, []);

  useEffect(() => {
    updateSize();
  }, [editRoomMode, isClientFileOpen]);

  const noCurrentRoom =
    !firstLoadingRoomItemsQuery && !data?.meeting[0]?.provider?.current_room;
  const loadingRoomImages =
    (loadingPreImages || (loadingImages && loadingImages.includes(true))) &&
    !noCurrentRoom;

  const noRoomMessage =
    userRole === UserRole.THERAPIST
      ? "No room is currently opened. Please open a room."
      : "No room is currently opened. Please wait for your provider to open a room.";

  return (
    <div className={styles.space} data-testid="space-room">
      <div
        className={clsx(styles.canvasAndSideBarContainer, {
          [styles.noControlCanvasSection]: !userCanControl,
          [styles.editing]: editRoomMode,
        })}
      >
        <div className={styles.canvasAndTopBarContainer}>
          {editRoomMode && !noCurrentRoom && userRole === UserRole.THERAPIST ? (
            <EditRoomTip setRemoteEditRoomMode={setRemoteEditRoomMode} />
          ) : null}
          <div
            className={clsx(styles.canvasSection, {
              [styles.noControlCanvasSection]: !userCanControl,
            })}
            ref={canvasSectionRef}
          >
            <div
              data-testid="room-canvas-container"
              className={clsx(styles.canvasContainer, {
                [styles.noControlCanvasContainer]: !userCanControl,
              })}
              onPointerLeave={baseOnMouseOut}
            >
              <canvas id="room-canv" ref={rawCanvasRef} />
              {isBackgroundLoaded &&
                roomItems?.map((item) => (
                  <RoomItemOverlay
                    key={item.id}
                    roomItem={item}
                    thumbnailSrc={
                      item.resource.thumbnail_file_key
                        ? thumnbnailSrcs[item.resource.thumbnail_file_key]
                        : undefined
                    }
                    backgroundImageSizeRef={backgroundImageSizeRef}
                    canvasRef={canvasRef}
                    updateThumbnailKey={updateThumbnailKey}
                    isEditingRoom={editRoomMode}
                    editItemId={editItemId}
                    editItemMoving={editItemMoving}
                    isHovering={hoverItemId === item.id}
                  />
                ))}
              {showRoomCustomizationActivityModal && !editRoomMode ? (
                <RoomCustomizationActivityModal
                  peersRef={peersRef}
                  peers={peers}
                  roomWidth={canvasRef.current?.width}
                />
              ) : null}
              <div className={styles.remoteTmpCanvas}>
                <canvas
                  id="room-remoteCursorCanv"
                  ref={rawRemoteCursorCanvasRef}
                />
              </div>
              {isBackgroundLoaded ? (
                <div
                  id={"feelingsChartOverlay"}
                  className={styles.feelingsChartOverlayForTour}
                  style={fileActivityStyle}
                />
              ) : null}
              {isBackgroundLoaded ? (
                <div
                  id={"pencilCupOverlay"}
                  className={styles.feelingsChartOverlayForTour}
                  style={websiteActivityStyle}
                />
              ) : null}
              {isBackgroundLoaded && hasAlbumItem ? (
                <div
                  id={"albumOverlay"}
                  className={styles.feelingsChartOverlayForTour}
                  style={albumActivityStyle}
                />
              ) : null}
            </div>
            {noCurrentRoom ? (
              <div className={styles.noRoomMessage}>{noRoomMessage}</div>
            ) : null}
            {loadingRoomImages ? (
              <div
                className={clsx(styles.loadingContainer, {
                  [styles.semitransparent]: isBackgroundLoaded,
                })}
              >
                {" "}
                <LoadingAnimation />{" "}
              </div>
            ) : null}
            {firstLoadingRoomItemsQuery ? (
              <div className={styles.loadingContainer}>
                {" "}
                <LoadingAnimation />{" "}
              </div>
            ) : null}
            {error ? (
              <ConnectionError
                errorMessage={
                  "There was an error loading the room. Please try again later."
                }
                loading={false}
                showInviteLink={false}
              />
            ) : null}
          </div>
        </div>
      </div>
      {editRoomMode && userRole === UserRole.CLIENT ? (
        <DisabledOverlay message={"Hold tight! This room is being edited."} />
      ) : null}
    </div>
  );
};

export default SpaceRoom;

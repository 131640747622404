import { readLargeEvent, sendLargeEventToPeers } from "utils/webrtcUtils";
import { Peers } from "pages/Space/hooks/connection/usePeerWebRTCConnection";

export const useRemoteMediaPlayer = (
  handleSetIsPlaying: (isPlaying: boolean) => void,
  handleSeekTo: (time: number) => void
) => {
  const emitIsPlayingChange = (
    isPlaying: boolean,
    peersRef: React.MutableRefObject<Peers>
  ) => {
    sendLargeEventToPeers(peersRef.current, "is-playing-change", { isPlaying });
  };

  const emitSeekTo = (
    time: number,
    peersRef: React.MutableRefObject<Peers>
  ) => {
    sendLargeEventToPeers(peersRef.current, "seek-to", { time });
  };

  const onReceiveMessageCallback = async (event: MessageEvent) => {
    const data = await readLargeEvent(event);
    if (!data) {
      return;
    }
    const eventType = data.event;
    switch (eventType) {
      case "is-playing-change":
        const isPlaying: boolean = data.data.isPlaying;
        handleSetIsPlaying(isPlaying);
        break;
      case "seek-to":
        const time: number = data.data.time;
        handleSeekTo(time);
        break;
    }
  };

  return {
    emitIsPlayingChange,
    emitSeekTo,
    onReceiveMessageCallback,
  };
};

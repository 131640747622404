import React, { useState } from "react";
import styles from "./CopyButton.module.css";
import Button from "components/Button/Button";
import checkIcon from "assets/icons/check.svg";
import copyIcon from "assets/icons/copy.svg";
import clsx from "clsx";
import Tooltip from "components/Tooltip/Tooltip";

type CopyButtonProps = {
  textToCopy: string;
  compact?: boolean;
};

const CopyButton = ({ textToCopy, compact }: CopyButtonProps) => {
  const [copiedLink, setCopiedLink] = useState(false);

  const copy = () => {
    navigator.clipboard.writeText(textToCopy);
    setCopiedLink(true);
    setTimeout(() => setCopiedLink(false), 1000);
  };

  if (compact) {
    const icon = copiedLink ? checkIcon : copyIcon;
    return (
      <Tooltip text={copiedLink ? "Copied" : "Copy Link"}>
        <img
          id={"copyButton"}
          src={icon}
          className={styles.compactIcon}
          onClick={copy}
        />
      </Tooltip>
    );
  }

  return (
    <Button onClick={copy}>
      {copiedLink ? (
        <div className={styles.button}>
          <img
            src={checkIcon}
            className={clsx(styles.buttonIcon, styles.checkIcon)}
          />
          Copied
        </div>
      ) : (
        <div className={styles.button}>
          <img src={copyIcon} className={styles.buttonIcon} />
          Copy Link
        </div>
      )}
    </Button>
  );
};

export default CopyButton;

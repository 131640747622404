const whiteboardImage = require("assets/itemIcons/whiteboard.png");
const albumImage = require("assets/itemIcons/album.png");
const feelingsChartImage = require("assets/itemIcons/feelingsChart.png");
const sketchImage = require("assets/itemIcons/sketch.png");
const sandTrayImage = require("assets/itemIcons/sandTray.png");
const tvImage = require("assets/itemIcons/tv.png");
const gameImage = require("assets/itemIcons/game.png");
const worksheetImage = require("assets/itemIcons/worksheet.png");
const paintBucketImage = require("assets/itemIcons/paintBucket.png");
const easelImage = require("assets/itemIcons/easel.png");
const neonImage = require("assets/itemIcons/neon.png");
const tabletImage = require("assets/itemIcons/tablet.png");
const game2Image = require("assets/itemIcons/game2.png");
const game3Image = require("assets/itemIcons/game3.png");
const game4Image = require("assets/itemIcons/game4.png");
const paintImage = require("assets/itemIcons/paint.png");
const bookImage = require("assets/itemIcons/book.png");
const book2Image = require("assets/itemIcons/book2.png");
const book3Image = require("assets/itemIcons/book3.png");
const glassWhiteboardImage = require("assets/itemIcons/glassWhiteboard.png");
const jeopardyImage = require("assets/itemIcons/jeopardy.png");
const paint2Image = require("assets/itemIcons/paint2.png");
const sketch2Image = require("assets/itemIcons/sketch2.png");
const spinnerImage = require("assets/itemIcons/spinner.png");
const thermometerImage = require("assets/itemIcons/thermometer.png");
const treasureImage = require("assets/itemIcons/treasure.png");
const videoGameImage = require("assets/itemIcons/videoGame.png");
const houseImage = require("assets/itemIcons/house.png");

export enum Platform {
  WHITEBOARD,
  BROWSER_SANDBOX,
  MEDIA_PLAYER,
  ROOM_CUSTOMIZATION,
  ALBUM,
}

export const ICON_ID = {
  WHITEBOARD: "WHITEBOARD",
  ALBUM: "ALBUM",
  FEELINGS_CHART: "FEELINGS_CHART",
  SKETCH: "SKETCH",
  SAND_TRAY: "SAND_TRAY",
  TV: "TV",
  GAME: "GAME",
  WORKSHEET: "WORKSHEET",
  PAINT_BUCKET: "PAINT_BUCKET",
  EASEL: "EASEL",
  NEON: "NEON",
  TABLET: "TABLET",
  GAME_2: "GAME_2",
  GAME_3: "GAME_3",
  GAME_4: "GAME_4",
  PAINT: "PAINT",
  BOOK: "BOOK",
  BOOK_2: "BOOK_2",
  BOOK_3: "BOOK_3",
  GLASS_WHITEBOARD: "GLASS_WHITEBOARD",
  JEOPARDY: "JEOPARDY",
  PAINT_2: "PAINT_2",
  SKETCH_2: "SKETCH_2",
  SPINNER: "SPINNER",
  THERMOMETER: "THERMOMETER",
  TREASURE: "TREASURE",
  VIDEO_GAME: "VIDEO_GAME",
  POSTER: "POSTER",
  HOUSE: "HOUSE",
} as const;

export type WhiteboardAction = {
  type: Platform.WHITEBOARD;
  backgroundImageKey?: string;
};

export type BrowserSandboxAction = {
  type: Platform.BROWSER_SANDBOX;
  url: string;
};

export type MediaPlayerAction = {
  type: Platform.MEDIA_PLAYER;
  url: string;
};

export type RoomCustomizationAction = {
  type: Platform.ROOM_CUSTOMIZATION;
};

export type AlbumAction = {
  type: Platform.ALBUM;
};

export type Action =
  | WhiteboardAction
  | BrowserSandboxAction
  | MediaPlayerAction
  | RoomCustomizationAction
  | AlbumAction;

export type IconConfig = {
  src: any;
  scale: number;
  srcWidth: number;
  srcHeight: number;
};

const iconIdToConfig: { [id: string]: IconConfig } = {
  [ICON_ID.WHITEBOARD]: {
    src: whiteboardImage,
    scale: 0.4,
    srcWidth: 744,
    srcHeight: 1156,
  },
  [ICON_ID.ALBUM]: {
    src: albumImage,
    scale: 0.2,
    srcWidth: 148,
    srcHeight: 504,
  },
  [ICON_ID.FEELINGS_CHART]: {
    src: feelingsChartImage,
    scale: 0.2,
    srcWidth: 868,
    srcHeight: 1364,
  },
  [ICON_ID.SKETCH]: {
    src: sketchImage,
    scale: 0.12,
    srcWidth: 1188,
    srcHeight: 1188,
  },
  [ICON_ID.SAND_TRAY]: {
    src: sandTrayImage,
    scale: 0.22,
    srcWidth: 1240,
    srcHeight: 476,
  },
  [ICON_ID.TV]: {
    src: tvImage,
    scale: 0.25,
    srcWidth: 1240,
    srcHeight: 844,
  },
  [ICON_ID.GAME]: {
    src: gameImage,
    scale: 0.46,
    srcWidth: 482,
    srcHeight: 163,
  },
  [ICON_ID.WORKSHEET]: {
    src: worksheetImage,
    scale: 0.14,
    srcWidth: 1176,
    srcHeight: 476,
  },
  [ICON_ID.PAINT_BUCKET]: {
    src: paintBucketImage,
    scale: 0.25,
    srcWidth: 547,
    srcHeight: 422,
  },
  [ICON_ID.EASEL]: {
    src: easelImage,
    scale: 0.55,
    srcWidth: 500,
    srcHeight: 967,
  },
  [ICON_ID.NEON]: {
    src: neonImage,
    scale: 0.18,
    srcWidth: 449,
    srcHeight: 561,
  },
  [ICON_ID.TABLET]: {
    src: tabletImage,
    scale: 0.24,
    srcWidth: 453,
    srcHeight: 328,
  },
  [ICON_ID.GAME_2]: {
    src: game2Image,
    scale: 0.46,
    srcWidth: 484,
    srcHeight: 164,
  },
  [ICON_ID.GAME_3]: {
    src: game3Image,
    scale: 0.46,
    srcWidth: 478,
    srcHeight: 164,
  },
  [ICON_ID.GAME_4]: {
    src: game4Image,
    scale: 0.46,
    srcWidth: 480,
    srcHeight: 164,
  },
  [ICON_ID.PAINT]: {
    src: paintImage,
    scale: 0.1,
    srcWidth: 1420,
    srcHeight: 1380,
  },
  [ICON_ID.BOOK]: {
    src: bookImage,
    scale: 0.2,
    srcWidth: 102,
    srcHeight: 505,
  },
  [ICON_ID.BOOK_2]: {
    src: book2Image,
    scale: 0.2,
    srcWidth: 107,
    srcHeight: 500,
  },
  [ICON_ID.BOOK_3]: {
    src: book3Image,
    scale: 0.2,
    srcWidth: 105,
    srcHeight: 500,
  },
  [ICON_ID.GLASS_WHITEBOARD]: {
    src: glassWhiteboardImage,
    scale: 0.5,
    srcWidth: 471,
    srcHeight: 327,
  },
  [ICON_ID.JEOPARDY]: {
    src: jeopardyImage,
    scale: 0.19,
    srcWidth: 1392,
    srcHeight: 944,
  },
  [ICON_ID.PAINT_2]: {
    src: paint2Image,
    scale: 0.25,
    srcWidth: 568,
    srcHeight: 550,
  },
  [ICON_ID.SKETCH_2]: {
    src: sketch2Image,
    scale: 0.3,
    srcWidth: 479,
    srcHeight: 479,
  },
  [ICON_ID.SPINNER]: {
    src: spinnerImage,
    scale: 0.12,
    srcWidth: 956,
    srcHeight: 924,
  },
  [ICON_ID.THERMOMETER]: {
    src: thermometerImage,
    scale: 0.08,
    srcWidth: 332,
    srcHeight: 1428,
  },
  [ICON_ID.TREASURE]: {
    src: treasureImage,
    scale: 0.2,
    srcWidth: 563,
    srcHeight: 515,
  },
  [ICON_ID.VIDEO_GAME]: {
    src: videoGameImage,
    scale: 0.2,
    srcWidth: 571,
    srcHeight: 337,
  },
  [ICON_ID.POSTER]: {
    src: null,
    scale: 1,
    srcWidth: 250,
    srcHeight: 195,
  },
  [ICON_ID.HOUSE]: {
    src: houseImage,
    scale: 0.17,
    srcWidth: 859,
    srcHeight: 751,
  },
} as const;

export const getIconConfig = (iconId: string) => {
  return iconIdToConfig[iconId];
};

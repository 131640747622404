import {
  useInsertAnalyticsResourceSearchCombinedMutation,
  useInsertAnalyticsResourceSearchFilterMutation,
} from "generated/graphql";
import { useSelector } from "react-redux";
import { selectUserId } from "redux/userRedux";
import { logUnexpectedError } from "utils/errorUtils";
import { useEffect } from "react";
import {
  formatAgeRange,
  MAX_AGE_FILTER,
  MIN_AGE_FILTER,
} from "./ResourceFilters/AgeDropDown/AgeDropDown";

type UseLogSearchesInput = {
  search: string;
  approaches: string[];
  topics: string[];
  types: string[];
  sources: string[];
  ageMin: number;
  ageMax: number;
};

export const useLogSearches = ({
  search,
  approaches,
  topics,
  types,
  sources,
  ageMin,
  ageMax,
}: UseLogSearchesInput) => {
  const [insertFilterAnalyticsMutation] =
    useInsertAnalyticsResourceSearchFilterMutation();
  const [insertSearchAnalyticsMutation] =
    useInsertAnalyticsResourceSearchCombinedMutation();
  const userId = useSelector(selectUserId);

  const logFilterUsage = (filterType: string, value: string) => {
    insertFilterAnalyticsMutation({
      variables: {
        filterType,
        value,
        userId,
      },
    }).catch(logUnexpectedError);
  };

  useEffect(() => {
    if (search) {
      logFilterUsage("search", search);
    }
  }, [search]);

  useEffect(() => {
    if (approaches.length > 0) {
      logFilterUsage("approach", JSON.stringify(approaches));
    }
  }, [approaches]);

  useEffect(() => {
    if (topics.length > 0) {
      logFilterUsage("topic", JSON.stringify(topics));
    }
  }, [topics]);

  useEffect(() => {
    if (types.length > 0) {
      logFilterUsage("type", JSON.stringify(types));
    }
  }, [types]);

  useEffect(() => {
    if (sources.length > 0) {
      logFilterUsage("source", JSON.stringify(sources));
    }
  }, [sources]);

  useEffect(() => {
    if (ageMin !== MIN_AGE_FILTER || ageMax !== MAX_AGE_FILTER) {
      logFilterUsage("age", formatAgeRange(ageMin, ageMax));
    }
  }, [ageMin, ageMax]);

  useEffect(() => {
    if (
      search ||
      approaches.length > 0 ||
      topics.length > 0 ||
      types.length > 0 ||
      sources.length > 0 ||
      ageMin !== MIN_AGE_FILTER ||
      ageMax !== MAX_AGE_FILTER
    ) {
      const value = JSON.stringify([
        `search: ${search}`,
        `approach: ${JSON.stringify(approaches)}`,
        `topic: ${JSON.stringify(topics)}`,
        `type: ${JSON.stringify(types)}`,
        `source: ${JSON.stringify(sources)}`,
        `age: ${formatAgeRange(ageMin, ageMax)}`,
      ]);
      insertSearchAnalyticsMutation({
        variables: {
          value,
          userId,
        },
      }).catch(logUnexpectedError);
    }
  }, [search, approaches, topics, types, sources, ageMin, ageMax]);
};

import React, { useState } from "react";

import styles from "./ResourceEntry.module.css";
import { GetResourcesQuery } from "generated/graphql";
import clsx from "clsx";
import ResourceThumbnail from "./ResourceThumbnail/ResourceThumbnail";
import ResourceMetadata, {
  ResourceMetadataType,
} from "./ResourceMetadata/ResourceMetadata";
import ResourceCategoryIcon from "./ResourceCategoryIcon/ResourceCategoryIcon";
import ResourceAction from "./ResourceAction/ResourceAction";
import { ReactComponent as LockIcon } from "assets/icons/lock.svg";
import Tooltip from "components/Tooltip/Tooltip";
import { getOrganizationConfig } from "utils/organizationUtils";
import { logUnexpectedError } from "utils/errorUtils";
import ResourceNameInput from "./ResourceNameInput/ResourceNameInput";
import { ResourceType } from "../../utils/drawingUtils";

type ResourceEntryProps = {
  resource: GetResourcesQuery["resource"][0];
  thumbnailSrc: string | null | undefined;
  isSelected: boolean;
  isFavoriteRow?: boolean;
  isSharedRoomRow?: boolean;
  displayOnly?: boolean;
  onClick?: (resource: GetResourcesQuery["resource"][0]) => Promise<void>;
  onDoubleClick?: (resource: GetResourcesQuery["resource"][0]) => Promise<void>;
};

const ResourceEntry = ({
  resource,
  thumbnailSrc,
  isSelected,
  isFavoriteRow,
  isSharedRoomRow,
  displayOnly,
  onClick,
  onDoubleClick,
}: ResourceEntryProps) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false); // track this manually rather than use CSS to avoid the action menu press triggering it
  const [renameMode, setRenameMode] = useState(false);

  const clickResource = async () => {
    try {
      if (displayOnly || !onClick) {
        return;
      }
      await onClick(resource);
    } catch (err) {
      logUnexpectedError(err);
    }
  };

  const doubleClickResource = async () => {
    try {
      if (displayOnly || !onDoubleClick) {
        return;
      }
      await onDoubleClick(resource);
    } catch (err) {
      logUnexpectedError(err);
    }
  };

  const attribution = resource.attribution;
  const resourceType = resource.file_key
    ? ResourceType.FILE
    : resource.url
    ? ResourceType.WEBSITE
    : ResourceType.FILE;

  const hasMetadata =
    resource.approaches?.length > 0 ||
    resource.age_min ||
    resource.age_max ||
    resource.topics?.length > 0;
  const isCustom =
    (!!resource.owner_id && !resource.organization_id) ||
    (!!resource.organization_id && !!resource.shared_type);
  const organizationConfig = getOrganizationConfig(resource.organization_id);

  return (
    <div
      className={clsx(styles.container, {
        [styles.selected]: isSelected,
        [styles.custom]: isCustom,
        [styles.menuOpen]: menuIsOpen,
        [styles.active]: isActive && !displayOnly,
        [styles.displayOnly]: displayOnly,
        [styles.nonDisplay]: !displayOnly,
        [styles.sharedRoom]: isSharedRoomRow,
      })}
      onClick={clickResource}
      onDoubleClick={doubleClickResource}
      onMouseDown={() => setIsActive(true)}
      onMouseUp={() => setIsActive(false)}
    >
      <ResourceThumbnail
        thumbnailSrc={thumbnailSrc}
        resourceType={resourceType}
      />
      <div className={styles.rightSection}>
        <div
          className={clsx(styles.row, styles.headerRow, {
            [styles.headerRowLarge]: hasMetadata,
          })}
        >
          <div className={styles.headerAndIcons}>
            {isCustom && (
              <div className={styles.iconContainer}>
                <Tooltip text={"Private"}>
                  <LockIcon className={styles.lockIcon} />
                </Tooltip>
              </div>
            )}
            {!isCustom && organizationConfig?.hasCustomActivityBank && (
              <div className={styles.iconContainer}>
                <Tooltip text={organizationConfig.name}>
                  <img
                    className={styles.organizationIcon}
                    src={organizationConfig?.icon}
                  />
                </Tooltip>
              </div>
            )}
            {renameMode ? (
              <ResourceNameInput
                resource={resource}
                renameMode={renameMode}
                setRenameMode={setRenameMode}
              />
            ) : (
              <Tooltip text={resource.name}>
                <div
                  className={clsx(styles.header, {
                    [styles.headerSharedRoom]: isSharedRoomRow,
                  })}
                >
                  {resource.name}
                </div>
              </Tooltip>
            )}
            {!isFavoriteRow && (
              <>
                <ResourceCategoryIcon category={resource.categories} />
                {resource.is_psychoeducational ? (
                  <ResourceCategoryIcon category={"Psychoeducational"} />
                ) : null}
              </>
            )}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.attribution}>
            {attribution ? `By ${attribution}` : ""}
          </div>
        </div>
        {hasMetadata ? (
          <div className={styles.row}>
            <ResourceMetadata
              metadataType={ResourceMetadataType.APPROACH}
              value={resource.approaches}
              width={1.2}
            />
            {!isFavoriteRow && (
              <>
                <ResourceMetadata
                  metadataType={ResourceMetadataType.AGE}
                  value={{ ageMin: resource.age_min, ageMax: resource.age_max }}
                  width={0.8}
                />
                {!isSharedRoomRow && (
                  <ResourceMetadata
                    metadataType={ResourceMetadataType.TOPIC}
                    value={resource.topics}
                    width={2}
                  />
                )}
              </>
            )}
          </div>
        ) : null}
      </div>
      {displayOnly ? null : (
        <ResourceAction
          resource={resource}
          thumbnailSrc={thumbnailSrc}
          menuIsOpen={menuIsOpen}
          isFavoriteRow={isFavoriteRow}
          setMenuIsOpen={setMenuIsOpen}
          setRenameMode={() => setRenameMode(true)}
        />
      )}
    </div>
  );
};

export default ResourceEntry;

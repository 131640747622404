const FILE_TYPE = "image/png";
const COMPRESSION = 0.8;

export const videoToBlobImage = async (video: HTMLVideoElement) => {
  const tmpCanvas = document.createElement("canvas");
  tmpCanvas.height = video.videoHeight;
  tmpCanvas.width = video.videoWidth;
  const ctx = tmpCanvas.getContext("2d");
  ctx?.drawImage(video, 0, 0, tmpCanvas.width, tmpCanvas.height);
  return await canvasToBlobImage(tmpCanvas);
};

export const canvasToBlobImage: (canvas: HTMLCanvasElement) => Promise<File> = (
  canvas
) => {
  return new Promise((resolve, reject) => {
    try {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error("Could not generate the blob from the canvas."));
            return;
          }
          resolve(new File([blob], "snapshot.png", { type: FILE_TYPE }));
        },
        FILE_TYPE,
        COMPRESSION
      );
    } catch (error) {
      reject(error);
    }
  });
};

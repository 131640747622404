// Only applies to App, not AdminPage
import {
  selectUserRole,
  setEncodedAuthToken,
  signOut,
  UserRole,
} from "redux/userRedux";
import { signOutServer } from "./signInUtils";
import { useDispatch, useSelector } from "react-redux";
import { selectSessionEHRSystem } from "redux/ehrSystemRedux";

export const useAppSignOut = () => {
  const dispatch = useDispatch();
  const userRole = useSelector(selectUserRole);
  const ehrSystem = useSelector(selectSessionEHRSystem);

  return async () => {
    if (userRole === UserRole.CLIENT) {
      return;
    }
    dispatch(setEncodedAuthToken(null));
    await signOutServer();
    if (!!ehrSystem) {
      dispatch(signOut());
    } else if (process.env.REACT_APP_COGNITO_LOGOUT) {
      location.href = process.env.REACT_APP_COGNITO_LOGOUT;
    }
  };
};

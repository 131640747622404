import Modal from "components/Modal/Modal";
import styles from "./ConfirmationModal.module.css";
import Button from "components/Button/Button";
import React from "react";

type ConfirmationModalProps = {
  closeModal: () => void;
  children: React.ReactNode;
};

const ConfirmationModal = ({
  closeModal,
  children,
}: ConfirmationModalProps) => {
  return (
    <Modal closeModal={closeModal}>
      <div className={styles.confirmationText}>{children}</div>
      <Button onClick={closeModal}>Continue</Button>
    </Modal>
  );
};

export default ConfirmationModal;

import React, {
  MouseEventHandler,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styles from "./SnapshotDetailsModal.module.css";
import Modal from "components/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { createPortal } from "react-dom";
import {
  selectClientHasJoinedRoom,
  selectCurrentClient,
  selectSnapshotDetail,
  setSnapshotDetail,
  toggleShowConfirmSnapshotDelete,
} from "redux/clientManagementRedux";

import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";
import { getFileUrl } from "utils/fileUtils";
import clsx from "clsx";
import { MIN_WIDTH_RATIO, calculateFitDimensions } from "utils/sizingUtils";
import { logUnexpectedError } from "utils/errorUtils";
import SnapshotDeleteConfirmModal from "./SnapshotDeleteConfirmModal";
import {
  useLogClientManagementEvent,
  useLogSnapshotSharedCount,
} from "utils/metricsUtils";
import {
  GetAlbumSnapshotsDocument,
  useSetSnapshotSharedWithClientMutation,
} from "generated/graphql";
import albumIcon from "assets/itemIcons/album.png";
import Checkbox from "components/Checkbox/Checkbox";

type SnapshotDetailsModalProps = {};

const SnapshotDetailsModal = ({}: SnapshotDetailsModalProps) => {
  const snapshotDetail = useSelector(selectSnapshotDetail);
  const dispatch = useDispatch();
  const currentClient = useSelector(selectCurrentClient);

  const [downloadUrl, setDownloadUrl] = useState<string | undefined>(undefined);

  const [imgWidth, setImgWidth] = useState<number | undefined>(undefined);
  const imgContainerRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  const logClientManagementEvent = useLogClientManagementEvent();
  const clientHasJoinedRoom = useSelector(selectClientHasJoinedRoom);

  const [setSnapshotSharedWithClient] =
    useSetSnapshotSharedWithClientMutation();

  const logSnapshotSharedCount = useLogSnapshotSharedCount();

  useEffect(() => {
    if (snapshotDetail) {
      // not awaiting to let it run in the background
      logClientManagementEvent({
        action: "SNAPSHOT_OPENED",
        snapshotId: snapshotDetail.id,
        duringSession: clientHasJoinedRoom,
      });
    }
  }, [snapshotDetail]);

  useEffect(() => {
    if (snapshotDetail) {
      const asyncGetFileUrl = async () => {
        const response = await getFileUrl(
          snapshotDetail.fileKey,
          "snapshot",
          true
        );
        if (response !== "") {
          setDownloadUrl(response);
        }
      };
      asyncGetFileUrl();
    }
  }, [snapshotDetail]);

  const closeModal = () => {
    dispatch(setSnapshotDetail(undefined));
  };

  const resizeImage = () => {
    if (imgContainerRef.current) {
      if (!imgRef.current) {
        logUnexpectedError(new Error("imgRef is null"));
        return;
      }
      const img = imgRef.current;
      const width = img.naturalWidth;
      const height = img.naturalHeight;

      const { heightIsLimiting, fitWidth } = calculateFitDimensions(
        imgContainerRef.current.clientHeight,
        imgContainerRef.current.clientWidth,
        height,
        width,
        MIN_WIDTH_RATIO
      );
      if (heightIsLimiting) {
        setImgWidth(fitWidth);
      } else {
        setImgWidth(undefined);
      }
    }
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", resizeImage);
    return () => window.removeEventListener("resize", resizeImage);
  }, []);

  // from here on, snapshotDetail is guaranteed to be defined
  if (!snapshotDetail) {
    return null;
  }

  const snapshotDate = new Date(snapshotDetail.created_at);

  const dateString = snapshotDate.toLocaleString("en-US", {
    day: "numeric",
    month: "numeric",
    year: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });

  const downloadFilename = `${
    snapshotDate.toISOString().match(/\d\d\d\d-\d\d-\d\d/)?.[0]
  }-${snapshotDetail?.resource}.png`;

  const handleShare: MouseEventHandler<HTMLDivElement> = async (event) => {
    if (event.target instanceof HTMLInputElement) {
      return;
    }
    const result = await setSnapshotSharedWithClient({
      variables: {
        snapshotId: snapshotDetail.id,
        shared: !snapshotDetail.sharedWithClient,
      },
      refetchQueries: [
        {
          query: GetAlbumSnapshotsDocument,
          variables: { clientId: currentClient?.canonical_id },
        },
      ],
    });
    if (!result.data?.update_resource_snapshot_by_pk?.id) {
      logUnexpectedError(
        new Error("Failed to set snapshot as shared with client")
      );
      return;
    }
    logSnapshotSharedCount();
    dispatch(
      setSnapshotDetail({
        ...snapshotDetail,
        sharedWithClient: !snapshotDetail.sharedWithClient,
      })
    );
  };

  const handleDelete = () => {
    dispatch(toggleShowConfirmSnapshotDelete());
  };

  const logDownloadEvent = () => {
    // not awaiting to let it run in the background
    logClientManagementEvent({
      action: "SNAPSHOT_DOWNLOADED",
      snapshotId: snapshotDetail.id,
      duringSession: clientHasJoinedRoom,
    });
  };

  return createPortal(
    <Modal closeModal={closeModal}>
      <div className={styles.container}>
        <div
          className={clsx(styles.preview, {
            [styles.zoomed]: imgWidth !== undefined,
          })}
          ref={imgContainerRef}
        >
          <img
            src={snapshotDetail.fileUrl}
            onLoad={resizeImage}
            className={styles.imgPreview}
            style={!!imgWidth ? { width: imgWidth } : undefined}
            ref={imgRef}
          />
        </div>
        <div className={styles.info}>
          <div>{snapshotDetail.clientName}</div>
          <div>{snapshotDetail.resource}</div>
          <div>{dateString}</div>
        </div>
        <div className={styles.buttons}>
          <div
            className={clsx(styles.button, {
              [styles.shared]: snapshotDetail.sharedWithClient,
            })}
            onClick={handleShare}
          >
            <div>
              <Checkbox
                checked={snapshotDetail.sharedWithClient}
                className={styles.checkbox}
              />
              <img src={albumIcon} />
            </div>
            Share to Client Album
          </div>
          <a
            className={styles.button}
            href={downloadUrl}
            download={downloadFilename}
            target="_blank"
            rel="noreferrer"
            onClick={logDownloadEvent}
          >
            <DownloadIcon />
            Download
          </a>
          <div className={styles.button} onClick={handleDelete}>
            <DeleteIcon />
            Delete
          </div>
          <SnapshotDeleteConfirmModal />
        </div>
      </div>
    </Modal>,
    document.body
  );
};

export default SnapshotDetailsModal;

import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import ConnectionError from "./subpages/ConnectionError/ConnectionError";
import {
  selectEncodedClientToken,
  selectSignedOut,
  selectUserRole,
  setCurrentRoomId,
  setProviderId,
  setProviderIsInternal,
  setUserJoinTimestamp,
  UserRole,
} from "redux/userRedux";

import JoinedSpace from "./JoinedSpace";
import { useSyncState } from "./hooks/useSyncState";
import { selectMeetingID, setMeetingID } from "redux/spaceNavigationRedux";
import { useGetSpaceDataQuery } from "generated/graphql";
import { useConnections } from "./hooks/connection/useConnections";
import {
  setAudioProviderOnly,
  setEnableResourceNameViewer,
  setIsVideoBlurred,
  setShowResourceNameOnItemHover,
  setVideoConferencing,
} from "redux/settingsRedux";
import { useUserMedia } from "pages/Space/hooks/video/useUserMedia";
import { setCurrentClient } from "redux/clientManagementRedux";
import { logUnexpectedError } from "utils/errorUtils";
import { isMobileDevice } from "utils/deviceUtils";
import { useRemoteSettings } from "pages/Space/components/ControlBar/useRemoteSettings";
import { useSyncMediaError } from "./hooks/video/useSyncMediaError";
import { selectSessionEHRSystem } from "redux/ehrSystemRedux";

const Space = () => {
  const meetingID = useSelector(selectMeetingID);
  const userRole = useSelector(selectUserRole);
  const encodedClientToken = useSelector(selectEncodedClientToken);

  const dispatch = useDispatch();

  const ehrSystem = useSelector(selectSessionEHRSystem);

  const isTherapist = userRole === UserRole.THERAPIST;

  const { data: spaceData, error: spaceDataError } = useGetSpaceDataQuery({
    variables: {
      meetingID: meetingID || "",
      isProvider: isTherapist,
    },
    // Avoiding query in first render where encodedClientToken is not set yet
    // for clients
    skip: !meetingID || (!isTherapist && !encodedClientToken),
  });

  useEffect(() => {
    if (spaceDataError) {
      logUnexpectedError(spaceDataError);
    }
  }, [spaceDataError]);

  useEffect(() => {
    if (spaceData) {
      const roomId = spaceData.meeting_by_pk?.provider?.current_room?.id;
      dispatch(setCurrentRoomId(roomId));
      const provider = spaceData?.meeting_by_pk?.provider;
      const providerId = provider?.id;
      dispatch(setProviderId(providerId));
      const isInternal = provider ? provider.is_internal : null;
      dispatch(setProviderIsInternal(isInternal));
      const joinTimestamp = provider?.join_timestamp;
      dispatch(setUserJoinTimestamp(joinTimestamp));
      const audioProviderOnly =
        provider?.provider_settings?.audio_provider_only;
      dispatch(setAudioProviderOnly(audioProviderOnly));
      const videoConferencing = provider?.provider_settings?.video_conferencing;
      dispatch(setVideoConferencing(videoConferencing));
      if (userRole === UserRole.THERAPIST) {
        const resourceNameHover =
          provider?.provider_settings?.resource_name_hover;
        const resourceNameViewer =
          provider?.provider_settings?.resource_name_viewer;
        dispatch(setShowResourceNameOnItemHover(resourceNameHover));
        dispatch(setEnableResourceNameViewer(resourceNameViewer));
        if (!isMobileDevice()) {
          const videoBlur = provider?.provider_settings?.video_blur;
          dispatch(setIsVideoBlurred(videoBlur));
        }
      }
      const currentClient = provider?.current_client;
      if (currentClient) {
        dispatch(
          setCurrentClient({
            canonical_id: currentClient.canonical_id,
            name: currentClient.name,
          })
        );
      }
    }
  }, [spaceData]);

  const {
    localMediaStream,
    userMediaError,
    closeUserMedia,
    reloadUserMedia,
    loadingUserMedia,
  } = useUserMedia();

  const {
    remoteDataChannelRef,
    remoteMediaStream,
    peers,
    peersRef,
    connectionError,
    isConnectedToRemote,
    isConnectingToRemote,
    isSettingUpConnectionToRemote,
    isOffline,
    isResettingTheRoom,
    endSession,
    leave,
    signalActivity,
    getRemoteVideoBytesReceived,
  } = useConnections(meetingID, localMediaStream);

  const remoteSetters = useRemoteSettings(peersRef, peers);

  const { triggerMediaErrorSync } = useSyncMediaError(
    peersRef,
    peers,
    userMediaError
  );

  // if is connected to at least one peer
  const isConnectedToPeer = Object.values(peersRef.current).reduce(
    (previousValue, currentPeer) => previousValue || currentPeer.isConnected,
    false
  );

  // is connecting to at least one peer
  const isConnectingToPeer = Object.values(peersRef.current).reduce(
    (previousValue, currentPeer) => previousValue || currentPeer.isConnecting,
    false
  );

  const isConnectedToTherapist =
    isTherapist ||
    Object.values(peersRef.current).find(
      (peer) => peer.role === UserRole.THERAPIST
    )?.isConnected;

  useSyncState(
    peers,
    remoteSetters.setRemoteIsPeerMuted,
    remoteSetters.setRemoteIsPeerVideoOff,
    triggerMediaErrorSync
  );

  useEffect(() => {
    if (meetingID) {
      dispatch(setMeetingID(meetingID));
    }
  }, [meetingID]);

  const connectionEnded =
    connectionError ||
    (!isConnectedToRemote && !isConnectingToRemote) ||
    (!isTherapist && !isConnectedToPeer && !isConnectingToPeer);

  useEffect(() => {
    if (connectionEnded) {
      closeUserMedia();
    } else {
      if (
        localMediaStream
          ?.getTracks()
          .every((track) => track.readyState === "ended")
      ) {
        reloadUserMedia();
      }
    }
  }, [connectionEnded]);

  const signedOut = useSelector(selectSignedOut);

  // This state is only relevant for EHR systems sessions, because for
  // Teleo-initiated sessions, we always redirect users at sign-out right away.
  if (signedOut) {
    return (
      <ConnectionError
        errorMessage="You have been signed out."
        loading={false}
        showInviteLink={false}
      />
    );
  }

  if (!meetingID) {
    return (
      <ConnectionError
        errorMessage={"Invalid url"}
        loading={false}
        showInviteLink={false}
      />
    );
  }

  let statusMessage = connectionError;
  let loading = false;
  let showInviteLink = false;

  // If the user is offline, we show a message to reconnect to the internet.
  if (!statusMessage && isOffline) {
    loading = true;
    statusMessage =
      "You are offline. Please reconnect to the internet to continue.";
  }

  // If the user is not connected to the remote, we show a message to connect to the room.
  if (!statusMessage && !isConnectedToRemote) {
    if (isResettingTheRoom) {
      statusMessage = "Session ended. Resetting the room...";
    } else if (!isConnectingToRemote) {
      statusMessage = "Disconnected.";
    } else {
      statusMessage = "Connecting to the room...";
      if (isTherapist && !ehrSystem && isSettingUpConnectionToRemote) {
        showInviteLink = true;
      }
    }
    loading = isConnectingToRemote;
  }

  // Showing a reconnecting message for clients, because they can't
  // be alone in the room, so they should wait for the provider to
  // reconnect / rejoin.
  if (!statusMessage && !isConnectedToTherapist) {
    statusMessage = "Connecting to the room...";
    loading = true;
  }

  const hasSessionEnded =
    statusMessage === "Session ended." ||
    statusMessage.includes("Disconnected.") ||
    statusMessage.includes("Session timed out") ||
    statusMessage.includes("Signing out");

  return (
    <>
      {!hasSessionEnded && (
        <JoinedSpace
          peersRef={peersRef}
          peers={peers}
          localMediaStream={localMediaStream}
          userMediaError={userMediaError}
          reloadUserMedia={reloadUserMedia}
          loadingUserMedia={loadingUserMedia}
          isConnectedToPeer={isConnectedToPeer}
          remoteDataChannelRef={remoteDataChannelRef}
          remoteMediaStream={remoteMediaStream}
          endSession={endSession}
          leave={leave}
          signalActivity={signalActivity}
          getRemoteVideoBytesReceived={getRemoteVideoBytesReceived}
          remoteSetters={remoteSetters}
        />
      )}
      <ConnectionError
        errorMessage={statusMessage}
        loading={loading}
        showInviteLink={showInviteLink}
        hasSessionEnded={hasSessionEnded}
        endSession={endSession}
        isGlobalError
      />
    </>
  );
};

export default Space;

import React from "react";
import styles from "./ClientDeleteConfirmModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectClientHasJoinedRoom,
  selectCurrentClient,
  selectShowConfirmClientDelete,
  setCurrentClient,
  toggleShowConfirmClientDelete,
} from "redux/clientManagementRedux";
import { createPortal } from "react-dom";
import Modal from "components/Modal/Modal";
import Button from "components/Button/Button";
import {
  GetClientsDocument,
  useSetCurrentClientMutation,
  useSoftDeleteClientMutation,
} from "generated/graphql";
import { selectUserId } from "redux/userRedux";
import { useLogClientManagementEvent } from "utils/metricsUtils";
import { logUnexpectedError } from "utils/errorUtils";
import { useTrackDeleteClientConfirmation } from "../ClientManagement/useClientManagementAnalytics";

interface ClientDeleteConfirmModalProps {}

const ClientDeleteConfirmModal = ({}: ClientDeleteConfirmModalProps) => {
  const showConfirmClientDelete = useSelector(selectShowConfirmClientDelete);
  const currentClient = useSelector(selectCurrentClient);
  const [softDeleteClient] = useSoftDeleteClientMutation();
  const dispatch = useDispatch();

  const providerId = useSelector(selectUserId);
  const [setCurrentClientMutation] = useSetCurrentClientMutation();

  const closeModal = () => dispatch(toggleShowConfirmClientDelete());
  const userId = useSelector(selectUserId);

  const logClientManagementEvent = useLogClientManagementEvent();
  const { trackDelete, trackCancel } = useTrackDeleteClientConfirmation();

  const clientHasJoinedRoom = useSelector(selectClientHasJoinedRoom);

  const handleCancel = () => {
    trackCancel();
    closeModal();
  };

  const handleDelete = async () => {
    if (!currentClient) {
      logUnexpectedError(new Error("Cannot delete client without a client."));
      alert(
        "Something went wrong while deleting the client. Please try again."
      );
      return;
    }
    const result = await softDeleteClient({
      variables: { clientCanonicalId: currentClient.canonical_id },
      refetchQueries: [
        { query: GetClientsDocument, variables: { providerId: userId } },
      ],
    });
    if (result.data?.update_client_by_pk?.canonical_id) {
      const result = await setCurrentClientMutation({
        variables: {
          clientId: undefined,
          providerId: providerId,
        },
      });
      if (result.errors || !result.data?.update_user_by_pk?.id) {
        alert(
          "Something went wrong after deleting the client. Please refresh your page."
        );
        logUnexpectedError(
          result.errors || new Error("No user updated when deleting client")
        );
        // keep doing the next actions even if this fails
      }
      // not awaiting to let it run in the background
      logClientManagementEvent({
        action: "CLIENT_DELETED",
        clientCanonicalId: currentClient.canonical_id,
        duringSession: clientHasJoinedRoom,
      });
      trackDelete();
      dispatch(setCurrentClient(undefined));
      closeModal();
    } else {
      alert(
        "Something went wrong while deleting the client. Please try again."
      );
    }
  };

  return showConfirmClientDelete
    ? createPortal(
        <Modal closeModal={handleCancel}>
          <div className={styles.container}>
            <div className={styles.heading}>Delete Client Record</div>
            <div className={styles.confirmationText}>
              Are you sure you want to delete the client record for{" "}
              {currentClient?.name}? This will delete all stored snapshots for
              this client.
            </div>
            <div className={styles.buttonRow}>
              <Button onClick={handleCancel} secondary>
                Cancel
              </Button>
              <Button className={styles.deleteButton} onClick={handleDelete}>
                Delete
              </Button>
            </div>
          </div>
        </Modal>,
        document.body
      )
    : null;
};
export default ClientDeleteConfirmModal;

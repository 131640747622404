import { RoomItemFragment, RoomItemResourceFragment } from "generated/graphql";
import { backendRequest } from "utils/backendRequest";
import {
  Action,
  getIconConfig,
  ICON_ID,
  Platform,
} from "pages/Space/subpages/SpaceRoom/itemsConfig";

export const ROOM_CUSTOMIZATION_RESOURCE_ID = "82";
export const POSTER_RESOURCE_ID = "94";
export const YOUNGER_FEELINGS_CHART_RESOURCE_ID = "23";
export const OLDER_FEELINGS_CHART_RESOURCE_ID = "26";
export const MANDALA_RESOURCE_ID = "10";
export const ALBUM_RESOURCE_ID = "187";

const getYouTubeNoCookieUrl = (youtubeUrl: string): string | null => {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = youtubeUrl.match(regex);

  if (match && match[1]) {
    const videoCode = match[1];
    return `https://www.youtube-nocookie.com/embed/${videoCode}?t=0`;
  } else {
    return null;
  }
};

export const resourceToAction = (
  resource: RoomItemResourceFragment
): Action | null => {
  const url = resource.url;
  const fileKey = resource.file_key;

  if (fileKey) {
    return {
      type: Platform.WHITEBOARD,
      backgroundImageKey: fileKey,
    };
  }

  if (url) {
    const youtubeUrl = getYouTubeNoCookieUrl(url);
    if (youtubeUrl) {
      return {
        type: Platform.MEDIA_PLAYER,
        url: youtubeUrl,
      };
    }

    if (url.includes("soundcloud.com")) {
      return {
        type: Platform.MEDIA_PLAYER,
        url,
      };
    }

    return {
      type: Platform.BROWSER_SANDBOX,
      url,
    };
  }

  if (resource.id === ROOM_CUSTOMIZATION_RESOURCE_ID) {
    return {
      type: Platform.ROOM_CUSTOMIZATION,
    };
  }

  if (resource.id === POSTER_RESOURCE_ID) {
    return null;
  }

  if (resource.id === ALBUM_RESOURCE_ID) {
    return { type: Platform.ALBUM };
  }

  return {
    type: Platform.WHITEBOARD,
  };
};

export const submitPublicResource = async (resourceId: string) => {
  const result = await backendRequest({
    path: "/submit-new-activity",
    options: {
      method: "POST",
      body: JSON.stringify({ resourceId }),
      headers: {
        "Content-Type": "text/plain",
      },
      keepalive: true, // make sure the request is sent completely even once the page is destroyed
    },
  });
  return result.ok;
};

const getIconIdForItem = (
  defaultIconId: string | null | undefined,
  fileKey: string | null | undefined
) => {
  if (defaultIconId) {
    return defaultIconId;
  }
  if (fileKey) {
    return ICON_ID.WORKSHEET;
  }
  return ICON_ID.TABLET;
};

export const getNextZ = (roomItems: RoomItemFragment[]) => {
  const maxZ = Math.max(...roomItems.map((item) => item.z));
  return isNaN(maxZ) || maxZ === -Infinity ? 0 : maxZ + 1;
};

export const getDefaultConfig = (
  defaultIconId: string | null | undefined,
  fileKey: string | null | undefined,
  backgroundImageSize: { width: number; height: number } | undefined,
  roomItems: RoomItemFragment[] | undefined
) => {
  if (!backgroundImageSize || !roomItems) {
    return null;
  }

  const iconId = getIconIdForItem(defaultIconId, fileKey);

  const { srcWidth, srcHeight, scale } = getIconConfig(iconId);
  const rx = 0.5 - (srcWidth * scale) / backgroundImageSize.width / 2;
  const ry = 0.5 - (srcHeight * scale) / backgroundImageSize.height / 2;

  const z = getNextZ(roomItems);

  return {
    iconId,
    rx,
    ry,
    z,
  };
};

export const truncateResourceName = (
  name: string | null | undefined,
  maxResourceNameLength: number
) => {
  if (!name || name.length <= maxResourceNameLength) {
    return name;
  }
  return `${name.slice(0, maxResourceNameLength)}...`;
};

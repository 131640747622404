import { configureStore } from "@reduxjs/toolkit";
import { spaceNavigationSlice } from "./spaceNavigationRedux";
import { userSlice } from "./userRedux";
import { settingsSlice } from "./settingsRedux";
import { editRoomNavigationSlice } from "./editRoomNavigationRedux";
import { clientManagementSlice } from "./clientManagementRedux";
import { clientAlbumSlice } from "./clientAlbumRedux";
import { eHRSystemSlice } from "./ehrSystemRedux";

export const store = configureStore({
  reducer: {
    clientAlbum: clientAlbumSlice.reducer,
    clientManagement: clientManagementSlice.reducer,
    spaceNavigation: spaceNavigationSlice.reducer,
    editRoomNavigation: editRoomNavigationSlice.reducer,
    user: userSlice.reducer,
    settings: settingsSlice.reducer,
    EHRSystem: eHRSystemSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // TODO: see if it makes sense to not use redux for non-serializable state
    }),
});

import React from "react";

import styles from "./SourceModal.module.css";
import Button from "components/Button/Button";
import CopyTextBox from "../CopyTextBox/CopyTextBox";
import { useDispatch } from "react-redux";
import { setShowSourceModal } from "redux/spaceNavigationRedux";
import Modal from "components/Modal/Modal";

type SourceModalProps = {
  externalUrl?: string;
};

const SourceModal = ({ externalUrl }: SourceModalProps) => {
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(setShowSourceModal(false));
  };

  const heading = externalUrl
    ? "You are visiting"
    : "This page is a Teleo tool.";
  const text = externalUrl
    ? "This is an external website outside of Teleo. Take care to ensure HIPAA compliance."
    : "Teleo handles PHI according to HIPAA guidelines.";

  return (
    <Modal closeModal={closeModal}>
      <div className={styles.heading}>{heading}</div>
      {externalUrl ? (
        <CopyTextBox text={externalUrl} className={styles.copyTextBox} />
      ) : null}
      <div className={styles.text}>{text}</div>
      <Button onClick={closeModal}>OK</Button>
    </Modal>
  );
};

export default SourceModal;

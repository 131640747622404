import { useEffect, useRef, useState } from "react";
import mixpanel from "mixpanel-browser";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  selectDecodedAuthToken,
  selectEncodedAuthToken,
  selectSignInExpires,
  selectUserRole,
  setDecodedAuthToken,
  setEncodedAuthToken,
  setOrganizationId,
  setSignInExpires,
  setUserRole,
  UserRole,
} from "redux/userRedux";
import * as Sentry from "@sentry/react";
import { logUnexpectedError } from "./errorUtils";
import { backendRequest } from "utils/backendRequest";

// Set redirectPath to null to not attempt AWS Cognito sign in if not already signed in
export const useSignIn = (redirectPath: string | null, signOut: () => void) => {
  const encodedAuthTokenFromRedux = useSelector(selectEncodedAuthToken);
  const decodedAuthTokenFromRedux = useSelector(selectDecodedAuthToken);
  const expires = useSelector(selectSignInExpires);
  const expiresRef = useRef(expires);
  useEffect(() => {
    expiresRef.current = expires;
  }, [expires]);
  const userRole = useSelector(selectUserRole);
  const timeout = useRef<NodeJS.Timeout>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ready, setReady] = useState(false);

  // TODO: get the auth token more securely than in the URL, maybe use authorization code grant/PKCE flow (https://docs.aws.amazon.com/cognito/latest/developerguide/cognito-user-pools-app-integration.html) (although this is fine for now since URL params are encrypted)
  // TODO: also shorten access token expiration and use refresh tokens, which might also require using authorization code grant (https://hasura.io/blog/best-practices-of-using-jwt-with-graphql)
  const url = window.location.href;

  const authParams = url
    .split("#")[1]
    ?.split("&")
    .reduce((res, item) => {
      const parts = item.split("=");
      // @ts-ignore
      res[parts[0]] = parts[1];
      return res;
    }, {});

  // @ts-ignore
  const encodedAuthTokenFromUrl = authParams && authParams?.id_token;

  const resetSignOutTimeout = () => {
    if (expiresRef.current) {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      const timeoutPeriod = expiresRef.current - Date.now();
      timeout.current = setTimeout(signOut, timeoutPeriod);
    }
  };

  // Since timers do not run consistently when the site is not active, check on the timeouts whenever the page becomes
  // active again, and reset the timers.
  const checkOnVisibilityChange = () => {
    if (document.visibilityState === "hidden") {
      return;
    }
    if (expiresRef.current && expiresRef.current <= Date.now()) {
      signOut();
    }
    resetSignOutTimeout();
  };

  useEffect(() => {
    const asyncCode = async () => {
      try {
        if (encodedAuthTokenFromUrl) {
          // Clear the url params (which contain auth tokens) for easier navigation and bookmarking
          const url = window.location.href;
          const urlWithoutParams = url.split("?")[0].split("#")[0];
          history.replaceState({}, "", urlWithoutParams);
        }
        if (
          encodedAuthTokenFromRedux &&
          decodedAuthTokenFromRedux &&
          expires &&
          userRole === UserRole.THERAPIST
        ) {
          // Don't try to sign in again if the sign in state is already set in redux
          // Just set the automatic sign out based on the expiry.
          resetSignOutTimeout();
          window.addEventListener("visibilitychange", checkOnVisibilityChange);
          setReady(true);
          return;
        }
        const mixpanelUserId = mixpanel.get_distinct_id();

        const result = await backendRequest({
          path: "/sign-in",
          searchParams: encodedAuthTokenFromUrl
            ? {
                token: encodedAuthTokenFromUrl,
                mixpanelUserId,
              }
            : undefined,
        });

        if (result.ok) {
          const json = await result.json();
          if (!json) {
            throw new Error("Sign in response is empty");
          }
          const encodedToken = json.encodedToken;
          dispatch(setEncodedAuthToken(encodedToken));
          const decodedToken = JSON.parse(json.decodedToken);
          if (decodedToken) {
            dispatch(setDecodedAuthToken(decodedToken));
          }
          const userId = decodedToken?.userId;
          if (userId) {
            Sentry.setUser({ id: userId });
          }
          const organizationId = decodedToken?.organizationId;
          if (organizationId) {
            dispatch(setOrganizationId(organizationId));
          }
          dispatch(setUserRole(UserRole.THERAPIST));
          dispatch(setSignInExpires(json.expires));
          resetSignOutTimeout();
          window.addEventListener("visibilitychange", checkOnVisibilityChange);
          // Expecting 404 if the user is not found or there was no token in the request
        } else if (result.status === 404) {
          if (process.env.REACT_APP_COGNITO_LOGOUT && redirectPath !== null) {
            location.href = process.env.REACT_APP_COGNITO_LOGOUT + redirectPath;
          } else {
            dispatch(setUserRole(UserRole.CLIENT));
          }
          // Expecting 403 only in this case where a EHR user is trying to sign in through cognito.
        } else if (result.status === 403) {
          const responseJson = await result.json();

          logUnexpectedError(responseJson.error);
          alert(responseJson.error);
          return;
        } else if (result.status === 503 && redirectPath !== "/admin") {
          navigate("/maintenance");
        } else {
          throw new Error(
            `Sign in response is not ok: ${result.status} - ${result.body}`
          );
        }
        setReady(true);
      } catch (err) {
        logUnexpectedError(err);
        alert("Internal error. Please, try again later.");
      }
    };
    asyncCode();

    return () => {
      window.removeEventListener("visibilitychange", checkOnVisibilityChange);
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  return {
    ready,
  };
};

export const signOutServer = async () => {
  try {
    mixpanel.reset();
    const result = await backendRequest({
      path: "/sign-out",
    });
    return result.ok;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getIsInternal = (decodedAuthToken: any) => {
  return decodedAuthToken?.groups?.includes("teleo-internal") || false;
};

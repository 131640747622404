import React, { useState } from "react";

import styles from "./TermsModal.module.css";
import Button from "components/Button/Button";
import Modal from "components/Modal/Modal";
import { useAppSignOut } from "utils/appSignOutUtils";
import {
  GetDemoStartDataDocument,
  useSetAgreedToTermsMutation,
} from "generated/graphql";
import { useSelector } from "react-redux";
import { selectUserId } from "redux/userRedux";
import LoadingAnimation from "components/LoadingAnimation/LoadingAnimation";
import Checkbox from "components/Checkbox/Checkbox";
import TextInput from "components/TextInput/TextInput";
import { logUserAdditionalInfo } from "utils/metricsUtils";
import DropDown from "components/DropDown/DropDown";

const MAX_NAME_LENGTH = 1000;

const LICENSE_TYPES = [
  "LPC",
  "LCSW, LICSW",
  "LMFT",
  "LMHC",
  "Psychologist (PhD or PsyD)",
  "RBT",
  "BCBA",
  "Occupational therapist (OT)",
  "Speech Language Pathologist (SLP)",
  "APRN, ARNP",
  "Psychiatrist",
  "Teacher",
  "Other",
] as const;

const TermsModal = () => {
  const appSignOut = useAppSignOut();
  const [setAgreedToTerms] = useSetAgreedToTermsMutation();
  const userId = useSelector(selectUserId);
  const [checked, setChecked] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [licenseType, setLicenseType] = useState<
    typeof LICENSE_TYPES[number] | ""
  >("");
  const [otherLicenseType, setOtherLicenseType] = useState("");

  const submit = async () => {
    if (!userId) {
      return;
    }
    let finalLicenseType = "";

    if (licenseType && licenseType !== "Other") {
      finalLicenseType = licenseType;
    } else if (licenseType === "Other") {
      finalLicenseType = otherLicenseType;
    }

    // TODO: Handle errors if the first request succeeded but the second one fails
    await setAgreedToTerms({
      variables: {
        userId,
        firstName,
        lastName,
        licenseType: finalLicenseType,
        timestamp: new Date().toISOString(),
      },
      refetchQueries: [
        // Explicit refetch is needed for the case of a newly signed-up user, where the GetDemoStartDataDocument query might return null initially (before the user is inserted)
        { query: GetDemoStartDataDocument, variables: { userId } },
      ],
    });
    await logUserAdditionalInfo(firstName, lastName, finalLicenseType);
  };

  const baaLink = (
    <a href={"https://www.teleo.space/baa"} target="_blank" rel="noreferrer">
      Business Associate Agreement
    </a>
  );
  const termsLink = (
    <a href={"https://www.teleo.space/terms"} target="_blank" rel="noreferrer">
      Terms and Conditions
    </a>
  );
  const privacyLink = (
    <a
      href={"https://www.teleo.space/privacy"}
      target="_blank"
      rel="noreferrer"
    >
      Privacy Policy
    </a>
  );

  const isLicenseTypeFilled =
    (licenseType === "Other" && otherLicenseType !== "") ||
    (licenseType !== "Other" && !!licenseType);

  const ready = firstName && lastName && isLicenseTypeFilled && checked;

  return (
    <Modal>
      {userId ? (
        <>
          <div className={styles.heading}>Get Started with Teleo</div>
          <div className={styles.form}>
            <div className={styles.formText}>Full Name</div>
            <div className={styles.inputRow}>
              <TextInput
                value={firstName}
                setValue={setFirstName}
                placeholder={"First Name"}
                className={styles.textInputLeft}
                maxLength={MAX_NAME_LENGTH}
              />
              <TextInput
                value={lastName}
                setValue={setLastName}
                placeholder={"Last Name"}
                className={styles.textInputRight}
                maxLength={MAX_NAME_LENGTH}
              />
            </div>
            <div className={styles.formText}>License Type</div>
            <div className={styles.fullInputRow}>
              <DropDown
                options={LICENSE_TYPES.map((license) => ({
                  value: license,
                  label: license,
                }))}
                value={licenseType}
                setValue={setLicenseType as (p: string) => void}
                placeholder={"Choose your license type"}
              />
              {licenseType === "Other" && (
                <TextInput
                  value={otherLicenseType}
                  setValue={setOtherLicenseType}
                  placeholder={"Specify your license type"}
                  maxLength={MAX_NAME_LENGTH}
                />
              )}
            </div>
            <div className={styles.checkboxRow}>
              <Checkbox
                onToggle={setChecked}
                checked={checked}
                outlined
                white
              />
              <div className={styles.formText}>
                I agree to the Teleo {baaLink}, the {termsLink}, and the{" "}
                {privacyLink}.
              </div>
            </div>
          </div>
          <div className={styles.buttonRow}>
            <Button onClick={appSignOut} className={styles.cancelButton}>
              Decline & Sign Out
            </Button>
            <Button onClick={submit} disabled={!ready}>
              Get Started
            </Button>
          </div>
        </>
      ) : (
        <LoadingAnimation />
      )}
    </Modal>
  );
};

export default TermsModal;

import React, { MouseEventHandler } from "react";
import { useSelector } from "react-redux";
import { getOperationAST } from "graphql";
import clsx from "clsx";

import { selectUserId } from "redux/userRedux";
import styles from "./ResourceFavorite.module.css";
import { ReactComponent as StarFillIcon } from "assets/icons/star_fill.svg";
import { ReactComponent as StarOutlineIcon } from "assets/icons/star_outline.svg";
import Tooltip from "components/Tooltip/Tooltip";
import {
  GetResourcesDocument,
  useUpsertResourceIsFavoriteMutation,
} from "generated/graphql";

type ResourceFavoriteProps = {
  resourceId: string;
  isFavorite: boolean;
  isFavoriteRow?: boolean;
};

const ResourceFavorite = ({
  resourceId,
  isFavorite,
  isFavoriteRow,
}: ResourceFavoriteProps) => {
  const userId = useSelector(selectUserId);
  const [upsertResourceIsFavorite] = useUpsertResourceIsFavoriteMutation();

  const stopPropagation: MouseEventHandler<HTMLButtonElement> = async (ev) => {
    ev.stopPropagation();
  };

  const handleFavoriteClick: MouseEventHandler<HTMLButtonElement> = async (
    ev
  ) => {
    ev.stopPropagation();

    const getResourcesOperationName =
      getOperationAST(GetResourcesDocument)?.name?.value;
    const refetchQueries = getResourcesOperationName
      ? [getResourcesOperationName]
      : [];

    upsertResourceIsFavorite({
      variables: {
        resourceId: resourceId,
        userId: userId,
        isFavorite: !isFavorite,
      },
      refetchQueries,
    });
  };

  return (
    <button
      className={clsx(styles.button, {
        [styles.buttonMarginBottom]: !isFavoriteRow,
      })}
      onClick={handleFavoriteClick}
      // Preventing adding the resource to room and other events
      onMouseDown={stopPropagation}
      onMouseUp={stopPropagation}
      onDoubleClick={stopPropagation}
    >
      <Tooltip text={"Favorite"}>
        {isFavorite ? (
          <StarFillIcon className={clsx(styles.starIcon, styles.filled)} />
        ) : (
          <StarOutlineIcon className={styles.starIcon} />
        )}
      </Tooltip>
    </button>
  );
};

export default ResourceFavorite;

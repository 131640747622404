import { useEffect } from "react";

import { readLargeEvent, sendLargeEventToPeers } from "utils/webrtcUtils";
import { Peers } from "pages/Space/hooks/connection/usePeerWebRTCConnection";

export const useRemoteCustomColor = ({
  peersRef,
  peers,
  id,
  changeCustomColor,
}: {
  peersRef: React.MutableRefObject<Peers>;
  peers: Peers;
  id: string;
  changeCustomColor: (color: string, position: number) => void;
}) => {
  const emitCustomColorChanged = (
    colorId: string,
    color: string,
    position: number
  ) => {
    sendLargeEventToPeers(peersRef.current, "custom-color-changed", {
      colorId,
      color,
      position,
    });
  };

  const onReceiveMessageCallback = async (event: MessageEvent) => {
    const data = await readLargeEvent(event);
    if (!data) {
      return;
    }
    const eventType = data.event;

    if (eventType === "custom-color-changed" && data.data.colorId === id) {
      changeCustomColor(data.data.color, data.data.position);
    }
  };

  useEffect(() => {
    const peerDataChannels = Object.values(peers).map(
      (peer) => peer.dataChannel
    );
    for (const peerDataChannel of peerDataChannels) {
      if (peerDataChannel) {
        peerDataChannel.addEventListener("message", onReceiveMessageCallback);
      }
    }

    return () => {
      for (const peerDataChannel of peerDataChannels) {
        if (peerDataChannel) {
          peerDataChannel.removeEventListener(
            "message",
            onReceiveMessageCallback
          );
        }
      }
    };
  }, [peers]);

  return { emitCustomColorChanged };
};

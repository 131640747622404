import React from "react";
import faceIcon from "assets/icons/face.svg";
import segmentIcon from "assets/icons/segment.svg";
import tagIcon from "assets/icons/tag.svg";
import styles from "./ResourceMetadata.module.css";
import { GetResourcesQuery } from "generated/graphql";
import Tooltip from "components/Tooltip/Tooltip";

export enum ResourceMetadataType {
  APPROACH,
  AGE,
  TOPIC,
}

const formatAgeValue = ({
  ageMin,
  ageMax,
}: {
  ageMin: number | null;
  ageMax: number | null;
}) => {
  if (ageMin === null && ageMax === null) {
    return "-";
  }
  ageMin = ageMin || 0;
  ageMax = ageMax || 100;
  if (ageMin === 0 && ageMax === 100) {
    return "All";
  }
  if (ageMax === 100) {
    return `${ageMin}+`;
  }
  return `${ageMin}-${ageMax}`;
};

const formatApproachValue = (
  value: GetResourcesQuery["resource"][0]["approaches"]
) => {
  if (!value || value.length === 0) {
    return "-";
  }
  return value.map((approach) => approach.approach).join(", ");
};

const formatTopicValue = (
  value: GetResourcesQuery["resource"][0]["topics"]
) => {
  if (!value || value.length === 0) {
    return "-";
  }
  return value.map((topic) => topic.topic).join(", ");
};

const metadataTypeToConfig = {
  [ResourceMetadataType.APPROACH]: {
    icon: segmentIcon,
    title: "Approach",
    valueFormatter: formatApproachValue,
  },
  [ResourceMetadataType.AGE]: {
    icon: faceIcon,
    title: "Age",
    valueFormatter: formatAgeValue,
  },
  [ResourceMetadataType.TOPIC]: {
    icon: tagIcon,
    title: "Topic",
    valueFormatter: formatTopicValue,
  },
};

type ResourceMetadataProps = {
  metadataType: ResourceMetadataType;
  value: any;
  width: number;
};

const ResourceMetadata = ({
  metadataType,
  value,
  width,
}: ResourceMetadataProps) => {
  const config = metadataTypeToConfig[metadataType];
  const formattedValue = config.valueFormatter(value);

  return (
    <div className={styles.container} style={{ flex: width }}>
      <Tooltip text={`${config.title}: ${formattedValue}`}>
        <div className={styles.innerContainer}>
          <img className={styles.icon} src={config.icon} />
          <div className={styles.value}>{formattedValue}</div>
        </div>
      </Tooltip>
    </div>
  );
};

export default ResourceMetadata;

import React from "react";
import ClientSelectionModal from "./ClientSelectionModal/ClientSelectionModal";
import { useSelector } from "react-redux";
import { selectClientSelectionStage } from "redux/clientManagementRedux";
import { createPortal } from "react-dom";
import ClientCreationModal from "./ClientCreationModal/ClientCreationModal";
import ClientConfirmationNameModal from "./ClientConfirmationNameModal/ClientConfirmationNameModal";

const ClientManagementModal = () => {
  const clientSelectionStage = useSelector(selectClientSelectionStage);
  switch (clientSelectionStage) {
    case "clientLoadSelect":
      return createPortal(<ClientSelectionModal />, document.body);
    case "clientAdmissionSelect":
      return createPortal(<ClientSelectionModal />, document.body);
    case "newClient":
      return createPortal(<ClientCreationModal />, document.body);
    case "confirmName":
      return createPortal(<ClientConfirmationNameModal />, document.body);
    case "none":
    default:
      return null;
  }
};

export default ClientManagementModal;

import {
  selectDecodedAuthToken,
  selectEmail,
  selectProviderId,
  selectProviderIsInternal,
  selectUserId,
  selectUserJoinTimestamp,
  selectUserRole,
  UserRole,
} from "redux/userRedux";
import mixpanel, { RequestOptions } from "mixpanel-browser";
import { useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { getIsInternal } from "./signInUtils";
import { logUnexpectedError } from "./errorUtils";
import {
  useInsertClientManagementAnalyticsMutation,
  useInsertMediaAnalyticsAsClientMutation,
  useInsertMediaAnalyticsAsProviderMutation,
  useInsertRoomItemUpdateMutation,
} from "generated/graphql";
import {
  selectBackgroundId,
  selectMeetingID,
} from "redux/spaceNavigationRedux";
import {
  selectClientHasJoinedRoom,
  selectCurrentClient,
} from "redux/clientManagementRedux";
import { backendRequest } from "utils/backendRequest";

const getRoleLabel = (role: UserRole | null) => {
  return role === UserRole.THERAPIST ? "provider" : "client";
};

const getHashedUserId = async (userId: string | null) => {
  if (!userId) {
    return null;
  }

  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(userId);

  // hash the message
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
};

export const useTrackUser = () => {
  const userId = useSelector(selectUserId);
  const role = useSelector(selectUserRole);
  const email = useSelector(selectEmail);
  const decodedAuthToken = useSelector(selectDecodedAuthToken);

  useEffect(() => {
    if (
      userId &&
      role !== undefined &&
      role !== null &&
      decodedAuthToken &&
      email !== undefined
    ) {
      const internal = async () => {
        const hashedUserId = await getHashedUserId(userId);
        if (hashedUserId) {
          mixpanel.identify(hashedUserId);
        } else {
          logUnexpectedError(
            "Mixpanel: could not call identify because getHashedUserId returned falsy"
          );
        }
        const roleLabel = getRoleLabel(role);
        const isInternal = getIsInternal(decodedAuthToken);
        mixpanel.people.set({
          Role: roleLabel,
          "Teleo Internal": isInternal,
          "Teleo User ID": userId,
          $email: email,
        });
      };
      internal();
    }
  }, [userId, role, decodedAuthToken, email]);
};

const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;

const getUserNewnessProperties = (userJoinTimestamp: string | undefined) => {
  if (!userJoinTimestamp) {
    return {
      ["User Newness - First Day"]: null,
      ["User Newness - First Week"]: null,
      ["User Newness - First Month"]: null,
    };
  }

  const durationSinceJoined = Date.now() - Date.parse(userJoinTimestamp);
  const joinedInLastDay = durationSinceJoined < ONE_DAY_IN_MS;
  const joinedInLastWeek = durationSinceJoined < 7 * ONE_DAY_IN_MS;
  const joinedInLastMonth = durationSinceJoined < 30 * ONE_DAY_IN_MS;

  return {
    ["User Newness - First Day"]: joinedInLastDay,
    ["User Newness - First Week"]: joinedInLastWeek,
    ["User Newness - First Month"]: joinedInLastMonth,
  };
};

export const useTrackEvent = () => {
  const role = useSelector(selectUserRole);
  const roleLabel = getRoleLabel(role);
  const decodedAuthToken = useSelector(selectDecodedAuthToken);
  const providerIsInternal = useSelector(selectProviderIsInternal);
  const clientHasJoinedRoom = useSelector(selectClientHasJoinedRoom);
  const selfIsInternalFromToken = getIsInternal(decodedAuthToken);
  const selfIsInternalFromStorage =
    localStorage.getItem("isInternal") === "true";
  const isInternal =
    selfIsInternalFromToken || selfIsInternalFromStorage || providerIsInternal;
  const isInternalRef = useRef(isInternal); // for use in callback
  const providerId = useSelector(selectProviderId);
  const providerIdRef = useRef(providerId); // for use in callback
  const userJoinTimestamp = useSelector(selectUserJoinTimestamp);

  useEffect(() => {
    isInternalRef.current = isInternal;
  }, [isInternal]);
  useEffect(() => {
    providerIdRef.current = providerId;
  }, [providerId]);

  // role, decodedAuthToken, providerIsInternal, providerId, and userJoinTimestamp state must be set before calling
  // this should be true after GetSpaceData query completes
  const isReadyToTrackEventForClient =
    !!providerId && providerIsInternal !== undefined;
  const trackEvent = (
    eventName: string,
    eventProperties?: any,
    options?: RequestOptions
  ) => {
    const properties = {
      Role: roleLabel,
      ["Teleo Internal"]: isInternalRef.current,
      ["Provider Teleo Id"]: providerIdRef.current,
      ["During Session"]: clientHasJoinedRoom,
      ...getUserNewnessProperties(userJoinTimestamp),
      ...eventProperties,
      $current_url: window.location.origin + window.location.pathname,
    };
    mixpanel.track(eventName, properties, options);
  };
  return {
    trackEvent,
    isReadyToTrackEventForClient,
  };
};

export const useTrackEventWithShowHide = (
  eventTitle: string,
  eventProperties?: any
) => {
  const { trackEvent, isReadyToTrackEventForClient } = useTrackEvent();

  useEffect(() => {
    trackEvent(`${eventTitle} - shown`, eventProperties);
    return () => {
      trackEvent(`${eventTitle} - hidden`, eventProperties);
    };
  }, []);

  return { trackEvent, isReadyToTrackEventForClient };
};

// userId, decodedAuthToken, and userJoinTimestamp state must be set before calling
export const useAnalyticsInfo = () => {
  const userId = useSelector(selectUserId);
  const decodedAuthToken = useSelector(selectDecodedAuthToken);
  const providerIsInternal = useSelector(selectProviderIsInternal);
  const selfIsInternalFromToken = getIsInternal(decodedAuthToken);
  const selfIsInternalFromStorage =
    localStorage.getItem("isInternal") === "true";
  const isInternal =
    selfIsInternalFromToken || selfIsInternalFromStorage || providerIsInternal;
  const providerId = useSelector(selectProviderId);
  const userJoinTimestamp = useSelector(selectUserJoinTimestamp);

  return async () => {
    const mixpanelUserId =
      (await getHashedUserId(userId)) || mixpanel.get_distinct_id();
    return {
      mixpanelUserId,
      isInternal,
      userJoinTimestamp,
      teleoUserId: userId,
      providerTeleoUserId: providerId,
    };
  };
};

export const logUserAdditionalInfo = (
  firstName: string,
  lastName: string,
  licenseType: string
) => {
  const bodyParams = {
    firstName,
    lastName,
    licenseType,
  };
  backendRequest({
    path: "/stats-additional-user-info",
    options: {
      method: "POST",
      body: JSON.stringify(bodyParams),
      headers: {
        "Content-Type": "application/json",
      },
      keepalive: true, // make sure the request is sent completely even once the page is destroyed
    },
  }).catch(logUnexpectedError);
};

export const logVideoConferencingEnabled = () => {
  backendRequest({
    path: "/stats-video-conferencing",
    options: {
      method: "POST",
      keepalive: true, // make sure the request is sent completely even once the page is destroyed
    },
  }).catch(logUnexpectedError);
};

export type ActivityType =
  | "CHANGED_ROOM_ACTIVITY"
  | "ADDED_CUSTOM_ACTIVITY"
  | "CHANGED_POSTER"
  | "ADDED_ROOM"
  | "TOGGLED_FULLSCREEN_VIDEO_ON"
  | "SENT_INVITE_EMAIL_FROM_ROOM"
  | "SENT_INVITE_EMAIL_FROM_HOME"
  | "BACKGROUND_CHANGED"
  | "ROOM_ITEM_ADD"
  | "ROOM_ITEM_DELETE"
  | "ROOM_ITEM_MOVE"
  | "ROOM_ITEM_CHANGE_ICON"
  | "ADDED_CLIENT"
  | "ADDED_SNAPSHOT"
  | "SHARED_SNAPSHOT";

export const logUsageEvent = (
  activity: ActivityType,
  meetingID: string | undefined
) => {
  backendRequest({
    path: "/stats-usage",
    searchParams: {
      activity,
      ...(meetingID ? { meetingID } : {}),
    },
    options: {
      method: "POST",
      keepalive: true, // make sure the request is sent completely even once the page is destroyed
    },
  }).catch(logUnexpectedError);
};

export const useLogClientCount = () => {
  const meetingID = useSelector(selectMeetingID);
  return () => {
    logUsageEvent("ADDED_CLIENT", meetingID);
  };
};

export const useLogSnapshotCount = () => {
  const meetingID = useSelector(selectMeetingID);
  return () => {
    logUsageEvent("ADDED_SNAPSHOT", meetingID);
  };
};

export const useLogSnapshotSharedCount = () => {
  const meetingID = useSelector(selectMeetingID);
  return () => {
    logUsageEvent("SHARED_SNAPSHOT", meetingID);
  };
};

export const useLogRoomItemEvent = () => {
  const [insertRoomItemUpdateMutation] = useInsertRoomItemUpdateMutation();
  const meetingID = useSelector(selectMeetingID);
  const backgroundId = useSelector(selectBackgroundId);
  const userId = useSelector(selectUserId);

  return async (variables: {
    action: "ADD" | "DELETE" | "MOVE" | "CHANGE_ICON" | "CHANGE_ACTIVITY";
    iconId: string;
    resourceId: string;
    previousIconId?: string;
    previousResourceId?: string;
  }) => {
    try {
      if (!backgroundId || !userId) {
        return;
      }
      await insertRoomItemUpdateMutation({
        variables: {
          backgroundId,
          userId,
          ...variables,
        },
      });
      // The "CHANGED_ROOM_ACTIVITY" count was already being tracked before
      // adding the more detailed "ROOM_ITEM_" logs, so we continued using
      // the "CHANGED_ROOM_ACTIVITY" count to populate the Hubspot property.
      logUsageEvent(
        variables.action === "CHANGE_ACTIVITY"
          ? "CHANGED_ROOM_ACTIVITY"
          : `ROOM_ITEM_${variables.action}`,
        meetingID
      );
    } catch (e) {
      logUnexpectedError(e);
    }
  };
};

export const useLogEmailInviteEvent = (from: "HOME" | "ROOM") => {
  const meetingID = useSelector(selectMeetingID);
  return () => {
    logUsageEvent(`SENT_INVITE_EMAIL_FROM_${from}`, meetingID);
  };
};

type ClientManagementEvent =
  | {
      action:
        | "CLIENT_SELECTED_FROM_SEARCH_RESULTS"
        | "CLIENT_SELECTED_FROM_FULL_LIST"
        | "CLIENT_SELECTED_AUTOMATICALLY";
      clientCanonicalId: string;
      duringSession: boolean;
    }
  | {
      action:
        | "NAME_CONFIRMATION_SHOWN"
        | "NAME_CONFIRMATION_OK"
        | "NAME_CONFIRMATION_CANCEL";
      clientName: string;
      incomingName: string;
    }
  | {
      action: "CLIENT_LOADED" | "CLIENT_ADMITTED";
      clientCanonicalId: string;
      previousClientCanonicalId?: string;
      duringSession?: boolean;
    }
  | {
      action: "CLIENT_SIDEBAR_OPENED" | "CLIENT_DELETED";
      clientCanonicalId: string;
      duringSession: boolean;
    }
  | {
      action: "SNAPSHOT_OPENED" | "SNAPSHOT_DOWNLOADED";
      snapshotId: string;
      duringSession: boolean;
    }
  | {
      action: "ADDITIONAL_CLIENT_ADMITTED";
      clientCanonicalId: string;
    };

export const useLogClientManagementEvent = () => {
  const userId = useSelector(selectUserId);

  const [insertClientManagementAnalytics] =
    useInsertClientManagementAnalyticsMutation();

  return async (event: ClientManagementEvent) => {
    try {
      const result = await insertClientManagementAnalytics({
        variables: { userId, ...event },
      });
      if (result.errors) {
        logUnexpectedError(result.errors);
      }
    } catch (error) {
      logUnexpectedError(error);
    }
  };
};

type MediaEvent =
  | "SELECT_AUDIO_INPUT"
  | "SELECT_VIDEO_INPUT"
  | "ENABLE_NOISE_SUPPRESSION"
  | "DISABLE_NOISE_SUPPRESSION"
  | "ENABLE_BLUR"
  | "DISABLE_BLUR";

export const useLogMediaEvent = () => {
  const userRole = useSelector(selectUserRole);
  const providerID = useSelector(selectProviderId);
  const currentClient = useSelector(selectCurrentClient);
  const [insertMediaAnalyticsAsClient] =
    useInsertMediaAnalyticsAsClientMutation();
  const [insertMediaAnalyticsAsProvider] =
    useInsertMediaAnalyticsAsProviderMutation();

  return async (event: MediaEvent) => {
    try {
      const vars = {
        action: event,
        providerID: undefined as string | undefined,
        clientCanonicalID: undefined as string | undefined,
      };

      if (userRole === UserRole.CLIENT && currentClient) {
        vars.clientCanonicalID = currentClient.canonical_id;
        const result = await insertMediaAnalyticsAsClient({
          variables: vars,
        });
        if (result.errors) {
          logUnexpectedError(result.errors);
        }
      }

      if (userRole === UserRole.THERAPIST && providerID) {
        vars.providerID = providerID;
        const result = await insertMediaAnalyticsAsProvider({
          variables: vars,
        });
        if (result.errors) {
          logUnexpectedError(result.errors);
        }
      }
    } catch (error) {
      logUnexpectedError(error);
    }
  };
};

import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { fabric } from "utils/fabricUtils";
import { fabricTypes } from "utils/fabric-impl";
import { sendEventToPeers } from "utils/webrtcUtils";
import { Peers } from "pages/Space/hooks/connection/usePeerWebRTCConnection";
import { selectCanControlCursor } from "redux/settingsRedux";
import { selectUserRole, UserRole } from "redux/userRedux";
import { store } from "redux/reduxStore";

export const useRemoteMouseOnCanvas = (
  remoteCursorCanvasRef: React.MutableRefObject<fabricTypes.Canvas | undefined>,
  zoomLevel: number,
  zoomLevelRef: React.MutableRefObject<number>,
  peersRefs: React.MutableRefObject<Peers>
) => {
  const userRole = useSelector(selectUserRole);
  const remoteCursorRef = useRef<fabricTypes.Image | "pending">();

  useEffect(() => {
    if (!remoteCursorRef.current || remoteCursorRef.current === "pending") {
      return;
    }
    remoteCursorRef.current.set({
      scaleX: 1 / zoomLevel,
      scaleY: 1 / zoomLevel,
    });
    remoteCursorRef.current.setCoords();
    remoteCursorCanvasRef.current?.renderAll();
  }, [zoomLevel]);

  const moveRemoteCursor = (serializedPoint: fabricTypes.Point) => {
    if (!remoteCursorRef.current) {
      remoteCursorRef.current = "pending";
      fabric.Image.fromURL("/cursor.svg", function (oImg: fabricTypes.Image) {
        oImg.set({
          left: serializedPoint.x,
          top: serializedPoint.y,
          height: 20,
          width: 20,
          scaleX: 1 / zoomLevelRef.current,
          scaleY: 1 / zoomLevelRef.current,
        });
        remoteCursorRef.current = oImg;
        remoteCursorCanvasRef.current?.add(oImg);
        remoteCursorCanvasRef.current?.renderAll();
      });
    } else if (remoteCursorRef.current !== "pending") {
      remoteCursorRef.current.set({
        left: serializedPoint.x,
        top: serializedPoint.y,
      });
      remoteCursorRef.current.setCoords();
      remoteCursorCanvasRef.current?.renderAll();
    }
  };

  const removeRemoteCursor = () => {
    if (remoteCursorRef.current && remoteCursorRef.current !== "pending") {
      // TODO: handle case when remoteCursorRef is 'pending'
      remoteCursorCanvasRef.current?.remove(remoteCursorRef.current);
      remoteCursorRef.current = undefined;
    }
  };

  const emitCursorMove = (point: { x: number; y: number }) => {
    const state = store.getState();
    if (userRole === UserRole.THERAPIST || selectCanControlCursor(state)) {
      sendEventToPeers(peersRefs.current, "cursor-moved", { point });
    }
  };

  const emitCursorOut = () => {
    const state = store.getState();
    if (userRole === UserRole.THERAPIST || selectCanControlCursor(state)) {
      sendEventToPeers(peersRefs.current, "cursor-out", {});
    }
  };

  const onReceiveMessageCallback = (event: MessageEvent) => {
    const data = JSON.parse(event.data);
    const eventType = data.event;
    if (eventType === "cursor-moved") {
      moveRemoteCursor(data.data.point);
    } else if (eventType === "cursor-out") {
      removeRemoteCursor();
    }
  };

  return {
    emitCursorMove,
    emitCursorOut,
    onReceiveMessageCallback,
  };
};

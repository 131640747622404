import { useSelector } from "react-redux";

import {
  selectClientSelectionStage,
  selectCurrentClient,
} from "redux/clientManagementRedux";
import { useTrackEventWithShowHide } from "utils/metricsUtils";

export const useTrackClientSelection = () => {
  const clientSelectionStage = useSelector(selectClientSelectionStage);
  const currentClient = useSelector(selectCurrentClient);
  const title = "Client selection";
  const properties = {
    "During Admission": clientSelectionStage === "clientAdmissionSelect",
    "Any Client Already Loaded": !!currentClient?.canonical_id,
  };
  const { trackEvent } = useTrackEventWithShowHide(title, properties);

  const trackLoad = (alreadyLoaded: boolean, clientSelectedSource: string) =>
    trackEvent(`${title} - loaded`, {
      ...properties,
      "Selected Client Already Loaded": alreadyLoaded,
      "Selected Source": clientSelectedSource,
    });

  const trackCancel = () => trackEvent(`${title} - cancelled`);

  return { trackLoad, trackCancel };
};

export const useTrackNewClient = () => {
  const title = "New client";
  const { trackEvent } = useTrackEventWithShowHide(title);

  const trackCreate = () => trackEvent(`${title} - created`);
  const trackCancel = () => trackEvent(`${title} - cancelled`);

  return { trackCreate, trackCancel };
};

export const useTrackSelectClientConfirmation = () => {
  const title = "Select client confirmation";
  const { trackEvent } = useTrackEventWithShowHide(title);

  const trackConfirm = () => trackEvent(`${title} - confirmed`);
  const trackCancel = () => trackEvent(`${title} - cancelled`);

  return { trackConfirm, trackCancel };
};

export const useTrackDeleteClientConfirmation = () => {
  const title = "Delete client confirmation";
  const { trackEvent } = useTrackEventWithShowHide(title);

  const trackDelete = () => trackEvent(`${title} - deleted`);
  const trackCancel = () => trackEvent(`${title} - cancelled`);

  return { trackDelete, trackCancel };
};

export const useTrackClientFile = () => {
  const title = "Client file";
  const { trackEvent } = useTrackEventWithShowHide(title);

  const trackDeleteClicked = () => trackEvent(`${title} - delete clicked`);
  const trackSwitchClicked = () => trackEvent(`${title} - switch clicked`);

  return { trackDeleteClicked, trackSwitchClicked };
};

import React, { useRef } from "react";
import styles from "./Checkbox.module.css";
import clsx from "clsx";

type CheckboxProps = {
  onToggle?: (isChecked: boolean) => void;
  checked?: boolean;
  outlined?: boolean;
  white?: boolean;
  children?: React.ReactNode;
  className?: string;
};

const Checkbox = ({
  onToggle,
  checked,
  outlined,
  white,
  children,
  className = "",
}: CheckboxProps) => {
  const checkboxRef = useRef<HTMLInputElement>(null);
  const onClick = () => {
    onToggle?.(checkboxRef.current?.checked || false);
  };

  return (
    <label className={clsx(styles.container, className)}>
      <div className={styles.label}>{children}</div>
      <input
        ref={checkboxRef}
        type={"checkbox"}
        checked={checked}
        readOnly
        onClick={onClick}
      />
      <span
        className={clsx(styles.checkmark, {
          [styles.outlined]: outlined,
          [styles.white]: white,
        })}
      />
    </label>
  );
};

export default Checkbox;

import React, { useState } from "react";
import OuterSpace from "./OuterSpace";
import { useAppSignOut } from "utils/appSignOutUtils";
import { useSignIn } from "utils/signInUtils";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logUnexpectedError } from "utils/errorUtils";
import { setMeetingID } from "redux/spaceNavigationRedux";
import { backendRequest } from "utils/backendRequest";
import { setOrganizationId } from "redux/userRedux";

const TeleoMeeting = () => {
  const params = useParams<{ meetingUrl?: string }>();
  const meetingID = params.meetingUrl ? `URL_${params.meetingUrl}` : undefined;

  const signOut = useAppSignOut();
  const { ready: signInIsDone } = useSignIn(null, signOut); // don't send to sign in page if not already signed in
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [roomIsValid, setRoomIsValid] = useState<boolean>();
  const [roomIsOpen, setRoomIsOpen] = useState<boolean>();
  const dispatch = useDispatch();
  const startMeeting = signInIsDone
    ? () => {
        const doAsync = async () => {
          const result = await backendRequest({ path: `/rooms/${meetingID}` });
          const data = await result.json();
          setRoomIsValid(data.valid);
          setRoomIsOpen(data.open);
          // If the room is not open and the correct provider is signed in, send them to the home page
          if (data.valid && data.open === false) {
            navigate("/");
          }
          if (data.organizationId) {
            dispatch(setOrganizationId(data.organizationId));
          }
          setLoading(false);
          dispatch(setMeetingID(meetingID));
        };
        doAsync().catch(logUnexpectedError);
      }
    : undefined;
  return (
    <OuterSpace
      loading={loading}
      roomIsValid={roomIsValid}
      roomIsOpen={roomIsOpen}
      startMeeting={startMeeting}
      startMeetingTrigger="onLoad"
      signOut={signOut}
    />
  );
};

export default TeleoMeeting;

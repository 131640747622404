import React from "react";
import styles from "./SnapshotDeleteConfirmModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectShowConfirmSnapshotDelete,
  selectSnapshotDetail,
  setSnapshotDetail,
  toggleShowConfirmSnapshotDelete,
} from "redux/clientManagementRedux";
import { createPortal } from "react-dom";
import Modal from "components/Modal/Modal";
import Button from "components/Button/Button";
import { useSoftDeleteSnapshotMutation } from "generated/graphql";

interface SnapshotDeleteConfirmModalProps {}

const SnapshotDeleteConfirmModal = ({}: SnapshotDeleteConfirmModalProps) => {
  const showConfirmSnapshotDelete = useSelector(
    selectShowConfirmSnapshotDelete
  );
  const currentSnapshot = useSelector(selectSnapshotDetail);
  const [softDeleteSnapshot] = useSoftDeleteSnapshotMutation();
  const dispatch = useDispatch();
  const closeModal = () => dispatch(toggleShowConfirmSnapshotDelete());
  const handeDelete = async () => {
    if (!currentSnapshot) return;

    const result = await softDeleteSnapshot({
      variables: { snapshotId: currentSnapshot?.id },
      refetchQueries: ["GetSnapshots"],
    });
    if (result.data?.update_resource_snapshot_by_pk?.id) {
      dispatch(setSnapshotDetail(undefined));
      closeModal();
    } else {
      alert(
        "Something went wrong while deleting the snapshot. Please try again."
      );
    }
  };

  return showConfirmSnapshotDelete
    ? createPortal(
        <Modal closeModal={closeModal}>
          <div className={styles.container}>
            <div className={styles.heading}>Delete Snapshot</div>
            <div className={styles.confirmationText}>
              Are you sure you want to delete this snapshot?
            </div>
            <div className={styles.buttonRow}>
              <Button onClick={closeModal}>Cancel</Button>
              <Button
                className={styles.deleteButton}
                onClick={handeDelete}
                secondary
              >
                Delete
              </Button>
            </div>
          </div>
        </Modal>,
        document.body
      )
    : null;
};
export default SnapshotDeleteConfirmModal;

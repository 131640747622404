import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import styles from "./SharedRoomRow.module.css";
import arrowDropDownIcon from "assets/icons/arrow_drop_down.svg";
import arrowDropUpIcon from "assets/icons/arrow_drop_up.svg";
import copyIcon from "assets/icons/copy.svg";
import checkIcon from "assets/icons/check.svg";
import deleteIcon from "assets/icons/delete.svg";
import { setDeleteSharedRoomModalSharedRoom } from "redux/spaceNavigationRedux";
import Tooltip from "components/Tooltip/Tooltip";
import { selectUserId } from "redux/userRedux";
import ResourceEntry from "pages/Space/subpages/SpaceRoom/components/ResourceEntry/ResourceEntry";
import { getFileUrls } from "utils/fileUtils";
import { THUMBNAIL_ERROR_KEY } from "pages/Space/subpages/SpaceRoom/utils/drawingUtils";
import { logUnexpectedError } from "utils/errorUtils";

type SharedRoomRowProps = {
  sharedRoom: any;
  sharedRoomFolderId: string | undefined;
  onClick: () => void;
  onCopy: (sharedRoomId: string) => Promise<void>;
  isSelected: boolean;
};

const SharedRoomRow = ({
  sharedRoom,
  sharedRoomFolderId,
  onClick,
  onCopy,
  isSelected,
}: SharedRoomRowProps) => {
  const dispatch = useDispatch();

  const [thumnbnailSrcs, setThumbnailSrcs] = useState();
  const [isCopyComplete, setIsCopyComplete] = useState(false);
  const userId = useSelector(selectUserId);
  const hasDelete = sharedRoom.source_owner_id === userId;
  const initials =
    sharedRoom.sourceOwner.first_name[0] + sharedRoom.sourceOwner.last_name[0];

  const handleCopySharedRoom = async (event: React.MouseEvent) => {
    event.stopPropagation();
    await onCopy(sharedRoom.id);
    setIsCopyComplete(true);
  };

  const showDeleteSharedRoomModal = (event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch(
      setDeleteSharedRoomModalSharedRoom({
        id: sharedRoom.id,
        name: sharedRoom.name,
        sharedRoomFolderId,
      })
    );
  };

  useEffect(() => {
    if (isCopyComplete) {
      const timeout = setTimeout(() => {
        setIsCopyComplete(false);
      }, 2000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isCopyComplete]);

  const sharedRoomCreatedDate = new Date(sharedRoom.created_at);
  const createdDate = sharedRoomCreatedDate.toLocaleString("en-US", {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
  });
  const lastUpdatedDate = `Last updated: ${createdDate}`;
  const uses = `Uses: ${sharedRoom.session_usage_count}`;

  const sharedRoomItemToComponent = (sharedRoomItem: any) => {
    const resource = sharedRoomItem.resource;
    const thumbnailSrc = resource.thumbnail_file_key
      ? thumnbnailSrcs?.[resource.thumbnail_file_key]
      : null;
    return (
      <div key={resource.id} className={styles.resourceContainer}>
        <ResourceEntry
          resource={resource}
          thumbnailSrc={thumbnailSrc}
          isSelected={false}
          isSharedRoomRow
          displayOnly
        />
      </div>
    );
  };

  useEffect(() => {
    const asyncFun = async () => {
      if (!isSelected) {
        return;
      }

      const keysToGet = sharedRoom?.sharedRoomItems
        ?.map(
          (sharedRoomItem: any) => sharedRoomItem.resource.thumbnail_file_key
        )
        ?.filter(
          (key: any) =>
            typeof key === "string" && !!key && key !== THUMBNAIL_ERROR_KEY
        );

      if (keysToGet.length > 0) {
        const thumbnailImageUrls = await getFileUrls(keysToGet);
        setThumbnailSrcs(thumbnailImageUrls);
      }
    };
    asyncFun().catch(logUnexpectedError);
  }, [isSelected]);

  return (
    <>
      <div
        className={clsx(styles.container, {
          [styles.selected]: isSelected,
        })}
        onClick={onClick}
      >
        <Tooltip text={sharedRoom.name}>
          <span className={styles.text}>{sharedRoom.name}</span>
        </Tooltip>
        <span
          className={clsx(styles.iconContainer, {
            [styles.iconContainerSelected]: isSelected,
          })}
        >
          <Tooltip text="Number of times this room has been used during a session">
            <span className={styles.description}>{uses}</span>
          </Tooltip>
          <span className={styles.description}>{lastUpdatedDate}</span>
          <img
            className={styles.dropIcon}
            src={isSelected ? arrowDropUpIcon : arrowDropDownIcon}
          />
          <Tooltip
            text={`Shared by ${sharedRoom.sourceOwner.first_name} ${sharedRoom.sourceOwner.last_name}`}
            elementWrapperClassName={styles.initialsIconWrapper}
          >
            <div className={styles.circle}>
              <span className={styles.initialsIcon}>{initials}</span>
            </div>
          </Tooltip>
          <Tooltip
            text={isCopyComplete ? "Copy Complete" : "Copy Room"}
            elementWrapperClassName={clsx(styles.copyIcon, {
              [styles.copyIconExtraMargin]: !hasDelete,
            })}
          >
            {isCopyComplete ? (
              <img className={styles.rowIcon} src={checkIcon} />
            ) : (
              <img
                className={styles.rowIcon}
                src={copyIcon}
                onClick={handleCopySharedRoom}
              />
            )}
          </Tooltip>
          {hasDelete && (
            <img
              className={styles.rowIcon}
              src={deleteIcon}
              onClick={showDeleteSharedRoomModal}
            />
          )}
        </span>
      </div>
      {isSelected && (
        <div className={styles.sharedRoomItemsContainer}>
          {sharedRoom?.sharedRoomItems?.map(sharedRoomItemToComponent)}
        </div>
      )}
    </>
  );
};

export default SharedRoomRow;

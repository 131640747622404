import React, { useEffect } from "react";

import {
  selectBrowserSandboxInitialize,
  selectBrowserSandboxUrl,
  selectCurrentPage,
  selectDeleteRoomModalRoom,
  selectMeetingID,
  selectSharedRoomFolderModalFolderId,
  selectShowInviteModal,
  selectShowOpenRoomModal,
  selectShowSettingsModal,
  selectShareRoomModalRoomId,
  selectShowSourceModal,
  selectShowTimeoutWarningModal,
  selectWhiteboardBackground,
  SpacePage,
  selectDeleteSharedRoomModalSharedRoom,
} from "redux/spaceNavigationRedux";
import {
  selectDeleteResourceModalData,
  selectEditRoomMode,
  selectSharePubliclyModalData,
  selectShowAddResourceModal,
  selectShowChangeIconModal,
  selectShowDeleteItemModal,
  selectSuggestEditModalData,
} from "redux/editRoomNavigationRedux";

import { useSelector } from "react-redux";
import SpaceRoom from "./subpages/SpaceRoom/SpaceRoom";
import Whiteboard from "./subpages/Whiteboard/Whiteboard";
import BrowserSandbox from "./subpages/BrowserSandbox/BrowserSandbox";

import styles from "./JoinedSpace.module.css";
import SourceModal from "./components/SourceModal/SourceModal";
import { useAutomaticLogout } from "./hooks/useAutomaticLogout";
import TimeoutWarningModal from "./components/TimeoutWarningModal/TimeoutWarningModal";
import InviteModal from "./components/InviteModal/InviteModal";
import SettingsModal from "./components/SettingsModal/SettingsModal";
import { RemoteSetters } from "./components/ControlBar/useRemoteSettings";
import { useAppSignOut } from "utils/appSignOutUtils";
import {
  useGetRoomQuery,
  useSetClientCanonicalIdToRawSessionMutation,
  useUpdateLastActiveTimeMutation,
} from "generated/graphql";
import { selectUserId, selectUserRole, UserRole } from "redux/userRedux";
import ControlBar from "./components/ControlBar/ControlBar";
import OpenRoomModal from "./components/ControlBar/OpenRoomModal/OpenRoomModal";
import AddResourceModal from "./components/AddResourceModal/AddResourceModal";
import { useNavigateListener } from "./hooks/useNavigateListener";
import { useLogResourceUsage } from "pages/Space/subpages/SpaceRoom/hooks/useLogResourceUsage";
import SuggestEditModal from "./components/SuggestEditModal/SuggestEditModal";
import SharePubliclyModal from "./components/SharePubliclyModal/SharePubliclyModal";
import DeleteItemModal from "./components/DeleteItemModal/DeleteItemModal";
import ChangeIconModal from "./components/ChangeIconModal/ChangeIconModal";
import { usePendo } from "utils/pendoUtils";
import DeleteResourceModal from "./components/DeleteResourceModal/DeleteResourceModal";
import DeleteRoomModal from "./components/DeleteRoomModal/DeleteRoomModal";
import { Videos } from "./components/Videos/Videos";
import { Alerts } from "./components/Alerts/Alerts";
import {
  selectClientFileOpen,
  selectCurrentClient,
} from "redux/clientManagementRedux";
import ClientManagementModal from "./components/ClientManagement/ClientManagementModal";
import SnapshotDetailsModal from "./components/ClientFile/SnapshotDetailsModal";
import { useGetSessionId } from "./hooks/useSnapshot";
import { logUnexpectedError } from "utils/errorUtils";
import SideBar from "./subpages/SpaceRoom/components/SideBar/SideBar";
import Album from "./subpages/Album/Album";
import { Peers } from "./hooks/connection/usePeerWebRTCConnection";
import MediaPlayer from "./subpages/MediaPlayer/MediaPlayer";
import ShareRoomModal from "./components/ShareRoomModal/ShareRoomModal";
import SharedRoomFolderModal from "./components/SharedRoomFolderModal/SharedRoomFolderModal";
import DeleteSharedRoomModal from "./components/DeleteSharedRoomModal/DeleteSharedRoomModal";
import { useIncrementSharedRoomUsage } from "./subpages/SpaceRoom/hooks/useIncrementSharedRoomUsage";

type JoinedSpaceProps = {
  peersRef: React.MutableRefObject<Peers>;
  peers: Peers;
  localMediaStream: MediaStream | undefined;
  userMediaError: string | undefined;
  reloadUserMedia: () => void;
  loadingUserMedia: boolean;
  isConnectedToPeer: boolean;
  remoteDataChannelRef: React.MutableRefObject<RTCDataChannel | undefined>;
  remoteMediaStream: MediaStream;
  endSession: () => void;
  leave: (message?: string) => void;
  signalActivity: () => void;
  getRemoteVideoBytesReceived: () => Promise<number>;
  remoteSetters: RemoteSetters;
};

const JoinedSpace = ({
  peers,
  peersRef,
  localMediaStream,
  userMediaError,
  reloadUserMedia,
  loadingUserMedia,
  isConnectedToPeer,
  remoteDataChannelRef,
  remoteMediaStream,
  endSession,
  leave,
  signalActivity,
  getRemoteVideoBytesReceived,
  remoteSetters,
}: JoinedSpaceProps) => {
  const userId = useSelector(selectUserId);
  const appSignOut = useAppSignOut();
  usePendo(true);
  const [updateLastActivityTime] = useUpdateLastActiveTimeMutation();
  const signOut = () => {
    leave("Session timed out. Disconnected.");
    // TODO: Show the 'session timed out' error message. Since we need to sign out of AWS, which has to redirect to
    // a page, we need to show the error on the redirected page.
    appSignOut();
  };
  const onActivity = () => {
    signalActivity();
    if (userId) {
      updateLastActivityTime({
        variables: {
          userId,
          activeTimestamp: new Date().toISOString(),
        },
      }).catch(logUnexpectedError);
    }
  };
  useAutomaticLogout(signOut, onActivity);

  const page = useSelector(selectCurrentPage);
  const browserSandboxUrl = useSelector(selectBrowserSandboxUrl);
  const browserSandboxInitialize = useSelector(selectBrowserSandboxInitialize);
  const whiteboardBackground = useSelector(selectWhiteboardBackground);
  const showSourceModal = useSelector(selectShowSourceModal);
  const showInviteModal = useSelector(selectShowInviteModal);
  const showSettingsModal = useSelector(selectShowSettingsModal);
  const showTimeoutWarningModal = useSelector(selectShowTimeoutWarningModal);
  const showOpenRoomModal = useSelector(selectShowOpenRoomModal);
  const shareRoomModalRoomId = useSelector(selectShareRoomModalRoomId);
  const showAddResourceModal = useSelector(selectShowAddResourceModal);
  const showDeleteItemModal = useSelector(selectShowDeleteItemModal);
  const deleteResourceModalData = useSelector(selectDeleteResourceModalData);
  const showChangeIconModal = useSelector(selectShowChangeIconModal);
  const suggestEditModalData = useSelector(selectSuggestEditModalData);
  const sharePubliclyModalData = useSelector(selectSharePubliclyModalData);
  const deleteRoomModalRoom = useSelector(selectDeleteRoomModalRoom);
  const sharedRoomFolderModalFolderId = useSelector(
    selectSharedRoomFolderModalFolderId
  );
  const deleteSharedRoomModalSharedRoom = useSelector(
    selectDeleteSharedRoomModalSharedRoom
  );
  const currentClient = useSelector(selectCurrentClient);
  const editRoomMode = useSelector(selectEditRoomMode);
  const isClientFileOpen = useSelector(selectClientFileOpen);
  const userRole = useSelector(selectUserRole);

  const [setClientCanonicalIdToRawSession] =
    useSetClientCanonicalIdToRawSessionMutation();

  const getSessionId = useGetSessionId();
  useEffect(() => {
    if (userRole === UserRole.THERAPIST && isConnectedToPeer && currentClient) {
      (async () => {
        const sessionId = await getSessionId();
        if (sessionId) {
          const result = await setClientCanonicalIdToRawSession({
            variables: {
              rawSessionId: sessionId,
              clientCanonicalId: currentClient.canonical_id,
            },
          });
          if (
            result.errors ||
            !result.data?.update_user_session_raw_by_pk?.id
          ) {
            logUnexpectedError("Unexpectedly failed to set raw session id");
          }
        } else {
          logUnexpectedError("Unexpectedly failed to get raw session id");
        }
      })();
    }
  }, [isConnectedToPeer, currentClient]);

  useLogResourceUsage();
  useIncrementSharedRoomUsage();
  useNavigateListener(peers);

  const getContent = () => {
    if (page === SpacePage.ROOM) {
      return {
        element: (
          <SpaceRoom
            peersRef={peersRef}
            peers={peers}
            setRemoteEditRoomMode={remoteSetters.setRemoteEditRoomMode}
          />
        ),
        showEditRoom: true,
        showSnapshot: false,
      };
    }

    if (page === SpacePage.WHITEBOARD) {
      return {
        element: (
          <Whiteboard
            peersRef={peersRef}
            peers={peers}
            backgroundImageKey={whiteboardBackground}
            isConnectedToPeer={isConnectedToPeer}
          />
        ),
        showSnapshot: true,
      };
    }

    if (page === SpacePage.MEDIA_PLAYER && browserSandboxUrl) {
      return {
        element: (
          <MediaPlayer
            url={browserSandboxUrl}
            peersRef={peersRef}
            peers={peers}
          />
        ),
        showSnapshot: false,
      };
    }

    if (page === SpacePage.BROWSER_SANDBOX && browserSandboxUrl) {
      return {
        element: (
          <BrowserSandbox
            url={browserSandboxUrl}
            initialize={browserSandboxInitialize || false}
            remoteDataChannelRef={remoteDataChannelRef}
            remoteMediaStream={remoteMediaStream}
            peersRef={peersRef}
            getRemoteVideoBytesReceived={getRemoteVideoBytesReceived}
          />
        ),
        showSnapshot: true,
      };
    }

    if (page === SpacePage.ALBUM) {
      return {
        element: (
          <Album
            peersRef={peersRef}
            peers={peers}
            isConnectedToPeer={isConnectedToPeer}
          />
        ),
        showSnapshot: false,
      };
    }

    return null;
  };

  const content = getContent();

  const meetingID = useSelector(selectMeetingID);

  const { data, loading: loadingRoomItemsQuery } = useGetRoomQuery({
    variables: {
      meetingID: meetingID || "",
      isProvider: userRole === UserRole.THERAPIST,
    },
    skip: !meetingID,
    notifyOnNetworkStatusChange: true,
  });

  const noCurrentRoom =
    !loadingRoomItemsQuery && !data?.meeting[0]?.provider?.current_room;

  return (
    <div className={styles.container}>
      <div className={styles.activityContainer}>
        {content?.element}
        {((editRoomMode && !noCurrentRoom) || isClientFileOpen) &&
        userRole === UserRole.THERAPIST ? (
          <SideBar />
        ) : null}
        <Videos
          peers={peers}
          localStream={localMediaStream}
          userMediaError={userMediaError}
          reloadUserMedia={reloadUserMedia}
          loadingUserMedia={loadingUserMedia}
          setRemoteFullScreenVideoConferencing={
            remoteSetters.setRemoteFullScreenVideoConferencing
          }
          setRemoteIsPeerMuted={remoteSetters.setRemoteIsPeerMuted}
          setRemoteIsPeerVideoOff={remoteSetters.setRemoteIsPeerVideoOff}
          setRemotePeerIdWithControl={remoteSetters.setRemotePeerIdWithControl}
        />
        <Alerts peersRef={peersRef} />
      </div>
      <ControlBar
        isConnectedToPeer={isConnectedToPeer}
        endSession={endSession}
        leave={leave}
        setRemoteClientControl={remoteSetters.setRemoteClientControl}
        showEditRoom={content?.showEditRoom}
        showSnapshot={content?.showSnapshot}
        setRemoteEditRoomMode={remoteSetters.setRemoteEditRoomMode}
        sendRemoteRoomChanged={remoteSetters.sendRemoteRoomChanged}
        peersRef={peersRef}
      />
      {showSourceModal ? (
        <SourceModal
          externalUrl={
            page === SpacePage.BROWSER_SANDBOX ||
            page === SpacePage.MEDIA_PLAYER
              ? browserSandboxUrl
              : undefined
          }
        />
      ) : null}
      {showSettingsModal ? (
        <SettingsModal
          setRemoteAudioProviderOnly={remoteSetters.setRemoteAudioProviderOnly}
          setRemoteVideoConferencing={remoteSetters.setRemoteVideoConferencing}
          peersRef={peersRef}
          setRemoteEditRoomMode={remoteSetters.setRemoteEditRoomMode}
        />
      ) : null}
      {showInviteModal ? <InviteModal /> : null}
      {showTimeoutWarningModal ? <TimeoutWarningModal /> : null}
      {showOpenRoomModal ? (
        <OpenRoomModal
          sendRemoteRoomChanged={remoteSetters.sendRemoteRoomChanged}
        />
      ) : null}
      {sharedRoomFolderModalFolderId ? (
        <SharedRoomFolderModal
          sendRemoteRoomChanged={remoteSetters.sendRemoteRoomChanged}
        />
      ) : null}
      {shareRoomModalRoomId ? <ShareRoomModal /> : null}
      {showAddResourceModal ? <AddResourceModal /> : null}
      {showDeleteItemModal ? <DeleteItemModal /> : null}
      {deleteResourceModalData ? <DeleteResourceModal /> : null}
      {showChangeIconModal ? <ChangeIconModal /> : null}
      {suggestEditModalData ? <SuggestEditModal /> : null}
      {sharePubliclyModalData ? <SharePubliclyModal /> : null}
      {deleteRoomModalRoom ? (
        <DeleteRoomModal
          sendRemoteRoomChanged={remoteSetters.sendRemoteRoomChanged}
        />
      ) : null}
      {deleteSharedRoomModalSharedRoom ? <DeleteSharedRoomModal /> : null}
      <ClientManagementModal />
      <SnapshotDetailsModal />
    </div>
  );
};

export default JoinedSpace;

import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as VideoOffIcon } from "assets/icons/video_off.svg";
import { VideoButton } from "pages/Space/components/Videos/VideosButtons/VideoButton";
import {
  selectIsPeerVideoOff,
  selectIsVideoOff,
  setIsPeerVideoOff,
  setIsVideoOff,
} from "redux/settingsRedux";
import { getLocalPeerId } from "pages/Space/hooks/connection/usePeerWebRTCConnection";

type DisableClientVideoButtonProps = {
  setRemoteIsPeerVideoOff: (isPeerVideoOff: boolean, peerId?: string) => void;
  peerId?: string;
  className: string;
  style?: React.CSSProperties;
};

export const DisableClientVideoButton = ({
  setRemoteIsPeerVideoOff,
  peerId,
  className,
  style,
}: DisableClientVideoButtonProps) => {
  const dispatch = useDispatch();
  const isPeerVideoOff = useSelector((state) =>
    selectIsPeerVideoOff(state, peerId ?? "")
  );
  const isLocalVideoOff = useSelector(selectIsVideoOff);

  const isVideoOff = peerId === undefined ? isLocalVideoOff : isPeerVideoOff;

  const toggleVideoOff = () => {
    if (peerId === undefined) {
      dispatch(setIsVideoOff(!isVideoOff));
      setRemoteIsPeerVideoOff(!isVideoOff, getLocalPeerId());
    } else {
      dispatch(setIsPeerVideoOff({ peerId, isVideoOff: !isVideoOff }));
      setRemoteIsPeerVideoOff(!isVideoOff, peerId);
    }
  };

  return (
    <VideoButton
      className={className}
      style={style}
      onClick={toggleVideoOff}
      tooltipText={isVideoOff ? "Turn on camera" : "Turn off camera"}
      forceVisible={false}
      icon={VideoOffIcon}
      isIndicator={!!isVideoOff}
    />
  );
};

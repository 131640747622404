import React, { ChangeEvent, useEffect, useRef } from "react";
import styles from "./Slider.module.css";
import { COLORS } from "teleoConstants";

const SLIDER_COLOR = COLORS.MID_GRAY;
const RANGE_COLOR = COLORS.DARK;

type SliderProps = {
  min: number;
  max: number;
  fromValue: number;
  toValue: number;
  setFrom: (from: number) => void;
  setTo: (from: number) => void;
};

const Slider = ({
  min,
  max,
  fromValue,
  toValue,
  setFrom,
  setTo,
}: SliderProps) => {
  const toInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!toInputRef.current) {
      return;
    }
    const rangeDistance = max - min;
    const fromPosition = fromValue - min;
    const toPosition = toValue - min;
    toInputRef.current.style.background = `linear-gradient(
      to right,
      ${SLIDER_COLOR} 0%,
      ${SLIDER_COLOR} ${(fromPosition / rangeDistance) * 100}%,
      ${RANGE_COLOR} ${(fromPosition / rangeDistance) * 100}%,
      ${RANGE_COLOR} ${(toPosition / rangeDistance) * 100}%, 
      ${SLIDER_COLOR} ${(toPosition / rangeDistance) * 100}%, 
      ${SLIDER_COLOR} 100%)`;
  }, [fromValue, toValue]);

  const onFromChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newFrom = parseInt(event.target.value);
    if (!isNaN(newFrom) && newFrom <= toValue) {
      setFrom(newFrom);
    }
  };

  const onToChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newTo = parseInt(event.target.value);
    if (!isNaN(newTo) && newTo >= fromValue) {
      setTo(newTo);
    }
  };

  return (
    <div className={styles.container}>
      <input
        className={styles.fromInput}
        type="range"
        value={fromValue}
        min={min}
        max={max}
        onChange={onFromChange}
      />
      <input
        ref={toInputRef}
        className={styles.toInput}
        type="range"
        value={toValue}
        min={min}
        max={max}
        onChange={onToChange}
      />
    </div>
  );
};

export default Slider;

import React, { KeyboardEventHandler, useRef, useState } from "react";
import styles from "./DropDown.module.css";
import clsx from "clsx";
import arrowDropDownIcon from "assets/icons/arrow_drop_down.svg";
import arrowDropUpIcon from "assets/icons/arrow_drop_up.svg";

type DropDownOption = {
  value: string;
  label: string;
};

type DropDownProps = {
  options: DropDownOption[];
  value: string;
  setValue: (value: string) => void;
  placeholder?: string;
  className?: string;
  labelIcon?: string;
};

// TODO: Use aria activedescendant instead of focus for LI focus.
// TODO: Use ID's and add aria reference between elements
const DropDown = ({
  options,
  value,
  setValue,
  placeholder,
  className,
  labelIcon,
}: DropDownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropDownRef = useRef<HTMLDivElement>(null);
  const listRefs = useRef<(HTMLLIElement | null)[]>([]);

  const optionsWithPlaceholder = [...options];
  if (placeholder) {
    optionsWithPlaceholder.unshift({ value: "", label: placeholder });
  }
  const selectedLabel = optionsWithPlaceholder.find(
    (opt) => opt.value === value
  )?.label;

  const handleDropDownClick = () => {
    isOpen ? close(true) : open(false);
  };

  const handleDropDownKeyDown: KeyboardEventHandler<HTMLElement> = (e) => {
    switch (e.key) {
      case "Enter":
      case " ":
        open(true);
        break;
      default:
        break;
    }
  };

  const open = (moveFocus: boolean) => {
    setIsOpen(true);
    if (moveFocus) {
      listRefs.current[0]?.focus();
    }
  };

  const close = (forceFocus: boolean = false) => {
    setIsOpen(false);
    if (
      forceFocus ||
      listRefs.current.includes(document.activeElement as HTMLLIElement | null)
    ) {
      dropDownRef.current?.focus();
    }
  };

  const selectValue = (value: string) => {
    setIsOpen(false);
    setValue(value);
    dropDownRef.current?.focus();
  };

  const handleBlur = (e: React.FocusEvent) => {
    if (
      isOpen &&
      e.relatedTarget !== dropDownRef.current &&
      !listRefs.current.includes(e.relatedTarget as HTMLLIElement | null)
    ) {
      close(false);
    }
  };

  const handleLIKeyDown: (
    value: string
  ) => React.KeyboardEventHandler<HTMLLIElement> = (value) => (e) => {
    switch (e.key) {
      case "Enter":
      case " ":
        selectValue(value);
        break;
      case "ArrowUp":
        (e.currentTarget.previousSibling as HTMLElement)?.focus();
        break;
      case "ArrowDown":
        (e.currentTarget.nextSibling as HTMLElement)?.focus();
        break;
      case "Escape":
        close(true);
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={clsx(styles.dropDown, className, {
          [styles.placeholder]: value === "",
        })}
        role="combobox"
        tabIndex={0}
        onClick={handleDropDownClick}
        onKeyDown={handleDropDownKeyDown}
        onBlur={handleBlur}
        ref={dropDownRef}
      >
        <span className={styles.iconLabelContainer}>
          {labelIcon && <img className={styles.icon} src={labelIcon} />}
          {selectedLabel}
        </span>
        <div className={styles.arrow}>
          <img src={isOpen ? arrowDropUpIcon : arrowDropDownIcon} />
        </div>
      </div>
      <ul className={clsx({ [styles.open]: isOpen })} role="listbox">
        {optionsWithPlaceholder.map((opt, i) => (
          <li
            key={opt.value}
            onClick={() => selectValue(opt.value)}
            className={clsx({ [styles.placeholder]: opt.value === "" })}
            ref={(ref) => {
              listRefs.current[i] = ref;
            }}
            onBlur={handleBlur}
            onKeyDown={handleLIKeyDown(opt.value)}
            tabIndex={isOpen ? 0 : -1}
            role="option"
          >
            {opt.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DropDown;

import React, { MouseEventHandler, useEffect } from "react";

import styles from "./Modal.module.css";
import { ReactComponent as XIcon } from "assets/icons/x.svg";
import clsx from "clsx";

type ModalProps = {
  closeModal?: () => void;
  className?: string;
  children?: React.ReactNode;
};

const Modal = ({ closeModal, className, children }: ModalProps) => {
  const mouseDownElement = React.useRef<HTMLElement | null>(null);
  const stopPropagation: MouseEventHandler<HTMLDivElement> = (ev) => {
    ev.stopPropagation();
  };

  useEffect(() => {
    const mousedown = (ev: MouseEvent) => {
      mouseDownElement.current = ev.target as HTMLElement;
    };
    document.addEventListener("pointerdown", mousedown);

    return () => {
      document.removeEventListener("pointerdown", mousedown);
    };
  }, []);

  const handleOverlayClick: MouseEventHandler<HTMLDivElement> = (event) => {
    if (event.target === mouseDownElement.current) {
      if (closeModal) {
        closeModal();
      }
    }
  };

  return (
    <div
      className={clsx(styles.overlay, className)}
      onClick={handleOverlayClick}
    >
      <div className={styles.modal} onClick={stopPropagation}>
        {closeModal && (
          <span className={styles.close}>
            <XIcon className={styles.xIcon} onClick={closeModal} />
          </span>
        )}
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;

import { useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectClientFileOpen } from "redux/clientManagementRedux";
import { calculateFitDimensions } from "utils/sizingUtils";

export const useResizeAlbum = ({
  albumContainerRef,
}: {
  albumContainerRef: React.RefObject<HTMLDivElement>;
}) => {
  const isClientFileOpen = useSelector(selectClientFileOpen);
  const [albumSize, setAlbumSize] = useState<{
    height?: number;
    width?: number;
  }>({});

  const resizeAlbum = () => {
    const parentHeight = albumContainerRef.current?.offsetHeight;
    const parentWidth = albumContainerRef.current?.offsetWidth;
    const rawHeight = 799;
    const rawWidth = 1734;
    if (!parentHeight || !parentWidth || !rawHeight || !rawWidth) {
      return;
    }
    const { fitHeight, fitWidth } = calculateFitDimensions(
      parentHeight,
      parentWidth,
      rawHeight,
      rawWidth,
      null
    );
    setAlbumSize({ height: fitHeight, width: fitWidth });
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", resizeAlbum);

    return () => {
      window.removeEventListener("resize", resizeAlbum);
    };
  }, []);

  useEffect(() => {
    resizeAlbum();
  }, [isClientFileOpen]);

  return albumSize;
};

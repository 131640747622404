import React from "react";
import { useDispatch } from "react-redux";

import styles from "./WaitingRoomAlert.module.css";
import { removeWaitingRoomAlertClient } from "redux/clientManagementRedux";
import { useWaitingRoomAdmitHandler } from "utils/waitingRoomUtils";
import { logUnexpectedError } from "utils/errorUtils";
import { Peers } from "pages/Space/hooks/connection/usePeerWebRTCConnection";
import { Alert } from "./Alert";

type WaitingRoomAlertProps = {
  secretClientId: string;
  name: string;
  peersRef: React.MutableRefObject<Peers>;
};

export const WaitingRoomAlert = ({
  secretClientId,
  name,
  peersRef,
}: WaitingRoomAlertProps) => {
  const dispatch = useDispatch();

  const dismiss = () => {
    // Remove from alert list (but keep in waiting room menu)
    dispatch(removeWaitingRoomAlertClient(secretClientId));
  };

  const admit = useWaitingRoomAdmitHandler({ secretClientId, name, peersRef });

  const admitClickHandler = async () => {
    try {
      await admit();
      dispatch(removeWaitingRoomAlertClient(secretClientId));
    } catch (e) {
      logUnexpectedError(e);
    }
  };

  return (
    <Alert
      actionCTAText="Admit"
      actionHandler={admitClickHandler}
      dismissHandler={dismiss}
    >
      <div className={styles.nameText}>{name}</div>
      <div className={styles.text}>{"has entered your waiting room."}</div>
    </Alert>
  );
};

import React, { MouseEventHandler, useEffect } from "react";

import { ReactComponent as SettingsCameraIcon } from "assets/icons/settings_camera.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { VideoButton } from "./VideoButton";
import styles from "./MediaMenuButton.module.css";
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
} from "@floating-ui/react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import {
  selectAudioDevices,
  selectIsNoiseSuppressionEnabled,
  selectSelectedAudioDevice,
  selectSelectedVideoDevice,
  selectVideoDevices,
  setIsNoiseSuppressionEnabled,
  setSelectedAudioDevice,
  setSelectedVideoDevice,
} from "redux/settingsRedux";
import { useLogMediaEvent } from "utils/metricsUtils";

type MediaMenuButtonProps = {
  className: string;
  style?: React.CSSProperties;
};

export const MediaMenuButton = ({ className, style }: MediaMenuButtonProps) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const audioDevices = useSelector(selectAudioDevices);
  const videoDevices = useSelector(selectVideoDevices);

  const selectedAudioDevice = useSelector(selectSelectedAudioDevice);
  const selectedVideoDevice = useSelector(selectSelectedVideoDevice);
  const isNoiseSuppressionEnabled = useSelector(
    selectIsNoiseSuppressionEnabled
  );

  const dispatch = useDispatch();

  const logMediaEvent = useLogMediaEvent();

  const { refs, floatingStyles } = useFloating({
    open: isMenuOpen,
    placement: "right-start",
    strategy: "fixed",
    whileElementsMounted: autoUpdate,
    middleware: [offset({ mainAxis: 5, crossAxis: 0 }), flip(), shift()],
  });

  const toggleMenu: MouseEventHandler = (event) => {
    setIsMenuOpen(!isMenuOpen);
    event.stopPropagation();
  };

  const stopProppagation: MouseEventHandler = (event) => {
    event.stopPropagation();
  };

  const toggleNoiseSupression = () => {
    const newValue = !(isNoiseSuppressionEnabled ?? true);
    dispatch(setIsNoiseSuppressionEnabled(newValue));
    logMediaEvent(
      newValue ? "ENABLE_NOISE_SUPPRESSION" : "DISABLE_NOISE_SUPPRESSION"
    );
  };

  const selectAudioDevice = (device: MediaDeviceInfo) => {
    dispatch(setSelectedAudioDevice(device));
    logMediaEvent("SELECT_AUDIO_INPUT");
  };

  const selectVideoDevice = (device: MediaDeviceInfo) => {
    dispatch(setSelectedVideoDevice(device));
    logMediaEvent("SELECT_VIDEO_INPUT");
  };

  useEffect(() => {
    const globalClickHandler = (event: MouseEvent) => {
      if (!refs.floating.current?.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    };
    window.addEventListener("click", globalClickHandler);
    return () => {
      window.removeEventListener("click", globalClickHandler);
    };
  }, []);

  return (
    <div className={className}>
      <VideoButton
        id="MediaMenuButton"
        style={style}
        onClick={toggleMenu}
        tooltipText={"Options"}
        forceVisible={isMenuOpen}
        icon={SettingsCameraIcon}
        ref={refs.setReference}
      />
      {isMenuOpen && (
        <div
          className={styles.menuContainer}
          ref={refs.setFloating}
          style={floatingStyles}
          onClick={stopProppagation}
          onPointerDown={stopProppagation}
          onPointerUp={stopProppagation}
        >
          <div className={styles.sectionHeader}>Select a Microphone</div>
          {audioDevices?.length > 0 ? (
            audioDevices.map((device, index) => (
              <div
                className={styles.menuItem}
                key={device.deviceId}
                onClick={() => selectAudioDevice(device)}
              >
                <span className={styles.checkmark}>
                  {((!selectedAudioDevice && index === 0) ||
                    selectedAudioDevice?.deviceId === device.deviceId) && (
                    <CheckIcon />
                  )}
                </span>
                <div className={styles.menuItemLabel}>{device.label}</div>
              </div>
            ))
          ) : (
            <div className={clsx(styles.menuItem, styles.disabled)}>
              No Microphone device Found
            </div>
          )}
          <hr className={styles.sectionSeparator} />
          <div className={styles.sectionHeader}>Select a Camera</div>
          {videoDevices?.length > 0 ? (
            videoDevices.map((device, index) => (
              <div
                className={styles.menuItem}
                key={device.deviceId}
                onClick={() => selectVideoDevice(device)}
              >
                <span className={styles.checkmark}>
                  {((!selectedVideoDevice && index === 0) ||
                    selectedVideoDevice?.deviceId === device.deviceId) && (
                    <CheckIcon />
                  )}
                </span>
                <div className={styles.menuItemLabel}>{device.label}</div>
              </div>
            ))
          ) : (
            <div className={clsx(styles.menuItem, styles.disabled)}>
              No Camera device Found
            </div>
          )}
          <hr className={styles.sectionSeparator} />
          <div className={styles.menuItem} onClick={toggleNoiseSupression}>
            <span className={styles.checkmark}>
              {isNoiseSuppressionEnabled ?? true ? <CheckIcon /> : null}
            </span>
            <div className={styles.menuItemLabel}>Noise Suppression</div>
          </div>
        </div>
      )}
    </div>
  );
};

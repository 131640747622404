import React from "react";
import styles from "./Button.module.css";
import clsx from "clsx";

export type ButtonProps = {
  onClick?: () => void;
  href?: string;
  className?: string;
  disabled?: boolean;
  id?: string;
  secondary?: boolean;
  children: React.ReactNode;
};

const Button = ({
  onClick,
  href,
  className,
  disabled,
  id,
  secondary,
  children,
}: ButtonProps) => {
  const isExternalLink = !!href?.startsWith("http");
  if (onClick) {
    return (
      <button
        id={id}
        onClick={onClick}
        className={clsx(
          styles.button,
          { [styles.disabled]: disabled, [styles.secondary]: secondary },
          className
        )}
        disabled={disabled}
      >
        {children}
      </button>
    );
  } else if (href) {
    return (
      <a
        id={id}
        href={!disabled ? href : undefined}
        target={isExternalLink ? "_blank" : undefined}
        rel={isExternalLink ? "noopener noreferrer" : undefined}
        className={clsx(
          styles.button,
          styles.link,
          { [styles.disabled]: disabled, [styles.secondary]: secondary },
          className
        )}
      >
        {children}
      </a>
    );
  } else {
    return <></>;
  }
};

export default Button;

import React, { useState } from "react";

import styles from "./ResourceUsageSection.module.css";
import AdminPageButton from "./AdminPageButton";
import { backendRequest } from "utils/backendRequest";

type SearchUsageData = {
  byFilter: {
    filterType: string;
    value: string;
    distinctCount: number;
    count: number;
  }[];
  searchOnly: {
    value: string;
    distinctCount: number;
    count: number;
  }[];
  byType: {
    filterType: string;
    distinctCount: number;
    count: number;
  }[];
  combined: {
    value: string;
    distinctCount: number;
    count: number;
  }[];
};

const SearchUsageSection = () => {
  const [data, setData] = useState<SearchUsageData>();
  const [loading, setLoading] = useState(false);

  const fetchData = async (byTotal: boolean) => {
    try {
      setLoading(true);
      const result = await backendRequest({
        path: "/stats-search-usage",
        searchParams: { byTotal: byTotal.toString() },
      });

      const data = await result.json();
      setData(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setData(undefined);
      setLoading(false);
    }
  };

  const getByFilterRows = () => {
    if (loading) {
      return (
        <tr>
          <td>Loading</td>
        </tr>
      );
    }
    if (!data) {
      return (
        <tr>
          <td>No data</td>
        </tr>
      );
    }
    return data?.byFilter.map(
      ({ filterType, value, distinctCount, count }, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{filterType}</td>
          <td>{value}</td>
          <td>{distinctCount}</td>
          <td>{count}</td>
        </tr>
      )
    );
  };

  const getSearchRows = () => {
    if (loading) {
      return (
        <tr>
          <td>Loading</td>
        </tr>
      );
    }
    if (!data) {
      return (
        <tr>
          <td>No data</td>
        </tr>
      );
    }
    return data?.searchOnly.map(({ value, distinctCount, count }, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{value}</td>
        <td>{distinctCount}</td>
        <td>{count}</td>
      </tr>
    ));
  };

  const getByTypeRows = () => {
    if (loading) {
      return (
        <tr>
          <td>Loading</td>
        </tr>
      );
    }
    if (!data) {
      return (
        <tr>
          <td>No data</td>
        </tr>
      );
    }
    return data?.byType.map(({ filterType, distinctCount, count }, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{filterType}</td>
        <td>{distinctCount}</td>
        <td>{count}</td>
      </tr>
    ));
  };

  const getCombinedRows = () => {
    if (loading) {
      return (
        <tr>
          <td>Loading</td>
        </tr>
      );
    }
    if (!data) {
      return (
        <tr>
          <td>No data</td>
        </tr>
      );
    }
    return data?.combined?.map(({ value, distinctCount, count }, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{value}</td>
        <td>{distinctCount}</td>
        <td>{count}</td>
      </tr>
    ));
  };

  return (
    <div className={styles.container}>
      <h3>Search Usage Data</h3>
      <AdminPageButton onClick={() => fetchData(false)}>
        Load Search Usage Data, Sorted by Unique Users
      </AdminPageButton>
      <AdminPageButton onClick={() => fetchData(true)}>
        Load Search Usage Data, Sorted by Total Usage
      </AdminPageButton>
      <h4>By Type</h4>
      <table>
        <tbody>
          <tr key={"header"}>
            <th>Rank</th>
            <th>Filter Type</th>
            <th>Unique Users Used</th>
            <th>Total Times Used</th>
          </tr>
          {getByTypeRows()}
        </tbody>
      </table>
      <h4>By Filter</h4>
      <table>
        <tbody>
          <tr key={"header"}>
            <th>Rank</th>
            <th>Filter Type</th>
            <th>Value</th>
            <th>Unique Users Used</th>
            <th>Total Times Used</th>
          </tr>
          {getByFilterRows()}
        </tbody>
      </table>
      <h4>Text Search Only</h4>
      <table>
        <tbody>
          <tr key={"header"}>
            <th>Rank</th>
            <th>Value</th>
            <th>Unique Users Used</th>
            <th>Total Times Used</th>
          </tr>
          {getSearchRows()}
        </tbody>
      </table>
      <h4>Combined Filters</h4>
      <table>
        <tbody>
          <tr key={"header"}>
            <th>Rank</th>
            <th>Value</th>
            <th>Unique Users Used</th>
            <th>Total Times Used</th>
          </tr>
          {getCombinedRows()}
        </tbody>
      </table>
    </div>
  );
};

export default SearchUsageSection;

// @ts-ignore
import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf";

// copying implementation from "pdfjs-dist/webpack", but with the legacy worker
if (typeof window !== "undefined" && "Worker" in window) {
  pdfjsLib.GlobalWorkerOptions.workerPort = new Worker(
    new URL("pdfjs-dist/legacy/build/pdf.worker.js", import.meta.url),
    { type: "module" }
  );
}

import { Buffer } from "buffer";

const Base64Prefix = "data:application/pdf;base64,";

function readBlob(blob: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result));
    reader.addEventListener("error", reject);
    reader.readAsDataURL(blob);
  });
}

async function readFile(file: any) {
  return await fetch(file);
}

export async function loadPdfPageCanvas(pdfData: any) {
  pdfData = await readBlob(await (await readFile(pdfData)).blob());
  const data = Buffer.from(
    pdfData.startsWith(Base64Prefix)
      ? pdfData.substring(Base64Prefix.length)
      : pdfData,
    "base64"
  );
  return await loadPdfPageCanvasWithData(data, undefined);
}

// full hd resolution
const BASE_WIDTH = 2000;
const BASE_HEIGHT = 2000;

// limits based on maximum canvas dimensions
// Width and Height are limited to the maximum canvas size in Chrome.
// Area is limited by iPhone+safari limit.
const MAX_WIDTH = 65535;
const MAX_HEIGHT = 65535;
const MAX_AREA = 16777216;

export async function loadPdfPageCanvasWithData(
  data: any,
  maxNumPages: number | undefined
) {
  // Using DocumentInitParameters object to load binary data.
  const loadingTask = pdfjsLib.getDocument({ data, isEvalSupported: false });
  const pdf = await loadingTask.promise;
  const numPages = maxNumPages
    ? Math.min(maxNumPages, pdf.numPages)
    : pdf.numPages;

  // Get total height and max width
  let totalHeight = 0;
  let maxWidth = 0;
  for (let i = 0; i < numPages; i++) {
    const pageNumber = i + 1;
    const page = await pdf.getPage(pageNumber);
    const viewport = page.getViewport({ scale: 1 });
    totalHeight += viewport.height;
    if (viewport.width > maxWidth) {
      maxWidth = viewport.width;
    }
  }

  // Prepare canvas using PDF page dimensions
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (!context) {
    throw new Error("Internal Error: can't get context");
  }

  // Scale up the pdf to be converted to an image that fits a full hd resolution
  // so the image can be scaled down to fit the screen later.
  // It is important that this scaling is the same for every user rendering this same pdf in different screen sizes.
  const widthScale = maxWidth ? BASE_WIDTH / maxWidth : 1;
  const heightScale = totalHeight ? (BASE_HEIGHT * numPages) / totalHeight : 1;
  // gets the largest scale unless smaller than 1
  let scale = Math.max(widthScale, heightScale, 1);

  let canvasWidth = maxWidth * scale;
  let canvasHeight = totalHeight * scale;

  // reduce scaling based on width limit
  if (canvasWidth > MAX_WIDTH) {
    scale = scale * (MAX_WIDTH / canvasWidth);
    canvasWidth = MAX_WIDTH;
    canvasHeight = totalHeight * scale;
  }
  // reduce scaling based on height limit
  if (canvasHeight > MAX_HEIGHT) {
    scale = scale * (MAX_HEIGHT / canvasHeight);
    canvasHeight = MAX_HEIGHT;
    canvasWidth = maxWidth * scale;
  }
  // reduce scaling based on area limit
  if (canvasWidth * canvasHeight > MAX_AREA) {
    scale = scale * Math.sqrt(MAX_AREA / (canvasWidth * canvasHeight));
    canvasWidth = maxWidth * scale;
    canvasHeight = totalHeight * scale;
  }

  canvas.width = Math.floor(canvasWidth);
  canvas.height = Math.floor(canvasHeight);

  // Render PDF pages into canvas context
  for (let i = 0; i < numPages; i++) {
    const pageNumber = i + 1;
    const page = await pdf.getPage(pageNumber);
    const viewport = page.getViewport({ scale });
    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    };
    // TODO: Center the page horizontally. Note that using context.translate seems to have a bug that causes part of the content to be cut out.
    await page.render(renderContext).promise;
    // move down
    context.translate(0, viewport.height);
  }

  return canvas;
}

import React from "react";
import styles from "./RoundButton.module.css";
import clsx from "clsx";

type ButtonProps = {
  onClick: () => void;
  className?: string;
  disabled?: boolean;
  id?: string;
  label?: string;
  children: React.ReactNode;
};

const RoundButton = ({
  onClick,
  className,
  disabled,
  id,
  label,
  children,
}: ButtonProps) => {
  return (
    <div className={styles.container}>
      <button
        id={id}
        onClick={onClick}
        className={clsx(
          styles.button,
          { [styles.disabled]: disabled },
          className
        )}
        disabled={disabled}
      >
        {children}
      </button>
      {label ? <div className={styles.label}>{label}</div> : null}
    </div>
  );
};

export default RoundButton;

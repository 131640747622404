// A video stream component for either a local or remote stream

import React, { useEffect, useLayoutEffect, useRef } from "react";
import clsx from "clsx";

import styles from "./Video.module.css";

type VideoProps = {
  stream: MediaStream | undefined;
  isLocal?: boolean;
  setRawVideoDimensions: ({
    width,
    height,
  }: {
    width: number;
    height: number;
  }) => void;
};

export const Video = ({
  stream,
  isLocal,
  setRawVideoDimensions,
}: VideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const updateRawDimensions = () => {
    setRawVideoDimensions({
      width: videoRef.current?.videoWidth || 0,
      height: videoRef.current?.videoHeight || 0,
    });
  };

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
      updateRawDimensions();
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
    };
  }, [stream]);

  useLayoutEffect(() => {
    updateRawDimensions();
    videoRef.current?.addEventListener("resize", updateRawDimensions);

    return () => {
      videoRef.current?.removeEventListener("resize", updateRawDimensions);
    };
  }, [videoRef, setRawVideoDimensions]);

  return (
    <video
      className={clsx(styles.video, { [styles.localVideo]: isLocal })}
      ref={videoRef}
      autoPlay
      playsInline
      disablePictureInPicture
      muted={isLocal}
    />
  );
};

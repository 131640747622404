import Button, { ButtonProps } from "components/Button/Button";
import React, { useEffect, useState } from "react";

interface DelayedButtonProps extends ButtonProps {
  delay: number;
}

const DelayedButton = ({ delay, ...buttonProps }: DelayedButtonProps) => {
  const [showButton, setShowButton] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setShowButton(true);
    }, delay);
  }, [delay]);

  return showButton ? <Button {...buttonProps} /> : <></>;
};

export default DelayedButton;

// See https://developer.mozilla.org/en-US/docs/Web/API/WebRTC_API/Using_data_channels
// 16 kiB should be the limit, but setting this a bit smaller to be safe, and account for message metadata
import { v4 as uuid } from "uuid";
import { dataURLtoBlob, loadDataAsDataURL } from "./thumbnailUtils";
import {
  EventFunctionArgs,
  EventToPeersFunctionArgs,
} from "pages/Space/eventMessagesTypes";
import { getAddBreadcrumb, logUnexpectedError } from "utils/errorUtils";
import {
  getLocalPeerId,
  Peers,
} from "pages/Space/hooks/connection/usePeerWebRTCConnection";

const addBreadcrumb = getAddBreadcrumb("navigation");

const MAX_MESSAGE_SIZE = 15 * 1000;
const MAX_BLOB_SIZE = (MAX_MESSAGE_SIZE / 4) * 3; // before encoding. base64 is ceil(x/3)*4 the size of blob bytes

const partialMessageStore = new Map<string, (Blob | null)[]>(); // map from key to url

export const readLargeEvent = async (event: MessageEvent) => {
  const { id, part, numParts, content } = JSON.parse(event.data);
  if (!content) {
    return;
  }
  const partBlob = dataURLtoBlob(content, {});
  if (numParts === 1) {
    const text = await partBlob.text();
    return JSON.parse(text);
  }

  if (!partialMessageStore.has(id)) {
    partialMessageStore.set(id, new Array(numParts).fill(null));
  }

  const parts = partialMessageStore.get(id);
  if (!parts) {
    return;
  }
  parts[part] = partBlob;

  // Return the combined parts if we have all the parts
  if (!parts.some((p) => !p)) {
    const blobParts = parts as Blob[];
    const combinedParts = await new Blob(blobParts).text();
    partialMessageStore.delete(id);
    return JSON.parse(combinedParts);
  }
};

export const sendLargeEvent: (
  ...args: EventFunctionArgs
) => Promise<void> = async (type, data, sendChannel) => {
  if (sendChannel?.readyState === "open") {
    const senderId = getLocalPeerId();
    const message = new Blob([JSON.stringify({ event: type, senderId, data })]);
    const size = message.size;
    const numParts = Math.ceil(size / MAX_BLOB_SIZE);
    const messageId = numParts > 1 ? uuid() : "";
    for (let i = 0; i < numParts; i++) {
      const partBlob = message.slice(
        i * MAX_BLOB_SIZE,
        (i + 1) * MAX_BLOB_SIZE
      );
      const dataUrl = await loadDataAsDataURL(partBlob);
      const partMessage = {
        id: messageId,
        part: i,
        numParts,
        content: dataUrl,
      };
      if (sendChannel?.readyState !== "open") {
        // Discarding event and logging to sentry
        logUnexpectedError(
          new Error(
            `Data channel closed during sendLargeEvent for event ${type}`
          )
        );
        return;
      }
      sendChannel.send(JSON.stringify(partMessage));
    }
  }
};

export const sendEvent: (...args: EventFunctionArgs) => void = (
  type,
  data,
  sendChannel
) => {
  if (type === "navigate") {
    addBreadcrumb("info", "this user navigated", {
      page: data.currentPage,
    });
  }
  if (sendChannel?.readyState === "open") {
    sendChannel.send(JSON.stringify({ event: type, data }));
  }
};

export const sendLargeEventToPeers: (
  peers: Peers,
  ...args: EventToPeersFunctionArgs
) => Promise<void> = async (peers, type, data) => {
  await Promise.all(
    Object.values(peers).map((peer) =>
      // Could not fix this type error using type as a variable
      // @ts-ignore
      sendLargeEvent(type, data, peer.dataChannel)
    )
  );
};

export const sendEventToPeers: (
  peers: Peers,
  ...args: EventToPeersFunctionArgs
) => void = (peers, type, data) => {
  if (type === "navigate") {
    addBreadcrumb("info", "this user navigated", {
      page: data.currentPage,
    });
  }
  const senderId = data.senderId ?? getLocalPeerId();
  for (const peer of Object.values(peers)) {
    if (peer.dataChannel?.readyState === "open") {
      peer.dataChannel.send(JSON.stringify({ event: type, senderId, data }));
    }
  }
};

import React, { useEffect, useState } from "react";
import DropDown from "../DropDown/DropDown";
import styles from "./AgeDropDown.module.css";
import Slider from "./Slider/Slider";
import { useDebounce } from "../useDebounce";
import { useTrackResourceFilter } from "../../useActivityBankAnalytics";

export const MIN_AGE_FILTER = 0;
export const MAX_AGE_FILTER = 18;

export const formatAgeRange = (min: number, max: number) => {
  if (min === MIN_AGE_FILTER && max === MAX_AGE_FILTER) {
    return "Any age";
  }
  if (max === MAX_AGE_FILTER) {
    return `Ages ${min}+`;
  }
  if (min === max) {
    return `Age ${min}`;
  }
  return `Ages ${min}-${max}`;
};

type AgeDropDownProps = {
  selectedAgeMin: number;
  selectedAgeMax: number;
  setAgeMin: (value: number) => void;
  setAgeMax: (value: number) => void;
};

const AgeDropDown = ({
  selectedAgeMin,
  selectedAgeMax,
  setAgeMin,
  setAgeMax,
}: AgeDropDownProps) => {
  const [newlySelectedAgeMin, setNewlySelectedAgeMin] =
    useState<number>(selectedAgeMin);
  const [newlySelectedAgeMax, setNewlySelectedAgeMax] =
    useState<number>(selectedAgeMax);

  const [isTouched, setIsTouched] = useState(false);

  const { debounce, cancelDebounce, triggerAndClearDebounce } = useDebounce();

  const { trackOpen, trackClose, trackFilterChange, trackFilterClear } =
    useTrackResourceFilter("Age");

  const cancelSelection = () => {
    triggerAndClearDebounce();
  };

  const applySelection = () => {
    setAgeMin(newlySelectedAgeMin);
    setAgeMax(newlySelectedAgeMax);
    if (
      newlySelectedAgeMin !== selectedAgeMin ||
      newlySelectedAgeMax !== selectedAgeMax
    ) {
      trackFilterChange({
        "Filter Min Age": newlySelectedAgeMin,
        "Filter Max Age": newlySelectedAgeMax,
      });
    }
  };

  const clearSelection = () => {
    setNewlySelectedAgeMin(MIN_AGE_FILTER);
    setNewlySelectedAgeMax(MAX_AGE_FILTER);
    setAgeMin(MIN_AGE_FILTER);
    setAgeMax(MAX_AGE_FILTER);
    setIsTouched(false);
    cancelDebounce();
    trackFilterClear();
  };

  useEffect(() => {
    if (isTouched) {
      debounce(applySelection);
    }
  }, [newlySelectedAgeMin, newlySelectedAgeMax]);

  const newAgeRangeFormatted = formatAgeRange(
    newlySelectedAgeMin,
    newlySelectedAgeMax
  );

  // When the filter is cleared
  useEffect(() => {
    const valuesAreDefault =
      selectedAgeMin === MIN_AGE_FILTER && selectedAgeMax === MAX_AGE_FILTER;
    const newValuesAreChanged =
      newlySelectedAgeMin !== MIN_AGE_FILTER ||
      newlySelectedAgeMax !== MAX_AGE_FILTER;
    if (valuesAreDefault && newValuesAreChanged) {
      setNewlySelectedAgeMin(MIN_AGE_FILTER);
      setNewlySelectedAgeMax(MAX_AGE_FILTER);
      setIsTouched(false);
      cancelDebounce();
    }
  }, [selectedAgeMin, selectedAgeMax]);

  const value =
    selectedAgeMin === MIN_AGE_FILTER && selectedAgeMax === MAX_AGE_FILTER
      ? null
      : formatAgeRange(selectedAgeMin, selectedAgeMax);

  return (
    <DropDown
      placeholderText={"Age"}
      value={value}
      cancelSelection={cancelSelection}
      clearSelection={clearSelection}
      onOpen={trackOpen}
      onClose={trackClose}
    >
      <div className={styles.body}>
        <div className={styles.text}>{newAgeRangeFormatted}</div>
        <Slider
          min={MIN_AGE_FILTER}
          max={MAX_AGE_FILTER}
          fromValue={newlySelectedAgeMin}
          toValue={newlySelectedAgeMax}
          setFrom={(value) => {
            setIsTouched(true);
            setNewlySelectedAgeMin(value);
          }}
          setTo={(value) => {
            setIsTouched(true);
            setNewlySelectedAgeMax(value);
          }}
        />
      </div>
    </DropDown>
  );
};

export default AgeDropDown;

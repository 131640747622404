import React from "react";
import * as Sentry from "@sentry/react";

type SentryProviderProps = {
  children: React.ReactNode;
};

const SentryProvider = ({ children }: SentryProviderProps) => {
  // Remove URL fragments and parameters, which may include authentication information
  // TODO: scrub less when auth info is not in the url params
  const scrubUrlParams = (url: string) => {
    if (!url) {
      return url;
    }
    let isScrubbed = false;
    const fragmentSplit = url.split("#");
    if (fragmentSplit.length > 1) {
      isScrubbed = true;
    }
    const paramSplit = fragmentSplit[0].split("?");
    if (paramSplit.length > 1) {
      isScrubbed = true;
    }

    if (isScrubbed) {
      return `${paramSplit[0]}<PARAMS_SCRUBBED>`;
    } else {
      return url;
    }
  };

  const scrubGraphQLQuery = (query: string) => {
    const queryObject = JSON.parse(query);

    const scrubPrivateVars = (key: string) => {
      const privateVariableNames = [
        "name",
        "email",
        "url",
        "key",
        "sessionid",
        "suggestion",
      ] as const;
      if (
        privateVariableNames.some((varName) =>
          key.toLowerCase().includes(varName)
        )
      ) {
        queryObject.variables[key] = "<SCRUBBED>";
        return true;
      }
      return false;
    };

    const scrubPrivateQueries = (queryName: string, key: string) => {
      const privateQueryNames = [
        "InsertAnalyticsResourceSearchCombined",
        "InsertAnalyticsResourceSearchFilter",
      ] as const;
      if (key === "value" && queryName in privateQueryNames) {
        queryObject.variables[key] = "<SCRUBBED>";
        return true;
      }
      return false;
    };

    const scrubResourceState = (queryName: string, key: string) => {
      if (queryName === "UpsertResourceState" && key === "content") {
        queryObject.variables[
          key
        ] = `<SCRUBBED_${queryObject.variables[key]?.length}_CHARS>`;
        return true;
      }
      return false;
    };

    const scrubString = (key: string) => {
      if (typeof queryObject.variables[key] === "string") {
        // intentionally not scrubbing unicode characters
        // to let us identify unicode issues
        queryObject.variables[key] = queryObject.variables[key].replace(
          /[0-9A-Za-z]/g,
          "X"
        );
        return true;
      }
      return false;
    };

    const scrubNumber = (key: string) => {
      if (typeof queryObject.variables[key] === "number") {
        queryObject.variables[key] = "<SCRUBBED_NUMBER>";
        return true;
      }
      return false;
    };

    const scrubArray = (key: string) => {
      if (Array.isArray(queryObject.variables[key])) {
        queryObject.variables[
          key
        ] = `<SCRUBBED_${queryObject.variables[key]?.length}_ITEMS>`;
        return true;
      }
      return false;
    };

    if (queryObject?.operationName) {
      // scrub query vars
      for (const key of Object.keys(queryObject.variables)) {
        // scrub if not undefined | null | ""
        if (
          queryObject.variables[key] &&
          typeof queryObject.variables[key] !== "boolean"
        ) {
          // apply the first scrubbing that matches
          scrubPrivateVars(key) ||
            scrubPrivateQueries(queryObject.operationName, key) ||
            scrubResourceState(queryObject.operationName, key) ||
            scrubString(key) ||
            scrubNumber(key) ||
            scrubArray(key);
        }
      }
    }

    return JSON.stringify(queryObject);
  };

  // If not present, default to true
  const isSentryEnabled = process.env.REACT_APP_SENTRY_ENABLED ?? "true";
  if (isSentryEnabled === "true") {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_ENVIRONMENT,
      beforeSend: (event) => {
        if (event.request?.url) {
          event.request.url = scrubUrlParams(event.request.url);
        }
        if (event.request?.headers?.Referer) {
          event.request.headers.Referer = scrubUrlParams(
            event.request.headers.Referer
          );
        }
        return event;
      },
      beforeBreadcrumb: (breadcrumb, hint) => {
        const category = breadcrumb.category;
        if (
          (category === "fetch" || category === "xhr") &&
          breadcrumb.data?.url
        ) {
          breadcrumb.data.url = scrubUrlParams(breadcrumb.data?.url);
        }
        if (
          category === "fetch" &&
          breadcrumb.data?.method === "POST" &&
          breadcrumb.data?.url?.endsWith("/v1/graphql")
        ) {
          try {
            breadcrumb.data.body = scrubGraphQLQuery(hint?.input[1]?.body);
          } catch (e) {
            console.error("Error parsing graphql query breadcrumb", e);
          }
        }
        if (category === "navigation") {
          if (breadcrumb.data?.from) {
            breadcrumb.data.from = scrubUrlParams(breadcrumb.data?.from);
          }
          if (breadcrumb.data?.to) {
            breadcrumb.data.to = scrubUrlParams(breadcrumb.data?.to);
          }
        }
        return breadcrumb;
      },
    });
  }

  return (
    <Sentry.ErrorBoundary fallback={<p>Sorry, an error has occurred.</p>}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default SentryProvider;

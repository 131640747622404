import React from "react";

import styles from "./CopyTextBox.module.css";
import CopyButton from "../CopyButton/CopyButton";
import clsx from "clsx";

type CopyTextBoxProps = {
  text: string;
  className: string;
};

const CopyTextBox = ({ text, className }: CopyTextBoxProps) => {
  return (
    <div className={clsx(styles.box, className)}>
      <div className={styles.text}>{text}</div>
      <CopyButton textToCopy={text} compact />
    </div>
  );
};

export default CopyTextBox;

import { useEffect, useRef } from "react";
import { logUnexpectedError } from "utils/errorUtils";

const DEBOUNCE_TIMEOUT = 250;

/**
 * Custom hook that provides debouncing functionality.
 *
 * This hook allows you to debounce a function, ensuring that it is only called
 * after a specified delay has passed since the last time it was invoked. It also
 * provides methods to cancel the debounce and to immediately trigger the debounced
 * function and clear the debounce.
 *
 * @return {Object} An object containing the following methods:
 * - `debounce(fn: () => void)`: Debounces the provided function. The function will be
 *   called after the debounce timeout has passed.
 * - `cancelDebounce()`: Cancels any pending debounced function.
 * - `triggerAndClearDebounce()`: Immediately triggers the debounced function if it exists
 *   and clears the debounce.
 *
 * @example
 * const { debounce, cancelDebounce, triggerAndClearDebounce } = useDebounce();
 *
 * const handleInputChange = (event) => {
 *   debounce(() => {
 *     // Handle the input change
 *   });
 * };
 *
 * // To cancel the debounce, such as when clearing the debounced action
 * cancelDebounce();
 *
 * // To immediately trigger the debounced function and clear the debounce, such as when
 * // triggering the action earlier than the debounce timeout
 * triggerAndClearDebounce();
 *
 */
export const useDebounce = () => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const debouncedFunctionRef = useRef<(() => void) | null>(null);

  const cancelDebounce = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    if (debouncedFunctionRef.current) {
      debouncedFunctionRef.current = null;
    }
  };

  const debounce = (fn: () => void) => {
    cancelDebounce();
    debouncedFunctionRef.current = fn;
    timeoutRef.current = setTimeout(() => {
      if (!debouncedFunctionRef.current) {
        logUnexpectedError(
          "Debounced function is null when timeout triggered."
        );
        return;
      }

      try {
        debouncedFunctionRef.current();
      } finally {
        timeoutRef.current = null;
        debouncedFunctionRef.current = null;
      }
    }, DEBOUNCE_TIMEOUT);
  };

  const triggerAndClearDebounce = () => {
    try {
      if (debouncedFunctionRef.current) {
        debouncedFunctionRef.current();
      }
    } finally {
      cancelDebounce();
    }
  };

  useEffect(() => {
    return () => {
      cancelDebounce();
    };
  }, []);

  return { debounce, cancelDebounce, triggerAndClearDebounce };
};

import React, { ChangeEvent, useState } from "react";

import styles from "./SuggestEditModal.module.css";
import Button from "components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSuggestEditModalData,
  setSuggestEditModalData,
} from "redux/editRoomNavigationRedux";
import Modal from "components/Modal/Modal";
import ResourceEntry from "../../subpages/SpaceRoom/components/ResourceEntry/ResourceEntry";
import { logUnexpectedError } from "utils/errorUtils";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { backendRequest } from "utils/backendRequest";

const SuggestEditModal = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectSuggestEditModalData);
  const resource = data?.resource;
  const thumbnailSrc = data?.thumbnailSrc;
  const [suggestion, setSuggestion] = useState("");
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const closeModal = () => {
    dispatch(setSuggestEditModalData(null));
  };

  const textChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setSuggestion(event.target.value);
  };

  const submit = async () => {
    const result = await backendRequest({
      path: "/submit-activity-suggestion",
      options: {
        method: "POST",
        body: JSON.stringify({ suggestion, resourceId: resource?.id || "" }),
        headers: {
          "Content-Type": "text/plain",
        },
        keepalive: true, // make sure the request is sent completely even once the page is destroyed
      },
    });
    if (result.ok) {
      setHasSubmitted(true);
    } else {
      alert(
        "There was an error submitting your suggestion. Please try again later."
      );
      logUnexpectedError("Error submitting activity edit suggestion");
    }
  };

  if (hasSubmitted) {
    return (
      <ConfirmationModal closeModal={closeModal}>
        Thanks for your suggestion!
        <br />
        We will review it and get back to you soon.
      </ConfirmationModal>
    );
  }

  return (
    <Modal closeModal={closeModal}>
      <div className={styles.container}>
        <div className={styles.heading}>Suggest Edit</div>
        {resource ? (
          <ResourceEntry
            resource={resource}
            thumbnailSrc={thumbnailSrc}
            isSelected={false}
            displayOnly
          />
        ) : null}
        <div className={styles.text}>Suggestion</div>
        <textarea
          name="suggestion"
          value={suggestion}
          placeholder={"What should we change about this activity listing?"}
          className={styles.textarea}
          maxLength={6000}
          onChange={textChangeHandler}
        />
        <div className={styles.buttonRow}>
          <Button className={styles.cancelButton} onClick={closeModal}>
            Cancel
          </Button>
          <Button onClick={submit}>Suggest</Button>
        </div>
      </div>
    </Modal>
  );
};

export default SuggestEditModal;

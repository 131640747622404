import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectEditItemId } from "redux/editRoomNavigationRedux";
import { useTrackEvent } from "utils/metricsUtils";
import {
  MAX_AGE_FILTER,
  MIN_AGE_FILTER,
} from "./ResourceFilters/AgeDropDown/AgeDropDown";

export const useTrackActivityList = ({
  search,
  approaches,
  topics,
  types,
  sources,
  ageMin,
  ageMax,
}: {
  search: string;
  approaches: string[];
  topics: string[];
  types: string[];
  sources: string[];
  ageMin: number;
  ageMax: number;
}) => {
  const { trackEvent } = useTrackEvent();

  const editItemId = useSelector(selectEditItemId);
  const isEditing = !!editItemId;

  useEffect(() => {
    trackEvent("Activity bank - opened", { "Is Editing": isEditing });
    return () => {
      trackEvent("Activity bank - closed", { "Is Editing": isEditing });
    };
  }, []);

  const trackClearAllFilters = () => {
    const isSearchApplied = !!search;
    const isApproachesApplied = approaches.length > 0;
    const isTopicsApplied = topics.length > 0;
    const isTypesApplied = types.length > 0;
    const isSourcesApplied = sources.length > 0;
    const isAgeMinApplied = ageMin !== MIN_AGE_FILTER;
    const isAgeMaxApplied = ageMax !== MAX_AGE_FILTER;

    trackEvent("Activity filters - clear all", {
      Search: isSearchApplied,
      Approaches: isApproachesApplied,
      Topics: isTopicsApplied,
      Types: isTypesApplied,
      Sources: isSourcesApplied,
      "Age Min": isAgeMinApplied,
      "Age Max": isAgeMaxApplied,
    });
  };

  return { trackClearAllFilters };
};

export const useTrackResourceFilter = (filterName: string) => {
  const { trackEvent } = useTrackEvent();

  const trackOpen = () => {
    trackEvent("Activity filter - opened", { "Filter Name": filterName });
  };

  const trackClose = () => {
    trackEvent("Activity filter - closed", { "Filter Name": filterName });
  };

  const trackFilterChange = (properties?: { [key: string]: any }) => {
    trackEvent("Activity filter - changed", {
      "Filter Name": filterName,
      ...properties,
    });
  };

  const trackFilterClear = () => {
    trackEvent("Activity filter - cleared", { "Filter Name": filterName });
  };

  return { trackOpen, trackClose, trackFilterChange, trackFilterClear };
};

import React from "react";

import styles from "./ItemButtons.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectEditItemId,
  setShowChangeIconModal,
  setShowDeleteItemModal,
} from "redux/editRoomNavigationRedux";
import ItemButton from "./ItemButton/ItemButton";
import deleteIcon from "assets/icons/delete.svg";
import iconIcon from "assets/icons/category.svg";
import { selectRoomItems } from "redux/spaceNavigationRedux";
import { POSTER_RESOURCE_ID } from "utils/resourceUtils";

type ItemButtonsProps = {
  iconSize: number;
};

const ItemButtons = ({ iconSize }: ItemButtonsProps) => {
  const editItemId = useSelector(selectEditItemId);
  const roomItems = useSelector(selectRoomItems);

  const dispatch = useDispatch();

  const editItem = roomItems?.find((item) => item.id === editItemId);
  const isPoster = editItem?.resource.id === POSTER_RESOURCE_ID;

  const confirmDeleteItem = () => {
    dispatch(setShowDeleteItemModal(true));
  };

  const openItemIconModal = () => {
    dispatch(setShowChangeIconModal(true));
  };

  const marginStyle = {
    marginRight: iconSize * 0.5,
  };
  return (
    <div className={styles.container}>
      {isPoster ? null : (
        <ItemButton
          style={marginStyle}
          iconSrc={iconIcon}
          iconSize={iconSize}
          onClick={openItemIconModal}
          tooltipText={"Change Item Icon"}
        />
      )}
      <ItemButton
        isWarnColor
        iconSrc={deleteIcon}
        iconSize={iconSize}
        onClick={confirmDeleteItem}
        tooltipText={"Delete"}
      />
    </div>
  );
};

export default ItemButtons;

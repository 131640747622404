import React, { MouseEventHandler } from "react";

import styles from "./InRoomModal.module.css";
import xIcon from "assets/inRoomElements/x.png";

type InRoomModalProps = {
  closeModal?: () => void;
  sizeRelativeToParent?: number; // size the modal relative to the specified parent width- default is equivalent to ~1000px
  children?: React.ReactNode;
};

const InRoomModal = ({
  closeModal,
  sizeRelativeToParent = 1000,
  children,
}: InRoomModalProps) => {
  const stopPropagation: MouseEventHandler<HTMLDivElement> = (ev) => {
    ev.stopPropagation();
  };

  /*
   * Make relative size equivalent to default size at 1000px parent
   */
  const relativeMultiplier = sizeRelativeToParent / 1000;
  const relativeModalStyle = {
    borderRadius: 16 * relativeMultiplier,
    padding: 20 * relativeMultiplier,
  };
  const relativeXIconStyle = {
    height: 26 * relativeMultiplier,
    width: 26 * relativeMultiplier,
  };
  const relativeContentStyle = {
    padding: 10 * relativeMultiplier,
  };

  return (
    <div className={styles.overlay} onClick={closeModal}>
      <div
        className={styles.modal}
        style={relativeModalStyle}
        onClick={stopPropagation}
      >
        {closeModal ? (
          <img
            src={xIcon}
            className={styles.xIcon}
            style={relativeXIconStyle}
            onClick={closeModal}
          />
        ) : null}
        <div className={styles.content} style={relativeContentStyle}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default InRoomModal;

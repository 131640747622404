import React from "react";

import styles from "./SettingsModal.module.css";
import Button from "components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  resetNavigation,
  setShowSettingsModal,
  SpacePage,
} from "redux/spaceNavigationRedux";
import Modal from "components/Modal/Modal";
import Toggle from "components/Toggle/Toggle";
import {
  selectAudioProviderOnly,
  selectShowResourceNameOnItemHover,
  selectVideoConferencing,
  setAudioProviderOnly,
  setShowResourceNameOnItemHover,
  setVideoConferencing,
} from "redux/settingsRedux";
import {
  useSetAudioProviderOnlyMutation,
  useSetResourceNameHoverMutation,
  useSetVideoConferencingMutation,
} from "generated/graphql";
import { selectCurrentRoomId, selectUserId } from "redux/userRedux";
import { showOnboardingGuide } from "utils/pendoUtils";
import { sendEventToPeers } from "utils/webrtcUtils";
import { resetEditRoomNavigation } from "redux/editRoomNavigationRedux";
import { logUnexpectedError } from "utils/errorUtils";
import clsx from "clsx";
import { logVideoConferencingEnabled, useTrackEvent } from "utils/metricsUtils";
import { ReactComponent as HelpIcon } from "assets/icons/help.svg";
import { Peers } from "pages/Space/hooks/connection/usePeerWebRTCConnection";

type SettingsModalProps = {
  setRemoteAudioProviderOnly: (audioProviderOnly: boolean) => void;
  setRemoteVideoConferencing: (videoConferencing: boolean) => void;
  peersRef: React.MutableRefObject<Peers>;
  setRemoteEditRoomMode: (editRoomMode: boolean) => void;
};

const SettingsModal = ({
  setRemoteAudioProviderOnly,
  setRemoteVideoConferencing,
  peersRef,
  setRemoteEditRoomMode,
}: SettingsModalProps) => {
  const dispatch = useDispatch();
  const audioProviderOnly = useSelector(selectAudioProviderOnly);
  const videoConferencing = useSelector(selectVideoConferencing);
  const showResourceNameOnItemHover = useSelector(
    selectShowResourceNameOnItemHover
  );
  const userId = useSelector(selectUserId);
  const roomId = useSelector(selectCurrentRoomId);
  const [setAudioProviderOnlyMutation] = useSetAudioProviderOnlyMutation();
  const [setVideoConferencingMutation] = useSetVideoConferencingMutation();
  const [setResourceNameHoverMutation] = useSetResourceNameHoverMutation();

  const { trackEvent } = useTrackEvent();

  const closeModal = () => {
    dispatch(setShowSettingsModal(false));
  };

  const toggleAudioProviderOnly = () => {
    if (userId && !videoConferencing) {
      const newValue = !audioProviderOnly;
      // TODO: synchronize these better (probably just set value in DB with optimistic response)
      dispatch(setAudioProviderOnly(newValue));
      setAudioProviderOnlyMutation({
        variables: { userId, audioProviderOnly: newValue },
      }).catch(logUnexpectedError);
      setRemoteAudioProviderOnly(newValue);
    }
  };

  const toggleVideoConferencing = () => {
    if (userId) {
      const newValue = !videoConferencing;
      // TODO: synchronize these better (probably just set value in DB with optimistic response)
      dispatch(setVideoConferencing(newValue));
      setVideoConferencingMutation({
        variables: { userId, videoConferencing: newValue },
      }).catch(logUnexpectedError);
      setRemoteVideoConferencing(newValue);
      logVideoConferencingEnabled();
    }
  };

  const toggleShowResourceNameOnItemHover = () => {
    const newValue = !showResourceNameOnItemHover;
    // TODO: synchronize these better (probably just set value in DB with optimistic response)
    dispatch(setShowResourceNameOnItemHover(newValue));
    setResourceNameHoverMutation({
      variables: { userId, resourceNameHover: newValue },
    }).catch(logUnexpectedError);
    trackEvent("Item label hover changed", {
      "Hover State": newValue ? "enabled" : "disabled",
    });
  };

  const runOnboardingGuide = () => {
    closeModal();

    // Navigate to the main room
    dispatch(resetNavigation());
    sendEventToPeers(peersRef.current, "navigate", {
      currentPage: SpacePage.ROOM,
    });
    setRemoteEditRoomMode(false);
    dispatch(resetEditRoomNavigation());

    showOnboardingGuide();
  };

  const actualAudioProviderOnly = audioProviderOnly && !videoConferencing;
  const audioToggleText = actualAudioProviderOnly ? "Only Yours" : "Both";
  const videoToggleText = videoConferencing ? "On" : "Off";
  const itemHoverToggleText = showResourceNameOnItemHover ? "On" : "Off";

  const noCurrentRoom = roomId === undefined;

  return (
    <Modal closeModal={closeModal}>
      <div className={styles.wrapper}>
        <div className={styles.heading}>Settings</div>
        <div className={styles.row} onClick={toggleVideoConferencing}>
          <div className={styles.text}>Video Conferencing</div>
          <Toggle isOn={!!videoConferencing} text={videoToggleText} />
        </div>
        <div className={styles.smallText}>
          {"Enable or disable video chat."}
        </div>
        <div className={styles.row} onClick={toggleAudioProviderOnly}>
          <div
            className={clsx(styles.text, {
              [styles.disabledText]: videoConferencing,
            })}
          >
            Play audio from both devices
          </div>
          <Toggle
            isOn={!actualAudioProviderOnly}
            text={audioToggleText}
            disabled={videoConferencing}
          />
        </div>
        {videoConferencing ? (
          <div className={styles.warningText}>
            {"⚠️ Disable video conferencing to adjust this setting."}
          </div>
        ) : null}
        <div
          className={clsx(styles.smallText, {
            [styles.disabledText]: videoConferencing,
          })}
        >
          {
            "Playing audio from both your computer and your client's computer can cause an echo if both devices are transmitting audio, but is necessary if you are wearing headphones and not sharing audio."
          }
        </div>
        <div className={styles.row} onClick={toggleShowResourceNameOnItemHover}>
          <div className={styles.text}>
            Display activity details on item hover
          </div>
          <Toggle
            isOn={!!showResourceNameOnItemHover}
            text={itemHoverToggleText}
          />
        </div>
        <div className={styles.smallText}>
          {"Activity details will only be visible to you."}
        </div>
        <div className={styles.sectionHeader}>
          Help <HelpIcon className={styles.helpIcon} />
        </div>
        <div className={styles.row}>
          <div className={styles.text}>View Teleo onboarding tour</div>
          <Button
            onClick={runOnboardingGuide}
            className={styles.tourButton}
            disabled={noCurrentRoom}
          >
            Launch
          </Button>
        </div>
        {noCurrentRoom ? (
          <div className={styles.smallText}>
            {
              "⚠️ You must be in a room to view the onboarding tour. Please open a room and try again."
            }
          </div>
        ) : null}

        <div className={clsx(styles.row, styles.getSupport)}>
          <div className={styles.text}>Get Support</div>
          <Button
            href="https://www.teleo.space/support"
            className={styles.supportButton}
          >
            Get Help
          </Button>
        </div>
        <div className={clsx(styles.smallText)}>
          Read how-to guides, connect with our therapist community, or get help
          from the Teleo team.
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button onClick={closeModal}>Close</Button>
      </div>
    </Modal>
  );
};

export default SettingsModal;

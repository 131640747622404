import React from "react";

import styles from "./EditRoomTip.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  resetEditRoomNavigation,
  selectIsLoading,
} from "redux/editRoomNavigationRedux";
import RoundButton from "components/RoundButton/RoundButton";

import checkIcon from "assets/icons/check.svg";
import syncIcon from "assets/icons/sync.svg";
import cloudDoneIcon from "assets/icons/cloud_done.svg";

type EditRoomTipProps = {
  setRemoteEditRoomMode: (editRoomMode: boolean) => void;
};

const EditRoomTip = ({ setRemoteEditRoomMode }: EditRoomTipProps) => {
  const dispatch = useDispatch();
  const saving = useSelector(selectIsLoading);

  const close = () => {
    setRemoteEditRoomMode(false);
    dispatch(resetEditRoomNavigation());
  };

  return (
    <div className={styles.container} data-testid="edit-room-tip">
      <div className={styles.doneButtonSection}>
        <RoundButton
          className={styles.button}
          onClick={close}
          label={"Done Editing"}
        >
          <img className={styles.buttonIcon} src={checkIcon} />
        </RoundButton>
      </div>
      <div className={styles.verticalLine} />
      <div className={styles.savingInfoSection}>
        {saving ? (
          <img className={styles.loadingIcon} src={syncIcon} />
        ) : (
          <img className={styles.loadingIcon} src={cloudDoneIcon} />
        )}
        <div className={styles.message}>
          {saving ? "Saving..." : "All changes saved."}
        </div>
      </div>
    </div>
  );
};

export default EditRoomTip;

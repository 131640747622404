import React from "react";
import AdminPageButton from "./AdminPageButton";
import { backendRequest } from "utils/backendRequest";

type CloseMeetingButtonProps = {
  meetingID: string;
  callback: () => void;
};
const CloseMeetingButton = ({
  meetingID,
  callback,
}: CloseMeetingButtonProps) => {
  const closeMeeting = async () => {
    await backendRequest({
      path: `/rooms/${meetingID}`,
      options: { method: "DELETE" },
    });

    callback();
  };

  return <AdminPageButton onClick={closeMeeting}>Close</AdminPageButton>;
};

export default CloseMeetingButton;

import { createSlice } from "@reduxjs/toolkit";
import { Resource } from "generated/graphql";

export interface EditRoomNavigationState {
  editRoomMode?: boolean;
  editItemId?: string;
  editItemMoving?: string;
  showAddResourceModal?: boolean;
  showDeleteItemModal?: boolean;
  showChangeIconModal?: boolean;
  showBackgroundEditSideBar?: boolean;
  showAddItemSideBar?: boolean;
  suggestEditModalData?: {
    resource: Resource;
    thumbnailSrc: string | null | undefined;
  };
  sharePubliclyModalData?: {
    resource: Resource;
    thumbnailSrc: string | null | undefined;
  };
  deleteResourceModalData?: {
    resource: Resource;
    thumbnailSrc: string | null | undefined;
  };
  isLoading: string[];
  activityBankFilter?: {
    search: string;
    approaches: string[];
    topics: string[];
    types: string[];
    sources: string[];
    ageMin: number;
    ageMax: number;
  };
}

export interface RootState {
  editRoomNavigation: EditRoomNavigationState;
}

export const editRoomNavigationSlice = createSlice({
  name: "editRoomNavigation",
  initialState: { isLoading: [] } as EditRoomNavigationState,
  reducers: {
    resetEditRoomNavigation: (state) => {
      return { isLoading: [], activityBankFilter: state.activityBankFilter };
    },
    setEditRoomMode: (state, value) => {
      state.editRoomMode = value.payload;
    },
    setEditItemId: (state, value) => {
      state.editItemId = value.payload;
    },
    setEditItemMoving: (
      state,
      { payload }: { payload: string | undefined }
    ) => {
      state.editItemMoving = payload;
    },
    setShowAddResourceModal: (state, value) => {
      state.showAddResourceModal = value.payload;
    },
    setShowDeleteItemModal: (state, value) => {
      state.showDeleteItemModal = value.payload;
    },
    setShowChangeIconModal: (state, value) => {
      state.showChangeIconModal = value.payload;
    },
    setShowBackgroundEditSideBar: (state, value) => {
      state.showBackgroundEditSideBar = value.payload;
    },
    setShowAddItemSideBar: (state, value) => {
      state.showAddItemSideBar = value.payload;
    },
    setSuggestEditModalData: (state, value) => {
      state.suggestEditModalData = value.payload;
    },
    setSharePubliclyModalData: (state, value) => {
      state.sharePubliclyModalData = value.payload;
    },
    setDeleteResourceModalData: (state, value) => {
      state.deleteResourceModalData = value.payload;
    },
    setIsLoadingId: (state, value) => {
      state.isLoading.push(value.payload);
    },
    setIsDoneLoadingId: (state, value) => {
      const index = state.isLoading.indexOf(value.payload);
      if (index !== -1) {
        state.isLoading.splice(index, 1);
      }
    },
    setActivityBankFilter: (
      state,
      { payload }: { payload: EditRoomNavigationState["activityBankFilter"] }
    ) => {
      state.activityBankFilter = payload;
    },
  },
});

export const {
  resetEditRoomNavigation,
  setEditRoomMode,
  setEditItemId,
  setEditItemMoving,
  setShowAddResourceModal,
  setShowDeleteItemModal,
  setShowChangeIconModal,
  setShowBackgroundEditSideBar,
  setShowAddItemSideBar,
  setSuggestEditModalData,
  setSharePubliclyModalData,
  setDeleteResourceModalData,
  setIsLoadingId,
  setIsDoneLoadingId,
  setActivityBankFilter,
} = editRoomNavigationSlice.actions;

export const selectEditRoomMode = (state: RootState) =>
  state.editRoomNavigation.editRoomMode;
export const selectEditItemId = (state: RootState) =>
  state.editRoomNavigation.editItemId;
export const selectEditItemMoving = (state: RootState) =>
  state.editRoomNavigation.editItemMoving;
export const selectShowAddResourceModal = (state: RootState) =>
  state.editRoomNavigation.showAddResourceModal;
export const selectShowDeleteItemModal = (state: RootState) =>
  state.editRoomNavigation.showDeleteItemModal;
export const selectShowChangeIconModal = (state: RootState) =>
  state.editRoomNavigation.showChangeIconModal;
export const selectShowBackgroundEditSideBar = (state: RootState) =>
  state.editRoomNavigation.showBackgroundEditSideBar;
export const selectShowAddItemSideBar = (state: RootState) =>
  state.editRoomNavigation.showAddItemSideBar;
export const selectSuggestEditModalData = (state: RootState) =>
  state.editRoomNavigation.suggestEditModalData;
export const selectSharePubliclyModalData = (state: RootState) =>
  state.editRoomNavigation.sharePubliclyModalData;
export const selectDeleteResourceModalData = (state: RootState) =>
  state.editRoomNavigation.deleteResourceModalData;
export const selectIsLoading = (state: RootState) =>
  state.editRoomNavigation.isLoading.length > 0;
export const selectActivityBankFilter = (state: RootState) => {
  return state.editRoomNavigation.activityBankFilter;
};

export default editRoomNavigationSlice.reducer;

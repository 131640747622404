import { useSelector } from "react-redux";
import { selectClientFileOpen } from "redux/clientManagementRedux";
import { useEffect, useLayoutEffect, useState } from "react";
import { MIN_WIDTH_RATIO, calculateFitDimensions } from "utils/sizingUtils";
import { Snapshot } from "redux/clientAlbumRedux";

export const useResizeSnapshot = ({
  albumContainerRef,
  snapshotImageRef,
  viewingSnapshot,
}: {
  albumContainerRef: React.RefObject<HTMLDivElement>;
  snapshotImageRef?: React.RefObject<HTMLImageElement>;
  viewingSnapshot?: Snapshot;
}) => {
  const isClientFileOpen = useSelector(selectClientFileOpen);
  const [snapshotWidth, setSnapshotWidth] = useState<number | undefined>(
    undefined
  );

  const resizeSnapshot = () => {
    const parentHeight = albumContainerRef.current?.offsetHeight;
    const parentWidth = albumContainerRef.current?.offsetWidth;
    const rawHeight = snapshotImageRef?.current?.naturalHeight;
    const rawWidth = snapshotImageRef?.current?.naturalWidth;

    if (!parentHeight || !parentWidth || !rawHeight || !rawWidth) {
      return;
    }
    const { heightIsLimiting, fitWidth } = calculateFitDimensions(
      parentHeight,
      parentWidth,
      rawHeight,
      rawWidth,
      MIN_WIDTH_RATIO
    );

    if (heightIsLimiting) {
      setSnapshotWidth(fitWidth);
    } else {
      setSnapshotWidth(undefined);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", resizeSnapshot);

    return () => {
      window.removeEventListener("resize", resizeSnapshot);
    };
  }, []);

  useEffect(() => {
    resizeSnapshot();
    snapshotImageRef?.current?.addEventListener("load", resizeSnapshot);
    return () => {
      snapshotImageRef?.current?.removeEventListener("load", resizeSnapshot);
    };
  }, [isClientFileOpen, viewingSnapshot]);

  return snapshotWidth;
};

import { useEffect } from "react";
import {
  GetDemoStartDataQuery,
  useSetUrlNameMutation,
} from "generated/graphql";
import {
  selectEmail,
  selectOrganizationId,
  selectUserId,
} from "redux/userRedux";
import { useSelector } from "react-redux";
import { logUnexpectedError } from "utils/errorUtils";
import { ApolloError } from "@apollo/client";
import { store } from "redux/reduxStore";
import { getOrganizationConfig } from "utils/organizationUtils";

export const MAX_URL_NAME_LENGTH = 70;

export const useSetUrlName = (data: GetDemoStartDataQuery | undefined) => {
  const email = useSelector(selectEmail);
  const userId = useSelector(selectUserId);
  const [setUrlNameMutation] = useSetUrlNameMutation();

  const agreedToTerms =
    !!data?.user_by_pk?.agreed_to_terms || !!data?.user_by_pk?.health_system_id;
  const urlName = data?.user_by_pk?.provider_settings?.url_name;

  const sanitizeName = (name: string) => {
    // Remove accents/diacritics
    const normalizedName = name
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    // Strip starting and ending whitespace,
    // replace all non-alphabet characters with a dash,
    // lowercase the text, and
    // truncate it to the max length, taking into account possibly adding a suffix with a dash and 4 random characters
    return normalizedName
      .trim()
      .replace(/[^a-zA-Z0-9]/g, "-")
      .toLowerCase()
      .substring(0, MAX_URL_NAME_LENGTH - 5);
  };

  const getRandomLetter = () => {
    // This set of letters is chosen intentionally to avoid forming inappropriate words.
    const ALLOWED_LETTERS = [
      "b",
      "c",
      "d",
      "f",
      "g",
      "h",
      "j",
      "l",
      "m",
      "n",
      "p",
      "q",
      "r",
      "s",
      "t",
      "v",
      "w",
      "z",
    ];
    const index = Math.floor(Math.random() * ALLOWED_LETTERS.length);
    return ALLOWED_LETTERS[index];
  };

  // 4 random characters
  const getRandomSuffix = () => {
    return (
      getRandomLetter() +
      getRandomLetter() +
      getRandomLetter() +
      getRandomLetter()
    );
  };

  const getDefaultProviderName = (prefix?: string) => {
    // If the user is part of an organization, use the email and prefix it with given "prefix"
    if (prefix) {
      const emailUsername = email.split("@")[0];
      return `${prefix}-${sanitizeName(emailUsername)}`;
    }

    // Default to firstname-lastname
    const firstName = data?.user_by_pk?.first_name;
    const lastName = data?.user_by_pk?.last_name;
    const providerName =
      firstName || lastName ? sanitizeName(firstName + "-" + lastName) : "";
    return providerName;
  };

  const setDefaultUrlName = async () => {
    const state = store.getState();
    const organizationId = selectOrganizationId(state);
    const organizationConfig = getOrganizationConfig(organizationId);
    const defaultRoomLinkPrefix = organizationConfig?.defaultRoomLinkPrefix;
    const providerName = getDefaultProviderName(defaultRoomLinkPrefix);
    let name = providerName || getRandomSuffix();
    let success = false;
    const MAX_NUM_TRIES = 10;
    for (let i = 0; i < MAX_NUM_TRIES; i++) {
      try {
        await setUrlNameMutation({
          variables: { userId, urlName: name },
        });
        success = true;
        break;
      } catch (e) {
        // Keep trying if the error is that the name is taken.
        // Break if the error is something else.
        if (
          !(e instanceof ApolloError) ||
          e.message !==
            'Uniqueness violation. duplicate key value violates unique constraint "provider_settings_url_name_key"'
        ) {
          logUnexpectedError(e);
          break;
        }

        if (!!defaultRoomLinkPrefix) {
          logUnexpectedError(
            `${organizationConfig.name} room link for ${email} was taken.`
          );
        }

        // If that's taken, try firstname-lastname-xxxx, where xxxx is 4 random characters
        const suffix = getRandomSuffix();
        name = providerName ? providerName + "-" + suffix : suffix;
      }
    }
    if (!success) {
      alert("Internal error, please try signing in again.");
      logUnexpectedError("Could not set url name.");
    }
  };

  useEffect(() => {
    // Create a default url name if one hasn't been set
    if (data && agreedToTerms && !urlName) {
      setDefaultUrlName().catch(logUnexpectedError);
    }
  }, [data]);

  return {
    setDefaultUrlName,
  };
};

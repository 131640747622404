import React, {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import styles from "./SearchBar.module.css";
import clsx from "clsx";
import { ReactComponent as XIcon } from "assets/icons/x.svg";
import { useDebounce } from "../useDebounce";
import { useTrackResourceFilter } from "../../useActivityBankAnalytics";
import { isMobileDevice } from "utils/deviceUtils";

type SearchBarProps = {
  search: string;
  setSearch: (value: string) => void;
  smallWidth?: boolean;
};

const SearchBar = ({ search, setSearch, smallWidth }: SearchBarProps) => {
  const [searchValue, setSearchValue] = useState(search);
  const [hasBeenModifiedSinceSubmit, setHasBeenModifiedSinceSubmit] =
    useState(false);
  const inputElementRef = useRef<HTMLInputElement>(null);

  const { debounce, triggerAndClearDebounce } = useDebounce();

  const { trackFilterChange, trackFilterClear } =
    useTrackResourceFilter("Search Text");

  useEffect(() => {
    if (search === "" && searchValue !== "") {
      setSearchValue("");
      setHasBeenModifiedSinceSubmit(false);
    }
  }, [search]);

  const textChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    setHasBeenModifiedSinceSubmit(true);
  };

  const submitSearch = () => {
    setSearch(searchValue);
    setHasBeenModifiedSinceSubmit(false);
    if (hasBeenModifiedSinceSubmit) {
      trackFilterChange({ "Filter Value Length": searchValue.length });
    }
  };

  const detectEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      triggerAndClearDebounce();
      inputElementRef.current?.blur();
    }
  };

  const clearSearch = () => {
    setSearchValue("");
    if (!hasBeenModifiedSinceSubmit) {
      setSearch("");
    }
    trackFilterClear();
  };

  useEffect(() => {
    if (hasBeenModifiedSinceSubmit) {
      debounce(submitSearch);
    }
  }, [searchValue]);

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <input
          ref={inputElementRef}
          type="text"
          name="search"
          value={searchValue}
          placeholder={"Search"}
          className={clsx(styles.textInput, {
            [styles.textInputSmallWidth]: smallWidth,
          })}
          onChange={textChangeHandler}
          onKeyUp={detectEnter}
          autoFocus={!isMobileDevice(true)}
        />
        <SearchIcon className={styles.searchIcon} />
        {searchValue ? (
          <div className={styles.clearButton} onClick={clearSearch}>
            <XIcon className={styles.xIcon} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SearchBar;

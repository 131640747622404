import React from "react";

import styles from "./ClientConfirmationNameModal.module.css";
import Modal from "components/Modal/Modal";
import Button from "components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  admitClient,
  cancelNameConfirmationModal,
  selectClientHasJoinedRoom,
  selectCurrentClient,
  selectIncomingClient,
  selectSelectingClient,
} from "redux/clientManagementRedux";
import { useAdmitClient } from "utils/waitingRoomUtils";
import { ReactComponent as InstructionalIcon } from "assets/icons/instructional.svg";
import { selectUserId } from "redux/userRedux";
import { useSetCurrentClientMutation } from "generated/graphql";
import { useLogClientManagementEvent } from "utils/metricsUtils";
import { useTrackSelectClientConfirmation } from "../useClientManagementAnalytics";

type ClientConfirmationNameModalProps = {};

const ClientConfirmationNameModal = ({}: ClientConfirmationNameModalProps) => {
  const dispatch = useDispatch();

  const incomingClient = useSelector(selectIncomingClient);
  const selectingClient = useSelector(selectSelectingClient);

  const admitClientOnServer = useAdmitClient();

  const providerId = useSelector(selectUserId);
  const [setCurrentClient] = useSetCurrentClientMutation();

  const logClientManagementEvent = useLogClientManagementEvent();
  const { trackConfirm, trackCancel } = useTrackSelectClientConfirmation();

  const clientHasJoinedRoom = useSelector(selectClientHasJoinedRoom);
  const previouslySelectedClient = useSelector(selectCurrentClient);

  const closeModal = () => {
    dispatch(cancelNameConfirmationModal());
  };

  const handleSubmit = async () => {
    // should not be called without a client selected
    if (!selectingClient) {
      throw new Error("Cannot select no client.");
    }

    // not awaiting to let it run in the background
    logClientManagementEvent({
      action: "NAME_CONFIRMATION_OK",
      clientName: selectingClient.name,
      incomingName: incomingClient?.name ?? "",
    });

    await admitClientOnServer({});
    const result = await setCurrentClient({
      variables: {
        clientId: selectingClient.canonical_id,
        providerId: providerId,
      },
    });
    if (
      result.errors ||
      !result.data?.update_user_by_pk?.current_client_canonical_id
    ) {
      alert("There was an error loading the client. Please try again.");
      return;
    }
    // not awaiting to let it run in the background
    logClientManagementEvent({
      action: "CLIENT_ADMITTED",
      clientCanonicalId: selectingClient.canonical_id,
      previousClientCanonicalId: previouslySelectedClient?.canonical_id,
      duringSession: clientHasJoinedRoom,
    });
    trackConfirm();
    dispatch(admitClient(selectingClient));
  };

  const handleCancel = () => {
    // not awaiting to let it run in the background
    logClientManagementEvent({
      action: "NAME_CONFIRMATION_CANCEL",
      clientName: selectingClient?.name ?? "",
      incomingName: incomingClient?.name ?? "",
    });
    trackCancel();
    closeModal();
  };

  return (
    <Modal closeModal={handleCancel}>
      <div className={styles.container}>
        <div className={styles.heading}>Confirm Client Selection</div>

        <div className={styles.warningContainer}>
          <div className={styles.subheading}>
            <InstructionalIcon className={styles.instructionalIcon} />
            The selected client record doesn&apos;t seem to match the name
            provided by the client.
          </div>

          <div className={styles.nameDisplayLabel}>Name entered by client</div>
          <div className={styles.nameDisplay}>{incomingClient?.name}</div>

          <div className={styles.nameDisplayLabel}>Selected client record</div>
          <div className={styles.nameDisplay}>{selectingClient?.name}</div>
        </div>

        <div className={styles.buttonRow}>
          <Button onClick={handleCancel} secondary>
            Back
          </Button>

          <Button onClick={handleSubmit}>Confirm</Button>
        </div>
      </div>
    </Modal>
  );
};

export default ClientConfirmationNameModal;

import React from "react";

import styles from "./ShareRoomConfirmationModal.module.css";
import Button from "components/Button/Button";
import Modal from "components/Modal/Modal";

type SharedRoomConfirmationModalProps = {
  roomName: string;
  handleConfirm: () => void;
  closeModal: () => void;
};

const SharedRoomConfirmationModal = ({
  roomName,
  handleConfirm,
  closeModal,
}: SharedRoomConfirmationModalProps) => (
  <Modal closeModal={closeModal}>
    <div className={styles.heading}>No name change</div>
    <div className={styles.body}>
      {`"${roomName}" is the same as it is named in your personal folder. Please confirm the name is appropriate for sharing with colleagues, including removing references to specific patients.`}
    </div>
    <div className={styles.buttonRow}>
      <Button className={styles.cancelButton} onClick={closeModal}>
        Back
      </Button>
      <Button onClick={handleConfirm}>Confirm</Button>
    </div>
  </Modal>
);

export default SharedRoomConfirmationModal;

import React, { MouseEventHandler, Ref } from "react";

import styles from "pages/Space/components/Videos/VideosButtons/VideoButton.module.css";
import { useSelector } from "react-redux";
import Tooltip from "components/Tooltip/Tooltip";
import clsx from "clsx";
import { selectIsFullScreenVideo } from "redux/spaceNavigationRedux";

type VideoButtonProps = {
  id?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick: MouseEventHandler;
  tooltipText: string;
  forceVisible: boolean;
  icon: React.ComponentType<{ className: string }>;
  isIndicator?: boolean;
};

export const VideoButton = React.forwardRef(function VideoButton(
  {
    id,
    className,
    style,
    onClick,
    tooltipText,
    forceVisible,
    icon: Icon,
    isIndicator,
  }: VideoButtonProps,
  ref: Ref<HTMLDivElement>
) {
  const isFullScreen = useSelector(selectIsFullScreenVideo);

  return (
    <div
      id={id}
      className={clsx(styles.buttonContainer, className)}
      style={style}
      ref={ref}
    >
      {/* Passing the key prop to be sure to reset the tooltip
          component when the fullscreen state changes */}
      <Tooltip text={tooltipText} key={`${isFullScreen}`}>
        <button
          className={clsx(styles.button, {
            [styles.fullScreenButton]: isFullScreen,
            [styles.visible]: forceVisible,
            [styles.indicator]: isIndicator,
          })}
          onClick={onClick}
        >
          <Icon
            className={clsx(styles.icon, {
              [styles.red]: isIndicator,
            })}
          />
        </button>
      </Tooltip>
    </div>
  );
});

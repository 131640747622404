import React, { useEffect, useMemo, useRef, forwardRef } from "react";
import styles from "./ClientSelectionList.module.css";
import {
  Client,
  openNewClient,
  selectClientSearchText,
  selectCurrentClient,
  setClientSearchText,
} from "redux/clientManagementRedux";
import clsx from "clsx";
import TextInput from "components/TextInput/TextInput";
import Button from "components/Button/Button";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as XIcon } from "assets/icons/x.svg";
import { useDispatch, useSelector } from "react-redux";
import { createFuseInstance } from "utils/fuseUtils";
import { isMobileDevice } from "utils/deviceUtils";

type ListItemProps = {
  client: Client;
  onChange: (value?: Client) => void;
  isLoaded: boolean;
  isSelected: boolean;
  ref?: React.Ref<HTMLLIElement>;
};

const ListItem = forwardRef<HTMLLIElement, ListItemProps>(
  ({ client, onChange, isLoaded, isSelected }, ref) => {
    return (
      <li
        ref={ref}
        onClick={() => onChange(client)}
        className={clsx(styles.listItem, {
          [styles.loaded]: isLoaded,
          [styles.selected]: isSelected,
        })}
        title={client.name}
      >
        {client.name}
      </li>
    );
  }
);

ListItem.displayName = "ListItem";

type ClientSelectionListProps = {
  clientList: Client[];
  className?: string;
  value?: Client;
  onChange: (value?: Client) => void;
  shouldCenterAutoScrollRef: React.MutableRefObject<boolean>;
  showNewClientButton?: boolean;
};

const ClientSelectionList = ({
  clientList,
  className,
  value,
  onChange,
  shouldCenterAutoScrollRef,
  showNewClientButton = true,
}: ClientSelectionListProps) => {
  const dispatch = useDispatch();
  const searchText = useSelector(selectClientSearchText);
  const selectedRef = useRef<HTMLLIElement>(null);
  const currentClient = useSelector(selectCurrentClient);

  const fuseClientList = useMemo(() => {
    return createFuseInstance(clientList);
  }, [clientList]);

  const filteredList = useMemo(() => {
    if (searchText === "") {
      return clientList;
    }
    const fuseResult = fuseClientList.search(searchText);
    return fuseResult.map((fuseClient) => fuseClient.item);
  }, [searchText, fuseClientList]);

  useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({
        behavior: "auto",
        block: shouldCenterAutoScrollRef.current ? "center" : "nearest",
      });
      shouldCenterAutoScrollRef.current = false;
    }
  }, [value, clientList]);

  const openCreateClient = () => {
    dispatch(openNewClient());
  };

  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.searchInput}>
        <TextInput
          placeholder="Search clients"
          value={searchText}
          setValue={(value) => dispatch(setClientSearchText(value))}
          autoFocus={!isMobileDevice(true)}
        />
        <SearchIcon className={styles.searchIcon} />
        {!!searchText && (
          <div
            className={styles.clearButton}
            onClick={() => dispatch(setClientSearchText(""))}
          >
            <XIcon className={styles.xIcon} />
          </div>
        )}
      </div>
      {showNewClientButton && (
        <Button
          onClick={openCreateClient}
          className={styles.newClient}
          id="newClientButton"
        >
          <PlusIcon className={styles.plusIcon} />
          <span>New Client</span>
        </Button>
      )}
      <ul className={styles.list}>
        {filteredList.length > 0 && (
          <>
            {filteredList.map((client) => (
              <ListItem
                client={client}
                onChange={onChange}
                isLoaded={client.canonical_id === currentClient?.canonical_id}
                isSelected={client.canonical_id === value?.canonical_id}
                key={`filtered-${client.canonical_id}`}
                ref={
                  client.canonical_id === value?.canonical_id
                    ? selectedRef
                    : null
                }
              />
            ))}
          </>
        )}

        {filteredList.length === 0 && (
          <div className={styles.emptyListWrapper}>
            <div className={styles.emptyList}>
              {!!searchText ? "No Matches" : "No clients"}
            </div>
            {!searchText && (
              <div
                className={styles.newClientInList}
                onClick={openCreateClient}
              >
                Add a new client to get started
              </div>
            )}
          </div>
        )}
      </ul>
    </div>
  );
};

export default ClientSelectionList;

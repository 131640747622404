import { fabricTypes } from "utils/fabric-impl";

// Clear canvas contents when done to work around Safari canvas caching issues,
// see https://bugs.webkit.org/show_bug.cgi?id=195325,
// https://stackoverflow.com/questions/52532614/total-canvas-memory-use-exceeds-the-maximum-limit-safari-12,
// and https://pqina.nl/blog/total-canvas-memory-use-exceeds-the-maximum-limit/
export const clearFabricCanvas = (
  canvas: fabricTypes.StaticCanvas | undefined
) => {
  if (!canvas) {
    return;
  }
  canvas?.setHeight(0);
  canvas?.setWidth(0);
  // Also clear the intermediate canvases used when applying filters (blur, brightness, etc),
  // and the underlying object canvas if the object is based on a canvas (e.g. Images from pdfs).
  for (const obj of [...canvas.getObjects(), canvas.backgroundImage]) {
    // @ts-ignore
    if (!obj || obj._teleo_cached) {
      // Maintain these object canvases if we're caching the object somewhere
      continue;
    }
    // @ts-ignore
    if (obj._filteredEl) {
      // @ts-ignore
      obj._filteredEl.width = 0;
      // @ts-ignore
      obj._filteredEl.height = 0;
    }
    // @ts-ignore
    if (obj.getElement) {
      // @ts-ignore
      const element = obj.getElement();
      if (element && element instanceof HTMLCanvasElement) {
        // Set to 1 instead of 0 here because otherwise an exception is thrown when setting the
        // background image to size 0x0
        element.height = 1;
        element.width = 1;
      }
    }
  }
};

export const clearHTMLCanvas = (canvas: HTMLCanvasElement | null) => {
  if (!canvas) {
    return;
  }
  canvas.height = 0;
  canvas.width = 0;
};

import React, { Dispatch, MouseEventHandler, SetStateAction } from "react";
import clsx from "clsx";
import styles from "./ResourceAction.module.css";

import { ReactComponent as DotDotDotIcon } from "assets/icons/dotdotdot.svg";
import launchIcon from "assets/icons/launch.svg";
import editIcon from "assets/icons/edit.svg";
import renameIcon from "assets/icons/rename.svg";
import volunteerIcon from "assets/icons/volunteer.svg";
import deleteIcon from "assets/icons/delete.svg";
import { GetResourcesQuery } from "generated/graphql";
import { getFileUrl } from "utils/fileUtils";
import { useDispatch } from "react-redux";
import {
  setDeleteResourceModalData,
  setSharePubliclyModalData,
  setSuggestEditModalData,
} from "redux/editRoomNavigationRedux";
import ResourceFavorite from "../ResourceFavorite/ResourceFavorite";
import MenuRow from "pages/Space/components/Menu/MenuRow";
import Menu from "pages/Space/components/Menu/Menu";
import Tooltip from "components/Tooltip/Tooltip";

type ResourceActionProps = {
  resource: GetResourcesQuery["resource"][0];
  thumbnailSrc: string | null | undefined;
  menuIsOpen: boolean;
  isFavoriteRow?: boolean;
  setMenuIsOpen: Dispatch<SetStateAction<boolean>>;
  setRenameMode: () => void;
};

const ResourceAction = ({
  resource,
  thumbnailSrc,
  menuIsOpen,
  isFavoriteRow,
  setMenuIsOpen,
  setRenameMode,
}: ResourceActionProps) => {
  const resourceUser = resource.resource_users?.[0];
  const dispatch = useDispatch();

  const previewResource: MouseEventHandler<HTMLDivElement> = async (ev) => {
    ev.stopPropagation();
    if (resource.url) {
      let url = resource.url;
      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        url = `https://${url}`;
      }
      window.open(url);
    } else if (resource.file_key) {
      const backgroundImageUrl = await getFileUrl(resource.file_key);
      window.open(backgroundImageUrl);
    }
    setMenuIsOpen(false);
  };

  const openSuggestEditModal: MouseEventHandler<HTMLDivElement> = (ev) => {
    ev.stopPropagation();
    dispatch(setSuggestEditModalData({ resource, thumbnailSrc }));
    setMenuIsOpen(false);
  };

  const openDeleteModal: MouseEventHandler<HTMLDivElement> = (ev) => {
    ev.stopPropagation();
    dispatch(setDeleteResourceModalData({ resource, thumbnailSrc }));
    setMenuIsOpen(false);
  };

  const rename: MouseEventHandler<HTMLDivElement> = (ev) => {
    ev.stopPropagation();
    setRenameMode();
    setMenuIsOpen(false);
  };

  const sharePublicly: MouseEventHandler<HTMLDivElement> = (ev) => {
    ev.stopPropagation();
    dispatch(setSharePubliclyModalData({ resource, thumbnailSrc }));
    setMenuIsOpen(false);
  };

  const openMenu: MouseEventHandler<HTMLDivElement> = async (ev) => {
    ev.stopPropagation();
    setMenuIsOpen(true);
  };

  const closeMenu: MouseEventHandler<HTMLDivElement> = async (ev) => {
    ev.stopPropagation();
    setMenuIsOpen(false);
  };

  const stopPropagation: MouseEventHandler<HTMLDivElement> = async (ev) => {
    ev.stopPropagation();
  };

  return (
    <div
      className={clsx(styles.container, {
        [styles.containerHalfWidth]: isFavoriteRow,
      })}
    >
      {!isFavoriteRow && (
        <ResourceFavorite
          resourceId={resource.id}
          isFavorite={resourceUser?.is_favorite}
          isFavoriteRow={isFavoriteRow}
        />
      )}
      <div
        className={styles.menuContainer}
        onClick={openMenu}
        onMouseDown={stopPropagation}
        onMouseUp={stopPropagation}
      >
        <Tooltip text="More" elementWrapperClassName={styles.icon}>
          <DotDotDotIcon />
        </Tooltip>
        {menuIsOpen ? (
          <Menu closeMenu={closeMenu}>
            {resource.url || resource.file_key ? (
              <MenuRow
                label={"Preview"}
                iconSrc={launchIcon}
                onClick={previewResource}
              />
            ) : null}
            {resource.owner_id === null ? (
              <MenuRow
                label={"Suggest Edit"}
                iconSrc={editIcon}
                onClick={openSuggestEditModal}
              />
            ) : (
              <>
                <MenuRow
                  label={"Rename"}
                  iconSrc={renameIcon}
                  onClick={rename}
                />
                <MenuRow
                  label={"Share Publicly"}
                  iconSrc={volunteerIcon}
                  onClick={sharePublicly}
                />
                <MenuRow
                  label={"Delete"}
                  iconSrc={deleteIcon}
                  onClick={openDeleteModal}
                />
              </>
            )}
          </Menu>
        ) : null}
      </div>
      {isFavoriteRow && (
        <ResourceFavorite
          resourceId={resource.id}
          isFavorite={resourceUser?.is_favorite}
          isFavoriteRow={isFavoriteRow}
        />
      )}
    </div>
  );
};

export default ResourceAction;

import React, {
  ChangeEvent,
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";

import styles from "./ControlBar.module.css";
import Toggle from "components/Toggle/Toggle";
import {
  selectCurrentRoomId,
  selectDecodedAuthToken,
  selectUserId,
  selectUserRole,
  UserRole,
} from "redux/userRedux";
import { useDispatch, useSelector } from "react-redux";
import {
  selectClientCanControl,
  selectEnableResourceNameViewer,
  setClientCanControl,
  setEnableResourceNameViewer,
} from "redux/settingsRedux";
import {
  selectMeetingID,
  selectShowInviteModal,
  selectShowSettingsModal,
  setShowInviteModal,
  setShowSettingsModal,
} from "redux/spaceNavigationRedux";

import {
  openLoadModal,
  selectClientFileOpen,
  selectClientHasJoinedRoom,
  selectCurrentClient,
  toggleClientFileOpen,
} from "redux/clientManagementRedux";
import ControlBarItem from "./ControlBarItem";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as EndIcon } from "assets/icons/stop_circle.svg";
import { ReactComponent as InviteIcon } from "assets/icons/meeting_room.svg";
import { ReactComponent as SignOutIcon } from "assets/icons/leave.svg";
import { ReactComponent as CircleArrowsIcon } from "assets/icons/circle_arrows.svg";
import expandIcon from "assets/icons/expand_more.svg";
import { ReactComponent as WaitingIcon } from "assets/icons/waiting.svg";
import clsx from "clsx";
import { useAppSignOut } from "utils/appSignOutUtils";
import { logUnexpectedError } from "utils/errorUtils";
import {
  useGetControlBarDataQuery,
  useSetResourceNameViewerMutation,
  useSetRoomNameMutation,
} from "generated/graphql";
import RoomMenu from "./RoomMenu/RoomMenu";
import {
  resetEditRoomNavigation,
  selectEditRoomMode,
  setEditRoomMode,
} from "redux/editRoomNavigationRedux";
import { WaitingRoomMenu } from "../ClientManagement/WaitingRoomMenu/WaitingRoomMenu";
import { SwitchClientButton } from "../ClientManagement/SwitchClientButton";
import { ReactComponent as PersonIcon } from "assets/icons/person.svg";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { ReactComponent as EyeOffIcon } from "assets/icons/eye_off.svg";
import TakeSnapshotButton from "./Snapshots/TakeSnapshotButton";
import SnapshotSnackbarManager from "./Snapshots/SnapshotSnackbarManager";
import Tooltip from "components/Tooltip/Tooltip";
import { selectSessionEHRSystem } from "redux/ehrSystemRedux";
import { Peers } from "pages/Space/hooks/connection/usePeerWebRTCConnection";
import { useTrackEvent } from "utils/metricsUtils";

export const MAX_ROOM_NAME_LENGTH = 100;

type ControlBarProps = {
  showEditRoom?: boolean;
  showSnapshot?: boolean;
  isConnectedToPeer: boolean;
  endSession: () => void;
  leave: (message?: string) => void;
  setRemoteClientControl: (clientCanControl: boolean) => void;
  setRemoteEditRoomMode: (editRoomMode: boolean) => void;
  sendRemoteRoomChanged: () => void;
  peersRef: React.MutableRefObject<Peers>;
};

const ControlBar = ({
  showEditRoom,
  showSnapshot,
  isConnectedToPeer,
  endSession,
  leave,
  setRemoteClientControl,
  setRemoteEditRoomMode,
  sendRemoteRoomChanged,
  peersRef,
}: ControlBarProps) => {
  const appSignOut = useAppSignOut();
  const ehrSystem = useSelector(selectSessionEHRSystem);
  const decodedAuthToken = useSelector(selectDecodedAuthToken);

  const userRole = useSelector(selectUserRole);
  const userId = useSelector(selectUserId);
  const clientCanControl = useSelector(selectClientCanControl);
  const editRoomMode = useSelector(selectEditRoomMode);
  const enableResourceNameViewer = useSelector(selectEnableResourceNameViewer);
  const showInviteModal = useSelector(selectShowInviteModal);
  const showSettingsModal = useSelector(selectShowSettingsModal);
  const meetingID = useSelector(selectMeetingID);
  const roomId = useSelector(selectCurrentRoomId);
  const clientHasJoinedRoom = useSelector(selectClientHasJoinedRoom);

  const [roomMenuIsExpanded, setRoomMenuIsExpanded] = useState(false);
  const [roomNameLocal, setRoomNameLocal] = useState("");
  const [renameRoomMode, setRenameRoomMode] = useState(false);
  const roomNameInputRef = useRef<HTMLInputElement>(null);

  const clientButtonRef = useRef<HTMLDivElement>(null);

  const [waitingRoomMenuIsExpanded, setWaitingRoomMenuIsExpanded] =
    useState(false);

  const currentClient = useSelector(selectCurrentClient);

  const dispatch = useDispatch();

  const { trackEvent } = useTrackEvent();

  const [setRoomNameMutation] = useSetRoomNameMutation();
  const [setResourceNameViewerMutation] = useSetResourceNameViewerMutation();

  const { data } = useGetControlBarDataQuery({
    variables: {
      meetingID: meetingID ?? "",
    },
    skip: !meetingID || userRole !== UserRole.THERAPIST,
  });

  const roomNameFromDb = data?.meeting_by_pk?.provider?.current_room?.name;

  useEffect(() => {
    if (roomNameFromDb) {
      setRoomNameLocal(roomNameFromDb);
    }
  }, [roomNameFromDb]);

  useEffect(() => {
    if (renameRoomMode) {
      roomNameInputRef.current?.select();
    }
  }, [renameRoomMode]);

  const closeRoomMenu = () => {
    setRoomMenuIsExpanded(false);
  };

  const maybeRenameRoom = async () => {
    if (!roomId) {
      return;
    }

    // If the user cleared the input text, or the text is invalid, reset it.
    if (!roomNameLocal || roomNameLocal.length > MAX_ROOM_NAME_LENGTH) {
      if (roomNameFromDb) {
        setRoomNameLocal(roomNameFromDb);
      }
      return;
    }

    // If the name changed, update the room name in the db
    if (roomNameLocal !== roomNameFromDb) {
      await setRoomNameMutation({
        variables: {
          roomId,
          roomName: roomNameLocal,
        },
      });
    }
  };

  const closeRenameRoomMode = async () => {
    maybeRenameRoom();
    setRenameRoomMode(false);
  };

  const closeWaitingRoomMenu = () => {
    setWaitingRoomMenuIsExpanded(false);
  };

  const hasActiveMenuOrField =
    roomMenuIsExpanded || renameRoomMode || waitingRoomMenuIsExpanded;
  const closeActiveMenusAndFields = () => {
    closeRoomMenu();
    closeRenameRoomMode();
    closeWaitingRoomMenu();
  };

  if (userRole !== UserRole.THERAPIST) {
    return null;
  }

  const onSettings = () => {
    dispatch(setShowSettingsModal(true));
  };

  const controlText = clientCanControl ? "On" : "Off";
  const onToggleControl = () => {
    dispatch(setClientCanControl(!clientCanControl));
    setRemoteClientControl(!clientCanControl);
  };

  const onEditRoom = () => {
    setRemoteEditRoomMode(!editRoomMode);
    if (editRoomMode) {
      dispatch(resetEditRoomNavigation());
    } else {
      dispatch(setEditRoomMode(true));
    }
    if (isClientFileOpen) {
      dispatch(toggleClientFileOpen());
    }
  };

  const onWaitingRoomClick = () => {
    setWaitingRoomMenuIsExpanded(!waitingRoomMenuIsExpanded);
  };

  const onInvite = () => {
    dispatch(setShowInviteModal(true));
  };

  const signOut = async () => {
    leave("Signing out...");
    await appSignOut();
  };

  const toggleRoomMenu = () => {
    setRoomMenuIsExpanded(!roomMenuIsExpanded);
  };

  const renameRoom = () => {
    setRenameRoomMode(true);
  };

  const roomNameChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomNameLocal(event.target.value);
  };

  const toggleEnableResourceNameViewer = () => {
    const newValue = !enableResourceNameViewer;
    // TODO: synchronize these better (probably just set value in DB with optimistic response)
    dispatch(setEnableResourceNameViewer(newValue));
    setResourceNameViewerMutation({
      variables: { userId, resourceNameViewer: newValue },
    }).catch(logUnexpectedError);
    trackEvent("Item label viewer changed", {
      "Viewer State": newValue ? "enabled" : "disabled",
    });
  };

  const detectEnterToSubmit: KeyboardEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.key === "Enter") {
      closeRenameRoomMode();
    }
  };
  const isClientFileOpen = useSelector(selectClientFileOpen);

  const handleClientClick = () => {
    trackEvent("Control bar - client clicked");
    if (currentClient) {
      dispatch(toggleClientFileOpen());
    } else if (!ehrSystem || !decodedAuthToken?.allowedPatientID) {
      dispatch(openLoadModal());
    }
    if (editRoomMode) {
      dispatch(resetEditRoomNavigation());
    }
  };

  const handleSwitchClientClick = () => {
    trackEvent("Control bar - switch client clicked");
    dispatch(openLoadModal());
  };

  const roomNameToDisplay = roomNameFromDb || "Unnamed Room";
  const waitingRoomCount =
    data?.waiting_room_participant_aggregate.aggregate?.count;
  const someoneIsInWaitingRoom = waitingRoomCount && waitingRoomCount > 0;

  return (
    <div className={styles.container}>
      <div className={styles.itemContainer}>
        <ControlBarItem
          label={"Settings"}
          icon={SettingsIcon}
          onClick={onSettings}
          selected={showSettingsModal}
        />
        <ControlBarItem
          id={"clientControlToggle"}
          label={"Client Control"}
          onClick={onToggleControl}
        >
          <Toggle isOn={clientCanControl} text={controlText} />
        </ControlBarItem>
        {showEditRoom && roomId !== undefined ? (
          <ControlBarItem
            id={"editRoomButton"}
            label={"Edit Room"}
            icon={EditIcon}
            onClick={onEditRoom}
            key="editRoom"
            className={clsx(styles.activableButton, {
              [styles.active]: !!editRoomMode,
            })}
          />
        ) : null}
        <TakeSnapshotButton showSnapshot={showSnapshot} />
        <div className={styles.clientButtonContainer}>
          <Tooltip text={currentClient?.name}>
            <ControlBarItem
              id={"selectedClientToolbarButton"}
              icon={PersonIcon}
              className={clsx(styles.clientButton, styles.activableButton, {
                [styles.clientSelected]: !!currentClient,
                [styles.active]: !!isClientFileOpen,
              })}
              label={currentClient?.name ?? "Client"}
              labelClassName={
                !!currentClient ? styles.clientSelectedLabel : undefined
              }
              onClick={handleClientClick}
              key={currentClient?.canonical_id}
              ref={clientButtonRef}
            />
          </Tooltip>
          {currentClient && (
            <SwitchClientButton
              onClick={handleSwitchClientClick}
              tooltipText="Switch client"
              icon={CircleArrowsIcon}
              className={styles.switchClientButton}
            />
          )}
        </div>
        <SnapshotSnackbarManager clientButtonRef={clientButtonRef} />
      </div>
      <div className={styles.middleContainer}>
        <div className={styles.roomNameContainer}>
          <Tooltip
            text={"Activity names will only be visible to you."}
            elementWrapperClassName={styles.viewerTooltipWrapper}
            tooltipClassName={styles.viewerTooltip}
          >
            <div
              className={styles.viewerButton}
              onClick={toggleEnableResourceNameViewer}
            >
              {enableResourceNameViewer ? (
                <EyeOffIcon className={styles.eyeIcon} />
              ) : (
                <EyeIcon className={styles.eyeIcon} />
              )}
            </div>
          </Tooltip>
          {renameRoomMode ? (
            <input
              ref={roomNameInputRef}
              type="text"
              name="roomName"
              value={roomNameLocal}
              className={styles.textInput}
              onChange={roomNameChangeHandler}
              onKeyDown={detectEnterToSubmit}
              maxLength={MAX_ROOM_NAME_LENGTH}
              enterKeyHint={"done"}
            />
          ) : (
            <>
              {roomId ? (
                <div
                  className={styles.roomName}
                  onClick={renameRoom}
                  title={roomNameToDisplay}
                >
                  {roomNameToDisplay}
                </div>
              ) : (
                <div className={clsx(styles.roomName, styles.noRoomName)}>
                  [No Room Opened]
                </div>
              )}
              <div className={styles.roomMenuAndIconContainer}>
                <img
                  src={expandIcon}
                  className={clsx(styles.expandIcon, {
                    [styles.activeIcon]: roomMenuIsExpanded,
                    [styles.inactiveIcon]: !roomMenuIsExpanded,
                  })}
                  onClick={toggleRoomMenu}
                />
                {roomMenuIsExpanded ? (
                  <RoomMenu
                    closeMenu={closeRoomMenu}
                    renameRoom={renameRoom}
                    sendRemoteRoomChanged={sendRemoteRoomChanged}
                  />
                ) : null}
              </div>
            </>
          )}
        </div>
        <div
          className={clsx(styles.statusText, {
            [styles.activeStatusText]: isConnectedToPeer,
          })}
        >
          {isConnectedToPeer
            ? "Client is connected"
            : clientHasJoinedRoom
            ? "Client is connecting..."
            : "Client is not connected"}
        </div>
      </div>
      <div className={clsx(styles.itemContainer, styles.itemContainerRight)}>
        {clientHasJoinedRoom || isConnectedToPeer ? (
          <ControlBarItem
            onClick={endSession}
            label={"End Session"}
            icon={EndIcon}
          />
        ) : null}
        <div className={styles.waitingRoomItemContainer}>
          <ControlBarItem
            label={"Waiting"}
            icon={WaitingIcon}
            onClick={onWaitingRoomClick}
            selected={waitingRoomMenuIsExpanded}
          />
          {someoneIsInWaitingRoom ? (
            <div className={styles.notificationDot} />
          ) : null}
          {waitingRoomMenuIsExpanded ? (
            <WaitingRoomMenu
              closeMenu={closeWaitingRoomMenu}
              peersRef={peersRef}
            />
          ) : null}
        </div>
        {!ehrSystem && (
          <ControlBarItem
            id={"inviteButton"}
            label={"Invite"}
            icon={InviteIcon}
            onClick={onInvite}
            selected={showInviteModal}
          />
        )}
        <ControlBarItem
          className={styles.red}
          label={"Sign Out"}
          icon={SignOutIcon}
          onClick={signOut}
        />
      </div>
      {hasActiveMenuOrField ? (
        <div className={styles.overlay} onClick={closeActiveMenusAndFields} />
      ) : null}
    </div>
  );
};

export default ControlBar;

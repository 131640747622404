import { readLargeEvent, sendLargeEvent } from "utils/webrtcUtils";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUserRole, UserRole } from "redux/userRedux";
import {
  selectBrowserSandboxUrl,
  selectCurrentPage,
  selectCurrentRoomItemId,
  selectIsFullScreenVideo,
  selectShowRoomCustomizationActivityModal,
  selectWhiteboardBackground,
  setBrowserSandboxInitialize,
  setBrowserSandboxUrl,
  setCurrentPage,
  setCurrentResourceId,
  setCurrentRoomItemId,
  setIsFullScreenVideo,
  setShowRoomCustomizationActivityModal,
  setWhiteboardBackground,
} from "redux/spaceNavigationRedux";
import {
  selectAudioProviderOnly,
  selectClientCanControl,
  selectIsMuted,
  selectIsVideoOff,
  selectIsViewportLocked,
  selectPeerIdWithControl,
  selectVideoConferencing,
  setAudioProviderOnly,
  setClientCanControl,
  setIsPeerMuted,
  setIsPeerVideoOff,
  setIsViewportLocked,
  setPeerIdWithControl,
  setVideoConferencing,
} from "redux/settingsRedux";
import {
  selectEditRoomMode,
  setEditRoomMode,
} from "redux/editRoomNavigationRedux";
import {
  selectAlbumPages,
  selectCurrentAlbumPage,
  selectImgKeysToURL,
  selectViewingSnapshot,
  setAlbumPages,
  setCurrentAlbumPage,
  setImgKeysToURL,
  setViewingSnapshot,
} from "redux/clientAlbumRedux";
import {
  selectCurrentClient,
  setCurrentClient,
} from "redux/clientManagementRedux";
import { Peers, PeerWebRTCObject } from "./connection/usePeerWebRTCConnection";
import { store } from "redux/reduxStore";

export const SYNC_EVENT_NAME = "sync-state";

export const useSyncState = (
  peers: Peers,
  setRemoteIsPeerMuted: (isPeerMuted: boolean, peerId?: string) => void,
  setRemoteIsPeerVideoOff: (isPeerMuted: boolean, peerId?: string) => void,
  triggerMediaErrorSync: () => void
) => {
  const dispatch = useDispatch();
  const userRole = useSelector(selectUserRole);
  const currentClient = useSelector(selectCurrentClient);
  const editRoomMode = useSelector(selectEditRoomMode);
  const clientCanControl = useSelector(selectClientCanControl);
  const peerIdWithControl = useSelector(selectPeerIdWithControl);
  const currentPage = useSelector(selectCurrentPage);
  const whiteboardBackground = useSelector(selectWhiteboardBackground);
  const browserSandboxUrl = useSelector(selectBrowserSandboxUrl);
  const showRoomCustomizationActivityModal = useSelector(
    selectShowRoomCustomizationActivityModal
  );
  const audioProviderOnly = useSelector(selectAudioProviderOnly);
  const videoConferencing = useSelector(selectVideoConferencing);
  const isFullScreenVideo = useSelector(selectIsFullScreenVideo);
  const isMuted = useSelector(selectIsMuted);
  const isVideoOff = useSelector(selectIsVideoOff);
  const roomItemId = useSelector(selectCurrentRoomItemId);

  const currentAlbumPage = useSelector(selectCurrentAlbumPage);
  const albumPages = useSelector(selectAlbumPages);
  const imgKeysToURL = useSelector(selectImgKeysToURL);
  const viewingSnapshot = useSelector(selectViewingSnapshot);

  const isViewportLocked = useSelector(selectIsViewportLocked);

  const onReceiveMessageCallback = async (event: MessageEvent) => {
    const data = await readLargeEvent(event);
    if (!data) {
      return;
    }
    const eventType = data.event;
    const peerId = data.senderId;
    const state = store.getState();
    const currentIsMuted = selectIsMuted(state);
    const currentIsVideoOff = selectIsVideoOff(state);
    if (eventType === SYNC_EVENT_NAME && userRole === UserRole.CLIENT) {
      dispatch(setCurrentClient(data.data.currentClient));
      dispatch(setEditRoomMode(data.data.editRoomMode));
      dispatch(setClientCanControl(data.data.clientCanControl));
      dispatch(setPeerIdWithControl(data.data.peerIdWithControl));
      dispatch(setAudioProviderOnly(data.data.audioProviderOnly));
      dispatch(setVideoConferencing(data.data.videoConferencing));
      dispatch(setIsFullScreenVideo(data.data.isFullScreenVideo));
      dispatch(setIsPeerMuted({ peerId, isMuted: data.data.isMuted }));
      dispatch(setIsPeerVideoOff({ peerId, isVideoOff: data.data.isVideoOff }));
      const navigationState = data.data.navigationState;
      dispatch(setWhiteboardBackground(navigationState.whiteboardBackground));
      dispatch(setBrowserSandboxUrl(navigationState.browserSandboxUrl));
      dispatch(setBrowserSandboxInitialize(false));
      dispatch(setCurrentPage(navigationState.currentPage));
      dispatch(setCurrentRoomItemId(navigationState.currentRoomItemId));
      dispatch(
        setShowRoomCustomizationActivityModal(
          navigationState.showRoomCustomizationActivityModal
        )
      );
      dispatch(setCurrentResourceId(navigationState.resourceId));
      dispatch(setCurrentAlbumPage(data.data.currentAlbumPage));
      dispatch(setAlbumPages(data.data.albumPages));
      dispatch(setImgKeysToURL(data.data.imgKeysToURL));
      dispatch(setViewingSnapshot(data.data.viewingSnapshot));
      // Resend the client's mute and video off state to the provider when they reconnect
      setRemoteIsPeerMuted(currentIsMuted || false);
      setRemoteIsPeerVideoOff(currentIsVideoOff || false);
      // Also resend the client's media errors to the provider
      triggerMediaErrorSync();
      dispatch(setIsViewportLocked(data.data.isViewportLocked));
    }
  };

  useEffect(() => {
    const peerDataChannels = Object.values(peers).map(
      (peer) => peer.dataChannel
    );
    for (const peerDataChannel of peerDataChannels) {
      if (peerDataChannel) {
        peerDataChannel.addEventListener("message", onReceiveMessageCallback);
      }
    }

    return () => {
      for (const peerDataChannel of peerDataChannels) {
        if (peerDataChannel) {
          peerDataChannel.removeEventListener(
            "message",
            onReceiveMessageCallback
          );
        }
      }
    };
  }, [peers]);

  const setRemoteSyncState = (peer: PeerWebRTCObject) => {
    sendLargeEvent(
      SYNC_EVENT_NAME,
      {
        currentClient: currentClient,
        editRoomMode,
        clientCanControl,
        peerIdWithControl,
        audioProviderOnly,
        videoConferencing,
        isFullScreenVideo,
        isMuted,
        isVideoOff,
        navigationState: {
          currentPage,
          currentRoomItemId: roomItemId,
          whiteboardBackground,
          browserSandboxUrl,
          showRoomCustomizationActivityModal,
        },
        currentAlbumPage,
        albumPages,
        imgKeysToURL,
        viewingSnapshot,
        isViewportLocked,
      },
      peer.dataChannel
    );
  };

  useEffect(() => {
    // Sync state when the client connects
    if (userRole === UserRole.THERAPIST) {
      for (const peer of Object.values(peers)) {
        if (peer.isConnected && peer.isDataChannelOpen) {
          setRemoteSyncState(peer);
        }
      }
    }
  }, [userRole, peers]);
};

import { useDispatch, useSelector } from "react-redux";
import {
  selectEncodedAuthToken,
  selectEncodedClientToken,
  setClientId,
} from "redux/userRedux";
import { useEffect, useRef, useState } from "react";
import io, { Socket } from "socket.io-client";
import { useAnalyticsInfo } from "utils/metricsUtils";
import { logUnexpectedError } from "utils/errorUtils";
import mixpanel from "mixpanel-browser";
import {
  pushWaitingRoomAlertClient,
  removeWaitingRoomAlertClient,
  selectClientHasJoinedRoom,
  setClientHasJoinedRoom,
  setCurrentClient,
} from "redux/clientManagementRedux";
import { useRefetchWaitingRoom } from "utils/waitingRoomUtils";
import { selectSessionEHRSystem } from "redux/ehrSystemRedux";
import { backendRequest } from "utils/backendRequest";

export const useRoomsManager = (
  meetingID: string | undefined,
  roleString: string
) => {
  const server = process.env.REACT_APP_SIGNALING_SERVER || "";
  const ehrSystem = useSelector(selectSessionEHRSystem);
  const encodedAuthToken = useSelector(selectEncodedAuthToken);
  const encodedClientToken = useSelector(selectEncodedClientToken);
  const encodedClientTokenRef = useRef(encodedClientToken); // for callback
  useEffect(() => {
    encodedClientTokenRef.current = encodedClientToken;
  }, [encodedClientToken]);
  const roomManagerSocketRef = useRef<Socket>();
  const clientHasJoinedRoom = useSelector(selectClientHasJoinedRoom);
  const [connectionError, setConnectionError] = useState("");

  const getAnalyticsInfo = useAnalyticsInfo();
  const refetchWaitingRoom = useRefetchWaitingRoom();

  const dispatch = useDispatch();

  const setUpRoomsManagerConnection = async (
    onNewRoomContainer: (
      newContainerInstance: string,
      newContainerId: string
    ) => void,
    onSessionEnded: () => void,
    onCloseConnections: () => void
  ) => {
    const {
      containerInstance,
      containerId,
      connectionError: joinRoomError,
    } = await joinRoomInRoomsManager();
    if (joinRoomError) {
      return { containerInstance, containerId, connectionError: joinRoomError };
    }

    await setUpSocket(onNewRoomContainer, onSessionEnded, onCloseConnections);

    return { containerInstance, containerId, connectionError: joinRoomError };
  };

  const closeRoomsManagerConnection = () => {
    if (roomManagerSocketRef.current) {
      roomManagerSocketRef.current.disconnect();
    }
  };

  const updateParticipantStatus = async (joinedStatus: boolean) => {
    const mixpanelUserId = mixpanel.get_distinct_id();
    return await backendRequest({
      path: `/rooms/${meetingID}/participant`,
      searchParams: { mixpanelUserId },
      options: {
        method: "POST",
        body: JSON.stringify({ joinedStatus }),
        headers: {
          "Content-Type": "text/plain",
        },
        keepalive: true, // make sure the request is sent completely even once the page is destroyed
      },
    }).catch(logUnexpectedError); // Firefox aborts and throws an error if navigating away from a page before the response is received;
  };

  const joinRoomInRoomsManager = async () => {
    const participantResult = await updateParticipantStatus(true);
    const isTherapist = roleString === "therapist";

    const participantResultJson = await participantResult
      ?.json()
      .catch(() => {});
    const containerInstance = participantResultJson?.containerInstance;
    const containerId = participantResultJson?.containerId;
    const clientHasJoinedResponse = participantResultJson?.clientHasJoinedRoom;
    const clientId = participantResultJson?.clientId;
    const error = participantResultJson?.error;

    if (
      !participantResult ||
      !participantResultJson ||
      participantResult.status !== 200 ||
      error
    ) {
      let externalErrorMessage;
      if (error === "No such meeting.") {
        if (isTherapist) {
          externalErrorMessage =
            "Error: This room link is invalid or has been closed. Please check that the url is correct, or open the room again.";
        } else {
          externalErrorMessage =
            "Error: This room link is invalid or has been closed. Please check that the url is correct.";
        }
      } else if (error === "there is already a therapist in the room") {
        externalErrorMessage =
          "Error: A provider has already connected to this room.";
      } else if (error === "there is already a client in the room") {
        externalErrorMessage = "Error: This room is already in use.";
      } else if (error === "invalid") {
        externalErrorMessage = isTherapist
          ? "Error: This room belongs to another therapist. Try signing out or using an Incognito window if you'd like to join as a client."
          : "Error: You are not authorized to join this room.";
      } else {
        const status = participantResult
          ? participantResult.status
          : "No result";
        const message = `Error: updateParticipantStatus returned <${status}> with error <${error}>`;
        logUnexpectedError(message);
        externalErrorMessage = "Error: Internal error. Please try again later.";
      }

      return { connectionError: externalErrorMessage };
    }

    if (isTherapist) {
      dispatch(setClientHasJoinedRoom(!!clientHasJoinedResponse));
      if (clientHasJoinedResponse) {
        dispatch(setClientId(clientId));
      } else {
        dispatch(setClientId(undefined));
      }
    }

    if (!containerId || !containerInstance) {
      logUnexpectedError(
        "Error: Rooms Manager joinRoom did not return both a containerId and containerInstance"
      );
      return {
        connectionError: "Error: Internal error. Please try again later.",
      };
    }

    return { containerInstance, containerId };
  };

  const setUpSocket = async (
    onNewRoomContainer: (
      newContainerInstance: string,
      newContainerId: string
    ) => void,
    onSessionEnded: () => void,
    onCloseConnections: () => void
  ) => {
    const onNewWaitingRoomClient = (secretClientId: string) => {
      dispatch(pushWaitingRoomAlertClient(secretClientId));
      refetchWaitingRoom().catch(logUnexpectedError);
    };

    const onWaitingRoomClientLeft = (secretClientId: string) => {
      dispatch(removeWaitingRoomAlertClient(secretClientId));
      refetchWaitingRoom().catch(logUnexpectedError);
    };

    const onReregister = () => {
      setConnectionError(
        "Disconnected. You may have opened this Teleo room in another tab."
      );
    };

    const onClientJoined = (clientId: string) => {
      dispatch(setClientId(clientId));
      dispatch(setClientHasJoinedRoom(true));
    };

    const onLastClientLeft = () => {
      dispatch(setClientHasJoinedRoom(false));
      dispatch(setClientId(undefined));
      if (!ehrSystem) {
        dispatch(setCurrentClient(undefined));
      }
    };

    roomManagerSocketRef.current?.disconnect();

    const roomManagerSocket = io(server, {
      transports: ["websocket", "polling"],
      path: `/socket.io`,
      withCredentials: true,
      reconnectionDelayMax: 1500,
      timeout: 3000,
    });
    // event fired upon connection and reconnection
    roomManagerSocket.on("connect", () => {
      refetchWaitingRoom().catch(logUnexpectedError);
    });
    roomManagerSocket.on("waiting room - new client", onNewWaitingRoomClient);
    roomManagerSocket.on("waiting room - client left", onWaitingRoomClientLeft);
    roomManagerSocket.on("containerId", onNewRoomContainer);
    roomManagerSocket.on("session ended", onSessionEnded);
    roomManagerSocket.on("close", onCloseConnections);
    roomManagerSocket.on("reregistered", onReregister);
    roomManagerSocket.on("client joined", onClientJoined);
    roomManagerSocket.on("last client left", onLastClientLeft);
    roomManagerSocket.on("new room opened", () => {
      setConnectionError("Room closed: You've opened a room in another tab.");
    });
    roomManagerSocket.on("error", (errorCode) => {
      logUnexpectedError(errorCode);
      if (errorCode === "CLIENT_REGISTRATION_NOT_AUTHORIZED") {
        setConnectionError("Session ended.");
      } else {
        setConnectionError(
          `Internal error: ${errorCode}. Please try again later.`
        );
      }
    });
    const analyticsInfo = await getAnalyticsInfo();
    roomManagerSocket.emit(
      "register participant",
      meetingID,
      roleString,
      encodedAuthToken || encodedClientTokenRef.current,
      analyticsInfo
    );
    roomManagerSocketRef.current = roomManagerSocket;
  };

  const reconnectToRoomsManagerIfNeeded = (
    onNewRoomContainer: (
      newContainerInstance: string,
      newContainerId: string
    ) => void,
    onSessionEnded: () => void,
    onCloseConnections: () => void,
    force: boolean
  ) => {
    if (roomManagerSocketRef.current?.disconnected || force) {
      setUpSocket(onNewRoomContainer, onSessionEnded, onCloseConnections);
    }
  };

  const leaveRoomInRoomsManager = () => {
    updateParticipantStatus(false);
  };

  const signalActivity = () => {
    roomManagerSocketRef.current?.emit("activity", meetingID, roleString);
  };

  const updateAnalyticsInfo = (analyticsInfo: any) => {
    roomManagerSocketRef.current?.emit(
      "update analytics info",
      meetingID,
      roleString,
      analyticsInfo
    );
  };

  const endSession = async () => {
    return await backendRequest({
      path: `/rooms/${meetingID}/end`,
      options: {
        method: "POST",
      },
    }).catch(logUnexpectedError);
  };

  return {
    setUpRoomsManagerConnection,
    closeRoomsManagerConnection,
    leaveRoomInRoomsManager,
    reconnectToRoomsManagerIfNeeded,
    signalActivity,
    updateAnalyticsInfo,
    connectionError,
    clientHasJoinedRoom,
    endSession,
    roomManagerSocketRef,
  };
};

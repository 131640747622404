import React, { useMemo } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";

import styles from "./SnapshotSnackbarManager.module.css";

import { selectSnapshotTasks } from "redux/spaceNavigationRedux";

import SnapshotSnackbar from "./SnapshotSnackbar";

type SnapshotSnackbarManagerProps = {
  clientButtonRef?: React.RefObject<HTMLDivElement>;
};

const SnapshotSnackbarManager = ({
  clientButtonRef,
}: SnapshotSnackbarManagerProps) => {
  const snapshotTasks = useSelector(selectSnapshotTasks);

  const listPosition = useMemo(() => {
    if (clientButtonRef?.current) {
      const buttonRect = clientButtonRef?.current.getBoundingClientRect();
      return {
        left: buttonRect.left + buttonRect.width / 2 - 250 / 2,
        bottom: window.innerHeight - buttonRect.top,
      };
    }
  }, [clientButtonRef?.current]);

  return createPortal(
    <>
      <div className={styles.listContainer} style={listPosition}>
        {snapshotTasks
          .filter((task) => !["created", "generating"].includes(task.status))
          .map((snapshotTask) => (
            <SnapshotSnackbar
              key={snapshotTask.id}
              snapshotTask={snapshotTask}
              clientButtonRef={clientButtonRef}
            />
          ))}
      </div>
    </>,
    document.body
  );
};

export default SnapshotSnackbarManager;

import { fabric } from "utils/fabricUtils";
import { fabricTypes } from "utils/fabric-impl";
import { useEffect, useRef } from "react";
import { COLORS } from "teleoConstants";

export const useCursor = (
  canvas: fabricTypes.Canvas | undefined,
  canvasRef: React.MutableRefObject<fabricTypes.Canvas | undefined>,
  cursorCanvas: fabricTypes.Canvas | undefined,
  cursorCanvasRef: React.MutableRefObject<fabricTypes.Canvas | undefined>
) => {
  const cursorIsEnabledRef = useRef(false);

  const cursorRef = useRef<fabricTypes.Circle>(
    new fabric.Circle({
      top: 0,
      left: 0,
      originX: "right",
      originY: "bottom",
      erasable: false,
    })
  );

  const setCursorVisibility = (visible: boolean) => {
    if (visible) {
      cursorRef.current.set({
        fill: COLORS.WHITE,
        stroke: COLORS.DARK,
      });
    } else {
      cursorRef.current.set({
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(0,0,0,0)",
      });
    }
  };

  useEffect(() => {
    if (!canvas || !cursorCanvas) {
      return;
    }
    cursorCanvas.add(cursorRef.current);
    cursorCanvas.renderAll();
    canvas.on("mouse:move", (event) => {
      if (
        !canvasRef.current ||
        !cursorCanvasRef.current ||
        !cursorIsEnabledRef.current
      ) {
        return;
      }
      const pointer = canvasRef.current?.getPointer(event.e);
      const cursorRadius = cursorRef.current.radius || 0;
      cursorRef.current.set({
        top: pointer.y + cursorRadius,
        left: pointer.x + cursorRadius,
      });
      setCursorVisibility(true);
      cursorCanvasRef.current.renderAll();
    });
    canvas.on("mouse:out", () => {
      if (cursorIsEnabledRef.current) {
        setCursorVisibility(false);
        cursorCanvasRef.current?.renderAll();
      }
    });
  }, [canvas, cursorCanvas]);

  const setCursorRadius = (radius: number) => {
    cursorRef.current.set({
      radius,
      strokeWidth: radius / 15,
    });
    cursorCanvasRef.current?.renderAll();
  };

  const setShowCursor = (show: boolean) => {
    cursorIsEnabledRef.current = show;
  };

  return {
    setCursorRadius,
    setShowCursor,
  };
};

import React from "react";
import clsx from "clsx";

import styles from "./Alert.module.css";
import Button from "components/Button/Button";

type AlertProps = {
  children: React.ReactNode;
  actionCTAText: string;
  className?: string;
  dismissHandler: () => void;
  actionHandler: () => void;
};

export const Alert = ({
  children,
  actionCTAText,
  className,
  dismissHandler,
  actionHandler,
}: AlertProps) => (
  <div className={clsx(styles.container, className)}>
    <div className={styles.subcontainer}>{children}</div>
    <div className={styles.subcontainer}>
      <Button className={styles.button} onClick={dismissHandler} secondary>
        Dismiss
      </Button>
      <Button className={styles.button} onClick={actionHandler}>
        {actionCTAText}
      </Button>
    </div>
  </div>
);

import React from "react";
import styles from "./WaitingRoomMenuRow.module.css";
import clsx from "clsx";
import xIcon from "assets/icons/x.svg";
import checkIcon from "assets/icons/check.svg";
import Tooltip from "components/Tooltip/Tooltip";
import {
  useDismissClient,
  useWaitingRoomAdmitHandler,
} from "utils/waitingRoomUtils";
import { logUnexpectedError } from "utils/errorUtils";
import { Peers } from "pages/Space/hooks/connection/usePeerWebRTCConnection";

type WaitingRoomMenuRowProps = {
  clientId: string;
  name: string;
  closeMenu: () => void;
  peersRef: React.MutableRefObject<Peers>;
};

export const WaitingRoomMenuRow = ({
  clientId,
  name,
  closeMenu,
  peersRef,
}: WaitingRoomMenuRowProps) => {
  const dismissClientOnServer = useDismissClient();
  const dismiss = async () => {
    // Remove from waiting room participants list
    await dismissClientOnServer({ clientId });
  };

  const admit = useWaitingRoomAdmitHandler({
    secretClientId: clientId,
    name,
    peersRef,
  });
  const admitClickHandler = async () => {
    try {
      await admit();
      closeMenu();
    } catch (e) {
      logUnexpectedError(e);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>{name}</div>
      <div className={styles.buttonContainer}>
        <Tooltip text={"Remove"}>
          <div className={clsx(styles.button, styles.secondary)}>
            <img className={styles.buttonIcon} src={xIcon} onClick={dismiss} />
          </div>
        </Tooltip>
        <Tooltip text={"Admit"}>
          <div className={styles.button}>
            <img
              className={styles.buttonIcon}
              src={checkIcon}
              onClick={admitClickHandler}
            />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

import { logUnexpectedError } from "utils/errorUtils";
import { useUpdateSharedRoomUsageCountMutation } from "generated/graphql";
import { useSelector } from "react-redux";
import { selectCurrentRoomId, selectUserId } from "redux/userRedux";
import { useEffect } from "react";
import { selectClientHasJoinedRoom } from "redux/clientManagementRedux";

export const useIncrementSharedRoomUsage = () => {
  const [updateSharedRoomUsageCountMutation] =
    useUpdateSharedRoomUsageCountMutation();
  const userId = useSelector(selectUserId);
  const currentRoomId = useSelector(selectCurrentRoomId);
  const clientHasJoinedRoom = useSelector(selectClientHasJoinedRoom);

  const incrementSharedRoomUsage = () => {
    updateSharedRoomUsageCountMutation({
      variables: {
        roomId: currentRoomId,
      },
    }).catch(logUnexpectedError);
  };

  // Only increment shared room usage count if the client has joined the room
  useEffect(() => {
    if (userId && currentRoomId && clientHasJoinedRoom) {
      incrementSharedRoomUsage();
    }
  }, [clientHasJoinedRoom, currentRoomId]);
};

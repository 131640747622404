import React, { useState } from "react";

import styles from "./ResourceUsageSection.module.css";
import AdminPageButton from "./AdminPageButton";
import { backendRequest } from "utils/backendRequest";

type ResourceUsageData = {
  id: string;
  name: string;
  attribution: string;
  usageCount: number;
}[];

const ResourceUsageSection = () => {
  const [data, setData] = useState<ResourceUsageData>();
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await backendRequest({ path: "/stats-resource-usage" });

      const data = await result.json();
      setData(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setData(undefined);
      setLoading(false);
    }
  };

  const getResourceRows = () => {
    if (loading) {
      return (
        <tr>
          <td>Loading</td>
        </tr>
      );
    }
    if (!data) {
      return (
        <tr>
          <td>No data</td>
        </tr>
      );
    }
    return data.map(({ id, name, attribution, usageCount }, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{id}</td>
        <td>{name}</td>
        <td>{attribution}</td>
        <td>{usageCount}</td>
      </tr>
    ));
  };

  return (
    <div className={styles.container}>
      <h3>Resource Usage Data</h3>
      <AdminPageButton onClick={fetchData}>
        Load Resource Usage Data
      </AdminPageButton>
      <table>
        <tbody>
          <tr key={"header"}>
            <th>Rank</th>
            <th>Resource ID</th>
            <th>Name</th>
            <th>Attribution</th>
            <th>Times Used</th>
          </tr>
          {getResourceRows()}
        </tbody>
      </table>
    </div>
  );
};

export default ResourceUsageSection;

import { useEffect, useState } from "react";
import { getAddBreadcrumb, logUnexpectedError } from "utils/errorUtils";

const addBreadcrumb = getAddBreadcrumb("browser.network");

export const useReconnectWhenOnline = (reconnectIfNeeded: () => void) => {
  const [isOffline, setIsOffline] = useState(false);

  const handleOnline = () => {
    try {
      setIsOffline(false);
      reconnectIfNeeded();
      addBreadcrumb("info", "online");
      logUnexpectedError("Browser is back online after being offline.");
    } catch (err) {
      logUnexpectedError(err);
    }
  };

  const handleOffline = () => {
    setIsOffline(true);
    addBreadcrumb("info", "offline");
    // send the sentry error only when the browser is back online
    // see handleOnline function.
  };

  useEffect(() => {
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return {
    isOffline,
  };
};

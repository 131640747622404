import { logUnexpectedError } from "utils/errorUtils";
import { useLogResourceUsageMutation } from "generated/graphql";
import { useSelector } from "react-redux";
import { selectClientId, selectUserId } from "redux/userRedux";
import { useEffect, useRef } from "react";
import { selectCurrentResourceId } from "redux/spaceNavigationRedux";
import {
  selectClientHasJoinedRoom,
  selectCurrentClient,
} from "redux/clientManagementRedux";

export const useLogResourceUsage = () => {
  const [logResourceUsageMutation] = useLogResourceUsageMutation();
  const userId = useSelector(selectUserId);
  const clientId = useSelector(selectClientId);
  const currentResourceId = useSelector(selectCurrentResourceId);
  const clientHasJoinedRoom = useSelector(selectClientHasJoinedRoom);
  const resourceIsPending = useRef(false);
  const currentClient = useSelector(selectCurrentClient);

  const logResourceUsage = () => {
    if (!currentResourceId) {
      return;
    }

    logResourceUsageMutation({
      variables: {
        resourceId: currentResourceId,
        userId,
        clientId,
        lastUsed: new Date().toISOString(),
        clientCanonicalId: currentClient?.canonical_id,
      },
    }).catch(logUnexpectedError);
  };

  // In case the last resource opened was not logged because the client wasn't in the room, log it
  // when the client joins
  useEffect(() => {
    if (
      userId &&
      currentResourceId &&
      resourceIsPending.current &&
      clientHasJoinedRoom
    ) {
      resourceIsPending.current = false;
      logResourceUsage();
    }
  }, [clientHasJoinedRoom]);

  useEffect(() => {
    // Reset pending state if navigating away from a resource
    if (!currentResourceId) {
      resourceIsPending.current = false;
    }
    // Log the resource usage only on the therapist browser, and only when in session
    if (userId && currentResourceId) {
      if (clientHasJoinedRoom) {
        logResourceUsage();
      } else {
        resourceIsPending.current = true;
      }
    }
  }, [currentResourceId]);
};

import { calculateFitDimensions } from "utils/sizingUtils";
import React, { useEffect, useLayoutEffect, useState } from "react";

const MARGIN = 30;
const DEFAULT_VIDEO_WIDTH = 640;
const DEFAULT_VIDEO_HEIGHT = 480;

export const useFullScreenDimensions = (
  containerRef: React.RefObject<HTMLDivElement>,
  isFullScreen: boolean
) => {
  const [peerRawVideoDimensions, setPeerRawVideoDimensions] = useState({
    width: DEFAULT_VIDEO_WIDTH,
    height: DEFAULT_VIDEO_HEIGHT,
  });
  const [localRawVideoDimensions, setLocalRawVideoDimensions] = useState({
    width: DEFAULT_VIDEO_WIDTH,
    height: DEFAULT_VIDEO_HEIGHT,
  });
  const [parentDimensions, setParentDimensions] = useState({
    width: 0,
    height: 0,
  });

  const parentHeight = parentDimensions.height;
  const parentHeightWithMargin = parentHeight - MARGIN * 4;
  const parentWidth = parentDimensions.width;
  const parentWidthWithMargin = parentWidth - MARGIN * 2;

  const peerVideoWidth = peerRawVideoDimensions.width;
  const peerVideoHeight = peerRawVideoDimensions.height;
  const noPeerVideoSize = peerVideoWidth === 0 || peerVideoHeight === 0;
  const peerRawWidth = noPeerVideoSize ? DEFAULT_VIDEO_WIDTH : peerVideoWidth;
  const peerRawHeight = noPeerVideoSize
    ? DEFAULT_VIDEO_HEIGHT
    : peerVideoHeight;
  const { fitHeight: peerFullScreenHeight, fitWidth: peerFullScreenWidth } =
    calculateFitDimensions(
      parentHeightWithMargin,
      parentWidthWithMargin,
      peerRawHeight,
      peerRawWidth,
      null
    );

  const localVideoWidth = localRawVideoDimensions.width;
  const localVideoHeight = localRawVideoDimensions.height;
  const noLocalVideoSize = localVideoWidth === 0 || localVideoHeight === 0;
  const localRawWidth = noLocalVideoSize
    ? DEFAULT_VIDEO_WIDTH
    : localVideoWidth;
  const localRawHeight = noLocalVideoSize
    ? DEFAULT_VIDEO_HEIGHT
    : localVideoHeight;
  const localVideoRatio = localRawHeight / localRawWidth;
  // Make the video smaller on mobile devices.
  // Try to take up about 7% of the space of the fullscreen video, between 2500 and 20000 pixels.
  const localFullScreenArea = Math.max(
    Math.min(0.07 * peerFullScreenWidth * peerFullScreenHeight, 20000),
    2500
  );
  // No matter the aspect ratio, take up about the same number of pixels
  const localFullScreenWidth = Math.sqrt(localFullScreenArea / localVideoRatio);
  const localFullScreenHeight = localFullScreenWidth * localVideoRatio;

  // Keep parent dimensions up-to-date
  const updateParentDimensions = () => {
    setParentDimensions({
      width: containerRef.current?.offsetWidth || 0,
      height: containerRef.current?.offsetHeight || 0,
    });
  };
  useLayoutEffect(() => {
    window.addEventListener("resize", updateParentDimensions);
    return () => {
      window.removeEventListener("resize", updateParentDimensions);
    };
  }, []);
  useEffect(() => {
    updateParentDimensions();
  }, [isFullScreen]);

  return {
    setPeerRawVideoDimensions,
    setLocalRawVideoDimensions,
    peerFullScreenDimensions: {
      width: peerFullScreenWidth,
      height: peerFullScreenHeight,
    },
    localFullScreenDimensions: {
      width: localFullScreenWidth,
      height: localFullScreenHeight,
    },
    parentDimensions: { width: parentWidth, height: parentHeight },
  };
};

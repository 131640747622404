import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from "./ActivityList.module.css";
import { GetResourcesQuery, useGetResourcesQuery } from "generated/graphql";
import { selectOrganizationId, selectUserId } from "redux/userRedux";
import ResourceEntry from "../ResourceEntry/ResourceEntry";
import { getOrganizationConfig } from "utils/organizationUtils";
import { getFileUrls, sanitizeFileKeys } from "utils/fileUtils";
import { logUnexpectedError } from "utils/errorUtils";
import ResourceFilters from "./ResourceFilters/ResourceFilters";
import {
  MAX_AGE_FILTER,
  MIN_AGE_FILTER,
} from "./ResourceFilters/AgeDropDown/AgeDropDown";
import LoadingAnimation from "components/LoadingAnimation/LoadingAnimation";
import { POSTER_RESOURCE_ID } from "utils/resourceUtils";
import { useLogSearches } from "./useLogSearches";
import { mergeFiltersAsOr } from "utils/queryUtils";
import { useTrackActivityList } from "./useActivityBankAnalytics";
import {
  selectActivityBankFilter,
  setActivityBankFilter,
} from "redux/editRoomNavigationRedux";

const PAGE_SIZE = 60;
export const PSYCHOEDUCATIONAL = "Psychoeducational";

type ActivityListProps = {
  onClick: (resource: GetResourcesQuery["resource"][0]) => Promise<void>;
  onDoubleClick?: (resource: GetResourcesQuery["resource"][0]) => Promise<void>;
  selectedResourceId?: string;
  excludePoster?: boolean;
};

const ActivityList = ({
  onClick,
  onDoubleClick,
  selectedResourceId,
  excludePoster,
}: ActivityListProps) => {
  const reduxFilters = useSelector(selectActivityBankFilter);
  const userId = useSelector(selectUserId);
  const organizationId = useSelector(selectOrganizationId);
  const organizationConfig = getOrganizationConfig(organizationId);
  const [thumnbnailSrcs, setThumbnailSrcs] = useState();

  const [search, setSearch] = useState(reduxFilters?.search ?? "");
  const [approaches, setApproaches] = useState<string[]>(
    reduxFilters?.approaches ?? []
  );
  const [topics, setTopics] = useState<string[]>(reduxFilters?.topics ?? []);
  const [types, setTypes] = useState<string[]>(reduxFilters?.types ?? []);
  const [sources, setSources] = useState<string[]>(reduxFilters?.sources ?? []);
  const [ageMin, setAgeMin] = useState(reduxFilters?.ageMin ?? MIN_AGE_FILTER);
  const [ageMax, setAgeMax] = useState(reduxFilters?.ageMax ?? MAX_AGE_FILTER);
  const [limit, setLimit] = useState(PAGE_SIZE);

  const initialFiltersChangedState =
    search !== "" ||
    approaches.length > 0 ||
    topics.length > 0 ||
    types.length > 0 ||
    sources.length > 0 ||
    ageMin !== MIN_AGE_FILTER ||
    ageMax !== MAX_AGE_FILTER;
  const [isFilterApplied, setIsFilterApplied] = useState(
    initialFiltersChangedState
  );

  const dispatch = useDispatch();

  useLogSearches({
    search,
    approaches,
    topics,
    types,
    sources,
    ageMin,
    ageMax,
  });

  const { trackClearAllFilters } = useTrackActivityList({
    search,
    approaches,
    topics,
    types,
    sources,
    ageMin,
    ageMax,
  });

  useEffect(() => {
    dispatch(
      setActivityBankFilter({
        search,
        approaches,
        topics,
        types,
        sources,
        ageMin,
        ageMax,
      })
    );
  }, [search, approaches, topics, types, sources, ageMin, ageMax]);

  const ownerIdWhereOrs = [];
  const organizationIdWhereOrs = [];

  if (sources.length === 0 || sources.includes("Teleo Activity Bank")) {
    ownerIdWhereOrs.push({ owner_id: { _is_null: true } });
    organizationIdWhereOrs.push({ organization_id: { _is_null: true } });
  }

  if (
    organizationConfig?.hasCustomActivityBank &&
    (sources.length === 0 || sources.includes(organizationConfig.name))
  ) {
    ownerIdWhereOrs.push({ owner_id: { _is_null: true } });
    organizationIdWhereOrs.push({
      organization_id: { _eq: organizationId },
    });
  }

  if (sources.length === 0 || sources.includes("Private")) {
    ownerIdWhereOrs.push({ owner_id: { _eq: userId || "" } });
  }

  const filterByPsychoeducational = types.includes(PSYCHOEDUCATIONAL);
  const typesWithoutPsychoeducational = types.filter(
    (el) => el !== PSYCHOEDUCATIONAL
  );

  const typeWhere = mergeFiltersAsOr(
    filterByPsychoeducational,
    typesWithoutPsychoeducational.length > 0,
    { is_psychoeducational: { _eq: true } },
    { categories: { _in: typesWithoutPsychoeducational } }
  );

  const sharedResourceQueryVariables = {
    limit: limit,
    ownerIdWhere: { _or: ownerIdWhereOrs },
    organizationIdWhere:
      organizationIdWhereOrs.length > 0 ? { _or: organizationIdWhereOrs } : {},
    excludedIds: excludePoster ? [POSTER_RESOURCE_ID] : [],
    search: `%${search}%`,
    approachWhere:
      approaches.length > 0
        ? { approaches: { approach: { _in: approaches } } }
        : {},
    topicWhere: topics.length > 0 ? { topics: { topic: { _in: topics } } } : {},
    typeWhere: typeWhere,
    ageMinWhere: ageMin > MIN_AGE_FILTER ? { age_max: { _gte: ageMin } } : {},
    ageMaxWhere: ageMax < MAX_AGE_FILTER ? { age_min: { _lte: ageMax } } : {},
  };

  const { data: rawData, loading } = useGetResourcesQuery({
    variables: {
      ...sharedResourceQueryVariables,
      isFavoriteWhere: {},
    },
    skip: !userId,
    fetchPolicy: "cache-and-network",
  });

  const { data: rawFavoritesData, loading: favoritesLoading } =
    useGetResourcesQuery({
      variables: {
        ...sharedResourceQueryVariables,
        isFavoriteWhere: { resource_users: { is_favorite: { _eq: true } } },
      },
      skip: !userId,
      fetchPolicy: "cache-and-network",
    });

  const [data, setData] = useState<GetResourcesQuery | undefined>(rawData);

  const [favoritesData, setFavoritesData] = useState<
    GetResourcesQuery | undefined
  >(rawFavoritesData);

  useEffect(() => {
    if (!loading) {
      setData(rawData);
    }
  }, [rawData, loading]);

  useEffect(() => {
    if (!favoritesLoading) {
      setFavoritesData(rawFavoritesData);
    }
  }, [rawFavoritesData, favoritesLoading]);

  const resources = data?.resource;
  const numTotalResources = data?.resource_aggregate.aggregate?.count || 0;

  const numResults = data?.resource.length || 0;

  useEffect(() => {
    const asyncFun = async () => {
      if (!data) {
        return;
      }

      const resourceKeys =
        data.resource.map((resource) => resource.thumbnail_file_key) || [];
      const favoritesKeys =
        favoritesData?.resource.map(
          (resource) => resource.thumbnail_file_key
        ) || [];

      const keysToGet = sanitizeFileKeys([...resourceKeys, ...favoritesKeys]);

      if (keysToGet.length > 0) {
        const thumbnailImageUrls = await getFileUrls(keysToGet);
        setThumbnailSrcs(thumbnailImageUrls);
      }
    };
    asyncFun().catch(logUnexpectedError);

    const filtersChanged =
      search !== "" ||
      approaches.length > 0 ||
      topics.length > 0 ||
      types.length > 0 ||
      sources.length > 0 ||
      ageMin !== MIN_AGE_FILTER ||
      ageMax !== MAX_AGE_FILTER;
    setIsFilterApplied(filtersChanged);
  }, [data]);

  const resourceToComponent = (
    resource: GetResourcesQuery["resource"][0],
    isFavoriteRow: boolean = false
  ) => {
    const isSelected = selectedResourceId === resource.id;
    const thumbnailSrc = resource.thumbnail_file_key
      ? thumnbnailSrcs?.[resource.thumbnail_file_key]
      : null;
    return (
      <div key={resource.id} className={styles.resourceContainer}>
        <ResourceEntry
          resource={resource}
          thumbnailSrc={thumbnailSrc}
          isSelected={isSelected}
          onClick={onClick}
          onDoubleClick={onDoubleClick}
          isFavoriteRow={isFavoriteRow}
        />
      </div>
    );
  };

  const clearSearch = () => {
    setSearch("");
    setApproaches([]);
    setTopics([]);
    setTypes([]);
    setSources([]);
    setAgeMin(MIN_AGE_FILTER);
    setAgeMax(MAX_AGE_FILTER);
    trackClearAllFilters();
  };

  return (
    <div className={styles.container}>
      <ResourceFilters
        numResults={!data ? null : numTotalResources}
        isFilterApplied={isFilterApplied}
        search={search}
        setSearch={setSearch}
        approaches={approaches}
        setApproaches={setApproaches}
        topics={topics}
        setTopics={setTopics}
        types={types}
        setTypes={setTypes}
        sources={sources}
        setSources={setSources}
        ageMin={ageMin}
        setAgeMin={setAgeMin}
        ageMax={ageMax}
        setAgeMax={setAgeMax}
        clearSearch={clearSearch}
      />
      <div className={styles.resultsContainer}>
        {favoritesData?.resource?.length ? (
          <>
            <div className="favoriteResultsContainer">
              {favoritesData?.resource?.map((r) =>
                resourceToComponent(r, true)
              )}
            </div>
            <div className={styles.horizontalLine} />
          </>
        ) : null}
        {data ? (
          numResults > 0 ? (
            resources?.map((r) => resourceToComponent(r))
          ) : (
            <div className={styles.noResultsMessage}>No Results</div>
          )
        ) : null}
        {loading ? (
          <LoadingAnimation />
        ) : numResults < numTotalResources ? (
          <div
            onClick={() => setLimit(limit + PAGE_SIZE)}
            className={styles.loadMoreButton}
          >
            Load More
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ActivityList;

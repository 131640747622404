import React from "react";

import styles from "./ChangeBackgroundSideBar.module.css";
import clsx from "clsx";
import { useInsertAnalyticsBackgroundUpdateMutation } from "generated/graphql";
import { logUnexpectedError } from "utils/errorUtils";
import { useDispatch, useSelector } from "react-redux";
import { selectUserId } from "redux/userRedux";
import { logUsageEvent } from "utils/metricsUtils";
import { selectMeetingID } from "redux/spaceNavigationRedux";
import xIcon from "assets/icons/x.svg";
import {
  setIsDoneLoadingId,
  setIsLoadingId,
  setShowBackgroundEditSideBar,
} from "redux/editRoomNavigationRedux";
import { useBackgroundSelection } from "../../utils/useBackgroundSelection";
import { getBackgroundImage } from "../../utils/backgroundUtils";

const ChangeBackgroundSideBar = () => {
  const userId = useSelector(selectUserId);
  const meetingID = useSelector(selectMeetingID);
  const {
    selectedBackgroundId,
    loadingBackgroundId,
    possibleBackgrounds,
    onClick,
  } = useBackgroundSelection();
  const [insertAnalyticsBackgroundUpdateMutation] =
    useInsertAnalyticsBackgroundUpdateMutation();
  const dispatch = useDispatch();

  const selectBackground: (
    backgroundId: string
  ) => React.MouseEventHandler<HTMLDivElement> =
    (backgroundId: string) => async (event) => {
      const loadingKey = `CHANGE_BACKGROUND_${Date.now()}`;
      try {
        dispatch(setIsLoadingId(loadingKey));
        await onClick(backgroundId)(event);
        if (backgroundId) {
          await insertAnalyticsBackgroundUpdateMutation({
            variables: {
              userId: userId,
              isChosenByClient: false,
              isCreateRoom: false,
              backgroundId,
            },
          }).catch(logUnexpectedError);
          logUsageEvent("BACKGROUND_CHANGED", meetingID);
        }
        dispatch(setIsDoneLoadingId(loadingKey));
      } catch (err) {
        logUnexpectedError(err);
        dispatch(setIsDoneLoadingId(loadingKey));
      }
    };

  const close = () => {
    dispatch(setShowBackgroundEditSideBar(false));
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerRow}>
        <img src={xIcon} className={styles.hideIcon} onClick={close} />
        <div className={styles.header}>Change Room Background</div>
      </div>
      <div className={styles.horizontalLine} />
      <div className={styles.body}>
        <div className={styles.backgroundsContainer}>
          {possibleBackgrounds.map((backgroundId) => (
            <img
              key={backgroundId}
              className={clsx(styles.backgroundImage, {
                [styles.selectedBackground]:
                  backgroundId === selectedBackgroundId,
                [styles.loadingBackground]:
                  backgroundId === loadingBackgroundId,
              })}
              onClick={selectBackground(backgroundId)}
              src={getBackgroundImage(backgroundId)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChangeBackgroundSideBar;

import React, { useState } from "react";

import styles from "./ResourceUsageSection.module.css";
import AdminPageButton from "./AdminPageButton";
import { COLLECTION_ID } from "pages/Space/subpages/SpaceRoom/utils/backgroundUtils";
import { getPossibleBackgrounds } from "pages/Space/subpages/SpaceRoom/utils/useBackgroundSelection";
import { backendRequest } from "utils/backendRequest";

type BackgroundUsageData = {
  id: string;
  total: number;
  provider: number;
  client: number;
  firstRoom: number;
  totalCurrentRooms: number;
}[];

const BackgroundUsageSection = () => {
  const [data, setData] = useState<BackgroundUsageData>();
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await backendRequest({ path: "/stats-background-usage" });
      const data = await result.json();
      setData(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setData(undefined);
      setLoading(false);
    }
  };

  const getBackgroundRows = () => {
    if (loading) {
      return (
        <tr>
          <td>Loading</td>
        </tr>
      );
    }
    if (!data) {
      return (
        <tr>
          <td>No data</td>
        </tr>
      );
    }

    const backgroundRows = data.map(
      (
        { id, total, provider, client, firstRoom, totalCurrentRooms },
        index
      ) => (
        <tr key={index}>
          <td>{id}</td>
          <td>{totalCurrentRooms}</td>
          <td>{total}</td>
          <td>{provider}</td>
          <td>{client}</td>
          <td>{firstRoom}</td>
        </tr>
      )
    );

    const sumValues = (
      backgroundIds: string[],
      field: keyof Omit<BackgroundUsageData[0], "id">
    ) => {
      let sum = 0;
      for (const entry of data) {
        if (backgroundIds.includes(entry.id)) {
          sum += entry[field];
        }
      }
      return sum;
    };

    const youngerBackgrounds = getPossibleBackgrounds(COLLECTION_ID.YOUNGER);
    backgroundRows.push(
      <tr key={data.length}>
        <td>{"All Younger Rooms"}</td>
        <td>{sumValues(youngerBackgrounds, "totalCurrentRooms")}</td>
        <td>{sumValues(youngerBackgrounds, "total")}</td>
        <td>{sumValues(youngerBackgrounds, "provider")}</td>
        <td>{sumValues(youngerBackgrounds, "client")}</td>
        <td>{sumValues(youngerBackgrounds, "firstRoom")}</td>
      </tr>
    );

    const olderBackgrounds = getPossibleBackgrounds(COLLECTION_ID.OLDER);
    backgroundRows.push(
      <tr key={data.length + 1}>
        <td>{"All Older Rooms"}</td>
        <td>{sumValues(olderBackgrounds, "totalCurrentRooms")}</td>
        <td>{sumValues(olderBackgrounds, "total")}</td>
        <td>{sumValues(olderBackgrounds, "provider")}</td>
        <td>{sumValues(olderBackgrounds, "client")}</td>
        <td>{sumValues(olderBackgrounds, "firstRoom")}</td>
      </tr>
    );

    return backgroundRows;
  };

  return (
    <div className={styles.container}>
      <h3>Background Usage Data</h3>
      <AdminPageButton onClick={fetchData}>
        Load Background Usage Data
      </AdminPageButton>
      <table>
        <tbody>
          <tr key={"header"}>
            <th>ID</th>
            <th>Total Current Rooms</th>
            <th>Total Times Chosen (after 6/21/23)</th>
            <th>Total Times Chosen by Provider</th>
            <th>Total Times Chosen by Client</th>
            <th>Total Times Chosen for First Room</th>
          </tr>
          {getBackgroundRows()}
        </tbody>
      </table>
    </div>
  );
};

export default BackgroundUsageSection;

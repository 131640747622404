import React, { forwardRef } from "react";

import styles from "./ControlBarItem.module.css";
import clsx from "clsx";

type ControlBarItemProps = {
  label: string;
  onClick?: () => void;
  iconSrc?: string;
  icon?: React.ComponentType<{ className: string }>;
  selected?: boolean;
  className?: string;
  labelClassName?: string;
  id?: string;
  children?: React.ReactNode;
};

const ControlBarItem = (
  {
    label,
    onClick,
    icon: Icon,
    selected,
    className,
    labelClassName,
    id,
    children,
  }: ControlBarItemProps,
  ref?: React.Ref<HTMLDivElement>
) => {
  return (
    <div
      id={id}
      data-testid="control-bar-item"
      className={clsx(
        styles.container,
        { [styles.selected]: selected },
        className
      )}
      onClick={onClick}
      ref={ref}
    >
      <div className={styles.top}>
        {Icon ? <Icon className={styles.icon} /> : null}
        {children}
      </div>
      <div className={clsx(styles.label, labelClassName)}>{label}</div>
    </div>
  );
};

export default forwardRef<HTMLDivElement, ControlBarItemProps>(ControlBarItem);

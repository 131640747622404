import React, { ChangeEvent, useState } from "react";

import styles from "./UserPilotLengthSection.module.css";
import AdminPageButton from "./AdminPageButton";
import { backendRequest } from "utils/backendRequest";

const MAX_TEXT_LENGTH = 1000;

const UserPilotLengthSection = () => {
  const [result, setResult] = useState<string>();
  const [email, setEmail] = useState<string>("");
  const [pilotLength, setPilotLength] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const submitPilotLength = async () => {
    try {
      setLoading(true);
      const result = await backendRequest({
        path: "/sign-up-pilot-length",
        searchParams: { email, pilotLength },
        options: {
          method: "POST",
          keepalive: true, // make sure the request is sent completely even once the page is destroyed
        },
      });
      if (result.status === 200) {
        setResult("Success!");
      } else {
        try {
          const { error } = await result.json();
          setResult(`Error: ${error}`);
        } catch (err) {
          setResult(`Error: server returned ${result.status}`);
        }
      }
    } catch (err) {
      console.log(err);
      setResult(`Error: ${err}`);
    } finally {
      setLoading(false);
    }
  };

  const emailChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const pilotLengthChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setPilotLength(event.target.value);
  };

  return (
    <div className={styles.container}>
      <h3>Set User Pilot Length</h3>
      Email
      <input
        type="text"
        value={email}
        className={styles.emailInput}
        onChange={emailChangeHandler}
        maxLength={MAX_TEXT_LENGTH}
      />
      Pilot Length (in days)
      <input
        type="text"
        value={pilotLength}
        onChange={pilotLengthChangeHandler}
        maxLength={MAX_TEXT_LENGTH}
      />
      <AdminPageButton onClick={submitPilotLength}>Submit</AdminPageButton>
      {loading ? "..." : result}
    </div>
  );
};

export default UserPilotLengthSection;

export const isMobileDevice = (includeTablets = false) => {
  const userAgent = window.navigator.userAgent;
  const isPhone = /Mobi/i.test(userAgent) && !/iPad|tablet/i.test(userAgent);

  const isTablet =
    /iPad|tablet/i.test(userAgent) ||
    // iPadOS 13+ lies and says it's not a tablet
    (navigator.userAgent.includes("Mac") && "ontouchend" in document);

  return isPhone || (isTablet && includeTablets);
};

import React from "react";

import styles from "./CenteredLoadingDiv.module.css";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";

const CenteredLoadingDiv = () => {
  return (
    <div className={styles.loadingContainer}>
      <LoadingAnimation />
    </div>
  );
};

export default CenteredLoadingDiv;

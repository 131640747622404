import * as Sentry from "@sentry/react";

export const logUnexpectedError = (err: any) => {
  console.error(err);
  // encapsulating in Error object to get stack trace
  if (!(err instanceof Error)) {
    Sentry.captureException(new Error(err));
  }
  // some errors don't have stack trace, so we encapsulate them in a new Error object
  else if (!err.stack) {
    Sentry.captureException(new Error(err.message, { cause: err }));
  } else {
    Sentry.captureException(err);
  }
};

type BreadCrumbCategory =
  | "webrtc.peer"
  | "webrtc.remote"
  | "socket.remote"
  | "browser.network"
  | "userMedia"
  | "navigation"
  | "videoChat"
  | "connections"
  | "connectionHealth";

export const getAddBreadcrumb = (category: BreadCrumbCategory) => {
  // If not present, default to true
  const isSentryEnabled = process.env.REACT_APP_SENTRY_ENABLED ?? "true";
  if (isSentryEnabled !== "true") {
    return console.log.bind(console, category);
  } else {
    return (level: Sentry.SeverityLevel, message: string, data?: any) => {
      if (process.env.REACT_APP_DEBUG_CONSOLE === "true") {
        console.log(category, message, data);
      }
      Sentry.addBreadcrumb({
        category,
        message,
        level,
        type: "default",
        data,
      });
    };
  }
};

import React, { useRef, useState } from "react";

import { useSelector } from "react-redux";

import { ApolloProvider } from "@apollo/client";
import { selectEncodedAuthToken, selectUserId } from "redux/userRedux";
import Home from "./Home";
import { createApolloClient } from "utils/dbUtils";
import styles from "pages/Space/subpages/BrowserSandbox/BrowserSandbox.module.css";
import LoadingAnimation from "./LoadingAnimation/LoadingAnimation";
import { useTrackUser } from "utils/metricsUtils";

function LoggedInApp() {
  const encodedAuthToken = useSelector(selectEncodedAuthToken);
  const encodedAuthTokenRef = useRef(encodedAuthToken);
  const userId = useSelector(selectUserId);

  const [client] = useState(createApolloClient(encodedAuthTokenRef, null));

  useTrackUser();

  if (!userId) {
    return (
      <div className={styles.loadingContainer}>
        <LoadingAnimation />
      </div>
    );
  }

  return (
    <ApolloProvider client={client}>
      <Home />
    </ApolloProvider>
  );
}

export default LoggedInApp;

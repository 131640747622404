import { MutableRefObject, useRef, useState } from "react";

// Create a state variable together with a ref that tracks its value, which can
// be used in callbacks.
export const useStateWithRef = <Type>(
  initialState: Type
): [
  stateValue: Type,
  setValue: (newValue: Type) => void,
  ref: MutableRefObject<Type>
] => {
  const [stateValue, setStateValue] = useState(initialState);
  const ref = useRef(stateValue);
  const setValue = (newValue: Type) => {
    setStateValue(newValue);
    ref.current = newValue;
  };
  return [stateValue, setValue, ref];
};

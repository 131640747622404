import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import { fabric, isFabricIText } from "utils/fabricUtils";

import styles from "./Whiteboard.module.css";
import { useRemoteCanvas } from "./useRemoteCanvas";
import WhiteboardControls, {
  DEFAULT_OPTION,
  OPTIONS_BY_ID,
} from "./WhiteboardControls";
import { ControlButtonType } from "./ControlButton";
import { v4 as uuid } from "uuid";
import { loadPdfPageCanvas } from "utils/pdfUtils";
import { getFileUrl, isImageFile, isPdfFile } from "utils/fileUtils";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { selectCanControl } from "redux/settingsRedux";
import { selectUserRole, UserRole } from "redux/userRedux";
import {
  selectCurrentResourceId,
  selectCurrentRoomItemId,
} from "redux/spaceNavigationRedux";
import { useRemoteMouseOnCanvas } from "./useRemoteMouseOnCanvas";
import LoadingAnimation from "components/LoadingAnimation/LoadingAnimation";
import { logUnexpectedError } from "utils/errorUtils";
import ConnectionError from "../ConnectionError/ConnectionError";
import {
  MIN_WIDTH_RATIO,
  calculateFitDimensions,
  isSmallerThanFilledWidth,
  getIs2xZoomEnabled,
} from "utils/sizingUtils";
import { fabricTypes } from "utils/fabric-impl";
import { zoomLevelType } from "./whiteboardTypes";
import { getPaintBrush } from "pages/Space/subpages/SpaceRoom/utils/drawingUtils";
import { useCursor } from "./useCursor";
import { useStateWithRef } from "hooks/useStateWithRef";
import { clearFabricCanvas, clearHTMLCanvas } from "utils/canvasUtils";
import ActivityNavigationHeader from "pages/Space/components/ActivityNavigationHeader/ActivityNavigationHeader";
import {
  useGetResourceStateQuery,
  useUpsertResourceStateMutation,
} from "generated/graphql";
import { useSnapshot } from "pages/Space/hooks/useSnapshot";
import { selectClientFileOpen } from "redux/clientManagementRedux";
import { TextSize } from "./TextButton";
import { Peers } from "pages/Space/hooks/connection/usePeerWebRTCConnection";
import { useLockViewport } from "./useLockViewport";

export const HIT_SLOP = 12;

const CANVAS_RAW_HEIGHT = 750;
const CANVAS_RAW_WIDTH = 1300;

// TODO: store/load/send existing canvas if peer (re)joins after something has already been drawn
// TODO: sync scroll and zoom as well when peer joins
// TODO: ensure that changes will be easily backwards compatible if one person is on newer version of site (or ask person to refresh)

type WhiteboardProps = {
  backgroundImageKey?: string;
  peersRef: React.MutableRefObject<Peers>;
  peers: Peers;
  isConnectedToPeer: boolean;
};

const Whiteboard = ({
  backgroundImageKey,
  peersRef,
  peers,
  isConnectedToPeer,
}: WhiteboardProps) => {
  const canvasSectionRef = useRef<HTMLDivElement>(null);
  const [canvas, setCanvas] = useState<fabricTypes.Canvas>();
  const canvasRef = useRef<fabricTypes.Canvas>();
  const [cursorCanvas, setCursorCanvas] = useState<fabricTypes.Canvas>();
  const cursorCanvasRef = useRef<fabricTypes.Canvas>(); // for local eraser cursor, so it's always in front and can't be deleted, etc
  const remoteTmpCanvasRef = useRef<fabricTypes.Canvas>(); // for in-progress drawing paths, so the canvas brushes do not disrupt each other
  const [remoteTmpCanvas, setRemoteTmpCanvas] = useState<fabricTypes.Canvas>();
  const remoteCursorCanvasRef = useRef<fabricTypes.Canvas>(); // for the remote cursor, so it's always in front and can't be deleted, etc
  const isMouseDownRef = useRef(false);
  const numDragRef = useRef(0);
  const selectedOptionRef = useRef(DEFAULT_OPTION.id);
  const focusedTextRef = useRef<fabricTypes.IText>();
  const textSizeRef = useRef<TextSize>("M");
  const backgroundImageSize = useRef<{ width: number; height: number }>();
  const [widthIsLimiting, setWidthIsLimiting] = useState<boolean>();
  const [is2xZoomEnabled, setIs2xZoomEnabled] = useState<boolean>(false);
  const [is3xZoomEnabled, setIs3xZoomEnabled] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [visibleHeightState, setVisibleHeightState, visibleHeightStateRef] =
    useStateWithRef(1);
  const [zoomLevelRatio, setZoomLevelRatio, zoomLevelRatioRef] =
    useStateWithRef(1);
  const [zoomLevel, setZoomLevel, zoomLevelRef] =
    useStateWithRef<zoomLevelType>("1x");
  const rawCanvasRef = useRef<HTMLCanvasElement>(null);
  const rawCursorCanvasRef = useRef<HTMLCanvasElement>(null);
  const rawRemoteTmpCanvasRef = useRef<HTMLCanvasElement>(null);
  const rawRemoteCursorCanvasRef = useRef<HTMLCanvasElement>(null);

  const lastSavedWhiteboardStateRef = useRef("");
  const whiteboardQueryCompleteRef = useRef(false);

  const clientCanControl = useSelector(selectCanControl);
  const userRole = useSelector(selectUserRole);
  const userCanControl = userRole === UserRole.THERAPIST || clientCanControl;

  const resourceId = useSelector(selectCurrentResourceId);
  const roomItemId = useSelector(selectCurrentRoomItemId);
  const [upsertResourceStateMutation] = useUpsertResourceStateMutation();
  const isClientFileOpen = useSelector(selectClientFileOpen);

  const resourceStateQuery = useGetResourceStateQuery({
    variables: {
      roomItemId: roomItemId,
      resourceId: resourceId ?? "",
    },
    skip: !roomItemId || !resourceId,
    fetchPolicy: "no-cache",
  });
  const whiteboardQueryComplete = !!resourceStateQuery.data?.resource_state;
  useEffect(() => {
    whiteboardQueryCompleteRef.current = whiteboardQueryComplete;
  }, [whiteboardQueryComplete]);
  const whiteboardObjects = resourceStateQuery.data?.resource_state[0]?.content;

  useEffect(() => {
    // Clear canvas contents when unmounting to work around Safari canvas caching issues
    return () => {
      for (const canvas of [
        canvasRef.current,
        cursorCanvasRef.current,
        remoteTmpCanvasRef.current,
        remoteCursorCanvasRef.current,
      ]) {
        clearFabricCanvas(canvas);
      }
      for (const rawCanvas of [
        rawCanvasRef.current,
        rawCursorCanvasRef.current,
        rawRemoteTmpCanvasRef.current,
        rawRemoteCursorCanvasRef.current,
      ]) {
        clearHTMLCanvas(rawCanvas);
      }
    };
  }, []);

  const {
    emitModifyObjects,
    emitReset,
    emitEraserPathMouseDown,
    emitPathMouseDown,
    emitPathMouseMove,
    emitPathMouseUp,
    emitAddPath,
    emitBrushChange,
    emitAddOrModifyText,
    onReceiveMessageCallback: onReceiveMessageCallbackRemoteCanvas,
  } = useRemoteCanvas(
    canvas,
    remoteTmpCanvas,
    canvasRef,
    remoteTmpCanvasRef,
    visibleHeightStateRef,
    peersRef
  );

  const {
    emitCursorMove,
    emitCursorOut,
    onReceiveMessageCallback: onReceiveMessageCallbackRemoteMouse,
  } = useRemoteMouseOnCanvas(
    remoteCursorCanvasRef,
    zoomLevelRatio,
    zoomLevelRatioRef,
    peersRef
  );

  const { setCursorRadius, setShowCursor } = useCursor(
    canvas,
    canvasRef,
    cursorCanvas,
    cursorCanvasRef
  );

  const {
    onReceiveMessageCallback: onReceiveMessageCallbackScroll,
    onToggleViewportLock,
  } = useLockViewport({
    canvasSectionRef,
    peersRef,
    zoomLevel,
    setZoomLevel,
    userCanControl,
    isZoomEnabled: is2xZoomEnabled || is3xZoomEnabled || zoomLevel !== "1x",
  });

  useEffect(() => {
    const peerDataChannels = Object.values(peers).map(
      (peer) => peer.dataChannel
    );
    for (const peerDataChannel of peerDataChannels) {
      if (peerDataChannel) {
        peerDataChannel.addEventListener(
          "message",
          onReceiveMessageCallbackRemoteCanvas
        );
        peerDataChannel.addEventListener(
          "message",
          onReceiveMessageCallbackRemoteMouse
        );
        peerDataChannel.addEventListener(
          "message",
          onReceiveMessageCallbackScroll
        );
      }
    }

    return () => {
      for (const peerDataChannel of peerDataChannels) {
        if (peerDataChannel) {
          peerDataChannel.removeEventListener(
            "message",
            onReceiveMessageCallbackRemoteCanvas
          );
          peerDataChannel.removeEventListener(
            "message",
            onReceiveMessageCallbackRemoteMouse
          );
          peerDataChannel.removeEventListener(
            "message",
            onReceiveMessageCallbackScroll
          );
        }
      }
    };
  }, [peers]);

  const serializeWhiteboard = () => {
    const canvasObjects = canvasRef.current?.toObject().objects;
    if (!canvasObjects) {
      return;
    }
    const ids = canvasRef.current?.getObjects().map((object) => object.id);
    const serializedWhiteboard = JSON.stringify({
      objects: canvasObjects,
      ids,
    });
    return serializedWhiteboard;
  };

  const maybeSaveWhiteboardState = () => {
    if (!whiteboardQueryCompleteRef.current) {
      return;
    }
    const whiteboardObjectsToSave = serializeWhiteboard();
    if (
      !whiteboardObjectsToSave ||
      whiteboardObjectsToSave === lastSavedWhiteboardStateRef.current
    ) {
      return;
    }
    // not awaiting, so it runs on background
    upsertResourceStateMutation({
      variables: {
        roomItemId: roomItemId,
        resourceId: resourceId ?? "",
        content: whiteboardObjectsToSave,
      },
    }).catch(logUnexpectedError);
    lastSavedWhiteboardStateRef.current = whiteboardObjectsToSave;
  };

  useEffect(() => {
    if (userRole === UserRole.THERAPIST) {
      // Update saved whiteboard state every 10 seconds
      const saveStateInterval = setInterval(maybeSaveWhiteboardState, 10000);
      return () => {
        clearInterval(saveStateInterval);
      };
    }
  }, []);

  // Sync state when peer joins
  useEffect(() => {
    if (isConnectedToPeer && userRole === UserRole.THERAPIST) {
      maybeSaveWhiteboardState();
    }
  }, [isConnectedToPeer]);

  useEffect(() => {
    if (whiteboardObjects && canvas) {
      const { objects, ids } = JSON.parse(whiteboardObjects);
      fabric.util.enlivenObjects(
        objects,
        function (objects: any) {
          objects.forEach((o: any, index: number) => {
            if (ids[index]) {
              o.set({ id: ids[index] });
              if (isFabricIText(o)) {
                o.on("changed", () => {
                  emitAddOrModifyText({
                    id: o.id,
                    text: o.text || "",
                    options: {},
                  });
                });
              }
              canvasRef.current?.add(o);
            }
          });
          canvasRef.current?.renderAll();
        },
        ""
      );
    }
  }, [whiteboardObjects, canvas]);

  const baseOnMouseOut = () => {
    emitCursorOut();
  };

  const calculateCanvasDimensions = () => {
    const parentHeight = canvasSectionRef.current?.clientHeight || 0;
    const parentWidth = canvasSectionRef.current?.clientWidth || 0;
    const rawWidth = backgroundImageKey
      ? backgroundImageSize.current?.width || 0
      : CANVAS_RAW_WIDTH;
    const rawHeight = backgroundImageKey
      ? backgroundImageSize.current?.height || 0
      : CANVAS_RAW_HEIGHT;
    const { fitHeight, fitWidth, ratio, heightIsLimiting } =
      calculateFitDimensions(
        parentHeight,
        parentWidth,
        rawHeight,
        rawWidth,
        MIN_WIDTH_RATIO,
        zoomLevelRef.current
      );

    const getVisibleCanvasPixelHeightBeforeZoom = () => {
      if (zoomLevelRef.current !== "1x") {
        const { ratio: ratioWithoutZoom } = calculateFitDimensions(
          parentHeight,
          parentWidth,
          rawHeight,
          rawWidth,
          MIN_WIDTH_RATIO
        );
        return parentHeight / ratioWithoutZoom;
      } else {
        return heightIsLimiting ? parentHeight / ratio : fitHeight / ratio;
      }
    };
    const visibleHeight = getVisibleCanvasPixelHeightBeforeZoom(); // used to set brush and eraser width and text size
    setVisibleHeightState(visibleHeight);
    setWidthIsLimiting(!heightIsLimiting);
    setZoomLevelRatio(ratio);
    setIs2xZoomEnabled(
      getIs2xZoomEnabled(parentHeight, parentWidth, fitHeight, fitWidth)
    );
    setIs3xZoomEnabled(isSmallerThanFilledWidth(fitWidth, parentWidth));
    return {
      canvasHeight: fitHeight,
      canvasWidth: fitWidth,
      canvasZoomLevel: ratio,
      visibleHeight,
    };
  };

  function updateSize() {
    const { canvasHeight, canvasWidth, canvasZoomLevel } =
      calculateCanvasDimensions();
    const previousHeight = canvasRef.current?.height;
    const previousScroll = canvasSectionRef.current?.scrollTop;
    for (const canvas of [
      canvasRef.current,
      cursorCanvasRef.current,
      remoteTmpCanvasRef.current,
      remoteCursorCanvasRef.current,
    ]) {
      if (!canvas) {
        continue;
      }
      canvas.setHeight(canvasHeight);
      canvas.setWidth(canvasWidth);
      canvas.setZoom(canvasZoomLevel);
    }
    if (previousHeight && previousScroll) {
      const newScroll = (previousScroll * canvasHeight) / previousHeight;
      canvasSectionRef.current.scrollTop = newScroll;
    }
  }

  useEffect(() => {
    updateSize();
  }, [isClientFileOpen]);

  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(updateSize, [zoomLevel]);

  const loadImage = (imageUrl: string) => {
    return new Promise<fabricTypes.Image>((resolve) => {
      fabric.Image.fromURL(
        imageUrl,
        function (oImg: fabricTypes.Image) {
          resolve(oImg);
        },
        { crossOrigin: "anonymous" }
      );
    });
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let backgroundImage;
        if (backgroundImageKey) {
          const backgroundImageUrl = await getFileUrl(backgroundImageKey);
          if (isImageFile(backgroundImageKey)) {
            backgroundImage = await loadImage(backgroundImageUrl);
          } else if (isPdfFile(backgroundImageKey)) {
            const pdfPageCanvas = await loadPdfPageCanvas(backgroundImageUrl);
            backgroundImage = new fabric.Image(pdfPageCanvas);
          }
          if (backgroundImage) {
            if (!backgroundImage.height || !backgroundImage.width) {
              throw new Error(
                "ERROR! background image dimensions are not defined"
              );
            }
            backgroundImageSize.current = {
              height: backgroundImage.height || 0,
              width: backgroundImage.width || 0,
            };
          }
        }

        const { canvasHeight, canvasWidth, canvasZoomLevel, visibleHeight } =
          calculateCanvasDimensions();

        if (!canvasRef.current) {
          // Fabric will create a wrapper around the html canvas element with the id 'canv'
          canvasRef.current = new fabric.Canvas("whiteboard-canv", {
            height: canvasHeight,
            width: canvasWidth,
            backgroundColor: "white",
            selection: false,
            isDrawingMode: true,
            targetFindTolerance: HIT_SLOP * 2,
          });
          if (!canvasRef.current) {
            return;
          }
          setCanvas(canvasRef.current);

          canvasRef.current?.setZoom(canvasZoomLevel);

          if (DEFAULT_OPTION.config.type === ControlButtonType.FREE_DRAW) {
            canvasRef.current.freeDrawingBrush = getPaintBrush(
              DEFAULT_OPTION.config.color,
              visibleHeight,
              canvasRef.current
            );
            canvasRef.current.freeDrawingCursor = "crosshair";
          }

          if (backgroundImage) {
            backgroundImage.set("erasable", false);
            // const scale = 1 / window.devicePixelRatio;
            canvasRef.current.setBackgroundImage(backgroundImage, () => {});
          }

          canvasRef.current.on("mouse:down", (event) => {
            const pointer = canvasRef.current?.getPointer(event.e);
            isMouseDownRef.current = true;
            numDragRef.current = 0;
            const selectedOptionType =
              selectedOptionRef.current &&
              OPTIONS_BY_ID[selectedOptionRef.current].type;
            if (selectedOptionType === ControlButtonType.FREE_DRAW && pointer) {
              emitPathMouseDown(pointer);
            }
            if (selectedOptionType === ControlButtonType.DELETE && pointer) {
              emitEraserPathMouseDown(pointer);
            }
            if (
              selectedOptionType === ControlButtonType.TEXT &&
              pointer &&
              canvasRef.current
            ) {
              const targetObj = canvasRef.current?.findTarget(event.e, false);
              if (isFabricIText(targetObj)) {
                // do not allow editing/selecting text that has been erased
                if (!targetObj.eraser) {
                  targetObj.enterEditing();
                  targetObj.set({ selected: true });
                  targetObj.hiddenTextarea?.focus(); // display the keyboard on mobile
                  focusedTextRef.current = targetObj;
                  targetObj.setCursorByClick(event.e);
                } else {
                  targetObj.set({ selected: false });
                }
              }
            }
          });
          canvasRef.current.on("mouse:move", (event) => {
            const pointer = canvasRef.current?.getPointer(event.e);
            if (pointer) {
              emitCursorMove(pointer);
            }
            if (!isMouseDownRef.current) return;
            numDragRef.current += 1;
            const selectedOptionType =
              selectedOptionRef.current &&
              OPTIONS_BY_ID[selectedOptionRef.current].type;
            if (
              (selectedOptionType === ControlButtonType.FREE_DRAW ||
                selectedOptionType === ControlButtonType.DELETE) &&
              pointer
            ) {
              emitPathMouseMove(pointer);
            }
          });
          canvasRef.current.on("mouse:up", (event) => {
            const pointer = canvasRef.current?.getPointer(event.e);
            isMouseDownRef.current = false;
            const selectedOptionType =
              selectedOptionRef.current &&
              OPTIONS_BY_ID[selectedOptionRef.current].type;
            if (pointer) {
              if (
                selectedOptionType === ControlButtonType.FREE_DRAW ||
                selectedOptionType === ControlButtonType.DELETE
              ) {
                emitPathMouseUp(pointer);
              }
              if (
                selectedOptionType === ControlButtonType.TEXT &&
                canvasRef.current &&
                numDragRef.current < 4
              ) {
                const targetObj = canvasRef.current?.findTarget(event.e, false);
                // do not create new text if existing unerased text is found
                if (isFabricIText(targetObj) && !targetObj.eraser) {
                  return;
                }
                let fontSize = visibleHeightStateRef.current * 0.03;
                if (textSizeRef.current === "S") {
                  fontSize = fontSize * 0.5;
                } else if (textSizeRef.current === "L") {
                  fontSize = fontSize * 2;
                }
                const options = {
                  left: pointer.x,
                  top: pointer.y - fontSize * 0.6, // middle of cursor
                  fontSize,
                  fontFamily: "Catamaran, sans-serif",
                  padding: HIT_SLOP,
                };
                const text = new fabric.IText("", options) as fabricTypes.IText;
                text.set({ id: uuid() });
                canvasRef.current.add(text);
                text.enterEditing();
                text.hiddenTextarea?.focus(); // display the keyboard on mobile
                text.on("changed", () => {
                  emitAddOrModifyText({
                    id: text.id,
                    text: text.text || "",
                    options,
                  });
                });
                focusedTextRef.current = text;
                canvasRef.current.renderAll();
              }
            }
            numDragRef.current = 0;
          });
          canvasRef.current.on("path:created", (event) => {
            // (For paths created by the current local user)
            const selectedOptionType =
              selectedOptionRef.current &&
              OPTIONS_BY_ID[selectedOptionRef.current].type;
            if (selectedOptionType !== ControlButtonType.FREE_DRAW) {
              return;
            }
            // Add an id, and emit the object to be added to the remote canvas
            const id = uuid();
            // @ts-ignore
            event.path.set({
              id,
              perPixelTargetFind: true,
              padding: HIT_SLOP * 2,
            });
            // @ts-ignore
            event.path.setCoords();
            // @ts-ignore
            emitAddPath(event.path);
          });
          canvasRef.current.on("erasing:end", (event) => {
            // @ts-ignore
            const targets = event.targets;
            const modifiedObjects = targets.map((target: { id: any }) => ({
              obj: target,
              id: target.id,
            }));
            emitModifyObjects(modifiedObjects);
          });
        }
        if (!remoteTmpCanvasRef.current) {
          remoteTmpCanvasRef.current = new fabric.Canvas(
            "whiteboard-remoteTmpCanv",
            {
              height: canvasHeight,
              width: canvasWidth,
              backgroundColor: undefined,
            }
          );
          remoteTmpCanvasRef.current?.setZoom(canvasZoomLevel);
          remoteTmpCanvasRef.current?.on("path:created", (event) => {
            // (For paths created from the remote user events)
            // Remove paths from the remote brush, so they can be added with id to the main canvas
            // @ts-ignore
            remoteTmpCanvasRef.current?.remove(event.path);
          });
          setRemoteTmpCanvas(remoteTmpCanvasRef.current);
        }
        if (!remoteCursorCanvasRef.current) {
          remoteCursorCanvasRef.current = new fabric.Canvas(
            "whiteboard-remoteCursorCanv",
            {
              height: canvasHeight,
              width: canvasWidth,
              backgroundColor: undefined,
            }
          );
          remoteCursorCanvasRef.current?.setZoom(canvasZoomLevel);
        }
        if (!cursorCanvasRef.current) {
          cursorCanvasRef.current = new fabric.Canvas("whiteboard-cursorCanv", {
            height: canvasHeight,
            width: canvasWidth,
            backgroundColor: undefined,
          });
          cursorCanvasRef.current?.setZoom(canvasZoomLevel);
          setCursorCanvas(cursorCanvasRef.current);
        }
      } catch (e) {
        logUnexpectedError(e);
        setError(true);
      } finally {
        setLoading(false);
      }
    })();
  }, [backgroundImageKey]);

  const onReset = () => {
    const objects = canvasRef.current?.getObjects();
    if (objects) {
      canvasRef.current?.remove(...objects);
    }
    emitReset();
    maybeSaveWhiteboardState();
  };

  useSnapshot(rawCanvasRef.current);

  return (
    <div
      data-testid="whiteboard"
      className={clsx(styles.wrapper, {
        [styles.wrapperNoControl]: !userCanControl,
      })}
    >
      <div
        className={clsx(styles.page, {
          [styles.pageNoControl]: !userCanControl,
        })}
      >
        <ActivityNavigationHeader
          peersRef={peersRef}
          onReset={onReset}
          onGoBack={maybeSaveWhiteboardState}
        />
        <div className={styles.whiteboard}>
          <div
            className={clsx(styles.canvasSection, {
              [styles.canvasSectionWithTopAndBottomFree]: widthIsLimiting,
            })}
            ref={canvasSectionRef}
          >
            <div
              className={styles.canvasContainer}
              onPointerLeave={baseOnMouseOut}
            >
              <canvas id="whiteboard-canv" ref={rawCanvasRef} />
              <div className={styles.remoteTmpCanvas}>
                <canvas id="whiteboard-cursorCanv" ref={rawCursorCanvasRef} />
              </div>
              <div className={styles.remoteTmpCanvas}>
                <canvas
                  id="whiteboard-remoteTmpCanv"
                  ref={rawRemoteTmpCanvasRef}
                />
              </div>
              <div className={styles.remoteTmpCanvas}>
                <canvas
                  id="whiteboard-remoteCursorCanv"
                  ref={rawRemoteCursorCanvasRef}
                />
              </div>
            </div>
          </div>
          <WhiteboardControls
            peersRef={peersRef}
            peers={peers}
            canvasRef={canvasRef}
            focusedTextRef={focusedTextRef}
            textSizeRef={textSizeRef}
            emitBrushChange={emitBrushChange}
            selectedOptionRef={selectedOptionRef}
            visibleHeight={visibleHeightState}
            setCursorRadius={setCursorRadius}
            setShowCursor={setShowCursor}
            updateCanvasSize={updateSize}
            is2xZoomEnabled={is2xZoomEnabled}
            is3xZoomEnabled={is3xZoomEnabled}
            zoomLevel={zoomLevel}
            setZoomLevel={setZoomLevel}
            onToggleViewportLock={onToggleViewportLock}
            isFileActivity={!!backgroundImageKey}
          />
          {loading ? (
            <div className={styles.loadingContainer}>
              {" "}
              <LoadingAnimation />{" "}
            </div>
          ) : null}
          {error ? (
            <ConnectionError
              errorMessage={
                "There was an error loading the page. Please try again later."
              }
              loading={false}
              showInviteLink={false}
            />
          ) : null}
          {/* Make sure font gets pre-loaded */}
          <div style={{ fontFamily: "Catamaran", width: 0 }}>&nbsp;</div>
        </div>
      </div>
    </div>
  );
};

export default Whiteboard;

import { useRef, useEffect } from "react";
import { useTrackEvent } from "utils/metricsUtils";
import {
  IssueDetectorResult,
  NetworkScores,
  EventType,
} from "webrtc-issue-detector";
import { flattenObject } from "utils/objectUtils";
import WebRTCIssueDetector, {
  AvailableOutgoingBitrateIssueDetector,
  FramesDroppedIssueDetector,
  FramesEncodedSentIssueDetector,
  FrozenVideoTrackDetector,
  InboundNetworkIssueDetector,
  NetworkMediaSyncIssueDetector,
  OutboundNetworkIssueDetector,
  QualityLimitationsIssueDetector,
  UnknownVideoDecoderImplementationDetector,
} from "webrtc-issue-detector";
import { getAddBreadcrumb } from "utils/errorUtils";
import { useDispatch } from "react-redux";
import {
  selectLowestNetworkScore,
  setLastPeerIssueDetectedTimestamp,
  setLastRemoteIssueDetectedTimestamp,
  setLowestNetworkScore,
} from "redux/spaceNavigationRedux";
import { store } from "redux/reduxStore";

// 5 seconds to check for stats and issues
const WEBRTCISSUESDETECTOR_CHECK_INTERVAL = 5 * 1000;
// only send stats every 6 times (30 seconds)
export const WEBRTCISSUESDETECTOR_SCORES_SKIP_COUNT = 6;
// 3 is the threshold for a healthy score
const HEALTHY_SCORE_THRESHOLD = 3;

export const addBreadcrumb = getAddBreadcrumb("connectionHealth");

export let webRtcIssueDetector: WebRTCIssueDetector | undefined;

export const initializeWebRTCIssueDetector = () => {
  // create it before the first instance of RTCPeerConnection is created
  webRtcIssueDetector = new WebRTCIssueDetector({
    getStatsInterval: WEBRTCISSUESDETECTOR_CHECK_INTERVAL,
    detectors: [
      new QualityLimitationsIssueDetector(),
      new FramesDroppedIssueDetector(),
      new FramesEncodedSentIssueDetector(),
      new InboundNetworkIssueDetector({ highRttThresholdMs: 500 }),
      new OutboundNetworkIssueDetector(),
      new NetworkMediaSyncIssueDetector(),
      new AvailableOutgoingBitrateIssueDetector(),
      new UnknownVideoDecoderImplementationDetector(),
      new FrozenVideoTrackDetector(),
    ],
  });

  // start collecting getStats() and detecting issues
  webRtcIssueDetector.watchNewPeerConnections();
};

export const useWebRTCIssuesDetector = () => {
  const { trackEvent } = useTrackEvent();
  const dispatch = useDispatch();

  const networkScoresCountRef = useRef<{ [connectionID: string]: number }>({});

  const updateLastIssueDetectedTimestampIfNeeded = (scores: NetworkScores) => {
    if (
      (!scores.inbound || scores.inbound >= HEALTHY_SCORE_THRESHOLD) &&
      (!scores.outbound || scores.outbound >= HEALTHY_SCORE_THRESHOLD)
    ) {
      return;
    }

    const state = store.getState();
    const currentLowestScore = selectLowestNetworkScore(state);
    const lowestScore = Math.min(
      scores.inbound || Infinity,
      scores.outbound || Infinity
    );

    if (!currentLowestScore || lowestScore < currentLowestScore) {
      dispatch(setLowestNetworkScore(lowestScore));
    }

    const currentTimestamp = new Date().getTime();
    // Room connection has no outbound score
    // TODO: Remove this check when we have a better way to differentiate between room and peer network scores
    const isRoomNetworkScore = !scores.outbound;
    if (isRoomNetworkScore) {
      dispatch(setLastRemoteIssueDetectedTimestamp(currentTimestamp));
    } else {
      dispatch(setLastPeerIssueDetectedTimestamp(currentTimestamp));
    }
  };

  useEffect(() => {
    const issueHandler = (issues: IssueDetectorResult) => {
      addBreadcrumb("debug", "WebRTC issues", { issues });
      for (const issue of issues) {
        trackEvent(
          "WebRTC Issue Detector Issue",
          flattenObject(issue, "issue")
        );
      }
    };
    const networkScoresHandler = (scores: NetworkScores) => {
      updateLastIssueDetectedTimestampIfNeeded(scores);
      // only track the scores every WEBRTCISSUESDETECTOR_SCORES_SKIP_COUNT times
      // to avoid sending too many events to Mixpanel
      if (scores.connectionId) {
        // Initialize or increment counter
        // Initialize it with our skip count to track the first time
        networkScoresCountRef.current[scores.connectionId] =
          (networkScoresCountRef.current[scores.connectionId] ??
            WEBRTCISSUESDETECTOR_SCORES_SKIP_COUNT) + 1;

        // If we haven't reached the skip count, don't track
        if (
          networkScoresCountRef.current[scores.connectionId] <
          WEBRTCISSUESDETECTOR_SCORES_SKIP_COUNT
        ) {
          return;
        }

        networkScoresCountRef.current[scores.connectionId] = 0;
      }
      addBreadcrumb("debug", "Network scores", { scores });
      trackEvent(
        "WebRTC Issue Detector network scores",
        flattenObject(scores, "scores")
      );
    };
    webRtcIssueDetector?.eventEmitter.on(EventType.Issue, issueHandler);
    webRtcIssueDetector?.eventEmitter.on(
      EventType.NetworkScoresUpdated,
      networkScoresHandler
    );
    return () => {
      webRtcIssueDetector?.eventEmitter.off(EventType.Issue, issueHandler);
      webRtcIssueDetector?.eventEmitter.off(
        EventType.NetworkScoresUpdated,
        networkScoresHandler
      );
    };
  }, []);
};

import React, { MouseEventHandler, useState } from "react";

import {
  useFloating,
  autoUpdate,
  shift,
  flip,
  offset,
} from "@floating-ui/react";

import styles from "./MenuRow.module.css";
import arrowIcon from "assets/icons/arrow.svg";
import clsx from "clsx";

type MenuRowProps = {
  label: string;
  iconSrc?: any;
  onClick?: MouseEventHandler<HTMLDivElement>;
  loading?: boolean;
  elevated?: boolean; // for control bar in fullscreen video mode
  children?: React.ReactNode;
  triggerOpenOnArrow?: boolean;
};

const MenuRow = ({
  label,
  iconSrc,
  onClick,
  loading,
  elevated,
  children,
  triggerOpenOnArrow = false,
}: MenuRowProps) => {
  const [submenuOpened, setSubmenuOpened] = useState(false);

  const { refs, floatingStyles } = useFloating({
    open: submenuOpened,
    placement: "right-start",
    strategy: "fixed",
    whileElementsMounted: autoUpdate,
    middleware: [offset({ mainAxis: -3, crossAxis: -10 }), shift(), flip()],
  });

  const onMouseEnter = () => {
    if (!triggerOpenOnArrow && children) {
      setSubmenuOpened(true);
    }
  };

  const onMouseLeave = () => {
    if (children) {
      setSubmenuOpened(false);
    }
  };

  const onArrowHover = () => {
    if (triggerOpenOnArrow && !submenuOpened) {
      setSubmenuOpened(true);
    }
  };

  const onArrowClick: MouseEventHandler = (event) => {
    if (triggerOpenOnArrow) {
      event.stopPropagation();
    }
  };

  const onSubmenuClick: MouseEventHandler = (event) => {
    event.stopPropagation();
  };

  return (
    <div
      className={styles.container}
      onClick={loading ? undefined : onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={refs.setReference}
    >
      <div className={styles.leftSide}>
        {iconSrc && <img src={iconSrc} className={styles.icon} />}
        <div className={styles.label}>{loading ? "..." : label}</div>
      </div>
      {children ? (
        <img
          src={arrowIcon}
          className={clsx(styles.arrow, {
            [styles.open]: triggerOpenOnArrow && submenuOpened,
          })}
          onMouseOver={onArrowHover}
          onClick={onArrowClick}
        />
      ) : null}
      {submenuOpened ? (
        <div
          className={clsx(styles.submenuContainer, {
            [styles.submenuContainerElevated]: elevated,
          })}
          ref={refs.setFloating}
          style={floatingStyles}
          onClick={onSubmenuClick}
        >
          {children}
        </div>
      ) : null}
    </div>
  );
};

export default MenuRow;

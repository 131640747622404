import { useApolloClient } from "@apollo/client";
import {
  GetSnapshotsDocument,
  useGetCurrentRawSessionIdLazyQuery,
  useInsertResourceSnapshotMutation,
} from "generated/graphql";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentClient } from "redux/clientManagementRedux";
import {
  selectCurrentResourceId,
  selectSnapshotTasks,
  updateSnapshotTask,
} from "redux/spaceNavigationRedux";
import { selectUserId } from "redux/userRedux";
import { uploadFileWithThumbnail } from "utils/fileUtils";
import { useLogSnapshotCount } from "utils/metricsUtils";
import { canvasToBlobImage, videoToBlobImage } from "utils/snapshotUtils";

export const useGetSessionId = () => {
  const providerId = useSelector(selectUserId);
  const currentClient = useSelector(selectCurrentClient);
  const client = useApolloClient();
  const [getCurrentRawSessionIdQuery] = useGetCurrentRawSessionIdLazyQuery({
    fetchPolicy: "cache-first",
  });

  // clear cache whenever currentClient changes.
  // It does not matter if it changes from Client to undefined or
  // from Client to another Client
  useEffect(() => {
    client.cache.evict({ id: "ROOT_QUERY", fieldName: "user_session_raw" });
    client.cache.gc();
  }, [currentClient]);

  return async () => {
    const result = await getCurrentRawSessionIdQuery({
      variables: { providerId },
    });
    return result.data?.user_session_raw[0]?.id;
  };
};

export const useSnapshot = (
  sourceElement: HTMLCanvasElement | HTMLVideoElement | null
) => {
  const snapshotTasks = useSelector(selectSnapshotTasks);
  const currentResourceId = useSelector(selectCurrentResourceId);
  const currentClient = useSelector(selectCurrentClient);
  const [insertResourceSnapshot] = useInsertResourceSnapshotMutation();
  const getSessionId = useGetSessionId();

  const logSnapshotCount = useLogSnapshotCount();

  const dispatch = useDispatch();
  useEffect(() => {
    if (sourceElement) {
      for (const snapshotTask of snapshotTasks) {
        if (snapshotTask.status === "created") {
          dispatch(
            updateSnapshotTask({ ...snapshotTask, status: "generating" })
          );

          const asyncSnapshot = async (
            sourceElement: HTMLCanvasElement | HTMLVideoElement
          ) => {
            if (!currentResourceId) {
              alert(
                "Internal error taking a snapshot, please try again later."
              );
              return;
            }
            if (!currentClient) {
              alert(
                "Internal error taking a snapshot, please try again later."
              );
              return;
            }
            const imageFile =
              sourceElement instanceof HTMLCanvasElement
                ? await canvasToBlobImage(sourceElement)
                : await videoToBlobImage(sourceElement);

            dispatch(
              updateSnapshotTask({
                ...snapshotTask,
                status: "saving",
                image: imageFile,
              })
            );
            const uploadResult = await uploadFileWithThumbnail(
              imageFile,
              "snapshot",
              false
            );
            if (
              !uploadResult ||
              !uploadResult.key ||
              !uploadResult.thumbnailKey
            ) {
              alert(
                "Internal error taking a snapshot, please try again later."
              );
              return;
            }
            const sessionId = await getSessionId();
            const insertResult = await insertResourceSnapshot({
              variables: {
                clientId: currentClient.canonical_id,
                resourceId: currentResourceId,
                sessionRawId: sessionId,
                fileKey: uploadResult.key,
                thumbnailFileKey: uploadResult.thumbnailKey,
              },
              refetchQueries: [
                {
                  query: GetSnapshotsDocument,
                  variables: { clientCanonicalId: currentClient.canonical_id },
                },
              ],
            });
            if (!insertResult.data?.insert_resource_snapshot_one?.id) {
              alert(
                "Internal error taking a snapshot, please try again later."
              );
              return;
            }
            logSnapshotCount();
            dispatch(
              updateSnapshotTask({
                ...snapshotTask,
                status: "saved",
                image: imageFile,
              })
            );
          };

          asyncSnapshot(sourceElement);
        }
      }
    }
  }, [snapshotTasks]);
};

import { Socket } from "socket.io-client";
import { Peers } from "../usePeerWebRTCConnection";
import { useWebRTCIssuesDetector } from "./useWebRTCIssuesDetector";
import { useCollectDeviceInformationMetrics } from "./useCollectDeviceInformationMetrics";
import { useCollectTeleoConnectionStats } from "./useCollectTeleoConnectionStats";

export const useConnectionHealthMonitor = (
  peersRef: React.MutableRefObject<Peers>,
  remoteWebRTCConnectionRef: React.MutableRefObject<
    RTCPeerConnection | undefined
  >,
  remoteDataChannelRef: React.MutableRefObject<RTCDataChannel | undefined>,
  remoteMediaStreamRef: React.MutableRefObject<MediaStream | undefined>,
  remoteSocketRef: React.MutableRefObject<Socket | undefined>,
  gatewaySocketRef: React.MutableRefObject<Socket | undefined>
) => {
  useCollectDeviceInformationMetrics();

  const { getConnectionHealthStatusResults, clearMeasurements } =
    useCollectTeleoConnectionStats(
      peersRef,
      remoteWebRTCConnectionRef,
      remoteDataChannelRef,
      remoteMediaStreamRef,
      remoteSocketRef,
      gatewaySocketRef
    );

  useWebRTCIssuesDetector();

  return {
    getConnectionHealthStatusResults,
    clearMeasurements,
  };
};

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import styles from "pages/Space/components/Videos/VideosButtons/ConnectionQualityIndicator.module.css";
import Tooltip from "components/Tooltip/Tooltip";
import { ReactComponent as PoorConnection } from "assets/icons/poor_connection.svg";
import { ReactComponent as BadConnection } from "assets/icons/bad_connection.svg";
import {
  selectIsFullScreenVideo,
  selectLastPeerIssueDetectedTimestamp,
  selectLastRemoteIssueDetectedTimestamp,
  selectLowestNetworkScore,
  setLowestNetworkScore,
} from "redux/spaceNavigationRedux";
import { selectUserRole, UserRole } from "redux/userRedux";

type ConnectionQualityIndicatorProps = {
  className: string;
  style?: React.CSSProperties;
};

const LAST_ISSUE_DETECTED_THRESHOLD = 10000; // 10 seconds

// Check if the last issue detected is within the threshold
const getHasIssue = (lastIssueDetectedTimestamp: number | undefined) =>
  lastIssueDetectedTimestamp &&
  new Date().getTime() - lastIssueDetectedTimestamp <
    LAST_ISSUE_DETECTED_THRESHOLD;

export const ConnectionQualityIndicator = ({
  className,
  style,
}: ConnectionQualityIndicatorProps) => {
  const clearIssueTimeout = useRef<NodeJS.Timeout | undefined>(undefined);
  const dispatch = useDispatch();
  const [hasRemoteIssue, setHasRemoteIssue] = useState(false);
  const [hasPeerIssue, setHasPeerIssue] = useState(false);
  const userRole = useSelector(selectUserRole);
  const isTherapist = userRole === UserRole.THERAPIST;
  const isFullScreen = useSelector(selectIsFullScreenVideo);
  const lowestNetworkScore = useSelector(selectLowestNetworkScore);
  const lastRemoteIssueDetectedTimestamp = useSelector(
    selectLastRemoteIssueDetectedTimestamp
  );
  const lastPeerIssueDetectedTimestamp = useSelector(
    selectLastPeerIssueDetectedTimestamp
  );

  useEffect(() => {
    clearIssueTimeout.current = setTimeout(() => {
      setHasRemoteIssue(false);
      setHasPeerIssue(false);
      dispatch(setLowestNetworkScore(undefined));
    }, LAST_ISSUE_DETECTED_THRESHOLD);

    // TODO: We keep track of both the remote and peer lowest score to determine the best copy.
    // Before release, we simplified the copy to just "Bad Internet" and "Slow Internet".
    // For now, we will keep this logic in case the copy should be improved in the short-term
    // but we can clean up in the future if the copy is unchanged.
    if (getHasIssue(lastRemoteIssueDetectedTimestamp)) {
      setHasRemoteIssue(true);
    }
    if (getHasIssue(lastPeerIssueDetectedTimestamp)) {
      setHasPeerIssue(true);
    }

    return () => {
      clearTimeout(clearIssueTimeout.current);
      clearIssueTimeout.current = undefined;
    };
  }, [lastRemoteIssueDetectedTimestamp, lastPeerIssueDetectedTimestamp]);

  if (!hasRemoteIssue && !hasPeerIssue) {
    return null;
  }

  const isBadConnection = lowestNetworkScore && lowestNetworkScore < 2;

  const tooltipText = (
    <>
      <b>{isBadConnection ? "Bad" : "Slow"} Internet</b>
      <br />
      Try moving to where the signal is stronger
      {isTherapist && (
        <>
          <br />
          or turn off everyone&apos;s camera
        </>
      )}
    </>
  );

  return (
    <div className={className} style={style}>
      <Tooltip text={tooltipText} defaultTooltipIsShown>
        <div
          className={clsx(styles.connectionQualityIndicator, {
            [styles.fullScreenButton]: isFullScreen,
          })}
        >
          {isBadConnection ? (
            <BadConnection
              className={clsx(styles.icon, styles.badConnection)}
            />
          ) : (
            <PoorConnection className={styles.icon} />
          )}
        </div>
      </Tooltip>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  pushWaitingRoomAlertClients,
  selectWaitingRoomAlertClients,
} from "redux/clientManagementRedux";
import {
  useGetWaitingRoomNamesQuery,
  useGetWaitingRoomParticipantsQuery,
} from "generated/graphql";
import { selectUserId } from "redux/userRedux";
import { WaitingRoomAlert } from "./WaitingRoomAlert";
import { SharedResourceAlert } from "./SharedResourceAlert";
import styles from "./Alerts.module.css";
import {
  selectCurrentResourceId,
  selectMeetingID,
} from "redux/spaceNavigationRedux";
import { Peers } from "pages/Space/hooks/connection/usePeerWebRTCConnection";

type AlertsProps = {
  peersRef: React.MutableRefObject<Peers>;
};

export const Alerts = ({ peersRef }: AlertsProps) => {
  const userId = useSelector(selectUserId);
  const meetingID = useSelector(selectMeetingID);
  const waitingRoomAlertClients = useSelector(selectWaitingRoomAlertClients);
  const currentResourceId = useSelector(selectCurrentResourceId);
  const [hasBeenInitialized, setHasBeenInitialized] = useState(false);
  const dispatch = useDispatch();

  const { data: allParticipantsData } = useGetWaitingRoomParticipantsQuery({
    variables: {
      meetingID: meetingID ?? "",
    },
    skip: !meetingID || !userId,
  });

  useEffect(() => {
    if (!hasBeenInitialized && allParticipantsData) {
      dispatch(
        pushWaitingRoomAlertClients(
          allParticipantsData?.waiting_room_participant.map(
            (participant) => participant.client_id
          )
        )
      );
      setHasBeenInitialized(true);
    }
  }, [allParticipantsData]);

  const { data } = useGetWaitingRoomNamesQuery({
    variables: {
      meetingID: meetingID ?? "",
      secretClientIds: waitingRoomAlertClients,
    },
    skip: !meetingID || !userId,
  });

  if (!userId) {
    return null;
  }

  return (
    <div className={styles.container}>
      {data?.waiting_room_participant.map((participant, index) => {
        const clientId = participant.client_id;
        const name = participant.client_name;
        return (
          <WaitingRoomAlert
            key={index}
            secretClientId={clientId}
            name={name}
            peersRef={peersRef}
          />
        );
      })}
      {currentResourceId && (
        <SharedResourceAlert resourceId={currentResourceId} />
      )}
    </div>
  );
};
